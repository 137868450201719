import React from "react";
import { StyledCard } from "./ContentCard.styles";
import HelpOutlineSharpIcon from "@material-ui/icons/HelpOutlineSharp";
// import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import TouchAppIcon from "@material-ui/icons/TouchApp";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import HeadsetIcon from "@material-ui/icons/Headset";
// import DescriptionIcon from "@material-ui/icons/Description";
// import HtmlIcon from "../../svg/HtmlIcon";
import config from "../../../aws-exports";

import { ReactComponent as DocumentIcon } from "../../../assets/svg/doc.svg";
import HtmlIcon from "@material-ui/icons/Code";
import Subject from "@material-ui/icons/Subject";
// import Interactivity from "../../svg/Interactivity";
import { makeStyles } from "@material-ui/styles";
// import { ReactComponent as Audio } from "../../../assets/svg/Audio.svg";
import {
  Bookmark,
  BookmarkBorderOutlined,
  QueryBuilderOutlined,
  StarRounded,
} from "@material-ui/icons";
import { CardContent } from "@material-ui/core";

const useStyles = makeStyles({
  buttonBookmark: {
    border: "none",
    background: "transparent",
    cursor: "pointer",
    display: "flex",
    "&:focus": {
      border: "2px solid black",
    },
    "& > svg": {
      fontSize: "2.5rem",
    },
  },
});

const renderIcon = (param) => {
  if (param === "video" || param === "youtube" || param === "vimeo") {
    return (
      <PlayCircleOutlineIcon style={{ fill: "black", fontSize: "3rem" }} />
    );
  } else if (param === "pdf") {
    return <DocumentIcon style={{ fill: "black", height: "3rem" }} />;
  } else if (param === "Interactivity") {
    return <TouchAppIcon style={{ fill: "black", fontSize: "3rem" }} />;
  } else if (param === "audio") {
    return <HeadsetIcon style={{ fill: "black", fontSize: "3rem" }} />;
  } else if (param === "quiz") {
    return <HelpOutlineSharpIcon style={{ fill: "black", fontSize: "3rem" }} />;
  } else if (param === "scorm") {
    return (
      <PlayCircleOutlineIcon style={{ fill: "black", fontSize: "3rem" }} />
    );
  } else if (param === "html") {
    return <HtmlIcon style={{ fill: "black", fontSize: "3rem" }} />;
  }
};
const renderBgColor = (param) => {
  if (param === "video" || param === "youtube" || param === "vimeo") {
    return "#E2D2FE!important";
  } else if (param === "pdf" || param === "html") {
    return "#FDE1AB!important";
  } else if (param?.toLowerCase() === "interactivity") {
    return " #CCF0BF!important";
  } else if (param === "quiz") {
    return "#FFD27E!important";
  } else if (param === "scorm") {
    return "#BAE5F4!important";
  } else if (param === "audio") {
    return "#EECFCF!important";
  }
};
export default function ContentCard({ otype, title, dur, tid, ...rest }) {
  const classes = useStyles();

  return (
    <StyledCard color={renderBgColor(otype)} style={{ color: "black" }}>
      <CardContent className="card-content" onClick={rest.handleLocation}>
        <div className="card-content--header">
          {renderIcon(otype)}
          <label
            className="card-content--header-star-container"
            title="star-rating"
            aria-label="star rating block"
          >
            {config.ratingFeature && <StarRounded className="star" />}
            {config.ratingFeature && (
              <span className="rating" style={{ margin: "4px" }}>
                {rest?.star ? rest.star.toFixed(1) : "0.0"}
              </span>
            )}
          </label>
        </div>
        <div className="para-container">
          <p>{title}</p>
        </div>
      </CardContent>
      <div className="card-actions">
        <div className="card-actions--footer">
          {!rest.bookmarkPage && (
            <div className="card-actions--footer-time">
              <QueryBuilderOutlined className="clock-icon" />
              <DurationMinAndSec duration={dur} />
            </div>
          )}
          {rest?.bookmarkPage && (
            <div className="bookmark_date-container">
              <p className="bookmark_date-text">Bookmark Date</p>
              <span>:</span>
              <p className="bookmark_date-date">{rest.date}</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <button
              className={classes.buttonBookmark}
              onClick={rest.onClick}
              title="bookmarked"
            >
              <Bookmark className="bookmark-icon fill" />
            </button>
          )}
          {rest?.bookmark && !rest?.bookmarkPage && (
            <button
              className={classes.buttonBookmark}
              onClick={() => rest.handleBookmark(1, tid, rest.index)}
              title="bookmarked"
            >
              <Bookmark className="bookmark-icon fill" />
            </button>
          )}
          {!rest?.bookmark && !rest?.bookmarkPage && (
            <button
              className={classes.buttonBookmark}
              onClick={() => rest.handleBookmark(0, tid, rest.index)}
              title="bookmark"
            >
              <BookmarkBorderOutlined />
            </button>
          )}
        </div>
      </div>
    </StyledCard>
  );
}

const DurationMinAndSec = ({ duration }) => {
  let min = Math.floor(duration / 60)
    .toString()
    .padStart(2, "0");
  let sec = Math.floor(duration % 60)
    .toString()
    .padStart(2, "0");

  if (isNaN(min) && isNaN(sec)) {
    min = "00";
    sec = "00";
  }

  return (
    <span>
      {min}m {sec}s
    </span>
  );
};
