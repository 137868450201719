// FileUploader.jsx

import React, { useRef } from "react";
import "./FileUploader.css"; // Create a CSS file for styling
import AddIcon from "@mui/icons-material/Add";

const FileUploader = ({ files, setFiles, value }) => {
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();

    const droppedFiles = Array.from(e.dataTransfer.files);

    // Filter out unsupported files
    const supportedFiles = droppedFiles.filter((file) => {
      return value === "2" ? file.type.includes("pdf") : (file.type.includes("image") || file.type.includes("video"));
    });

    // Display an error message for unsupported files
    const unsupportedFiles = droppedFiles.filter(
      (file) => !supportedFiles.includes(file)
    );
    if (unsupportedFiles.length > 0) {
      alert(
        `Unsupported file(s): ${unsupportedFiles
          .map((file) => file.name)
          .join(", ")}`
      );
    }

    setFiles([...files, ...supportedFiles]);
  };

  const handleRemove = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleAddFile = () => {
    // Trigger the file input when the "Add File" button is clicked
    fileInputRef.current.click();
  };

  const handleFileInputChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    // Filter out unsupported files
    const supportedFiles = selectedFiles.filter((file) => {
      return value === "2" ? file.type.includes("pdf") : (file.type.includes("image") || file.type.includes("video"));
    });

    // Display an error message for unsupported files
    const unsupportedFiles = selectedFiles.filter(
      (file) => !supportedFiles.includes(file)
    );
    if (unsupportedFiles.length > 0) {
      alert(
        `Unsupported file(s): ${unsupportedFiles
          .map((file) => file.name)
          .join(", ")}`
      );
    }

    setFiles([...files, ...supportedFiles]);
  };

  return (
    <div
      className="file-uploader"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
    >
      <div className="file-container">
        {files.map((file, index) => (
          <div key={file.name} className="file-item">
            {file.type.includes("image") ? (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                className="file-preview"
              />
            ) : file.type.includes("video") ? (
              <video controls className="file-preview">
                <source src={URL.createObjectURL(file)} type={file.type} />
                Your browser does not support the video tag.
              </video>
            ) : (
              <p>{file.name}</p>
            )}
            <button
              className="remove-button"
              onClick={() => handleRemove(index)}
            >
              &#x2716;
            </button>
          </div>
        ))}
      </div>
      <div className="add-file-container" style={{display: "flex",justifyContent: "center"}}>
        <button className="add-file-button" onClick={handleAddFile}>
          <AddIcon style={{ fontSize: "2.5rem" }} />
          Add File
        </button>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileInputChange}
          multiple
          accept={value === "2" ? "application/pdf" : "image/*,video/*"}
        />
      </div>
      <p style={{color: "black", fontSize: "1.5rem", margin: "1rem 0rem"}}>Drag and drop files here</p>
    </div>
  );
};

export default FileUploader;
