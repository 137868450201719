import React, { useState, useEffect } from "react";
import {
  Button,
  CardMedia,
  Typography,
  Fade,
  CircularProgress,
  Modal,
  Box,
  FormControlLabel,
  Radio,
  Grid,
  RadioGroup,
  TextField,
  TextareaAutosize,
  Link,
  createStyles,
} from "@material-ui/core";
import swal from "sweetalert";

import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import { API, Auth } from "aws-amplify";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Slider from "@mui/material/Slider";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/styles";

const StyledButton = withStyles({
  root: {
    background:
      "linear-gradient(45deg, var(--color-button-background) 90%,var(--color-button-background) 30%)",
    borderRadius: 3,
    border: 0,
    color: "white",
    height: 38,
    padding: "0 30px",
    boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .2)",
    fontSize: "16px",
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

function FeedbackPopUp({
  courseDetails,
  openFeedback,
  handleOpenFeedback,
  setOpenFeedback,
  CheckFeedbackSubmitted,
  feedbackSubmitted,
}) {
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.73)",
      paddingTop: "1%",
    },
    modalSection: {
      // backgroundColor: theme.palette.background.paper,
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      // border: "2px solid #fff",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "80%",
      height: "80%",
      borderRadius: ".8rem",
      overflowX: "hidden",
      overflowY: "auto",
      "@media (max-width: 500px)": {
        width: "90%!important",
      },
      "@media (max-width: 770px)": {
        padding: "1rem",
      },
    },
    paper1: {
      // backgroundColor: theme.palette.background.paper,
      // border: "2px solid #fff",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "400px",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      borderRadius: ".8rem",
      "& > div > button > svg": {
        color:
          userDetails?.themeDark === "dark"
            ? theme.palette.background.paper
            : "black",
      },
      "@media (max-width: 430px)": {
        width: "90%",
      },
    },
    ratingroot: {
      //width: 300,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "1.5rem",
      "& > span": {
        margin: 0,
      },
    },
    quizroot: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    MuiGriditem: {
      padding: "0 12px",
    },
    quizpaper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    certcover: {
      height: "100%",
    },
    overlay: {
      backgroundImage:
        "linear-gradient(to right bottom, rgba(0,0,0,.2), rgba(0,0,0,.2))",
      width: "1100px",
      height: "660px",
    },
    label: {
      "& .MuiTypography-body1": {
        fontSize: "1.3rem",
        color: "#555555",
        fontWeight: "500",
        overflow: "hidden !important",
      },
    },
    labelSurvey: {
      "& .MuiTypography-body1": {
        fontSize: "1.25rem",
        color: "#000",
        fontWeight: "600",
        overflow: "hidden !important",
      },
      "& .MuiTypography-body1::after": {
        content: "*",
        color: "red",
      },
    },
    label2: {
      "&.MuiFormControlLabel-root": {
        paddingLeft: "30px",
        display: "flex",
        flexDirection: "column",
      },
      "&.MuiTypography-body1": {
        fontSize: "1.3rem",
      },
    },
    contentType: {
      "& .PrivateSwitchBase-input-118": {
        "& ::after": {
          content: "hi !important",
        },
      },
    },
    barResponsive: {
      height: "25px",
      borderRight: "2px solid #000",
      marginLeft: "2rem",
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    infoResponsive: {
      display: "flex",
      height: "inherit",
      // width: "50px",
      margin: "0 2rem",
      background: "transparent",
      border: "none",
      "@media (max-width: 500px)": {
        margin: "0rem 0.5rem",
      },
      "&:focus": {
        border: "2px solid black",
      },
    },
    closeResponsive: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
      display: "flex",
      marginRight: "-20px",
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      "@media (max-width: 770px)": {
        marginRight: 0,
      },
      "&:focus": {
        border: "2px solid black",
      },
    },
    headerSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    mainHeader: {
      display: "flex",
      flexWrap: "wrap",
      fontWeight: "600",
      alignItems: "center",
      paddingRight: "4rem",
      //marginBottom: "1rem",
      //marginTop:"2rem",
      // '@media (max-width: 500px)': {
      //   width: '60%',
      // },
      // '@media (max-width: 365px)': {
      //   width: '55%',
      // },
    },
    documentSection: {
      marginTop: "10px",
      width: "95%",
      height: "-webkit-fill-available",
      position: "absolute",
      marginBottom: "6rem",
      //paddingBottom: '20px',
      "@media (max-width: 330px)": {
        width: "100%",
      },
    },
    downloadIcon: {
      position: "absolute",
      right: "55px",
      //top: "2%",
      background: "transparent",
      border: "none",
      height: "2.3rem",
      width: "2.3rem",
      marginLeft: "2rem",
      cursor: "pointer",
      //marginTop: ".5rem",
      zIndex: "10000",
      "@media (max-width: 400px)": {
        right: "40px",
      },
    },
    separator: {
      height: "27px",
      borderRight: "2px solid #333333",
      marginLeft: "2rem",
      "@media (max-width: 500px)": {
        display: "none",
      },
    },
    videoContainer: {
      display: "contents",
    },
    videoInnerContainer: {
      width: "90%!important",
      height: "88%!important",
      margin: "2rem auto 0rem!important",
      "@media (max-width: 700px)": {
        width: "100%!important",
        height: "70%!important",
      },
    },
    rateCourseButton: {
      fontSize: "1.5rem",
      fontWeight: "600",
      lineHeight: "1.6rem",
      backgroundColor: "white!important",
      "&:focus": {
        border: "2px solid black",
        // backgroundColor: "white",
        color: "black",
      },
    },
    rateCourseSubmitButton: {
      fontWeight: "600",
      fontFamily: `myfontregular`,
      "&:focus": {
        border: "2px solid black",
        backgroundColor: "black",
        color: "white",
      },
    },
    pdfContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "88%",
      overflowX: "hidden",
      position: "relative",
      marginTop: "1rem",
      "& > div": {
        width: "100%",
        height: "100%",
        "& > iframe": {
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        },
      },
    },

    // Feedback form Modal CSS start
    feedbackModalStyle: {
      boxShadow: theme.shadows[5],
      width: "80vw",
      height: "80vh",
      overflowY: "auto",
      overflowX: "hidden",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
      borderRadius: ".8rem",
      "@media (max-width: 430px)": {
        width: "90%",
      },
    },
    feedbackHeaderSection: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "2rem",
      padding: "16px 20px 24px",
      zIndex: "99",
      width: "80vw",
      position: "fixed",
      boxShadow:
        "rgba(50,50,93,0.25) 0px 6px 12px -2px, rgba(0,0,0,0.3) 0px 3px 7px -3px",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "#272C38"
          : theme.palette.background.paper,
      "@media (max-width: 430px)": {
        width: "90%",
        padding: "16px 12px 4px",
      },
    },
    feedbackCloseButton: {
      border: "none",
      background: "transparent",
      cursor: "pointer",
      color:
        userDetails?.themeDark === "dark"
          ? theme.palette.background.paper
          : "black",
    },
    feedbackTextarea: {
      width: "80%",
      fontSize: "1.5rem!important",
      margin: "1rem auto",
      height: "10rem",
      backgroundColor:
        userDetails?.themeDark === "dark"
          ? "black!important"
          : theme.palette.background.paper,
      color: userDetails?.themeDark === "dark" ? "white!important" : "black",
    },
    feedbackSlider: {
      textAlign: "center",
      width: "70%!important",
      "& > span": {
        fontSize: "1.2rem",
        color: userDetails?.themeDark === "dark" ? "white!important" : "auto",
      },
      "@media (max-width: 770px)": {
        width: "95%!important",
      },
    },
    feedbackSubHeading: {
      textAlign: "left",
      margin: "5rem 0rem 3rem",
    },
    feedbackLabel: {
      fontSize: "1.2rem",
      fontWeight: "600",
      "@media (max-width: 770px)": {
        width: "4rem",
        fontSize: "1rem",
      },
    },
    feedbackLabelSection: {
      display: "flex",
      justifyContent: "space-between",
      width: "79%",
      margin: "auto",
      "@media (max-width: 770px)": {
        width: "100%",
      },
    },
    // Feedback form Modal CSS ends
  }));
  const classes = useStyles();
  const [state, setState] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorQuestion, setErrorQuestion] = useState("");
  const [questLoad, setQuestLoad] = useState(true);
  const [question, setQuestion] = useState({});

  const [userFeedback, setUserFeedback] = useState({
    question1: 0,
    question2: 0,
    question3: 0,
    question4: 0,
    question5: 0,
    question6: 0,
    question7: 0,
    question8: 0,
    question9: 0,
    question10: 0,
    question11: 0,
    question12: 0,
    question13: "",
  });

  const [errors, setErrors] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
    question6: "",
    question7: "",
    question8: "",
    question9: "",
    question10: "",
    question11: "",
    question12: "",
  });

  useEffect(() => {
    CheckFeedbackSubmitted(courseDetails);
    getFeedback_question();
  }, []);

  async function getFeedback_question() {
    setQuestLoad(true);
    // const bodyParam = {
    //   body: {
    //     lang_code: userDetails.uData.lang,

    //     ur_id: userDetails.uData.ur_id,
    //     schema: config.schema,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };

    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getFeedbackQuestion",
    //     bodyParam
    //   );

    try {
      const requestBody = {
        lang_code: userDetails.uData.lang,
        ur_id: userDetails.uData.ur_id,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getFeedbackQuestion?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      setQuestion(response);
      setQuestLoad(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function handleFeedback() {
    let obj = {};
    obj.json = userFeedback;
    setLoading(true);

    const jsonString = JSON.stringify({
      feed_json: obj,

      tid: courseDetails.tid,

      oid: config.aws_org_id,

      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);

    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/addUserFeedback",
        bodyParam
      );

      

      //setLoading(false);

      swal({
        title: `${userDetails?.languagejson?.Course_View?.Success ||
          "Success"}`,
        text: `${userDetails?.languagejson?.Course_View?.Feedback_Submited ||
          "Feedback Submited"}`,
        icon: "success",
        dangerMode: false,
      }).then((willDelete) => {
        if (willDelete) {
          handleCloseFeedback();
          CheckFeedbackSubmitted(courseDetails);
        }
      });

      //window.location.reload();
    } catch (error) {
      console.error(error);
    }
  }

  const handleCloseFeedback = () => {
    setOpenFeedback(false);
  };

  const marks = [
    {
      value: 5,
      label: "5",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 1,
      label: "1",
    },
  ];
  const marksTen = [
    {
      value: 10,
      label: "10",
    },
    {
      value: 9,
      label: "9",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 7,
      label: "7",
    },
    {
      value: 6,
      label: "6",
    },
    {
      value: 5,
      label: "5",
    },
    {
      value: 4,
      label: "4",
    },
    {
      value: 3,
      label: "3",
    },
    {
      value: 2,
      label: "2",
    },
    {
      value: 1,
      label: "1",
    },
  ];

  let quest = {
    segment: [
      {
        title: "Relevance",
        question: [
          "The course content was of relevance to me.",
          "Received a good and relevant overview of the subject",
          "Deeper insight on the specific subject was provided, as was the objective of the subject",
          "Concepts learnt can be applied",
        ],
      },
      {
        title: "Structure",
        question: [
          "Structure of the program content and delivery was easy to follow",
          "The pace of the program was just right",
        ],
      },
      {
        title: "Anchor",
        question: [
          "The Anchor was impressive and engaging",
          "The Anchor had deep knowledge of the subject",
          "The Anchor shared examples, and ideas to help us apply learnings shared in the session",
          "The Anchor can be rated as",
        ],
      },
      {
        title: "Overall Score",
        question: ["Overall, the Course was delivered well"],
      },
    ],
    overall_rating: {
      quest:
        "Considering your experience with this training: How likely are you to recommend this program to your friend or colleague?",
    },
  };

  function valuetext(value) {
    return `${value}`;
  }

  const handleRatingChange = (question, value) => {
    setUserFeedback((prevFeedback) => ({
      ...prevFeedback,
      [question]: value,
    }));
  };
  const handleTextareaChange = (event) => {
    const value = event.target.value;
    setUserFeedback((prevFeedback) => ({
      ...prevFeedback,
      question13: value,
    }));
  };

  const handleSubmit = () => {
    let hasErrors = false;
    const updatedErrors = { ...errors };

    /*  // Validate each question
    Object.keys(userFeedback).forEach((question) => {
      if (userFeedback[question] === 0) {
        updatedErrors[question] = "Please provide a rating";
        hasErrors = true;
      } else {
        updatedErrors[question] = "";
      }
    });

    if (userFeedback.question13.trim() === "") {
      hasErrors = true;
      setErrorQuestion("Please provide feedback");
    } */

    if (hasErrors) {
      setErrors(updatedErrors);
      return;
    }

    if (!hasErrors) {
      handleFeedback();
    }
  };

  return (
    <>
      <Modal
        className={classes.modal}
        open={openFeedback}
        onClose={handleCloseFeedback}
      >
        <Fade in={openFeedback}>
          <div className={classes.feedbackModalStyle}>
            <div className={classes.feedbackHeaderSection}>
              <Typography
                variant="h2"
                id="transition-modal-title"
                style={{ fontSize: "2.5rem" }}
              >
                {`${userDetails?.languagejson?.Course_View?.Feedback_header ||
                  "Please provide your feedback"}`}
              </Typography>
              <button
                onClick={handleCloseFeedback}
                className={classes.feedbackCloseButton}
              >
                <CloseIcon fontSize="large" />
              </button>
            </div>
            {feedbackSubmitted ? (
              <div style={{ textAlign: "center", padding: "200px" }}>
                {`${userDetails?.languagejson?.Course_View?.Response_recorded ||
                  "Your response has already been recorded"}`}
              </div>
            ) : questLoad ? (
              <div></div>
            ) : (
              <div style={{ padding: "2rem", margin: "6rem 0rem 3rem" }}>
                <div style={{ padding: "0rem 0rem 3rem" }}>
                  <h3 style={{ margin: "3rem 0rem" }}>
                    {`${question?.segment[0]?.title}`} :
                  </h3>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ textAlign: "left", margin: "3rem 0rem" }}>
                      {`${question?.segment[0]?.question[0]}`}
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question1}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question1", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question1 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question1}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[0]?.question[1]}`}
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question2}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question2", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question2 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question2}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[0]?.question[2]}`} :
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question3}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question3", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question3 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question3}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[0]?.question[3]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question4}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question4", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                </div>
                {errors.question4 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.question4}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    padding: "0rem 0rem 3rem",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <h3 style={{ margin: "3rem 0rem" }}>
                    {`${question?.segment[1]?.title}`}:
                  </h3>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ textAlign: "left", margin: "0rem 0rem 3rem" }}>
                      {`${question?.segment[1]?.question[0]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question5}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question5", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question5 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question5}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[1]?.question[1]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question6}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question6", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                </div>
                {errors.question6 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.question6}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    padding: "0rem 0rem 3rem",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <h3 style={{ margin: "3rem 0rem" }}>
                    {`${question?.segment[2]?.title}`} :
                  </h3>
                  <div style={{ textAlign: "center" }}>
                    <p style={{ textAlign: "left", margin: "0rem 0rem 3rem" }}>
                      {`${question?.segment[2]?.question[0]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question7}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question7", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question7 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question7}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[2]?.question[1]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question8}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question8", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question8 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question8}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[2]?.question[2]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question9}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question9", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                  {errors.question9 && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ fontSize: "14px" }}
                      >
                        {errors.question9}
                      </Typography>
                    </div>
                  )}
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[2]?.question[3]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question10}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question10", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                </div>
                {errors.question10 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.question10}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    padding: "0rem 0rem 3rem",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <h3 style={{ margin: "3rem 0rem" }}>
                    {`${question?.segment[3]?.title}`} :
                  </h3>
                  <div style={{ textAlign: "center" }}>
                    <p className={classes.feedbackSubHeading}>
                      {`${question?.segment[3]?.question[0]}`},
                    </p>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question11}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marks}
                      valueLabelDisplay="on"
                      min={0}
                      max={5}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question11", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.segment[0]?.degree[1]}`}</p>
                    </div>
                  </div>
                </div>
                {errors.question11 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.question11}
                    </Typography>
                  </div>
                )}
                <div
                  style={{
                    padding: "0rem 0rem 3rem",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <h3 style={{ margin: "3rem 0rem" }}>
                    {`${userDetails?.languagejson?.Course_View
                      ?.Provide_Feedback || "Provide_Feedback"}`}
                  </h3>
                  <div style={{ textAlign: "center" }}>
                    <textarea
                      name="Outlined"
                      placeholder="Type in here…"
                      variant="outlined"
                      minRows={5}
                      maxRows={10}
                      value={userFeedback.question13}
                      className={classes.feedbackTextarea}
                      onChange={handleTextareaChange}
                    />
                  </div>
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    variant="caption"
                    color="error"
                    style={{ fontSize: "14px" }}
                  >
                    {errorQuestion}
                  </Typography>
                </div>
                <div
                  style={{
                    padding: "0rem 0rem 3rem",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <h4 style={{ margin: "3rem 0rem" }}>
                    {`${question?.overall_rating.quest}`},
                  </h4>
                  <div style={{ textAlign: "center" }}>
                    <Slider
                      aria-label="Always visible"
                      value={userFeedback.question12}
                      getAriaValueText={valuetext}
                      step={1}
                      marks={marksTen}
                      valueLabelDisplay="on"
                      min={0}
                      max={10}
                      className={classes.feedbackSlider}
                      onChange={(event, value) =>
                        handleRatingChange("question12", value)
                      }
                    />
                    <div className={classes.feedbackLabelSection}>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.overall_rating?.degree[0]}`}</p>
                      <p
                        className={classes.feedbackLabel}
                      >{`${question?.overall_rating?.degree[1]}`}</p>
                    </div>
                  </div>
                </div>
                {errors.question12 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="caption"
                      color="error"
                      style={{ fontSize: "14px" }}
                    >
                      {errors.question12}
                    </Typography>
                  </div>
                )}
                <div style={{ textAlign: "center" }}>
                  <StyledButton
                    className={classes.rateCourseSubmitButton}
                    onClick={handleSubmit}
                  >
                    {`${userDetails?.languagejson?.Course_View?.Submit ||
                      "Submit"}`}
                  </StyledButton>
                </div>
              </div>
            )}
          </div>
        </Fade>
      </Modal>{" "}
    </>
  );
}

export default FeedbackPopUp;
