import React from 'react';

const renderIcon = (param, ostatus) => {
    switch(param) {
        case 'video':
          return <div className={"bgimage videoiconDardk "+ostatus}></div>;
        case 'youtube':
          return <div className={"bgimage videoiconDardk "+ostatus}></div>;
        case 'vimeo':
          return <div className={"bgimage videoiconDardk "+ostatus}></div>;
        case 'audio':
          return <div className={'bgimage audioiconDardk '+ostatus}></div>;
        case 'pdf':
          return <div className={'bgimage pdficonDardk '+ostatus}></div>;
        case  'interactivity':
          return <div className={'bgimage intericonDardk '+ostatus}></div>;
        case 'scorm':
          return <div className={'bgimage scormiconDardk '+ostatus}></div>;
         case 'html':
            return <div className={'bgimage htmliconDardk '+ostatus}></div>;
         case 'feedback':
              return <div className={'bgimage htmliconDardk '+ostatus}></div>;
         case 'quiz':
              return <div className={'bgimage quiziconDardk '+ostatus}></div>;
        default:
          return param;
      }
  };
const Renderimage = ({type, ostatus}) => {
  return (
    <>{ostatus == 0 ? (<>
       {renderIcon(type,"graybgcolor")}
    </>) : (<>
      {renderIcon(type,"darkbgcolor")}
    </>)}
   
    </>
    );
    };
    
export default Renderimage;