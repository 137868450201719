import React, { useState, useEffect } from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { motion, AnimatePresence } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import { API } from "aws-amplify";
import { Breadcrumbs, Grid, makeStyles } from "@material-ui/core";
//React router
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import ExploreSort from "./ExploreSort/ExploreSort";
import ExploreFilter from "./ExploreFilter/ExploreFilter";
import ProgramExploreSort from "./ExploreSort/ProgramExploreSort";
import ContentCard from "../../components/Cards/ContentCard/ContentCard";
function Explore() {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(3),
      height: "600px",
      width: "1100px",
      backgroundColor: "white",
    },
    heading: {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontSize: "12px",
    },
    gridContent: {
      margin: "2rem 0rem 0rem",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#282D37 !important"
          : theme.palette.background.paper,
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "auto",
      padding: "2rem",
      borderRadius: "5px",
      "& > div > div": {
        margin: 0,
      },
    },
  }));
  const classes = useStyles();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [ctype, setCtypeF] = useState("");
  const [adProductF, setAdProductF] = useState("");
  const [mrToolsF, setMrToolsF] = useState("");
  const [durF, setDurF] = useState("");
  const [anchorElS, setAnchorElS] = useState(null);
  const [openS, setOpenS] = useState(false);
  const [stype, setStypeF] = useState("");
  const [stypeV, setStypeFV] = useState("Newest");
  const [pstypeV, setPStypeFV] = useState("Newest");
  const [pstype, setPStypeF] = useState("CON");
  const [oObjectData, setOObjectData] = useState([]);
  const [programData, setProgramData] = useState([]);
  const [objectData, setObjectData] = useState([]);
  const [objectType, setObjectType] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [initialCourseData, setInitialCourseData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [progressType, setProgressType] = useState([]);
  const [courses, setCourses] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    } else if (userDetails.curExploreData.type == "program") {
      setProgramData(userDetails.curExploreData.data);
      if (userDetails.filter !== undefined && userDetails.filter.setPStypeF) {
        setPStypeF(userDetails.filter.setPStypeF);
        sortFun(userDetails.filter.setPStypeF, userDetails.curExploreData.data);
        commansetsort(userDetails.filter.setPStypeF);
      }
    } else if (userDetails.curExploreData.type == "content") {
      if (
        userDetails.filter !== undefined &&
        userDetails.filter.setctypeF !== undefined
      ) {
        setAdProductF(userDetails.filter.setAdProductF);
        setMrToolsF(userDetails.filter.setMrToolsF);
        setDurF(userDetails.filter.setDurF);
        setCtypeF(userDetails.filter.setctypeF);
        setStypeF(userDetails.filter.setStypeF);
        if (
          userDetails.filter.setdurF != "" ||
          userDetails.filter.setctypeF != ""
        ) {
          commansetsort(userDetails.filter.setStypeF);
          filter(userDetails.filter.setctypeF, userDetails.filter.setdurF);
        } else if (
          userDetails.filter.setStypeF !== undefined &&
          userDetails.filter.setStypeF != ""
        ) {
          sortFun(
            userDetails.filter.setStypeF,
            userDetails.curExploreData.data
          );
          commansetsort(userDetails.filter.setStypeF);
        }
      } else if (userDetails.curExploreData.from != "search") {
        let ctp =
          userDetails.filter === undefined ||
          userDetails.filter.setStypeF === undefined
            ? "CON"
            : userDetails.filter.setStypeF;
        setStypeFV("Newest");
        setStypeF(ctp);
        sortFun(ctp, userDetails.curExploreData.data);
        commansetsort(ctp);
      } else if (userDetails.curExploreData.from == "search") {
        let ctp =
          userDetails.filter === undefined ||
          userDetails.filter.setStypeF === undefined
            ? "CON"
            : userDetails.filter.setStypeF;
        setStypeF(ctp);
        sortFun(ctp, userDetails.curExploreData.data);
        commansetsort(ctp);
        setStypeFV("Select");
      }
    } else {
      if (userDetails.curExploreData.type === "Courses") {
        setCourses(userDetails.curExploreData.courses);
      }
    }
    const storedSelectedOptions =
      JSON.parse(localStorage.getItem("selectedOptions")) || [];
    const storedCategoryType =
      JSON.parse(localStorage.getItem("categoryType")) || [];
    const storedProgressType =
      JSON.parse(localStorage.getItem("progressType")) || [];
    setSelectedOptions(storedSelectedOptions);
    setCategoryType(storedCategoryType);
    setProgressType(storedProgressType);
    getCategoryData({
      storedSelectedOptions,
      storedCategoryType,
      storedProgressType,
    });
  }, []);
  async function getCategoryData(param) {
    const jsonData = {
      limit: 50,
      page: 1,
      type: ["objects"],
      schema: config.schema,
      tid: userDetails?.curExploreData?.topicId,
      oid: config.aws_org_id,
      tenant: userDetails?.uData?.oid,
      admin: false,
      ur_id: userDetails?.uData?.ur_id,
      groups: [16],
      user_lang: userDetails?.uData?.lang,
      progtypes:
        param.funcCall === "clear"
          ? []
          : (param.funcCall !== "apply"
            ? param.storedProgressType.length !== 0
            : null)
          ? param.storedProgressType
          : progressType,
      objtypes:
        param.funcCall === "clear"
          ? []
          : (param.funcCall !== "apply"
            ? param.storedSelectedOptions.length !== 0
            : null)
          ? param.storedSelectedOptions
          : selectedOptions,
      dur: null,
    };
    // Convert JSON to a string and encode it
    const jsonString = encodeURIComponent(JSON.stringify(jsonData));
    // Append the encoded string to the base URL
    const baseUrl = `/getCategoryDetails`; // Replace with your actual URL
    const fullUrl = `${baseUrl}?queryjson=${jsonString}`;
    try {
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        fullUrl,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken
          },
        }
      );
      if (response !== undefined) {
        let { objects, courses } = response;
        setInitialData(objects);
        let copyObject = [...objects];
        setObjectType(userDetails.filterObj[0]);
        let temp = [];
        let OCourse = [];
        for (let i = 0; i < courses?.length; i++) {
          if (
            courses[i].pid != undefined &&
            courses[i].pid !== 0 &&
            courses[i].pid.replace(/\s/g, "").length != 0
          ) {
            temp.push(courses[i]);
          } else {
            OCourse.push(courses[i]);
          }
        }
        OCourse?.sort((a, b) => b["con"] - a["con"]);
        setInitialCourseData(OCourse);
        copyObject?.sort((a, b) => b["con"] - a["con"]);
        setObjectData(copyObject);
        setOObjectData(copyObject);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function programClick(PNAME, PID, courses) {
    let sdata = { ...userDetails };
    sdata.curExploreProgramData = {
      from: userDetails.curExploreData.from !== "search" ? "explore" : "search",
      PNAME: PNAME,
      PID: PID,
      courses: courses,
      TNAME: userDetails.curExploreData.topicName,
      TOPICID: userDetails.curExploreData.topicId,
    };
    sdata.location = {
      from: sdata.location.from,
      topicName: sdata.location.topicName,
      topicId: sdata.location.topicId,
      viewAll: sdata.location.viewAll,
      programName: PNAME,
      programId: PID,
    };
    dispatch(awsSignIn(sdata));
    history.push(`/ExploreCourseView?id=${PID}`);
  }
  let breadcrumb = { ...userDetails };
  const handleLocation = (OD, OBJID) => {
    breadcrumb.location = {
      from: breadcrumb.location.from,
      topicName: breadcrumb.location.topicName,
      contentName: OD.ON,
      topicId: breadcrumb.location.topicId,
      viewAll: true,
      viewAllRoute: "/ExploreViewAll",
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`/content?id=${OBJID}`);
  };
  const handleCourseLocation = (TID, TN) => {
    breadcrumb.location = {
      prevLoc: userDetails.location.from === "Home" ? "Home" : "Explore",
      from: userDetails.location.from,
      topicName: userDetails.curExploreData.topicName,
      courseName: TN,
      topicId: userDetails.curExploreData.topicId,
      viewall: true,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`course/${TID}`);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(!open);
  };
  const contentTypeChange = (event) => {
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setDurF = durF;
    filterstoredata.filter.setMrToolsF = mrToolsF;
    if (userDetails.filter === undefined) {
      filterstoredata.filter.setCDurF = "";
      filterstoredata.filter.vtype = "content";
      filterstoredata.filter.setCMrToolsF = "";
    } else {
      if (filterstoredata.filter.vtype !== undefined) {
        filterstoredata.filter.vtype = userDetails.filter.vtype;
      } else {
        filterstoredata.filter.vtype = "content";
      }
      if (userDetails.filter.setCDurF !== undefined) {
        filterstoredata.filter.setCDurF = userDetails.filter.setCDurF;
      } else {
        filterstoredata.filter.setCDurF = "";
      }
      if (userDetails.filter.setCMrToolsF !== undefined) {
        filterstoredata.filter.setCMrToolsF = userDetails.filter.setCMrToolsF;
      } else {
        filterstoredata.filter.setCMrToolsF = "";
      }
    }
    filterstoredata.filter.setMrToolsF = mrToolsF;
    let val = event.target.value;
    if (ctype == event.target.value) {
      setCtypeF("");
      val = "";
      filterstoredata.filter.setCtypeF = "";
    } else {
      setCtypeF(event.target.value);
      filterstoredata.filter.setCtypeF = event.target.value;
    }
    filterstoredata.filter.setStypeF = stype;
    filterstoredata.filter.setAdProductF = adProductF;
    if (userDetails.filter === undefined) {
      filterstoredata.filter.setCStypeF = "";
      filterstoredata.filter.setPStypeF = "";
      filterstoredata.filter.setCAdProductF = "";
    } else {
      filterstoredata.filter.setCStypeF = userDetails.filter.setCStypeF;
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setCAdProductF = userDetails.filter.setCAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
    dispatch(awsSignIn(filterstoredata));
    filter(val, durF);
  };
  const DurHandleChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setStypeF = stype;
    if (durF == event.target.value) {
      setDurF("");
      val = "";
      filterstoredata.filter.setDurF = "";
    } else {
      setDurF(event.target.value);
      filterstoredata.filter.setDurF = event.target.value;
    }
    if (userDetails.filter !== undefined) {
      filterstoredata.filter.setCDurF = userDetails.filter.setCDurF;
      filterstoredata.filter.setCMrToolsF = userDetails.filter.setCMrToolsF;
      filterstoredata.filter.setCStypeF = userDetails.filter.setCStypeF;
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setCAdProductF = userDetails.filter.setCAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
    dispatch(awsSignIn(filterstoredata));
    filter(ctype, val);
  };
  function filter(otype, dur) {
    let data = [...userDetails.curExploreData.data];
    let filteredData = data.filter(function(e) {
      let res = false;
      let o = false;
      let d = false;
      if (otype != "") {
        if (otype == e.otype) {
          o = true;
        }
        if (
          otype == "video" &&
          (e.otype == "scorm" || e.otype == "youtube" || e.otype == "vimeo")
        ) {
          o = true;
        }
      }
      if (dur != "") {
        if (dur == "5") {
          if (e.dur < 300) {
            d = true;
          }
        } else if (dur == "15") {
          if (e.dur < 900) {
            d = true;
          }
        } else if (dur == "30") {
          if (e.dur < 1800) {
            d = true;
          }
        } else if (dur == "30_60") {
          if (e.dur >= 1800 && e.dur <= 3600) {
            d = true;
          }
        } else if (dur == "60") {
          if (e.dur > 3600) {
            d = true;
          }
        }
      }
      // All Value
      if (otype != "" && dur != "") {
        if (o && d) {
          res = true;
        }
      }
      // otype
      if (otype != "" && dur == "") {
        if (o) {
          res = true;
        }
      }
      // dur
      if (otype == "" && dur != "") {
        if (d) {
          res = true;
        }
      }
      if (otype == "" && dur == "") {
        res = true;
      }
      return res;
    });
    setObjectData(filteredData);
    let valstype = stype;
    if (userDetails.filter !== undefined) {
      valstype = userDetails.filter.setStypeF;
    } else if (valstype == "") {
      valstype = "CON";
    }
    sortFun(valstype, filteredData);
    setOpenSnackbar(true);
  }
  function clear() {
    setCategoryType([]);
    setSelectedOptions([]);
    setProgressType([]);
    setObjectData(oObjectData);
    setCtypeF("");
    setAdProductF("");
    setMrToolsF("");
    setDurF("");
    setAnchorEl(null);
    setOpen(!open);
    setStypeF(stype);
    setStypeFV(stypeV);
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    sortFun(stype, userDetails.curExploreData.data);
    getCategoryData({ funcCall: "clear" });
  }
  const handleClickS = (event) => {
    setAnchorElS(event.currentTarget);
    setOpenS(!openS);
  };
  const handleCloseS = () => {
    setAnchorElS(null);
    setOpenS(!openS);
  };
  const sortTypeChange = (event) => {
    let val = event.target.value;
    let filterstoredata = { ...userDetails };
    filterstoredata.filter = {};
    if (userDetails.curExploreData.type == "program") {
      sortFun(val, userDetails.curExploreData.data);
      setPStypeF(event.target.value);
      filterstoredata.filter.setPStypeF = event.target.value;
      filterstoredata.filter.setStypeF = stype;
    } else {
      sortFun(val, objectData);
      setStypeF(event.target.value);
      filterstoredata.filter.setStypeF = event.target.value;
    }
    commansetsort(val);
    filterstoredata.filter.setMrToolsF = mrToolsF;
    filterstoredata.filter.setAdProductF = adProductF;
    filterstoredata.filter.setCtypeF = ctype;
    filterstoredata.filter.setDurF = durF;
    if (userDetails.filter !== undefined) {
      filterstoredata.filter.setPStypeF = userDetails.filter.setPStypeF;
      filterstoredata.filter.setCDurF = userDetails.filter.setCDurF;
      filterstoredata.filter.setCMrToolsF = userDetails.filter.setCMrToolsF;
      filterstoredata.filter.setCStypeF = userDetails.filter.setCStypeF;
      filterstoredata.filter.setCAdProductF = userDetails.filter.setCAdProductF;
      filterstoredata.filter.odata = userDetails.filter.odata;
      filterstoredata.filter.cdata = userDetails.filter.cdata;
    }
    dispatch(awsSignIn(filterstoredata));
  };
  function sortFun(val, obdata, vtype) {
    let data = obdata == undefined ? [...objectData] : [...obdata];
    if (vtype == "program") {
      data.sort((a, b) => b[val] - a[val]); //.reverse();
      setProgramData(data);
    } else {
      if (val == "AZ") {
        data.sort((a, b) => {
          if (a.oname < b.oname) {
            return -1;
          }
          if (a.oname > b.oname) {
            return 1;
          }
          return 0;
        });
      } else if (val == "TV") {
        data.sort((a, b) => {
          return b.sortdt.tv - a.sortdt.tv;
        });
      } else if (val == "BKMD") {
        data.sort((a, b) => {
          return b?.sortdt?.bookmark - a?.sortdt?.bookmark;
        });
      } else if (val == "SHRD") {
        data.sort((a, b) => {
          return b.sortdt.share - a.sortdt.share;
        });
      } else if (val == "STAR") {
        data.sort((a, b) => {
          return b.sortdt.rate - a.sortdt.rate;
        });
      } else if (val == "CON") {
        data.sort((a, b) => {
          return b["con"] - a["con"];
        });
      } else {
        data.sort((a, b) => b[val] - a[val]); //.reverse();
      }
      setObjectData(data);
    }
  }
  const commansetsort = (val) => {
    if (userDetails.curExploreData.type == "program") {
      if (val == "TV") {
        setPStypeFV("Most Viewed");
      }
      if (val == "CON") {
        setPStypeFV("Newest");
      }
    } else {
      if (val == "TV") {
        setStypeFV("Most Viewed");
      }
      if (val == "STAR") {
        setStypeFV("Rating");
      }
      if (val == "BKMD") {
        setStypeFV("Most Bookmarked");
      }
      if (val == "SHRD") {
        setStypeFV("Most Shared");
      }
      if (val == "CON") {
        setStypeFV("Newest");
      }
      if (val == "AZ") {
        setStypeFV("A-Z");
      }
    }
  };
  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ fontSize: "1.6rem" }}
      />
    );
  }
  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  return (
    <div>
      {userDetails !== 0 && (
        <>
          {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "Search") 
              && (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "var(--color-primary)" }}
                  />
                }
                aria-label="breadcrumb"
                style={{ marginBottom: "3rem" }}
              >
                <Link
                  color="inherit"
                  to={userDetails.location.from === "Home" ? "/home" : "#"}
                  style={{
                    color: `${
                      userDetails.location.from === "Home" ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                  }}
                >
                  {userDetails.location.from}
                </Link>
                {userDetails.location.topicName && (
                  <Link
                    style={{
                      color: "#1974b5",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                    color="inherit"
                    to={`/explore/${userDetails.location.topicName}/${userDetails.location.topicId}`}
                  >
                    {userDetails.location.topicName}
                  </Link>
                )}
                {userDetails.location.viewAll && (
                  <Link
                    color="inherit"
                    to="/ExploreViewAll"
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                    }}
                  >
                    {`${userDetails?.languagejson?.Home_Page?.View_all ||
                      "View all"}`}
                  </Link>
                )}
              </Breadcrumbs>
            )
            }
          <div className="flex-between">
            <div className="flex-container" style={{ width: "100%" }}>
              <h3 style={{ width: "100%", fontWeight: "600" }}>
                {config.aws_org_id === "SPANDANA"
                  ? "Reading Material"
                  : "Content Repository"}
              </h3>
            </div>
            {userDetails.curExploreData.type === "program" ? (
              <div>
                <ProgramExploreSort
                  sortTypeChange={sortTypeChange}
                  pstypeV={pstypeV}
                  pstype={pstype}
                  stype={userDetails.curExploreData.from}
                />
              </div>
            ) : null}
            {userDetails.curExploreData.type !== "program" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <div>
                  <ExploreSort
                    handleClickS={handleClickS}
                    anchorElS={anchorElS}
                    handleCloseS={handleCloseS}
                    sortTypeChange={sortTypeChange}
                    stypeV={stypeV}
                    openS={openS}
                    stype={stype}
                    sorttp={userDetails.curExploreData.from}
                  />
                </div>
                <span>
                  {
                    <ExploreFilter
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      handleClose={handleClose}
                      contentTypeChange={contentTypeChange}
                      adProductF={adProductF}
                      DurHandleChange={DurHandleChange}
                      open={open}
                      ctype={ctype}
                      clear={clear}
                      mrToolsF={mrToolsF}
                      durF={durF}
                      type="content"
                      objectType={objectType}
                      initialCourseData={initialCourseData}
                      initialData={initialData}
                      setInitialData={setInitialData}
                      selectedOptions={selectedOptions}
                      setSelectedOptions={setSelectedOptions}
                      setProgressType={setProgressType}
                      progressType={progressType}
                      getCategoryData={getCategoryData}
                      categoryType={categoryType}
                      setCategoryType={setCategoryType}
                    />
                  }
                </span>
              </div>
            ) : null}
          </div>
          <Grid container spacing={2} className={classes.gridContent}>
            {programData !== undefined
              ? programData.map(({ PNAME, PID, courses }, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    key={idx + "hello"}
                    onClick={() => {
                      programClick(PNAME, PID, courses);
                    }}
                  >
                    <CardLarge
                      pid={PID}
                      ttitle={PNAME}
                      course={courses.length}
                      pcount={true}
                    />
                  </Grid>
                ))
              : null}
            {objectData !== undefined &&
              objectData.map(({ objid, otype, oname, dur, sortdt }, idx) => {
                let OD = {
                  OTYPE: otype,
                  ON: oname,
                  DUR: dur,
                };
                return (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    onClick={() => {
                      handleLocation(OD, objid);
                    }}
                  >
                    <ContentCard
                      otype={otype}
                      star={sortdt.rate}
                      title={oname}
                      dur={dur}
                    />
                  </Grid>
                );
              })}
            {courses !== undefined ? (
              <>
                {courses.map(({ tn, TID, TNUGGETS }, idx) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    onClick={() => handleCourseLocation(TID, tn)}
                  >
                    <CardLarge
                      tid={TID}
                      title={tn}
                      type={"topic"}
                      course={TNUGGETS}
                    />
                  </Grid>
                ))}
              </>
            ) : null}
          </Grid>
          {objectData.length === 0 && programData.length === 0 ? (
            <div
              style={{
                height: "75vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "grey",
              }}
            >
              <AnimatePresence>
                <motion.div
                  className="slick-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "easeInOut" },
                  }}
                >
                  <img
                    src={noThingIsHere}
                    alt="Nothing is here yet"
                    title="Nothing is here yet"
                    style={{ width: "30rem" }}
                  />
                </motion.div>
              </AnimatePresence>
            </div>
          ) : null}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={openSnackbar}
            autoHideDuration={5000}
            onClose={handleCloseA}
            message="Copied the link..!"
          >
            <Alert onClose={handleCloseA} severity="success">
              {objectData.length} Results
            </Alert>
          </Snackbar>
        </>
      )}
    </div>
  );
}
export default Explore;
