import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';
import {
  Stack, Typography, Breadcrumbs, Table,
  TableBody, TableCell, tableCellClasses, TableContainer,
  TableRow, TableHead, Paper, Button,
  Box, Tab, Tabs, Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import AddBoxIcon from '@mui/icons-material/AddBox';
import AddIcon from '@mui/icons-material/Add';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
// Sample Image Importation
import avatar1 from "../../assets/male1.png";
import avatar2 from "../../assets/male2.png";
import avatar3 from "../../assets/male3.png";
import goldIcon from "../../assets/svg/gold.svg";
import SilverIcon from "../../assets/svg/Silver.svg";
import bronzeIcon from "../../assets/svg/bronze.svg";
import CrownIcon from "../../assets/svg/Crown_UI.svg";
import LeaderBoardStand from "../../assets/svg/LeaderBoard_stand.svg";
import JSConfetti from 'js-confetti';
import { useQuery } from '@tanstack/react-query';
import { fetchData } from "../../api/api";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: "60%",
    maxWidth: 'none',
    height: "80vh",
    "@media (max-width: 750px)": {
      width: "90%",
    },
  },
  '& .MuiDialogContent-root': {
    padding: "1rem 2rem",
  },
  '& .MuiDialogTitle-root': {
    padding: "1rem 1rem 1rem 2rem",
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));
function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
function Leaderboard() {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    loaderSkeletonTabs: {
      width: "90vw",
      height: "30rem",
    },
    LPMainContainer: {
      width: "100%",
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "0 0 10px 0 hsla(0,0%,40%,.3607843137)",
      backgroundColor: userDetails.themeDark === "dark" ? "#272C38 !important" : theme.palette.background.paper,
    },
    paper: {
      padding: "1rem",
      paddingBottom: "2rem",
      marginBottom: "2rem",
      boxShadow: "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%)!important",
    },
    buttonSection: {
      textAlign: "center",
      margin: "1rem",
      width: "100%",
    },
    primaryButton: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.6rem!important",
      fontFamily: `myfontregular!important`,
      textTransform: "capitalize!important",
    },
    buttonSectionTable: {
      textAlign: "end",
      paddingBottom: "1rem",
      fontFamily: `myfontregular!important`,
    },
    cardSmall: {
      gap: "3rem",
      display: "flex",
      padding: "5rem 2rem 0rem",
      alignItems: "end",
      justifyContent: "center",
      "@media (max-width: 600px)": {
        gap: "1rem",
        padding: "3rem 2rem 0rem",
      },
    },
    cardSmallEven: {
      margin: "0rem",
      position: "relative",
      padding: "3rem",
      textAlign: "center",
      display: "grid",
      gap: "1rem",
      height: "30rem",
      width: "23rem",
      boxShadow: userDetails.themeDark === "dark" ? "0px 1px 15px 2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important" : "0 0 14px 0 hsla(0,0%,50%,.4)!important",
      backgroundColor: userDetails.themeDark === "dark" ? "#353944 !important" : theme.palette.background.paper,
      "@media (max-width: 600px)": {
        gap: "0.5rem",
        padding: "2rem",
        height: "auto",
        padding: "1rem",
        "& > *": {
          fontSize: "1rem",
        },
      },
    },
    cardSmallOdd: {
      margin: "0rem 2rem",
      position: "relative",
      padding: "2rem 4rem",
      textAlign: "center",
      display: "grid",
      gap: "1rem",
      height: "25rem",
      width: "20rem",
      bottom: "-7rem",
      boxShadow: userDetails.themeDark === "dark" ? "0px 1px 15px 2px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important" : "0 0 14px 0 hsla(0,0%,50%,.4)!important",
      backgroundColor: userDetails.themeDark === "dark" ? "#353944 !important" : theme.palette.background.paper,
      "@media (max-width: 770px)": {
        top: "4rem",
        padding: "2rem",
      },
      "@media (max-width: 600px)": {
        gap: "0.5rem",
        height: "auto",
        margin: "0rem",
        padding: "1rem",
        top: "2rem",
        "& > *": {
          fontSize: "1rem",
        },
      },
    },
    avatarIcon: {
      width: "60%",
      margin: "auto",
    },
    avatarIconTable: {
      width: "6rem",
    },
    badgeIcon: {
      position: "absolute",
      top: "-2.5rem",
      width: "5.5rem",
      right: "-2.5rem",
    },
    avatarIconGold: {
      width: "65%",
      margin: "auto",
    },
    badgeIconGold: {
      position: "absolute",
      top: "-3rem",
      width: "6rem",
      right: "-3rem",
    },
    LeaderBoardStandStyle: {
      textAlign: "center",
      "& > img": {
        width: "70%",
        "@media (max-width: 1420px)": {
          width: "80%",
        },
        "@media (max-width: 1155px)": {
          width: "100%",
        },
      },
    },
    crownIconStyle: {
      position: "absolute",
      bottom: "12rem",
      width: "65%",
      "@media (max-width: 600px)": {
        bottom: "6rem",
      },
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor: userDetails.themeDark === "dark" ? "#1C2027 !important" : theme.palette.background.paper,
        color: userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "& > h2 > button ": {
          color: userDetails.themeDark === "dark" ? "white !important" : "#1C2027 !important",
          "& > svg ": {
            fontSize: "2rem",
          },
        },
      },
    },
    dialogPaper: {
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "0 0 14px 0 hsla(0,0%,50%,.4)!important",
      backgroundColor: userDetails.themeDark === "dark" ? "#272C38 !important" : theme.palette.background.paper,
      color: userDetails.themeDark === "dark" ? "white !important" : "#272C38 !important",
      paddingBottom: "2rem",
      overflow: "auto",
      margin: "1rem 0rem",
      "& > div": {
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #8a8a8a !important",
        padding: "1rem",
        margin: "1rem",
      },
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      "@media (max-width: 850px)": {
        display: "block",
      },
    },
    dotStyle: {
      fontSize: "1.5rem",
      marginRight: "1rem",
      color: "gray",
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color: userDetails.themeDark === "dark" ? "white !important" : "black !important",
      fontFamily: `myfontregular!important`,
    },
    mainTab: {
      textTransform: "capitalize!important",
      fontSize: "2rem!important",
      paddingTop: "0!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "800!important",
    },
    mainDialogDelete: {
      "& > div > div": {
        margin: 0,
        height: "42vh!important",
        width: "60rem!important",
        backgroundColor: userDetails.themeDark === "dark" ? "#1C2027 !important" : theme.palette.background.paper,
        color: userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "@media (max-width: 650px)": {
          width: "93%!important",
          height: "46vh!important",
        },
        "& > h2 > button ": {
          color: userDetails.themeDark === "dark" ? "white !important" : "#1C2027 !important",
        },
        "& > svg ": {
          fontSize: "2rem",
        },
      },
    },
    buttonSectionDelete: {
      textAlign: "center",
      display: "flex",
      gap: "3rem",
      margin: "2rem 1rem",
      width: "100%",
      justifyContent: "center",
    },
    secondaryButton: {
      color: "var(--color-button-background)!important",
      fontSize: "1.6rem!important",
      fontFamily: `myfontregular!important`,
      textTransform: "capitalize!important",
      backgroundColor: "transparent!important",
      border: "2px solid var(--color-button-background)!important",
    },
    youStyle: {
      backgroundColor: "#ffdd7e7d",
      padding: "0.5rem 1.5rem",
      borderRadius: "8px",
      border: "1px solid #FFBC00",
      fontWeight: "700",
      fontSize: "1.7rem",
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--color-button-background)",
      color: theme.palette.common.white,
      fontSize: "1.5rem",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1.5rem",
      color: userDetails.themeDark === "dark" ? "white !important" : "#3F434D !important",
      borderBottom: "1px solid",
      borderColor: userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(even)': {
      backgroundColor: userDetails.themeDark === "dark" ? "#1b212f!important" : theme.palette.action.hover,
    },
    '&:nth-of-type(odd)': {
      backgroundColor: userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  const classes = useStyles();
  const [userObject, setUserObject] = React.useState();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const { isLoading, error, data, refetch } = useQuery(['getmdata'], async () => {
    const requestBody = {
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.uData?.oid,
      schema:config?.schema
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getLeaderboardData?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      }
    );
    return response;
  });
  const { isLoading1, uerror, data: users, refetch: userrefetch } = useQuery(['getudata'], async () => {
    const requestBody = {
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.uData?.oid,
      schema:config.schema
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getUsersList?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      }
    );
    return response;
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleUpdateUser = async (item) => {
    var body = {
      ur_id: userDetails?.uData?.ur_id,
      myfriend: item.ur_id,
      tenant: userDetails?.uData?.oid,
    }, apiname = '/updateMyFriend';
    await fetchData(body, apiname, userDetails);
    swal({
      title: "",
      text: `Your friend ${item.first_name} added successfully`,
      icon: "success",
    });
    refetch();
    userrefetch();
  };
  const handleClose = () => {
    setOpen(false);
  };
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}>
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography key="3" color="text.primary" style={{
      color: "#777",
      fontSize: "1.5rem",
      textDecoration: "none",
      cursor: "context-menu",
    }}>
      {`${userDetails?.languagejson?.header?.Leaderboard || "Leaderboard"}`}
    </Typography>,
  ];
  useEffect(() => {
    const jsConfetti = new JSConfetti();
    jsConfetti.addConfetti({
      emoji: {CrownIcon}
    }).then(() => jsConfetti.addConfetti());
    jsConfetti.addConfetti({
      confettiColors: [
        '#fdad35', '#f9b642', '#f8be4d', '#f4c658', '#f3cd62', '#efd88d',
      ],
    });
  }, []);
  const [open1, setOpen1] = useState(false);
  const handleClickOpen1 = (row) => {
    setUserObject(row);
    setOpen1(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleDelete = async () => {
    var body = {
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.uData?.oid,
      myfriend: userObject?.ur_id,
      delete: true,
    }, apiname = '/updateMyFriend';
    fetchData(body, apiname, userDetails);
    swal({
      title: "",
      text: `Your friend ${userObject.first_name} removed successfully`,
      icon: "success",
    });
    setOpen1(false);
    refetch();
    userrefetch();
  }
  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="large" style={{ color: "var(--color-primary)" }} />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="flex-between">
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {isLoading ? (
              <Skeleton className={classes.loaderSkeleton} />
            ) : (
              <>{`${userDetails?.languagejson?.header?.Leaderboard || "Leaderboard"}`}</>
            )}
          </h3>
        </div>
      </div>
      {isLoading ? (<>
        <Skeleton className={classes.loaderSkeletonTabs} />
      </>
      ) : (
        <div className={classes.root}>
          <div className={classes.LPMainContainer}>
            <TabContext value={value}>
              <Box sx={{
                borderBottom: 1,
                backgroundColor: "transparent",
                borderColor: "divider",
                overflow: "auto!important",
                "& > div": {
                  '@media (max-width: 768px)': {
                    width: "70vh",
                  },
                  "& > div > div > .Mui-selected": {
                    background: userDetails.themeDark === "dark" ? "linear-gradient(1deg, white, #d4eaff) !important" : "linear-gradient(1deg, #d4eaff, transparent)",
                    color: "var(--color-button-background)!important",
                  },
                },
              }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  indicatorColor="primary"
                  textColor="inherit"
                  variant="fullWidth"
                >
                  <Tab
                    label={`${userDetails?.languagejson?.Leaderboard?.Individual || "Individual"}`}
                    value="1"
                    className={classes.mainTab}
                    icon={<PersonIcon style={{ fontSize: 28, position: "relative", left: "-65px", top: "25px", margin: 0 }} />}
                  />
                  <Tab
                    label={`${userDetails?.languagejson?.Leaderboard?.Personal || "Personal"}`}
                    value="2"
                    className={classes.mainTab}
                    icon={<GroupsIcon style={{ fontSize: 30, position: "relative", left: "-65px", top: "27px", margin: 0 }} />}
                  />
                </Tabs>
              </Box>
              {/* Tab 1 */}
              <TabPanel value="1" className={classes[".css-13xfq8m-MuiTabPanel-root"]}>
                <div style={{ position: "relative", width: "100%", }}>
                  {data?.iusers && data?.iusers.length > 0 && <Box className={classes.cardSmall}>
                    {data?.iusers && data?.iusers.length > 1 && <Paper className={classes.cardSmallOdd}>
                      <img src={avatar1} alt="Avatar Name" title="Avatar Name" className={classes.avatarIcon} />
                      <h3>{data?.iusers[1].first_name}</h3>
                      <p>{data?.iusers[1].points} {`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</p>
                      <h4>{`${userDetails?.languagejson?.Leaderboard?.Level || "Level"}`} {data?.iusers[1].levels ?? 0}</h4>
                    </Paper>}
                    <Paper className={classes.cardSmallEven}>
                      <div style={{ position: "relative" }}>
                        <img src={CrownIcon} alt="Crown Icon" title="Crown Icon" className={classes.crownIconStyle} />
                        <img src={avatar3} alt="Avatar Name" title="Avatar Name" className={classes.avatarIconGold} />
                      </div>
                      <h2>{data?.iusers[0].first_name}</h2>
                      <p>{data?.iusers[0].points} {`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</p>
                      <h3>{`${userDetails?.languagejson?.Leaderboard?.Level || "Level"}`} {data?.iusers[0].levels ?? 0}</h3>
                    </Paper>
                    {data?.iusers && data?.iusers.length > 2 && <Paper className={classes.cardSmallOdd}>
                      <img src={avatar2} alt="Avatar Name" title="Avatar Name" className={classes.avatarIcon} />
                      <h3>{data?.iusers[2].first_name}</h3>
                      <p>{data?.iusers[2].points} {`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</p>
                      <h4>{`${userDetails?.languagejson?.Leaderboard?.Level || "Level"}`} {data?.iusers[2].levels ?? 0}</h4>
                    </Paper>}
                  </Box>}
                  <div className={classes.LeaderBoardStandStyle}>
                    <img src={LeaderBoardStand} alt="Leader Board Stand" title="Leader Board Stand" />
                  </div>
                </div>
                <canvas id="confetti" style={{height: 0}}></canvas>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      {data?.leaderboard !== undefined && <TableRow>
                        <StyledTableCell align="center"></StyledTableCell>
                        {data?.leaderboard[3]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Rank || "Rank"}`}</StyledTableCell>}
                        {data?.leaderboard[0]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Name || "Name"}`}</StyledTableCell>}
                        {data?.leaderboard[5]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Level || "Level"}`}</StyledTableCell>}
                        {data?.leaderboard[4]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.coins || "Coins"}`}</StyledTableCell>}
                      </TableRow>}
                    </TableHead>
                    <TableBody>
                      {data?.iusers?.map((row) => (
                        <StyledTableRow key={row.ur_id}>
                          <StyledTableCell component="th" scope="row" align="center" style={{ width: '10px' }}>
                            {row.ur_id === userDetails?.uData?.ur_id &&
                              <div className={classes.youStyle}>
                                {`${userDetails?.languagejson?.Leaderboard?.You || "You"}`}
                              </div>
                            }
                          </StyledTableCell>
                          {data?.leaderboard[3]?.lb_enable && <StyledTableCell component="th" scope="row" align="center">
                            #{row.rank}
                          </StyledTableCell>}
                          {data?.leaderboard[0]?.lb_enable && <StyledTableCell align="center">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2rem" }}>
                              {row.first_name}
                            </div>
                          </StyledTableCell>}
                          {data?.leaderboard[5]?.lb_enable && <StyledTableCell align="center">{row.levels ?? 0}</StyledTableCell>}
                          {data?.leaderboard[4]?.lb_enable && <StyledTableCell align="center">{row.points}</StyledTableCell>}
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </TabPanel>
              {/* Tab 2 */}
              <TabPanel value="2" className={classes[".css-13xfq8m-MuiTabPanel-root"]}>
                <div>
                  <div className={classes.buttonSectionTable}>
                    <Button variant="contained" className={classes.primaryButton} onClick={handleClickOpen}>
                      {`${userDetails?.languagejson?.Leaderboard?.Add_Friend || "Add Friend"}`}
                      <AddBoxIcon style={{ fontSize: "2.2rem", marginLeft: "0.6rem" }} />
                    </Button>
                  </div>
                  <div style={{ position: "relative", width: "100%", }}>
                    {data?.pusers && data?.pusers.length > 0 && <Box className={classes.cardSmall}>
                      {data?.pusers && data?.pusers.length > 1 && <Paper elevation={3} className={classes.cardSmallOdd}>
                        <img src={avatar1} alt="Avatar Name" title="Avatar Name" className={classes.avatarIcon} />
                        <h3>{data?.pusers[1].first_name}</h3>
                        <p>{data?.pusers[1].points} {`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</p>
                        <h4>Level {data?.pusers[1].levels ?? 0}</h4>
                        <img src={SilverIcon} alt="Silver Badge" title="Silver Badge" className={classes.badgeIcon} />
                      </Paper>}
                      <Paper elevation={3} className={classes.cardSmallEven}>
                        <div style={{ position: "relative" }}>
                          <img src={CrownIcon} alt="Crown Icon" title="Crown Icon" className={classes.crownIconStyle} />
                          <img src={avatar3} alt="Avatar Name" title="Avatar Name" className={classes.avatarIconGold} />
                        </div>
                        <h2>{data?.pusers[0].first_name}</h2>
                        <p>{data?.pusers[0].points} {`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</p>
                        <h3>Level {data?.pusers[0].levels ?? 0}</h3>
                      </Paper>
                      {data?.pusers && data?.pusers.length > 2 && <Paper elevation={3} className={classes.cardSmallOdd}>
                        <img src={avatar2} alt="Avatar Name" title="Avatar Name" className={classes.avatarIcon} />
                        <h3>{data?.pusers[2].first_name}</h3>
                        <p>{data?.pusers[2].points} {`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</p>
                        <h4>Level {data?.pusers[2].levels ?? 0}</h4>
                        <img src={bronzeIcon} alt="Bronze Badge" title="Bronze Badge" className={classes.badgeIcon} />
                      </Paper>}
                    </Box>}
                    <div className={classes.LeaderBoardStandStyle}>
                      <img src={LeaderBoardStand} alt="Leader Board Stand" title="Leader Board Stand" />
                    </div>
                  </div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                      <TableHead>
                        {data?.leaderboard !== undefined && <TableRow>
                          <StyledTableCell align="center"></StyledTableCell>
                          {data?.leaderboard[3]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Rank || "Rank"}`}</StyledTableCell>}
                          {data?.leaderboard[0]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Name || "Name"}`}</StyledTableCell>}
                          {data?.leaderboard[5]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Level || "Level"}`}</StyledTableCell>}
                          {data?.leaderboard[4]?.lb_enable && <StyledTableCell align="center" style={{ fontFamily: `myfontregular`, fontWeight: "800" }}>{`${userDetails?.languagejson?.Leaderboard?.Coins || "Coins"}`}</StyledTableCell>}
                          <StyledTableCell align="center"></StyledTableCell>
                        </TableRow>}
                      </TableHead>
                      <TableBody>
                        {data?.pusers?.map((row) => (
                          <StyledTableRow key={row.ur_id}>
                            <StyledTableCell component="th" scope="row" align="center" style={{ width: '10px' }}>
                              {row.ur_id === userDetails?.uData?.ur_id &&
                                <div className={classes.youStyle}>
                                  {`${userDetails?.languagejson?.Leaderboard?.You || "You"}`}
                                </div>
                              }
                            </StyledTableCell>
                            {data?.leaderboard[3]?.lb_enable && <StyledTableCell component="th" scope="row" align="center">
                              #{row.rank}
                            </StyledTableCell>}
                            {data?.leaderboard[0]?.lb_enable && <StyledTableCell align="center">
                              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2rem" }}>
                                {row.first_name}
                              </div>
                            </StyledTableCell>}
                            {data?.leaderboard[5]?.lb_enable && <StyledTableCell align="center">{row.levels ?? 0}</StyledTableCell>}
                            {data?.leaderboard[4]?.lb_enable && <StyledTableCell align="center">{row.points}</StyledTableCell>}
                            <StyledTableCell align="center">
                              {(row.ur_id != userDetails?.uData?.ur_id) && <DeleteIcon style={{ fontSize: "2.5rem", color: "#ff6163", cursor: "pointer" }}
                                onClick={() => handleClickOpen1(row)} />}
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </TabPanel>
            </TabContext>
            {/* Add Friend Dialog */}
            <div>
              <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classes.mainDialog}
              >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose} className={classes.dialogHeader}>
                  <h1>{`${userDetails?.languagejson?.Leaderboard?.Add_Friends || "Add Friends"}`}</h1><p style={{ fontSize: "1.5rem" }}>&nbsp;- {`${userDetails?.languagejson?.Leaderboard?.Click_add_button_to_add_your_friends_in_your_Leaderboard || "Click add button to add your friends in your Leaderboard"}`}.</p>
                </BootstrapDialogTitle>
                <DialogContent>
                  <Typography gutterBottom>
                    {!isLoading1 && <Paper className={classes.dialogPaper}>
                      {users && users?.body?.length > 0 && users?.body?.map((row) => (
                        <div key={row.ur_id}>
                          <div style={{ display: "flex", gap: "2rem", alignItems: "center", width: "22rem" }}>
                            <img src={avatar1} alt="Avatar Name" title="Avatar Name" style={{ width: "6rem", }} />
                            <h2>{row.first_name}</h2>
                          </div>
                          <div style={{ margin: "auto 1rem", }}>
                            <Button variant="contained" className={classes.primaryButton}
                              onClick={() => handleUpdateUser(row)}>
                              {`${userDetails?.languagejson?.Leaderboard?.Add || "Add"}`} <AddIcon style={{ fontSize: "2.2rem", marginLeft: "0.6rem" }} />
                            </Button>
                          </div>
                        </div>
                      ))}
                      {users?.body.length === 0 && <h3 style={{ textAlign: 'center', padding: "20px 0 0 0" }}>{`${userDetails?.languagejson?.Leaderboard?.No_Data || "No Data"}`}</h3>}
                    </Paper>}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <div className={classes.buttonSection}>
                    <Button variant="contained" className={classes.primaryButton} onClick={handleClose}>{`${userDetails?.languagejson?.Leaderboard?.Close || "Close"}`}</Button>
                  </div>
                </DialogActions>
              </BootstrapDialog>
            </div>
            {/* Delete Friend Dialog  */}
            <div>
              <BootstrapDialog
                onClose={handleClose1}
                aria-labelledby="customized-dialog-title"
                open={open1}
                className={classes.mainDialogDelete}
              >
                <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose1} className={classes.dialogHeader}>
                  <h1>{`${userDetails?.languagejson?.Leaderboard?.Confirmation || "Confirmation"}`}</h1>
                </BootstrapDialogTitle>
                <DialogContent>
                  <Typography gutterBottom style={{ fontSize: "2.2rem", textAlign: "center", margin: "2rem 1rem", fontFamily: `myfontregular`, }}>
                    {`${userDetails?.languagejson?.Leaderboard?.Do_you_want_to_delete || "Do you want to delete"}`} {userObject?.first_name}
                    {`${userDetails?.languagejson?.Leaderboard?.from_your_personal_leaderboard || "from your personal leaderboard"}`}?
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <div className={classes.buttonSectionDelete}>
                    <Button variant="contained" className={classes.secondaryButton} onClick={handleClose1}>{`${userDetails?.languagejson?.Leaderboard?.No_Cancel || "No, Cancel"}`}</Button>
                    <Button variant="contained" className={classes.primaryButton} onClick={handleDelete}>{`${userDetails?.languagejson?.Leaderboard?.Yes_Delete || "Yes, Delete"}`}</Button>
                  </div>
                </DialogActions>
              </BootstrapDialog>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default Leaderboard;
