import { createSlice } from '@reduxjs/toolkit';

export const rateContentSlice = createSlice({
  name: 'rate',
  initialState: {
    isRated: false,
    aLoading: false,
  },
  reducers: {
    setIsRated: (state, action) => {
      state.isRated = action.payload;
    },
    setALoading: (state, action) => {
      state.aLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setIsRated, setALoading } = rateContentSlice.actions;

export default rateContentSlice.reducer;
