import { API } from "aws-amplify";
import React from "react";
import { useSelector } from "react-redux";
import config from "../../../aws-exports";
import { authData } from "../../../redux/auth/authSlice";
import Carousel from "../Carousel";
import { useQuery } from "@tanstack/react-query";
import Loading from "../../Loading/Loading";
import { useState } from "react";
import { useEffect } from "react";
import { getRecenetView } from "../../../api/api";

const RecentlyViewedCarousel = ({ classDefine }) => {
  const userDetails = useSelector(authData);
  const [recentlyViewedData, setRecentlyViewedData] = useState([]);

  const { isLoading, error, data } = useQuery({
    queryKey: ["getRecenetView"],
    queryFn: () => getRecenetView(userDetails),
  });

  useEffect(() => {
    if (data) {
      setRecentlyViewedData(data);
    }
  }, [data]);

  return (
    <React.Fragment>
      {data && data.length > 0 && (
        <Carousel
          heading={`${userDetails?.languagejson?.Home_Page?.Recently_Viewed ||
            "Recently Viewed"}`}
          cardData={recentlyViewedData}
          setData={setRecentlyViewedData}
          loading={isLoading}
          classDefine={classDefine}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(RecentlyViewedCarousel);
