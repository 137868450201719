import React from "react"
import { motion } from "framer-motion"
import logincss from "../../pages/Login/Login.module.scss"
import config from "../../aws-exports"
import { Box } from "@mui/material"

const CustomLoginLayout = ({ children }) => {
  return (
    <motion.div
      className={logincss.loginformdiv}
      style={{ height: "100vh", display: "grid", placeItems: "center" }}
    >
      <motion.div
        className={logincss.loignformdiv3}
        style={{
          maxWidth: "750px",
        }}
        initial={{ x: "-100vw", opacity: 1 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{
          delay: 1,
          x: { type: "spring", stiffness: 100 },
          default: { duration: 2 },
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            // marginBottom: "2rem",
          }}
        >
          <img
            src={`https://${
              config.DOMAIN
            }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/logo-dark.png`}
            alt="logo"
            height="100px"
          />
          {config.aws_org_id?.toLowerCase() === "icici" && (
            <img
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/App-icon.png`}
              alt="logo"
              height="100px"
            />
          )}
        </Box>
        {children}
      </motion.div>
    </motion.div>
  )
}

export default CustomLoginLayout
