import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, AccordionDetails, AccordionSummary, Box, Accordion, Button } from "@mui/material";
import { Skeleton } from "@material-ui/lab";
import SearchIcon from "@material-ui/icons/Search";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FilterFramesIcon from '@mui/icons-material/FilterFrames';
import { callAPI, } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
import slTopic from "../../assets/slTopic.jpg";
import SearchHeader from "../../components/SocialLearning/SearchHeader";
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';

function ViewAllTopic({ setShow }) {
  const userDetails = useSelector(authData);
  const [offset, setOffset] = useState(0);
  const [postData, setPostData] = useState();
  const useStyles = makeStyles((theme) => ({
    mainLayout: {
      margin: "4rem 24rem 0rem",
      width: "auto!important",
      fontSize: "1.6rem!important",
      "@media (max-width: 900px)": {
        margin: "2rem 8rem 0rem",
      },
      "@media (max-width: 600px)": {
        margin: "0rem 1rem 0rem",
      },
      '& > div': {
        background: userDetails.themeDark === "dark" ? "#282D37!important " : "#fff!important",
        marginBottom: "1rem",
        boxShadow: userDetails.themeDark === "dark" ? "0px 0px 2px 1px #b8b8b84d!important " : "0px 0px 2px 1px rgba(102, 102, 102, 0.3)!important",
      },
    },
    bannerLayout: {
      backgroundImage: `url(${slTopic})`,
      height: "32rem",
      margin: "0rem 12rem 4rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundSize: "cover",
      borderRadius: "1rem",
      textAlign: "center",
      padding: "6rem 4rem",
      boxShadow: "0px 0px 9px -2px rgba(102, 102, 102, 0.7)",
      "@media (max-width: 900px)": {
        margin: "0rem 6rem 4rem",
      },
      "@media (max-width: 600px)": {
        padding: "2rem",
        margin: "0rem 0rem 4rem",
      },
      "& > h1": {
        color: "black", fontSize: "3rem", letterSpacing: "1.5px", background: "#ffffff73",
        borderRadius: "8px", width: "fit-content", padding: "1rem 4rem", margin: "auto",
      },
    },
    searchInput: {
      fontSize: "1.8rem",
      backgroundColor: userDetails.themeDark === "dark" ? "#1c2027!important " : "#fff!important",
      color: userDetails.themeDark === "dark" ? "#fff!important" : "#000!important",
      borderRadius: "8px",
      border: "none",
      transition: "all 0.2s",
      padding: "15px 7px 15px 50px",
      width: "100%",
      boxShadow: "0 0 4px 0 hsla(0,0%,50%,.3607843137)",
    },
    actionButtonFollow: {
      borderRadius: "3rem!important",
      border: "2px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: "transparent!important",
      margin: "0!important",
      padding: "0px 10px!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      "&:active": {
        color: "var(--color-button-background)!important",
      },
    },
    subContainer: {
      display: "flex",
      alignItems: "center",
      border: "1px solid",
      borderColor: userDetails.themeDark === "dark" ? "#444852" : "lightgray",
      marginBottom: "1rem",
      justifyContent: "space-between",
      "@media (max-width: 900px)": {
        flexWrap: "wrap",
        gap: "1.5rem",
      },
      '& > div:nth-child(1)': {
        display: "grid", width: "60%", padding: "1.53rem",
        '& > h2': {
          color: "var(--color-button-background)!important",
          fontSize: "1.7rem",
        },
      },
      '& > div:nth-child(2)': {
        fontSize: "2rem",
        textAlign: "center",
        fontWeight: "800",
        display: "flex",
        alignItems: "center",
        width: "20%",
        padding: "1.5rem",
        justifyContent: "center",
      },
      '& > div:nth-child(3)': {
        width: "20%", fontSize: "1.5rem", padding: "1.5rem", textAlign: "center", fontWeight: "800"
      },
    },
    totalPostStyle: {
      background: "#f4fff2",
      color: "black",
      width: "fit-content",
      padding: "4px 9px",
      fontSize: "1.6rem",
      border: "1px solid gray",
      borderRadius: "5px",
    },
    headerStyle: {
      display: "flex",
      gap: "4rem",
      alignItems: "center",
      fontSize: "1.7rem!important",
      "@media (max-width: 900px)": {
        flexWrap: "wrap",
        gap: "1.5rem",
      },
    },
    noPostStyle: {
      display: "flex",
      alignItems: "center",
      gap: "5px",
      width: "20%",
      backgroundColor: userDetails.themeDark === "dark" ? "#2B3243" : "#EBEBEB",
      padding: "1.5rem",
      justifyContent: "center",
    },
    arrowIconStyle: {
      fontSize: "3rem!important",
      color: userDetails.themeDark === "dark" ? "white" : "black",
    },
    subContainerOne: {
      display: "flex", alignItems: "center", fontSize: "1.3rem",
      border: "1px solid", justifyContent: "space-between",
      borderColor: userDetails.themeDark === "dark" ? "#444852" : "lightgray",
      fontWeight: "800", marginBottom: "2rem", borderRadius: "5px",
    },
    searchSection: {
      borderRadius: "5px",
      width: "70%",
      position: "relative",
      margin: "6rem auto 0rem",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    loaderSkeletonSlider: {
      width: "100%",
      height: "15rem",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    noLinkeIcon: {
      width:"3rem",
      fill: userDetails.themeDark === "dark" ? "white" : "black",
    },
  }));
  const fetchTopic = async () => {
    try {
      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      let obj = {};
      obj.method = 'get';
      obj.apiName = `/getSlTopics?schema=${config.schema}`;
      obj.body = {};
      const res = await callAPI(obj,userDetails);
      const filteredProducts = res.body.filter(product =>
        product.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      res.body = filteredProducts;
      console.log('getSlTopics data:', filteredProducts);
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  };
  const fetchPostsByTopicID = async (topicid) => {
    try {
      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      let obj = {};
      obj.method = 'get';
      obj.apiName = `/getPostsByTopicID?schema=${config.schema}&topicid=${topicid}&offset=${offset}&limit=100`;
      obj.body = {};
      const res = await callAPI(obj,userDetails);
      console.log('getPostsByTopicID data:', res);
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  };

  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const { isLoading, error, data } = useQuery({
    queryKey: ["fetchTopic", searchTerm],
    queryFn: fetchTopic, // Provide your query function here
  });
  const handleChange = (panel, item) => async (event, isExpanded) => {
    setMainLoader(true);
    setExpanded(isExpanded ? panel : false);
    const res = await fetchPostsByTopicID(item.topicid);
    setPostData(res);
    setMainLoader(false);
  };
  return (
    <>
      <SearchHeader />
      <div className={classes.bannerLayout}>
        <h1>Topics</h1>
        <div className={classes.searchSection}>
          <button
            className="search__button"
            title="Search"
            style={{ position: "absolute", top: "1.2rem", left: "1rem" }}
          >
            <SearchIcon
              style={{
                fontSize: "3rem",
                color: "gray",
              }}
            />
          </button>
          <input
            type="search"
            className={classes.searchInput}
            placeholder="Search here..."
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      
      <Box sx={{ width: '100%', typography: 'body1' }} className={classes.mainLayout}>
        {isLoading && <Skeleton className={classes.loaderSkeletonSlider} />}
        {!isLoading && data?.body?.map((item, ind) => (
          <Accordion expanded={expanded === ind} onChange={handleChange(ind, item)}>
            <AccordionSummary
              expandIcon={<ArrowDropDownIcon className={classes.arrowIconStyle} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.headerStyle}>
                <div style={{ fontWeight: "800" }}>{item.title}</div>
                <div className={classes.totalPostStyle}>Total Posts {item.total_posts}</div>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Box className={classes.subContainerOne}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "60%", padding: "1.53rem", fontSize: "1.5rem" }}>
                    <FilterFramesIcon style={{ fontSize: "2.5rem" }} />
                    Posts Name
                  </div>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px", width: "20%", padding: "1.5rem", justifyContent: "center", fontSize: "1.5rem" }}>
                  <ThumbUpAltIcon className={classes.noLinkeIcon}/>
                    No. Likes
                  </div>
                </Box>
                {!mainLoader && postData && postData.body?.map((row) => (
                  <Box className={classes.subContainer}>
                    <div>
                      <h2>{row.post_title}</h2>
                      <p style={{ fontSize: "1.4rem" }}>{row.content}</p>
                    </div>
                    <div>
                      {row.likes}
                    </div>
                  </Box>
                ))}
                {mainLoader && <Skeleton className={classes.loaderSkeletonSlider} />}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}

      </Box>
    </>
  );
}
export default ViewAllTopic;
