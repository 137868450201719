import 'cross-fetch/polyfill';
import 'regenerator-runtime/runtime';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { API } from 'aws-amplify';
import { authData } from "../../redux/auth/authSlice";
import config from '../../aws-exports';

const TimeSpent = () => {
  let sessionStart = Date.now();
  let timeSpent = 0;
  let heartbeatInterval;
  let updateWithoutInteractionInterval;
  let retryCount = 0;
  let isDocumentHidden = false;
  const userDetails = useSelector(authData);
  const urId = userDetails?.uData?.ur_id;
  const [isUnmounted, setIsUnmounted] = useState(false);
  let idleTimer;
  const idleTimeout = 30 * 60 * 1000;

  const sendHeartbeat = async (userDetails) => {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}/${(currentDate.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${currentDate.getDate().toString().padStart(2, '0')}`;
    try {

      const jsonString = JSON.stringify({ur_id: urId,
        cdate: formattedDate,
        time: Math.floor(timeSpent / 1000)});
      const base64EncodedString = btoa(jsonString);
      const bodyParam = {
        body: {
          json:base64EncodedString
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken	
        }
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        '/usertimespent',
        bodyParam
      );
      

      if (
        !response.body ||
        !Array.isArray(response.body) ||
        response.body.length === 0
      ) {
        throw new Error('Invalid response format from the server');
      }

      const responseBody = response.body[0];

      if (!responseBody.ur_id || !responseBody.cdate || !responseBody.time) {
        throw new Error('Invalid response format from the server');
      }

      sessionStart = Date.now();
      retryCount = 0;
    } catch (error) {
      console.error('Error sending heartbeat:', error.message);
      retryCount++;
      if (retryCount <= 3) {
        setTimeout(sendHeartbeat(userDetails), Math.pow(2, retryCount) * 1000);
      } else {
        console.error(
          'Max retry count reached. Consider implementing a more robust retry strategy.'
        );
      }
    }
  };

  const updateWithoutInteraction = () => {
    if (!isDocumentHidden) {
      const currentTime = Date.now();
      timeSpent += currentTime - sessionStart;
      sessionStart = currentTime;
    }
  };

  const handleMouseMove = () => {
    updateWithoutInteraction();
  };

  const handleVisibilityChange = () => {
    if (!isUnmounted) {
      if (document.hidden || document.visibilityState !== 'visible') {
        clearInterval(heartbeatInterval);
        clearInterval(updateWithoutInteractionInterval);
        isDocumentHidden = true;
  
        // Send the heartbeat only if the user is leaving the tab
        sendHeartbeat(userDetails);
      } else {
        isDocumentHidden = false;
        sessionStart = Date.now();
  
        // Reset the timeSpent variable to 0 when becoming visible again
        timeSpent = 0;
  
        updateWithoutInteractionInterval = setInterval(updateWithoutInteraction, 1000);
      }
    }
  };
  

  const handleHeartbeat = () => {
    if (!isDocumentHidden && document.visibilityState === 'visible') {
          sendHeartbeat(userDetails);
    }
  };

  const handleOnline = () => {
    if (!isDocumentHidden) {
      handleHeartbeat();
    }
  };

  const handleOffline = () => {
    clearInterval(heartbeatInterval);
    clearInterval(updateWithoutInteractionInterval);
    if (!isDocumentHidden) {
      timeSpent += Date.now() - sessionStart;
    }
  };

  const handleBeforeUnload = async () => {
    clearInterval(heartbeatInterval);
    if (!isDocumentHidden) {
      timeSpent += Date.now() - sessionStart;
      sendHeartbeat();
    }
  };

  const updateIdleTimeout = () => {
    if (!isDocumentHidden) {
      clearTimeout(idleTimer);
      idleTimer = setTimeout(() => {
        clearInterval(heartbeatInterval);
        clearInterval(updateWithoutInteractionInterval);
        timeSpent += Date.now() - sessionStart;
        sendHeartbeat(userDetails);
        
        // Pause time tracking after sending heartbeat
        clearInterval(heartbeatInterval);
        clearInterval(updateWithoutInteractionInterval);
      }, idleTimeout);
    }
  };  

  useEffect(() => {
    handleHeartbeat(); // Initial heartbeat

    updateWithoutInteractionInterval = setInterval(
      updateWithoutInteraction,
      1000
    );

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('keydown', handleMouseMove);
    document.addEventListener('scroll', handleMouseMove);

    document.addEventListener('visibilitychange', handleVisibilityChange);

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);
    window.addEventListener('beforeunload', handleBeforeUnload);

    document.addEventListener('mousemove', updateIdleTimeout);
    document.addEventListener('keydown', updateIdleTimeout);
    document.addEventListener('scroll', updateIdleTimeout);

    return () => {
      setIsUnmounted(true);
      clearInterval(heartbeatInterval);
      clearInterval(updateWithoutInteractionInterval);
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('keydown', handleMouseMove);
      document.removeEventListener('scroll', handleMouseMove);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('beforeunload', handleBeforeUnload);
      sendHeartbeat(userDetails);
      clearTimeout(idleTimer);
      document.removeEventListener('mousemove', updateIdleTimeout);
      document.removeEventListener('keydown', updateIdleTimeout);
      document.removeEventListener('scroll', updateIdleTimeout);
    };
  }, []);

  return null;
};

export default TimeSpent;