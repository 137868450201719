import { Box } from "@material-ui/core";
import React from "react";
import CustomInput from "../CustomInput/CustomInput";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
} from "./SignInAndSignUp";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { CustomButton } from "../CustomButton/CustomButton";
import { CircularProgress, Typography } from "@material-ui/core";
import { HeadingPrimary } from "../Styles/Heading";

// import ReactCodeInput from "react-verification-code-input";
import CodeIcon from "@material-ui/icons/Code";
const ResetPassword = ({
  resetPasswordOpenFirst,
  passwordResetFC,
  resetPasswordFC,
  resetPasswordLoader,
  emailVerification,
  setValues,
  values,
  handleChange,
  handleClickShowPassword,
  handleMouseDownPassword,
  errors,
  setErrors,
  setSignIn,
  setSignUp,
  setSignInForm,
  setEmailView,
  setForgotPassword,
  setResetPasswordOpenFirst,
}) => {
  const handleSubmit = (event) => {
    event.preventDefault();
    if (emailVerification) {
      passwordResetFC(event);
    } else {
      resetPasswordFC(event);
    }
  };
  return (
    <React.Fragment>
      <Box style={{ textAlign: "center" }}>
        <HeadingPrimary>Password Reset</HeadingPrimary>
      </Box>
      <form
        onSubmit={handleSubmit}
        noValidate
        autoComplete="off"
        style={displayCenter}
      >
        {emailVerification && (
          <Box style={inputBoxStyle}>
            <CustomInput
              placeholder="verificationCode"
              name="verificationCode"
              inputValues={values}
              value={values.verificationCode || ""}
              error={errors?.verificationCode}
              onChange={handleChange("verificationCode")}
            >
              <CodeIcon style={inputIconStyle} />
            </CustomInput>
          </Box>
        )}

        <Box style={inputBoxStyle}>
          <CustomInput
            placeholder="Password"
            name="resetPassword"
            type="password"
            inputValues={values}
            value={values.resetPassword || ""}
            error={errors?.resetPassword}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
            onChange={handleChange("resetPassword")}
          >
            <LockOutlinedIcon style={inputIconStyle} />
          </CustomInput>
        </Box>
        <Box style={inputBoxStyle}>
          <CustomInput
            placeholder="Confirm Password"
            type="password"
            name="confirmPassword"
            inputValues={values}
            value={values.confirmPassword || ""}
            error={errors?.passwordConfirm}
            onChange={handleChange("confirmPassword")}
            handleClickShowPassword={handleClickShowPassword}
            handleMouseDownPassword={handleMouseDownPassword}
          >
            <LockOutlinedIcon style={inputIconStyle} />
          </CustomInput>
        </Box>

        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="caption"
            display="block"
            style={{
              maxWidth: "450px",
              fontSize: "14px",
              textAlign: "center",
              fontFamily: "myfontregular",
            }}
            gutterBottom
          >
            *Password should be at least 8 characters, contain at least one
            number & one special character.
          </Typography>
        </Box>

        <Box style={{ textAlign: "center" }}>
          {resetPasswordLoader ? (
            <CustomButton disabled loading="true">
              <CircularProgress color="inherit" size={20} />
            </CustomButton>
          ) : (
            <CustomButton primary type="submit">
              Submit
            </CustomButton>
          )}
          <CustomButton
            type="button"
            onClick={() => {
              setSignIn(true);
              setSignUp(false);
              setSignInForm(true);
              setEmailView(false);
              setForgotPassword(false);
              setResetPasswordOpenFirst(false);
              setErrors({});
            }}
          >
            Cancel
          </CustomButton>
        </Box>
      </form>
    </React.Fragment>
  );
};

export default ResetPassword;
