import React, { useState, useRef } from "react";
import {
  Paper,
  Button,
  TableContainer,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableHead,
  TextField,
  TableCell,
  tableCellClasses,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import BACoin from "../../assets/Balance Available Coins.png";
import SearchIcon from "@material-ui/icons/Search";
// import AddIcon from '@mui/icons-material/Add';
// import RemoveIcon from '@mui/icons-material/Remove';
import config from "../../aws-exports";
import "./MyStoreCoins.scss";
import { Label } from "@material-ui/icons";
// import MyCoin from "../../assets/My Coins.png";
import noThingIsHere from "../../assets/Nothing here yet.gif";

export default function MyOrders(props) {
  const { data, refetch, userDetails } = props;
  const useStyles = makeStyles((theme) => ({
    paper: {
      // padding: "1rem",
      paddingBottom: "2rem",
      marginBottom: "2rem",
      // boxShadow:
      //   "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 0px 0px rgb(0 0 0 / 2%)!important",
      boxShadow: "none!important",
    },
    buttonSection: {
      textAlign: "center",
      margin: "1rem",
      width: "100%",
    },
    button: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
    },
    headerSectionTab: {
      marginBottom: "2rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 900px)": {
        display: "grid",
        gap: "2rem",
        justifyContent: "center",
      },
      "& > div": {
        width: "100%",
        "@media (min-width: 901px)": {
          width: "37rem",
        },
      },
    },
    headerBlock: {
      padding: "1rem",
      display: "flex",
      boxShadow: "0 0 4px 0 hsla(0,0%,50%,.3607843137)",
      borderRadius: "5px",
      backgroundColor: "#FDE1AB",
      gap: "1rem",
      alignItems: "center",
      "& > h2": {
        color: "black",
      },
      "& > span": {
        color: "black",
      },
    },
    searchInput: {
      fontSize: "1.8rem",
      // color: inherit;
      backgroundColor: "#fff!important",
      color: "black!important",
      border: "none",
      borderRadius: "1rem",
      transition: "all 0.2s",
      marginRight: "-3.25rem",
      padding: "7px 36px 7px 20px",
      width: "100%",
      boxShadow: "0 0 4px 0 hsla(0,0%,50%,.3607843137)",
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
          "& > svg ": {
            fontSize: "2rem",
          },
        },
      },
    },
    secondaryButton: {
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: "var(--color-button-background)!important",
      borderColor: "var(--color-button-background)!important",
      fontFamily: `myfontregular!important`,
    },
    tableContainerStyle: {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#5C5C5C !important"
          : theme.palette.background.paper,
    },
    copyButton: {
      fontSize: "1.5rem!important",
      textAlign: "left",
      gap: "1rem",
      textTransform: "capitalize!important",
      width: "30rem",
      fontWeight: "800!important",
      fontFamily: `myfontregular!important`,
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "var(--color-button-background)!important",
    },
    textArea: {
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "#3F434D !important",
      border: "none",
      padding: "7px",
      fontSize: "1.5rem",
      boxShadow: "0 0 4px 0 hsla(0deg, 0%, 50%, 0.3607843137)",
      transition: "all 0.2s",
      textAlign: "left",
      width: "22rem",
      borderRadius: "1rem",
      overflowWrap: "break-word",
    },
    responsiveImage: {
      width: "30%",
      "@media (max-width: 600px)": {
        width: "65%",
      },
    },
  }));

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [myRedeem, setMyRedeem] = useState();
  const [myorders, setMyorders] = useState(data?.myorders);
  const textRef = useRef(null);
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialog-paper": {
      width: "50%",
      maxWidth: "none",
    },
    "& .MuiDialogContent-root": {
      padding: "1rem 2rem",
    },
    "& .MuiDialogTitle-root": {
      padding: "1rem 1rem 1rem 2rem",
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#1b212f!important"
          : theme.palette.action.hover,
    },
    "&:nth-of-type(odd)": {
      backgroundColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--color-button-background)",
      color: theme.palette.common.white,
      fontSize: "1.5rem",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1.5rem",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "#3F434D !important",
      borderBottom: "1px solid",
      borderColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
  }));

  function BootstrapDialogTitle(props) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }
  const handleClose = () => {
    setOpen(false);
  };

  const handleCopy = (text) => {
    textRef.current = text;
    if (text) {
      navigator.clipboard
        .writeText(text)
        .then(() => {})
        .catch((error) => {
          console.error("Error copying text to clipboard:", error);
        });
    }
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <>
      <div className={classes.headerSectionTab}>
        <div className={classes.headerBlock}>
          <img
            src={BACoin}
            alt="Coins Available"
            title="Coins Available"
            style={{ width: "4rem" }}
          />
          <h2>{data?.myavailablecoin}</h2>
          <span>
            {`${userDetails?.languagejson?.Gamification?.Coins_Available ||
              "Coins Available"}`}{" "}
          </span>
        </div>
        <div style={{ borderRadius: "5px" }}>
          <input
            type="search"
            className={classes.searchInput}
            placeholder={`${userDetails?.languagejson?.Gamification?.Search ||
              "Search products here..."}`}
            // value={search}
            onChange={(e) => {
              if (data.myorders) {
                setMyorders(
                  data.myorders.filter((item) => {
                    const rnameMatch =
                      item.rname &&
                      item.rname
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase());
                    const qtyMatch =
                      typeof item.qty === "number" &&
                      item.qty.toString().includes(e.target.value);
                    const pointsMatch =
                      item.points &&
                      typeof item.points === "number" &&
                      item.points.toString().includes(e.target.value);
                    return rnameMatch || qtyMatch || pointsMatch;
                  })
                );
              }
            }}
          />
          <button
            className="search__button"
            title="Search"
            style={{ position: "relative", top: "0.8rem" }}
          >
            <SearchIcon
              style={{
                fontSize: "2.6rem",
                marginTop: "0.5rem",
                color: "gray",
              }}
            />
          </button>
        </div>
      </div>
      <Paper className={classes.paper}>
        {myorders && myorders.length > 0 && (
          <TableContainer
            component={Paper}
            className={classes.tableContainerStyle}
          >
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification
                      ?.Product_image || "Product Image"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.Product_name ||
                      "Product Name"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.Quantity ||
                      "Quantity"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.Coins_spent ||
                      "Coins Spent"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.Ordered_Date ||
                      "Ordered Date"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.Link ||
                      "Link to track Product Status"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification?.status ||
                      "Status"}`}
                  </StyledTableCell>
                  <StyledTableCell align="left" style={{ fontWeight: "700" }}>
                    {`${userDetails?.languagejson?.Gamification
                      ?.Reason_for_rejection || "Reason for rejection"}`}
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {myorders?.map((row) => (
                  <StyledTableRow key={row.rrid}>
                    <StyledTableCell scope="row">
                      <img
                        className="tableimg"
                        src={`https://${
                          config.DOMAIN
                        }/${config.aws_org_id.toLowerCase()}-resources/images/reward-images/${
                          row.url
                        }`}
                        alt="Product Image"
                        title="Product Image"
                      />
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.rname}</StyledTableCell>
                    <StyledTableCell align="left">{row.qty}</StyledTableCell>
                    <StyledTableCell align="left">
                      <div style={{ display: "flex", alignItems: "center" }}>
                        {row.carbs} <span>{row.points}</span>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left">{row.date}</StyledTableCell>
                    <StyledTableCell align="left">
                      {row.link !== undefined && row.link !== null && (
                        <Button
                          variant="outlined"
                          className={classes.copyButton}
                          onClick={() => handleCopy(row.link)}
                        >
                          {`${userDetails?.languagejson?.Gamification?.Link ||
                            "Click here to copy the link to track status"}`}
                          <ContentCopyIcon style={{ fontSize: "2rem" }} />
                        </Button>
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <p
                        className="textLabelField"
                        style={{
                          width: "100%",
                          backgroundColor:
                            row.status === null
                              ? "#FFE3A1"
                              : row.status === 1
                              ? "#CDF9DE"
                              : "#FFE8E8",
                          color:
                            row.status === null
                              ? " #7C4200"
                              : row.status === 1
                              ? "#005C0F"
                              : "#9E0000",
                        }}
                      >
                        {row.status === null
                          ? "Pending"
                          : row.status === 1
                          ? "Accepted"
                          : "Rejected"}
                      </p>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {row.reason && (
                        <p className={classes.textArea}>{row.reason}</p>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {!myorders ||
          (myorders.length === 0 && (
            <>
              <div style={{ textAlign: "center" }}>
                <img
                  src={noThingIsHere}
                  alt="logo"
                  className={classes.responsiveImage}
                />
              </div>
            </>
          ))}
      </Paper>

      <div>
        {/* Dialog 2*/}
        <div>
          <BootstrapDialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            className={classes.mainDialog}
          >
            <BootstrapDialogTitle
              id="customized-dialog-title"
              onClose={handleClose}
            >
              <h1>{`${userDetails?.languagejson?.Gamification?.Confirmation ||
                "Confirmation"}`}</h1>
            </BootstrapDialogTitle>
            <DialogContent>
              <Typography gutterBottom>
                <p style={{ fontSize: "1.5rem", marginBottom: "5rem" }}>
                  {`${userDetails?.languagejson?.Gamification
                    ?.Do_you_want_to_redeem ||
                    "Do you want to redeem the"}`}{" "}
                  {myRedeem?.rname}?
                </p>
              </Typography>
            </DialogContent>
            <DialogActions>
              <div className={classes.buttonSection}>
                <Button
                  variant="outlined"
                  className={classes.secondaryButton}
                  onClick={handleClose}
                >
                  {" "}
                  {`${userDetails?.languagejson?.Gamification?.No_Cancel ||
                    "No, Cancle"}`}
                </Button>
              </div>
              <div className={classes.buttonSection}>
                <Button variant="contained" className={classes.button}>
                  {`${userDetails?.languagejson?.Gamification?.Yes_Continue ||
                    "Yes, Continue"}`}
                </Button>
              </div>
            </DialogActions>
          </BootstrapDialog>
        </div>
      </div>
    </>
  );
}
