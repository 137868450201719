import { Card } from "@material-ui/core";
import styled from "styled-components";

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 345px;
  width: 100%;
  /* height: 100%; */
  /* padding: 1rem; */
  cursor: pointer;
  margin: 1rem;
  border-radius: 0.5rem;

  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background: ${(props) => (props.color ? props.color : "white")};
  margin-bottom: 1rem;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.4) 0px 3px 7px -3px;
  }
  .img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      height: 170px;
      object-fit: cover;
      @media screen and (max-width: 500px) {
        height: 130px;
      }
      @media screen and (max-width: 400px) {
        height: 100px;
      }
    }
    &--star-container {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: #fff;
      display: flex;
      align-items: center;
      border-radius: 4px;
      padding: 0 0.5rem;
      @media screen and (max-width: 500px) {
        top: 6px;
        right: 6px;
      }
      .star {
        fill: #ffda46;
        font-size: 2.5rem;
        @media screen and (max-width: 500px) {
          font-size: 2rem;
        }
      }
      .rating {
        color: #000;
        font-size: 1.4rem;
      }
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    width: 100%;
    height: 100%;
    padding: 1rem;
    /* border-radius: 0.5rem; */
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    /* background-color: #f2f3f5; */
    height: 18rem;
    /* margin-bottom: 1rem; */
    .para-container {
      /* height: 40px; */
      /* width: 70%; */
      p {
        display: -webkit-box;
        max-width: 345px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font-weight: 600;
      }
    }
    &--header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 1.5rem 0;
      &-star-container {
        background-color: #fff;
        display: flex;
        align-items: center;
        border-radius: 5px;
        padding: 0 0.5rem;
        position: absolute;
        top: 25px;
        right: 20px;
        box-shadow: 0px 0px 4px -2px;
        .star {
          fill: #ffda46;
          font-size: 2.5rem;
        }
        .rating {
          color: #000;
          font-size: 1.4rem;
        }
        @media screen and (min-width: 1850px) {
          right: 56px;
        }
      }
    }
  }
  .card-actions {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* height: 100%; */
    padding: 1rem;
    background-color: white;

    &--footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      &-time {
        display: flex;
        gap: 5px;
        align-items: center;
        font-size: 1.4rem;
      }
      .clock-icon {
        font-size: 2.5rem;
      }
      .points {
        background-color: #FFDA46;
        width: 3rem;
        height: 3rem;
        border-radius: 3rem;
        font-weight: 700;
        align-items: center;
        display: flex;
        justify-content: center;
      }

      .bookmark_date-container {
        display: flex;
        align-items: center;
        gap: 5px;
      }
      .bookmark-icon {
        font-size: 2.5rem;

        &.fill {
          fill: yellow;
        }
      }
    }
  }
`;
