export default function validateResetPasswordVerification(values) {
    let errors = {};
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    if (!values.newPassword) {
      errors.newPassword = "New password is required";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.newPassword = "Passwords don't Match!";
    } else {
      if (!regularExpression.test(values.newPassword)) {
        errors.newPassword = "Match password pattern";
      }
    }
    if (!values.confirmPassword) {
      errors.passwordConfirm = "Confirmation password is required";
    }
    if (!values.currentPassword) {
      errors.currentPassword = "Enter verification Code.";
    }
  
    return errors;
  }
  
  export function validateResetPassword(values) {
    let errors = {};
    var regularExpression = /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/;
    if (!values.newPassword) {
      errors.newPassword = "New password is required";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.newPassword = "Passwords don't Match!";
    } else {
      if (!regularExpression.test(values.newPassword)) {
        errors.newPassword = "Match password pattern";
      }
    }
    if (!values.confirmPassword) {
      errors.passwordConfirm = "Confirmation password is required";
    }

    if (!regularExpression.test(values.currentPassword)) {
        errors.currentPassword = "Match password pattern";
      }

    if (!values.currentPassword) {
        errors.currentPassword = "Enter current password.";
      }
  
    return errors;
  }
  