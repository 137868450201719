import { Box } from "@mui/material";
import React, { useState } from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";

import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

import { CircularProgress } from "@mui/material";
import Swal from "sweetalert2";

// import "./phoneInput.styles.css";
// import "react-phone-number-input/style.css";
import { useForm } from "../../Hooks/useForm";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { API, Auth } from "aws-amplify";
import config from "../../aws-exports";
import CustomInput from "../../components/CustomInput/CustomInput";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { HeadingPrimary } from "../../components/Styles/Heading";
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../components/SignInAndSignUp/SignInAndSignUp";

import { useHistory, useLocation } from "react-router-dom";
import validateSignUp from "./SignInSignUpValidationRules/SignUpValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import CustomLoginLayout from "./CustomLoginLayout";
const SignUpONDC = () => {
  const userDetails = useSelector(authData);
  const { state } = useLocation();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [signUpBtnLoader, setSignUpBtnLoader] = useState(false);
  const {
    values,
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validateSignUp(values)).length !== 0) {
      setErrors(validateSignUp(values));
      return;
    } else {
      const { name, password, email, last_name } = values;
      let phoneNumberCopied = "+91" + values.phoneNumber;
      setSignUpBtnLoader(true);
      try {
        const response = await Auth.signUp({
          username: email,
          password: password,
          attributes: {
            email, // optional
            phone_number: phoneNumberCopied, // optional - E.164 number convention
            // other custom attributes
            name: name,
            locale: "NSECORPORATE",
          },
        });

        let body = {
          tenant: "NSECORPORATE",
          type: "signup",
          eid: response.userSub,
          emailid: email,
          gen: 0,
          first_name: name,
          last_name: last_name,
          dev: 2,
          st_id: 2,
          cno: null,
          schema: config.schema,
        };

        Swal.fire({
          title: "Email verification link sent to email",
          text: "You will be now redirected to SMS verification",
          icon: "info",
          // confirmButtonColor: Theme.color.primary,
          //confirmButtonText: "Ok",
        }); /* .then((result) => {
          if (result.isConfirmed) {
            addUser(body);

            
          }
        }); */
        addUser(body);

        setSignUpBtnLoader(false);
      } catch (error) {
        setErrors(cognitoValidation(error));
        setSignUpBtnLoader(false);
      }
    }
  };

  const PhoneVerify = async () => {
    //event.preventDefault();
    try {
      setSignUpBtnLoader(true);
      //let userdata = JSON.parse(localStorage.getItem("userdata"));
      let username = values?.name;
      const jsonString = JSON.stringify({
        emailid: values?.email,
          password: values?.password,
          confirmPassword: values?.confirmPassword,
          phoneNumber: values.phoneNumber,
          schema: config.schema,
          company_name: "ONDC",
      });
      const base64EncodedString = btoa(jsonString);
      const bodyParam = {
        body: {
          json:base64EncodedString
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/userSignUp",
        bodyParam
      );
      if (response.statusCode === 200) {
        let body = {
          ...response.body,
          username: response?.body?.data.emailid || state?.username,
        };
        localStorage.setItem("userdata", JSON.stringify(body));
        let userObj = {};
        userObj.emailid = values.email;
        userObj.password = values.confirmPassword;

        localStorage.setItem("userCred", JSON.stringify(userObj));
        setSignUpBtnLoader(false);
        history.push({
          pathname: "/confirm-sign-up",
          state: body,
        });
      } else {
        let code = response?.code;
        setSignUpBtnLoader(false);
        setErrors({});
        switch (code) {
          case "InvalidPhoneNumber":
            return setErrors((prev) => ({
              ...prev,
              phoneNumber: response?.body?.message,
            }));
          case "InvalidPassword":
            return setErrors((prev) => ({
              ...prev,
              confirmPassword: response?.body?.message,
            }));

          default:
            return false;
        }
      }
    } catch (err) {
      console.error(err);
      setSignUpBtnLoader(false);
      throw err;
    }
  };

  const addUser = async (user) => {
    const jsonString = JSON.stringify(user);
    const base64EncodedString = btoa(jsonString);

    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/addUser",
        bodyParam
      );
    
      PhoneVerify(user);
    } catch (err) {
    }
  };

  return (
    <React.Fragment>
      <CustomLoginLayout>
        <Box style={{ textAlign: "center" }}>
          <HeadingPrimary>Create an account</HeadingPrimary>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          style={displayCenter}
        >
          <React.Fragment>
            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-name-1"
                placeholder="First Name"
                name="name"
                type="text"
                value={values.name || ""}
                error={errors.name}
                onChange={handleChange("name")}
              >
                <PersonOutlineOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-Lname"
                placeholder="Last Name"
                name="last_name"
                value={values.last_name || ""}
                error={errors.last_name}
                type="text"
                onChange={handleChange("last_name")}
              >
                <PersonOutlineOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-name-1"
                placeholder="Email*"
                name="email"
                type="email"
                value={values.email || ""}
                error={errors.email}
                onChange={handleChange("email")}
              >
                <EmailOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-phone"
                placeholder="Enter phone number"
                name="phoneNumber"
                value={values.phoneNumber || ""}
                error={errors.phoneNumber}
                type="text"
                onChange={handleChange("phoneNumber")}
              >
                <PhoneIphoneIcon style={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password*"
                type="password"
                name="password"
                inputValues={values}
                value={values.password || ""}
                error={errors.password}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
              >
                <LockOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-confirmPassword"
                placeholder="Confirm Password*"
                type="password"
                name="confirmPassord"
                inputValues={values}
                value={values.confirmPassword || ""}
                error={errors.confirmPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("confirmPassword")}
              >
                <LockOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <label
              style={{
                fontSize: "10px",
                width: "360px",
                textAlign: "center",
                marginLeft: "70px",
                color: "black",
              }}
            >
              *Password should be at least 8 characters, contain at least one
              number & one special character.
            </label>
            <Box component="div" style={{ textAlign: "center", mb: 3 }}>
              <CustomButton
                type="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </CustomButton>
              {signUpBtnLoader ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton primary type="submit">
                  SignUp
                </CustomButton>
              )}
            </Box>
          </React.Fragment>
        </form>
      </CustomLoginLayout>
    </React.Fragment>
  );
};

export default SignUpONDC;
