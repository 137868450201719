import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Box } from "@mui/material";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Person2Icon from '@mui/icons-material/Person2';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import avatar from "../../assets/male1.png";
import ReplyIcon from '@mui/icons-material/Reply';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import SearchHeader from "../../components/SocialLearning/SearchHeader";
import avatar1 from "../../assets/media/homepage.jpg";

function OtherUserProfile({setShow}) {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      // flexGrow: 1,
    },
    mainLayout: {
      margin: "0rem 12rem",
      width: "auto!important",
      fontSize: "1.6rem!important",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
    },
    mainLayout1: {
      boxShadow: userDetails.themeDark === "dark"? "none!important": "0px -1px 0px 1px rgba(102, 102, 102, 0.3)!important",
      margin: "0rem 12rem",
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
      width: "auto!important",
      fontSize: "1.6rem!important",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
    },
    loaderSkeletonSlider: {
      width: "100%",
      height: "25rem",
    },
    actionButton: {
      backgroundColor: userDetails.themeDark === "dark"? "#353944": "#EBEBEB",
      borderRadius: "1.5rem!important",
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
      margin: "1rem!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: userDetails.themeDark === "dark"? "lightgray!important": "black!important",
      gap: "1rem",
      "&:active": {
      color: "var(--color-button-background)!important",
      },
      "& > svg": {
        fontSize: "3rem", color: userDetails.themeDark === "dark"? "lightgray!important": "black!important",
        "&:active": {
          color: "var(--color-button-background)!important",
        },
      },
    },
    actionButtonFollowAbout: {
      borderRadius: "3rem!important",
      border: "2px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: "white!important",
      margin: "0!important",
      padding: "0px 17px!important",
      fontSize: "2rem!important",
      textTransform: "capitalize!important",
      "&:active": {
      color: "var(--color-button-background)!important!important",
      },
    },
    firstTrend: {
      color: userDetails.themeDark === "dark"? "white!important": "black!important",
      padding: "1rem",
      backgroundColor: userDetails.themeDark === "dark"? "#353944": "#EBEBEB",
      borderRadius: "4px",
    },
    socialIcons: {
      fontSize:"2.5rem!important",
      color: userDetails.themeDark === "dark"? "lightgray!important": "black!important",
    },
    leftPaper: {
      width:"100%",
      margin: "1rem 0rem!important",
      boxShadow: userDetails.themeDark === "dark"? "none!important": "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    rightPaper: {
      width:"100%",
      margin: "1rem 0rem!important",
      boxShadow: userDetails.themeDark === "dark"? "none!important": "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    bannerLayout: {
      backgroundImage: `url(${avatar1})`,
      height: "12rem",
      backgroundSize: "cover",
      margin: "1rem 0rem 0rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    aboutProfile: {
      width: "9rem",
      borderRadius: "7rem",
      height: "9rem",
    },
    tabButton: {
      textTransform: "capitalize!important",
      fontSize: "2rem!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "800!important",
      color: userDetails.themeDark === "dark"? "white!important": "black",
      "@media (max-width: 450px)": {
        padding: "0.5rem!important",
        maxWidth: "18rem!important",
      },
    },
    secondGridResponsive: {
      padding: '1rem 2rem 0rem 1rem!important',
      "@media (max-width: 900px)": {
        padding: "1rem 1rem 1rem 0rem!important",
      },
    },
    userMenuItems: {
      display: "flex",
      justifyContent: "space-between",
      margin: "2rem",
      flexWrap: "wrap",
    },
    userMenuButton: {
      fontSize: "1.4rem!important",
      textTransform: "capitalize!important",
      gap: "0.5rem",
      color: userDetails.themeDark === "dark"? "lightgray!important" : "gray!important",
    },
    profileContainer: {
      display: "flex",
      alignItems: "center",
      gap: "3rem",
      padding: "0rem 2rem",
      margin: "-1rem 0rem 0rem",
      "@media (max-width: 900px)": {
        margin: "-1rem 0rem 0rem",
        flexWrap: "wrap",
        gap: "2rem",
      },
    },
    tabButtons: {
      borderColor: 'divider',
      boxShadow: userDetails.themeDark === "dark"? "none!important": "0px 1px 0px 1px rgba(102, 102, 102, 0.3)!important",
      borderRadius: "4px",
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
    },
  }));

  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);

  // PRE S
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.scrollTo(0, 0);
      getlearningContent();
      return () => {};
    }
  }, []);

  const getlearningContent = async () => {
    // const bodyParam = {
    //   body: {
    //     ur_id: userDetails?.uData?.ur_id,
    //     schema: config.schema,
    //     lpid: userDetails?.learning?.lpid,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: userDetails?.accessToken	
    //   },
    // };

    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getLearningContent",
    //     bodyParam
    //   );
    try {
      const requestBody = {
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        lpid: userDetails?.learning?.lpid,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getLearningContent?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	         Accept: "application/json",	
           Authorization: userDetails?.accessToken
          },
        }
      );
      if (response && response.statusCode === 200) {
        response.body.lname = userDetails?.learning?.lname;
        console.log("response", response);
        setIsLoading(false);
      } else {
        response.body.lname = userDetails?.learning?.lname;
        response.body.res = [];
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <>
      <SearchHeader />
      <div className={classes.mainLayout1}>
        <div className={classes.bannerLayout}>{/* Banner Section*/}</div>
        <div className={classes.profileContainer}>
          <img className={classes.aboutProfile} src={avatar} alt="Avatar" />
          <p style={{fontSize: "2.6rem", fontWeight: "900"}}>User number1</p>
          <Button className={classes.actionButtonFollowAbout}>Follow</Button>
          <ReplyIcon style={{transform: "scaleX(-1)",fontSize: "3rem"}}/>
        </div>
      </div>
      <Box sx={{ width: '100%', typography: 'body1'}} className={classes.mainLayout}>
        <TabContext value={value}>
          <Box className={classes.tabButtons}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Posts" value="1" className={classes.tabButton} />
              <Tab label="Media" value="2" className={classes.tabButton} />
            </TabList>
          </Box>
          <TabPanel value="1" style={{padding: "1rem 0rem"}}>
            <div>
              {isLoading ? (
                <Skeleton className={classes.loaderSkeletonSlider} />
              ) : (
                <div className={classes.root}>
                  <div>
                    <Grid container spacing={2} style={{margin: '0', padding: '0'}}>
                      <Grid item xs={12} sm={12} md={8} lg={8} style={{ padding: '1rem 1rem 1rem 0rem'}}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              height: "auto",
                            },
                          }}
                        >
                          <Paper elevation={3} className={classes.leftPaper}>
                            <Button className={classes.actionButton}>
                              <WhatshotIcon /> Hot
                            </Button>
                            <Button className={classes.actionButton}>
                              Topics <KeyboardArrowDownIcon />
                            </Button>
                            <Button className={classes.actionButton}>
                              <ArrowCircleUpIcon /> New
                            </Button>
                            <Button className={classes.actionButton}>
                              Today <KeyboardArrowDownIcon />
                            </Button>
                          </Paper>
                          <Paper elevation={3} className={classes.leftPaper}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: "2rem",}}>
                              <div>
                                <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
                                  <Person2Icon style={{fontSize:"2.5rem",}} />
                                  <h3>User_Name</h3>
                                  <FiberManualRecordIcon style={{fontSize:"0.8rem",color: "gray"}} />
                                  <p style={{color: "gray"}} >10 hours ago</p>
                                </div>
                                <h2 style={{margin: "1rem 0rem",}}>Heading of the post</h2>
                                <p>Some random text Some random text Some random text Some random text Some random text Some random text
                                Some random text Some random text Some random text Some random text Some random textSome random text
                                </p>
                              </div>
                            </div>
                            <div className={classes.userMenuItems}>
                              <Button className={classes.userMenuButton}><FavoriteBorderOutlinedIcon className={classes.socialIcons} />10k</Button>
                              <Button className={classes.userMenuButton}><ChatBubbleOutlineOutlinedIcon className={classes.socialIcons} />1k comm</Button>
                              <Button className={classes.userMenuButton}><ShareOutlinedIcon className={classes.socialIcons} />Share</Button>
                              <Button className={classes.userMenuButton}><SaveOutlinedIcon className={classes.socialIcons} />Save</Button>
                              <Button><MoreHorizOutlinedIcon className={classes.socialIcons} /></Button>
                            </div>
                          </Paper>
                          <Paper elevation={3} className={classes.leftPaper}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: "2rem",}}>
                              <div>
                                <div style={{display: "flex", alignItems: "center", gap: "1rem"}}>
                                  <Person2Icon style={{fontSize:"2.5rem",}} />
                                  <h3>User_Name</h3>
                                  <FiberManualRecordIcon style={{fontSize:"0.8rem",color: "gray"}} />
                                  <p style={{color: "gray"}} >10 hours ago</p>
                                </div>
                                <h2 style={{margin: "1rem 0rem",}}>Heading of the post</h2>
                                <p>Some random text Some random text Some random text Some random text Some random text Some random text
                                Some random text Some random text Some random text Some random text Some random textSome random text
                                </p>
                              </div>
                            </div>
                            <div className={classes.userMenuItems}>
                              <Button className={classes.userMenuButton}><FavoriteBorderOutlinedIcon className={classes.socialIcons} />10k</Button>
                              <Button className={classes.userMenuButton}><ChatBubbleOutlineOutlinedIcon className={classes.socialIcons} />1k comm</Button>
                              <Button className={classes.userMenuButton}><ShareOutlinedIcon className={classes.socialIcons} />Share</Button>
                              <Button className={classes.userMenuButton}><SaveOutlinedIcon className={classes.socialIcons} />Save</Button>
                              <Button><MoreHorizOutlinedIcon className={classes.socialIcons} /></Button>
                            </div>
                          </Paper>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} className={classes.secondGridResponsive}>
                      <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              height: "auto",
                            },
                          }}
                        >
                          <Paper elevation={3} className={classes.rightPaper}>
                            <div className={classes.firstTrend}>
                              <h4>About User</h4>
                            </div>
                            <div style={{padding: '1rem',}}>
                              <div>
                                <h4>Hello this is my profile!</h4>
                              </div>
                            </div>
                            <div style={{padding: '1rem',display: "flex", gap: "1rem", alignItems: "center"}}>
                              <AccessTimeIcon style={{fontSize:"3rem",}} />
                              <p>Created on Dec 31, 2021</p>
                            </div>
                            <div style={{justifyContent: 'space-around',display: "flex", gap: "1rem", padding: "1rem", borderTop: "1px solid lightgray"}}>
                              <Button className={classes.actionButtonFollowAbout} style={{padding: "0rem 3rem"}}>Chat</Button>
                              <Button className={classes.actionButtonFollowAbout} style={{padding: "0rem 3rem"}}>Follow</Button>
                            </div>
                          </Paper>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value="2" style={{padding: "1rem 0rem"}}>Item Two</TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
export default OtherUserProfile;
