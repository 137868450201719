import styled from "styled-components";
export const GridContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(12, [col-start] 1fr);
  gap: 2.5rem;
  margin-bottom: 4rem;
  grid-auto-flow: dense; /* Add this line */
  .item-1 {
    grid-column: col-start / span 12;
  }
  .item-2 {
    grid-column: col-start / span 6;
  }
  .item-3 {
    grid-column: span 6 / -1;
  }
  .item-4 {
    grid-column: col-start / span 12;
  }
  @media only screen and (max-width: 770px) {
    .item-1 {
      grid-column: col-start / span 12;
    }
    .item-2 {
      grid-column: col-start / span 12;
    }
    .item-3 {
      grid-column: col-start / span 12;
    }
    .item-4 {
      grid-column: col-start / span 12;
    }
  }
  @media only screen and (max-width: 475px) {
    .item-1 {
      grid-column: col-start / span 12;
    }
    .item-2 {
      grid-column: col-start / span 12;
    }
    .item-3 {
      grid-column: col-start / span 12;
    }
    .item-4 {
      grid-column: col-start / span 12;
    }
  }
  @media only screen and (max-width: 400px) {
    .item-1 {
      grid-column: col-start / span 12;
    }
    .item-2 {
      grid-column: col-start / span 12;
    }
    .item-3 {
       grid-column: col-start / span 12;
    }
    .item-4 {
      grid-column: col-start / span 12;
    }
  }
  @media only screen and (max-width: 330px) {
    .item-1 {
      grid-column: col-start / span 12;
    }
    .item-2 {
      grid-column: col-start / span 12;
    }
    .item-3 {
      grid-column: col-start / span 12;
    }
    .item-4 {
      grid-column: col-start / span 12;
    }
  }
`;
