import React, {useState} from 'react';
import ReactApexChart from 'react-apexcharts';

const RadarGraph = ({ skillsDataGraph }) => {
  // Extracting labels and values from the data object
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({}); 

  const categories = skillsDataGraph?.map((item) => item.skill);

  const counts = skillsDataGraph?.map((item) => ({
    skill: item.skill,
    completedCount: item.unique_values_count + item.completion_dates_count,
    totalCount: item.total_content_count + item.total_topic_count,
  }));

  const handleChartLeave = () => {
    setTooltipContent(""); 
  };
 
  // Function to handle mouse hover over a category
  const handleCategoryHover = (event, categoryIndex) => {
    const { pageX, pageY } = event;
    const { skill, completedCount, totalCount } = skillsDataGraph[categoryIndex];
   
    const content = `
      <div>Skill: ${skill}</div>
      <div>Completion Count: ${completedCount}</div>
      <div>Total Count: ${totalCount}</div>
    `;
    setTooltipContent(content); 
    setTooltipPosition({ top: pageY + 10, left: pageX + 10 }); 
  };
  const options = {
    chart: {
      type: "radar",
      events: {
        dataPointMouseEnter: (event, config) => {
          console.log(config, "config");
          handleCategoryHover(event, config.dataPointIndex);
        },
        dataPointMouseLeave: handleChartLeave,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "14px", 
        },
      },
    },
    legend: {
       fontSize: "18px", 
    },
    colors: ["#81c9c4", "#bfd555"],
  };

  const series = [
    {
      name: "Completion Count",
      data: counts?.map((item) => item.completedCount),
      
    },
    {
      name: "Total Count",
      data: counts?.map((item) => item.totalCount),
    },
  ];

  return (
      <div className="radar-chart" onMouseLeave={handleChartLeave}>
      <ReactApexChart
        options={options}
        series={series}
        type="radar"
        width={650}
        height={600}
      />
      {tooltipContent && (
        <div
          className="custom-tooltip"
          style={{ position: "absolute", top: tooltipPosition.top, left: tooltipPosition.left }}
          dangerouslySetInnerHTML={{ __html: tooltipContent }}
        />
      )}
    </div>
      
  );
};

export default RadarGraph;
