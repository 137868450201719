import { API } from "aws-amplify";
import Cookies from "js-cookie";
import config from "../aws-exports";
// SOCIAL LEARNING //
export const getTopics = async (userDetails) => {
  try {
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      `/topic?schema=${config.schema}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const data = JSON.parse(response?.body);
    return data;
  } catch (error) {
    throw error;
  }
};
export const getUsers = async (userDetails) => {
  try {
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      `/getTagPeopleList?schema=${config.schema}&tenant=${userDetails?.uData?.oid}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response?.body;
  } catch (error) {
    throw error;
  }
};
export const getLabels = async (userDetails) => {
  try {
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      `/getLabel?schema=${config.schema}`,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response?.body;
  } catch (error) {
    throw error;
  }
};
export const createPost = async (req, userDetails) => {
  try {
    const jsonString = JSON.stringify({ schema: config.schema, ...req });
    const base64EncodedString = btoa(jsonString);
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      `/post`,
      {
        body: { json: base64EncodedString },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const data = JSON.parse(response?.body);
    return data;
  } catch (error) {
    throw error;
  }
};
export const createUpdateAttachmentAPI = async (req, userDetails) => {
  try {
    const jsonString = JSON.stringify({ schema: config.schema, ...req });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: { json: base64EncodedString },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      `/post/${req.postid}`,
      bodyParam
    );
    const data = JSON.parse(response?.body);
    return data;
  } catch (error) {
    throw error;
  }
};
export const callAPI = async (req, userDetails) => {
  try {
    const jsonString = JSON.stringify({ schema: config.schema, ...req.body });
    const base64EncodedString = btoa(jsonString);
    var obj = {
      body: { json: base64EncodedString },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    if (req.method !== "post") {
      obj.queryStringParameters = req.body;
    }
    let response = await API[req.method](
      config.aws_cloud_logic_custom_name,
      req.apiName,
      obj
    );
    return response;
  } catch (error) {
    return error;
  }
};
// Function to send heartbeat to the server
export const timeSpent = async (userDetails) => {
  try {
    const jsonString = JSON.stringify({
      ur_id: userDetails?.uData?.ur_id,
      type: "week",
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      "/usertimespent",
      bodyParam
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const sessionDetails = async (userDetails, sid) => {
  try {
    const requestBody = {
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.uData?.oid,
      eid: userDetails.username,
      sid,
      schema: config.schema,
      emailid: userDetails?.email,
      groups: userDetails?.uData?.gid,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getSessionDetails?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getFeaturedContent = async (userDetails) => {
  try {
    const requestBody = {
      oid: userDetails?.uData?.oid,
      schema: config.schema,
      owner: "web",
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getCategories?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { topics } = response;
    return topics;
  } catch (error) {
    throw error;
  }
};
export const getCategories = async (userDetails) => {
  try {
    const requestBody = {
      oid: userDetails?.uData?.oid,
      schema: config.schema,
      owner: "web",
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getCategories?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { topics } = response;
    return topics;
  } catch (error) {
    throw error;
  }
};
export const getBanners = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      schema: config.schema,
      tenants: userDetails?.uData?.st_id,
      groups: userDetails?.uData?.gid,
      web: true,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getPostersBanners?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    if (response) {
      const banners = response.body.filter((item) => item.type === 1);
      const posters = response.body.filter((item) => item.type === 2);
      const sortedDataB = banners.sort((a, b) => {
        // Extract numeric part and convert to number
        let numA = parseInt(a.order_p);
        let numB = parseInt(b.order_p);
        return numA - numB;
      });
      const sortedDataP = posters.sort((a, b) => {
        // Extract numeric part and convert to number
        let numA = parseInt(a.order_p);
        let numB = parseInt(b.order_p);
        return numA - numB;
      });
      let data = { posters: sortedDataP, banners: sortedDataB };
      return data;
    }
  } catch (err) {
    throw err;
  }
};
export const getUserDetails = async (userDetails) => {
  try {
    const requestBody = {
      emailid: userDetails.email,
      schema: config.schema,
      address: config.profileSet,
      ur_id: userDetails.uData.ur_id,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getUserDetails?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { body } = response;
    return body && body[0];
  } catch (err) {
    throw err;
  }
};
export const getCertificates = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      tenant: userDetails.locale,
      eid: userDetails.username,
      emailid: userDetails.email,
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/listUserCerts?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { certificates } = response;
    if (response.errorType === undefined) {
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      return certificates;
    }
  } catch (error) {
    throw error;
  }
};
export const getMyTopics = async (userDetails) => {
  try {
    const requestBody = {
      schema: config.schema,
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.locale,
      groups: userDetails?.uData?.gid,
      user_lang: userDetails?.uData?.lang,
    };
    // Convert the request body to a JSON-encoded string
    const jsonString = JSON.stringify(requestBody);
    // Encode the JSON string in the query string
    const base64EncodedString = btoa(jsonString);
    // Construct the full URL with the encoded JSON string in the query parameter
    const reportInfo = `/getMyTopics?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    // Make the API call using Amplify's API.get
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { myTopics, toptopics } = response;
    let completedCourse = [],
      continueLearning = [];
    if (myTopics) {
      completedCourse = myTopics?.filter((top) => {
        top.ref_type = 1;
        if (
          top.comp_date !== null &&
          (top.pid === undefined || top.pid === null || top.pid === 0)
        )
          return true;
        return false;
      });
      continueLearning = myTopics?.filter((top) => {
        top.ref_type = 1;
        if (top.comp_date === null) return true;
        return false;
      });
    }
    return { completedCourse, continueLearning, myTopics, toptopics };
  } catch (err) {
    throw err;
  }
};
export const listBookMark = async (userDetails) => {
  try {
    const requestBody = {
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
      tenant: userDetails?.locale,
      groups: userDetails?.uData?.gid,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/listBookMark?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { items } = response;
    return items;
  } catch (err) {
    throw err;
  }
};
export const getRecenetView = async (userDetails) => {
  try {
    const requestBody = {
      schema: config.schema,
      ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.locale,
      emailid: userDetails?.email,
      groups: userDetails?.uData?.gid,
      user_lang: userDetails?.uData?.lang,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getRecenetView?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const recentview = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return recentview;
  } catch (err) {
    console.error(err);
    return err.message;
  }
};
export const dashboard = async (userDetails) => {
  try {
    const jsonString = JSON.stringify({
      tenant: userDetails?.uData?.oid,
      eid: userDetails?.sub,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
      groups: userDetails?.uData?.gid,
    });
    const base64EncodedString = btoa(jsonString);
    const res = await API.post(
      config.aws_cloud_logic_custom_name,
      "/dashboard",
      {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return res;
  } catch (err) {
    throw err;
  }
};
export const postNotificationStatusWeb = async (userDetails, notify) => {
  const jsonString = JSON.stringify({
    oid: config.aws_org_id,
    eid: userDetails.username,
    tenant: userDetails.locale,
    schema: config.schema,
    ur_id: userDetails.uData?.ur_id,
    unread: notify?.unReadNotify,
  });
  const base64EncodedString = btoa(jsonString);
  const bodyParam = {
    body: {
      json: base64EncodedString,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: userDetails?.accessToken,
    },
  };
  try {
    if (notify?.unReadNotify.length > 0) {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/postNotificationStatusWeb",
        bodyParam
      );
      await getNotifications(userDetails);
    }
  } catch (err) {
    throw err;
  }
};
export const getNotifications = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      eid: userDetails.username,
      tenant: userDetails.locale,
      schema: config.schema,
      ur_id: userDetails.uData?.ur_id,
      groups: userDetails?.uData?.gid,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getNotifications?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    const { notifications } = response;
    const res = {};
    var unrad = [];
    const notiBadge = notifications.filter((notify) => {
      if (notify.read === 0 || notify.read === "0") {
        unrad.push(notify.nt_id);
        return true;
      }
    });
    res.unReadNotify = unrad;
    res.notRead = notiBadge.length;
    const decNotification = notifications.sort((a, b) => {
      const aTime = parseInt(a.time);
      const bTime = parseInt(b.time);
      return bTime - aTime;
    });
    res.notification = decNotification;
    return res;
  } catch (err) {
    console.error(err);
  }
};
export const profileUpdate = async (data, userDetails) => {
  const jsonString = JSON.stringify(data);
  const base64EncodedString = btoa(jsonString);
  const bodyParam = {
    body: { json: base64EncodedString },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: userDetails?.accessToken,
    },
  };
  try {
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      "/updateProfile",
      bodyParam
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getLearningPathDetails = async (
  userDetails,
  id = undefined,
  from = "landingpage"
) => {
  const bodyParams = {
    schema: config.schema,
    tenant: userDetails?.uData?.oid,
    groups: userDetails?.uData?.gid,
  };
  if (from !== "landingpage") {
    bodyParams.body.tid = id;
    bodyParams.body.ur_id = userDetails?.uData?.ur_id;
  }
  try {
    const requestBody = bodyParams;
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getLearningPath?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response?.body || [];
  } catch (err) {
    throw err;
  }
};
export const fetchData = async (pbody, apiname, userDetails) => {
  const bodyParam = {
    body: {},
    headers: {
      "content-Type": "application/json",
      Accept: "application/json",
      Authorization: userDetails?.accessToken,
    },
  };
  pbody.oid = config.aws_org_id;
  pbody.schema = config.schema;
  const jsonString = JSON.stringify(pbody);
  const base64EncodedString = btoa(jsonString);
  bodyParam.body.json = base64EncodedString;
  try {
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      apiname,
      bodyParam
    );
    return response;
  } catch (error) {
    return false;
  }
};
export const getAllCourses = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      tenant: userDetails?.uData?.oid,
      schema: config.schema,
      ur_id: userDetails.uData?.ur_id,
      groups: userDetails?.uData?.gid,
      user_lang: userDetails?.uData?.lang,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getAllCourses?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response;
  } catch (error) {
    return false;
  }
};
export const getPincode = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      schema: config.schema,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getPincode?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response.body;
  } catch (error) {
    return false;
  }
};
export const languageChange = async (userDetails, langCode) => {
  const jsonString = JSON.stringify({
    oid: config.aws_org_id,
    schema: config.schema,
    lang_code: langCode,
    ur_id: userDetails?.uData?.ur_id,
  });
  const base64EncodedString = btoa(jsonString);
  const bodyParam = {
    body: {
      json: base64EncodedString,
    },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: userDetails?.accessToken,
    },
  };
  try {
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      "/languageChange",
      bodyParam
    );
    return response.body;
  } catch (error) {
    return false;
  }
};
export const getWidgets = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userDetails?.uData?.ur_id,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getWidgets?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response;
  } catch (error) {
    return false;
  }
};
export const configUpdateWidgets = async (data, userDetails) => {
  const jsonString = JSON.stringify(data);
  const base64EncodedString = btoa(jsonString);
  const bodyParam = {
    body: { json: base64EncodedString },
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: userDetails?.accessToken,
    },
  };
  try {
    const response = await API.post(
      config.aws_cloud_logic_custom_name,
      "/updateWidgets",
      bodyParam
    );
    return response;
  } catch (error) {
    throw error;
  }
};
export const getUserCertificate = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      tenant: userDetails?.uData?.oid,
      eid: userDetails.username,
      emailid: userDetails.email,
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/listUserCerts?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response.certificates;
  } catch (error) {
    console.error(error);
  }
};
export const getUserCompetencies = async (userDetails) => {
  try {
    const requestBody = {
      in_ur_id: userDetails.uData.ur_id,
      in_offset: 0,
      in_limit: 100,
      schema: config.schema,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getCompetencyWithSkills?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response.competency;
  } catch (error) {
    console.error(error);
  }
};
export const getSkillAnalyticsGraphData = async (userDetails, skills) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      schema: config.schema,
      in_skills: skills,
      in_ur_id: userDetails?.uData?.ur_id,
    };
    const jsonString = JSON.stringify(requestBody);
    // Encode the JSON string in the query string
    const base64EncodedString = btoa(jsonString);
    // Construct the full URL with the encoded JSON string in the query parameter
    const reportInfo = `/CTgetSkillAnalyticsGraphData?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    // Make the API call using Amplify's API.get
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getAssignedContent = async (userDetails) => {
  try {
    const requestBody = {
      oid: userDetails?.uData?.oid,
      schema: config.schema,
      groups: userDetails?.uData?.gid,
      ur_id: userDetails?.uData?.ur_id,
    };
    const jsonString = JSON.stringify(requestBody);
    // Encode the JSON string in the query string
    const base64EncodedString = btoa(jsonString);
    // Construct the full URL with the encoded JSON string in the query parameter
    const reportInfo = `/getAssignedContent?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    // Make the API call using Amplify's API.get
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    if (response) {
      let mergedArray = response.courses.concat(
        response.objects,
        response.sessions
      );
      mergedArray.sort((a, b) => {
        const dateA = new Date(a.pub_date);
        const dateB = new Date(b.pub_date);
        return dateB - dateA;
      });
      return mergedArray;
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const getActiveUsers = async (userDetails, active, count) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      schema: config.schema,
      tenant: userDetails?.uData?.oid,
      active: active,
      count: count,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getActiveUsers?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    if (response) {
      return response.body;
    }
  } catch (error) {
    return false;
  }
};
export const getMyPeerDetails = async (userDetails, limit) => {
  try {
    const requestBody = {
      schema: config.schema,
      my_ur_id: userDetails?.uData?.ur_id,
      tenant: userDetails?.uData?.oid,
      my_gid: userDetails?.uData?.gid,
      comp_ur_id: userDetails?.myPeer?.ur_id,
      comp_gid: userDetails?.myPeer?.gid,
      limit: limit,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getMyPeerDetails?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    if (response) {
      return response;
    }
  } catch (error) {
    return false;
  }
};

export const sl_getReportData = async (userDetails) => {
  try {
    const requestBody = {
      oid: config.aws_org_id,
      schema: config.SCHEMA_NAME,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/sl_getReportData?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      }
    );
    return response.body;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
