import React from "react";

import ListItem from "@material-ui/core/ListItem";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

import { withStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import sorticon from "../../../assets/sort.png";

import Menu from "@material-ui/core/Menu";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core";
import Tippy from "@tippyjs/react";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const defaultTippyProps = {
  // animation: "shift-away-subtle",
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom-start",
  delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,

  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
export default function CourseExploreSort({ sortTypeChange, cstype, sorttp }) {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(3),
      height: "600px",
      width: "1100px",
      backgroundColor: "white",
    },
    buttonStyle: {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: theme.typography.fontWeightRegular,
      backgroundColor: "transparent",
      border: "1px solid transparent",
      "&:focus": {
        border: "1px solid black",
      },
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontSize: "12px",
    },
    sortIconStyle: {
      cursor: "pointer",
      margin: "1rem 0.5rem",
      padding: 0,
      // backgroundColor: "white",
      border: "1px solid transparent",
      "&:focus": {
        border: "1px solid black",
      },
    },
    mainTippy: {
      backgroundColor: userDetails.themeDark === "dark"? "#3F434D !important": theme.palette.background.paper,
      boxShadow: userDetails.themeDark === "dark"? "none !important" : "auto",
      color: userDetails.themeDark === "dark"? "white !important" : "#3F434D !important",
    },
  }));
  const classes = useStyles();

  return (
    <motion.div
      style={{ display: "flex" }}
      variants={containerVarients}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Tippy className={classes.mainTippy}
        content={
          <ListItem>
            <div style={{ width: "200px" }}>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="sortType"
                  name="sortType"
                  value={cstype}
                  onChange={(e) => sortTypeChange(e)}
                >
                  {sorttp !== "Search" && (
                    <>
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="TV"
                        control={<Radio />}
                        label="Most Viewed"
                      />
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="STAR"
                        control={<Radio />}
                        label="Rating"
                      />
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="BKMD"
                        control={<Radio />}
                        label="Most Bookmarked"
                      />
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="SHRD"
                        control={<Radio />}
                        label="Most Shared"
                      />
                      <FormControlLabel
                        classes={{ label: classes.label }}
                        value="CON"
                        control={<Radio />}
                        label="Newest"
                      />
                    </>
                  )}
                  <FormControlLabel
                    classes={{ label: classes.label }}
                    value="AZ"
                    control={<Radio />}
                    label="A-Z"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </ListItem>
        }
        {...defaultTippyProps}
      >
        <ListItem className={classes.sortIconStyle}>
          {/* <ListItemText className={classes.heading} primary="Filter By" />  */}
          <button className={classes.buttonStyle} title="Sort">
            <div style={{ display: "flex" }}>
              <img src={sorticon} height={"30px"} width={"40px"} alt="Sort" />
            </div>

            {/* <span style={{ flexGrow: "30%" }}>
              {" "}
              Sort By :{" "}
              <span
                style={{
                  color: "blue",
                  flexGrow: "1",
                  textAlign: "center",
                  margin: "10px",
                }}
              > */}
            {/* {cstypeV} */}
            {/* <span
                  style={{
                    width: "140px",
                    position: "absolute",
                    bottom: "0px",
                    left: " 80px",
                    borderBottom: "1.5px solid black",
                  }}
                ></span>
              </span>
            </span> */}
          </button>
          {/* {cropenS ? <ExpandLess /> : <ExpandMore />} */}
        </ListItem>
      </Tippy>
    </motion.div>
  );
}
