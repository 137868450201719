import { Box } from "@material-ui/core"
import React from "react"
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../components/SignInAndSignUp/SignInAndSignUp"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { CircularProgress, Typography } from "@material-ui/core"

// import ReactCodeInput from "react-verification-code-input";
import CodeIcon from "@material-ui/icons/Code"

import validateResetPasswordVerification from "./SignInSignUpValidationRules/ResetPasswordValidation"
import { useState } from "react"
import { useForm } from "../../Hooks/useForm"
import Swal from "sweetalert2"
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules"
import { Theme } from "../../aws-exports"
import { Auth } from "aws-amplify"
import { useHistory, useLocation } from "react-router-dom"
import { CustomButton } from "../../components/CustomButton/CustomButton"
import CustomInput from "../../components/CustomInput/CustomInput"
import { HeadingPrimary } from "../../components/Styles/Heading"

import CustomLoginLayout from "./CustomLoginLayout"

const ConfirmForgotPassword = () => {
  const {
    values,
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false })
  const [errors, setErrors] = useState({})
  const history = useHistory()
  const { state } = useLocation()
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false)
  const passwordResetFC = async (event) => {
    event.preventDefault()
    let emailid = localStorage.getItem("email");
    if (Object.keys(validateResetPasswordVerification(values)).length !== 0) {
      setErrors(validateResetPasswordVerification(values))
      return
    } else {
      setResetPasswordLoader(true)
      try {
        await Auth.forgotPasswordSubmit(
          state.email || emailid,
          values.verificationCode,
          values.confirmPassword
        )

        Swal.fire({
          title: "Success",
          text: "Password Changed Successfully",
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        }).then((willDelete) => {
          if (willDelete.isConfirmed) {
            history.push({
              pathname: "/",
              state: { username: state.username },
            })
            setResetPasswordLoader(false)
          }
        })

        // alert("Changed password Successfully");
      } catch (error) {
        setErrors(cognitoValidation(error))
        setResetPasswordLoader(false)
        console.error(error)
      }
    }
  }
  return (
    <React.Fragment>
      <CustomLoginLayout>
        <Box style={{ textAlign: "center" }}>
          <HeadingPrimary>Password Reset</HeadingPrimary>
        </Box>
        <form
          onSubmit={passwordResetFC}
          noValidate
          autoComplete="off"
          style={displayCenter}
        >
          <Box style={inputBoxStyle}>
            <CustomInput
              placeholder="Verification Code"
              name="verificationCode"
              inputValues={values}
              value={values.verificationCode || ""}
              error={errors?.verificationCode}
              onChange={handleChange("verificationCode")}
            >
              <CodeIcon style={inputIconStyle} />
            </CustomInput>
          </Box>

          <Box style={inputBoxStyle}>
            <CustomInput
              placeholder="Password"
              name="resetPassword"
              type="password"
              inputValues={values}
              value={values.resetPassword || ""}
              error={errors?.resetPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              onChange={handleChange("resetPassword")}
            >
              <LockOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box style={inputBoxStyle}>
            <CustomInput
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
              inputValues={values}
              value={values.confirmPassword || ""}
              error={errors?.passwordConfirm}
              onChange={handleChange("confirmPassword")}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
            >
              <LockOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              display="block"
              style={{
                maxWidth: "450px",
                fontSize: "14px",
                textAlign: "center",
                fontFamily: "myfontregular",
              }}
              gutterBottom
            >
              *Password should be at least 8 characters, contain at least one
              number & one special character.
            </Typography>
          </Box>

          <Box style={{ textAlign: "center" }}>
            {resetPasswordLoader ? (
              <CustomButton disabled loading="true">
                <CircularProgress color="inherit" size={20} />
              </CustomButton>
            ) : (
              <CustomButton primary type="submit">
                Submit
              </CustomButton>
            )}
            <CustomButton
              type="button"
              onClick={() => {
                
                history.goBack()
              }}
            >
              Cancel
            </CustomButton>
          </Box>
        </form>
      </CustomLoginLayout>
    </React.Fragment>
  )
}

export default ConfirmForgotPassword
