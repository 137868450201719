import React, { useContext, useState, useEffect } from "react";
import { API, Auth } from "aws-amplify";
import { awsSignIn } from "../../redux/auth/authSlice";
import awsExports from "../../aws-exports";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import { CircularProgress, Typography, Box, Button } from "@material-ui/core";
import { CustomButton } from "../CustomButton/CustomButton";
import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import CustomInput from "../CustomInput/CustomInput";
import "react-phone-number-input/style.css";
import { useForm } from "../../Hooks/useForm";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import { Theme } from "../../aws-exports";
import validate from "./SignInSignUpValidationRules/SignInValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import { HeadingPrimary } from "../Styles/Heading";
import Swal from "sweetalert2";
import "./phoneInput.styles.css";
import config from "../../aws-exports";
import validateResetPasswordVerification, {
  validateResetPassword,
} from "./SignInSignUpValidationRules/ResetPasswordValidation";
import validateResetPasswordEmail from "./SignInSignUpValidationRules/ResetPasswordValidationEmail";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { languageChange } from "../../api/api";
const SignIn = () => {
  const history = useHistory();
  const query = useQueryParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const {
    values,
    handleChange,
    setValues,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });
  const { setSignUp, setSignIn, signIn, signUp } = useContext(
    SignInAndSignUpValues
  );
  const [forgotPassword, setForgotPassword] = useState(false);
  const [resetPasswordOpenFirst, setResetPasswordOpenFirst] = useState(false);
  const [signInForm, setSignInForm] = useState(true);
  const [emailVerification, setEmailVerification] = useState(false);
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const [emailVerifyLoader, setEmailVerifyLoader] = useState(false);
  const [resetPasswordLoader, setResetPasswordLoader] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        const user = await Auth.signIn(values.username, values.password);
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          setSignInForm(false);
          setResetPasswordOpenFirst(true);
          setSignUp(false);
          setValues({ ...values, showPassword: false });
          setForgotPassword(false);
          setBtnLoading(false);
        }
        const info = await Auth.currentUserInfo();
        let userData = info.attributes;
        userData.username = info.username;
        userData.id = info.id;
        let authkey = localStorage.getItem("accessToken");
        const tokenrequestBody = {
          client_key: values.username,
          client_secret: config.secretKeyAfter, 
          schema: config.schema
        };
        const tokenjsonString = JSON.stringify(tokenrequestBody);
        const tokenbase64EncodedString = btoa(tokenjsonString);
        const tokenreportInfo = `/getUserToken?json=${encodeURIComponent(
          tokenbase64EncodedString
        )}`;
        const tokenresponse = await API.get(
          awsExports.aws_cloud_logic_custom_name,
          tokenreportInfo,
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization:authkey
            },
          }
        );
        userData.accessToken = tokenresponse.accessToken;
        userData.refreshToken = tokenresponse.refreshToken;
        localStorage.setItem(
          "after_logIn_accessToken",
          tokenresponse?.accessToken
        );
        window.after_logIn_accessToken = tokenresponse?.accessToken
        userData.beforeAuth = authkey
        userData.location = {};
        getUserDetails(userData);
      } catch (error) {
        setBtnLoading(false);
        setErrors(cognitoValidation(error));
      }
    }
  };
  const getUserDetails = async (userdata) => {
    try {
      const requestBody = {
        emailid: userdata.email,
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userdata?.accessToken,
          },
        }
      );
      if (response && response.body[0] && response.body[0].enable) {
        userdata.uData = response.body[0];
        analyticsWebApp(userdata);
      } else {
        var obj = {};
        obj.password = "EmailID is Disabled";
        setErrors(obj);
        setBtnLoading(false);
        history.push("/");
      }
    } catch (err) {
      console.error(err);
    }
  };
  async function analyticsWebApp(userDetails) {
    const jsonString = {
      oid: config.aws_org_id,
      eventtype: "AuthenticatedViaCognito",
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      email: userDetails.username,
      name: userDetails.name,
      emailid: userDetails.email,
      tenant: userDetails.locale,
      ur_id: userDetails.uData?.ur_id,
      schema: config.schema,
    };
    jsonString.emailid = userDetails.emailid;
    const nameModify = JSON.stringify(jsonString);
    const base64EncodedString = btoa(nameModify);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const res = await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      let lang = await languageChange(userDetails, userDetails.uData?.lang);
      userDetails.languagejson = lang?.languagejson;
      userDetails.themeDark = "light";
      dispatch(awsSignIn(userDetails));
      setBtnLoading(false);
      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.replace({
          pathname: '/home',
          state: { poster: true }
        });
      }
    } catch (err) {
      history.push("/home");
    }
  }
  const resetPasswordFC = async (event) => {
    event.preventDefault();
    if (Object.keys(validateResetPassword(values)).length !== 0) {
      setErrors(validateResetPassword(values));
      return;
    } else {
      try {
        setResetPasswordLoader(true);
        await Auth.signIn(values.username, values.password).then((users) => {
          if (users.challengeName === "NEW_PASSWORD_REQUIRED") {
            Auth.completeNewPassword(users, values.confirmPassword);
          }
        });
        setResetPasswordLoader(false);
        Swal.fire({
          title: "Success",
          text: "Password Changed Successfully",
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        }).then((willDelete) => {
          if (willDelete) {
            setSignInForm(true);
            setResetPasswordOpenFirst(false);
          }
        });
      } catch (error) {
        console.error(error);
        setErrors(cognitoValidation(error));
      }
    }
  };
  const passwordResetFC = async (event) => {
    event.preventDefault();
    if (Object.keys(validateResetPasswordEmail(values)).length !== 0) {
      setErrors(validateResetPasswordEmail(values));
      return;
    } else {
      if (Object.keys(validateResetPasswordVerification(values)).length !== 0) {
        setErrors(validateResetPasswordVerification(values));
        return;
      } else {
        setResetPasswordLoader(true);
        try {
          await Auth.forgotPasswordSubmit(
            values.verificationEmail,
            values.verificationCode,
            values.confirmPassword
          );
          setResetPasswordLoader(false);
          Swal.fire({
            title: "Success",
            text: "Password Changed Successfully",
            icon: "success",
            confirmButtonColor: Theme.color.primary,
            confirmButtonText: "Ok",
          }).then((willDelete) => {
            if (willDelete.isConfirmed) {
              setSignIn(true);
              setSignInForm(true);
              setForgotPassword(false);
              setSignUp(false);
            }
          });
        } catch (error) {
          setResetPasswordLoader(false);
          setErrors(cognitoValidation(error));
          console.error(error);
        }
      }
    }
  };
  return (
    <React.Fragment>
      {signInForm && (
        <React.Fragment>
          <Box style={{ textAlign: "center" }}>
            <HeadingPrimary tabIndex={2}>
              <h3>Login to your account</h3>
            </HeadingPrimary>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <div style={inputBoxStyle}>
              <CustomInput
                id="input-username"
                placeholder="Username"
                type="email"
                value={values.username || ""}
                error={errors.email}
                onChange={handleChange("username")}
                style={{ fontFamily: "myfontregular" }}
              >
                <PersonOutlineOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </div>
            <div style={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password"
                type="password"
                inputValues={values}
                value={values.password || ""}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
                error={errors.password}
                style={{ fontFamily: `myfontregular` }}
              >
                <LockOutlinedIcon style={inputIconStyle} />
              </CustomInput>
              <Button
                onClick={() => {
                  setForgotPassword(true);
                  setSignInForm(false);
                  setErrors({});
                  setValues({});
                }}
                variant="caption"
                display="block"
                gutterBottom
                style={{
                  fontSize: "1.4rem",
                  textAlign: "right",
                  cursor: "pointer",
                  fontFamily: `myfontregular`,
                  padding: "0",
                  textTransform: "none",
                  float: "right",
                  color: "black",
                }}
              >
                Forgot password?
              </Button>
            </div>
            <div style={{ textAlign: "center" }}>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={10} />
                </CustomButton>
              ) : (
                <CustomButton
                  type="submit"
                  primary
                  className="btnlogin"
                  style={{ fontFamily: `myfontregular` }}
                >
                  Login
                </CustomButton>
              )}
            </div>
          </form>
          {config.signUp === true ? (
            <Typography
              onClick={() => {
                setSignIn(false);
                setSignUp(true);
                setValues({});
              }}
              variant="caption"
              display="block"
              gutterBottom
              style={{
                fontSize: "1.4rem",
                textAlign: "center",
                cursor: "pointer",
                display: "flex",
                gap: "0.5rem",
                margin: "1rem 0rem",
              }}
            >
              Are you a new user?
              <span
                style={{
                  textDecoration: "underline",
                  color: Theme.color.secondary,
                  fontWeight: "bold",
                }}
              >
                Sign up here
              </span>
            </Typography>
          ) : null}
        </React.Fragment>
      )}
      {forgotPassword && (
        <ForgotPassword
          emailVerifyLoader={emailVerifyLoader}
          setEmailVerifyLoader={setEmailVerifyLoader}
          setEmailVerification={setEmailVerification}
          passwordResetFC={passwordResetFC}
          setForgotPassword={setForgotPassword}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          values={values}
          handleChange={handleChange}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
          setSignIn={setSignIn}
          setSignUp={setSignUp}
          signIn={signIn}
          signUp={signUp}
          forgotPassword={forgotPassword}
          setSignInForm={setSignInForm}
        />
      )}
      {resetPasswordOpenFirst && (
        <ResetPassword
          resetPasswordFC={resetPasswordFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
          setSignIn={setSignIn}
          setSignUp={setSignUp}
          signIn={signIn}
          signUp={signUp}
        />
      )}
    </React.Fragment>
  );
};
export default SignIn;
