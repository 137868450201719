import { Box, Container } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";

const Loding = () => {
  return (
    <Box>
      <Skeleton variant="rect" width={310} height={170} />
      <Box pt={0.5}>
        <Skeleton />
        <Skeleton width="60%" />
      </Box>
    </Box>
  );
};

export default Loding;
