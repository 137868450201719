import React, { useEffect } from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { styled } from '@mui/material/styles';
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import EmptyChart from "../../assets/Empty Chart.png";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
const BorderLinearProgress1 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1E7BB9' : '#c1c1c1',
  },
}));
const BorderLinearProgress2 = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 1,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1EB91E' : '#7f7f7f',
  },
}));
const BorderLinearProgressEmpty = styled(LinearProgress)(({ theme }) => ({
  height: 20,
  borderRadius: 1,
  "& > span" : {
    backgroundColor: "lightgray",
  },
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 5,
  },
}));
function ProgressTracker(props) {
  const {cmpcount = 0, cncount = 0 } = props;
  var data1 = [
    { label: 'In progress', value: cncount, color: '#1E7BB9' },
    { label: 'Completed', value: cmpcount, color: '#1EB91E' },
  ];
  const totalCourseCount = props.totalCourseCount;
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      height: "100%",
      padding: "1.5rem 0rem",
      backgroundColor:
      userDetails.themeDark === "dark"
          ? "#282D37 !important"
          : "#ffffff",
      borderRadius: "5px",
      boxShadow:
      userDetails.themeDark === "dark"
          ? "none"
          : "0px 0px 20px 0px #c3d0dc",
    },
    mainBox: {
      backgroundColor:
      userDetails.themeDark === "dark"
          ? "#3F434D !important"
          : "#F2F2F2",
      margin: "1.35rem 2rem",
      padding:"2rem 1rem 0rem 2rem",
      borderRadius: "5px",
      boxShadow:
      userDetails.themeDark === "dark"
          ? "none"
          : "0px 2px 5px 0px rgb(155 155 155 / 36%)",
      "@media (max-width: 550px)": {
        padding:"2rem",
        margin:0
      },
    },
    pieChartStyle: {
      width: "40%",
      "@media (max-width: 750px)": {
        width: "100%",
      },
      "@media (min-width: 770px) and (max-width: 1360px)": {
        width: "100%",
      },
      "& > div > svg > g > g > g > path:nth-child(1)": {
        fill: "#1E7BB9"
      },
      "& > div > svg > g > g > g > path:nth-child(2)": {
        fill: "#1EB91E"
      },
      "& > div > svg > g > g > g > path:nth-child(3)": {
        fill: "#F8C354"
      },
    },
    pieChartStyle1: {
      width: "40%",
      "@media (max-width: 750px)": {
        width: "100%",
      },
      "@media (min-width: 770px) and (max-width: 1360px)": {
        width: "100%",
      },
      "& > div > svg > g > g > g > path": {
        fill: "#1E7BB9"
      },
    },
    flexStyleResponsive: {
      display: "flex",
      "@media (max-width: 600px)": {
        display: "block",
        paddingBottom: "1rem",
      },
      "@media (min-width: 770px) and (max-width: 1111px)": {
        display: "block",
        height: "23rem",
        overflow: "auto",
        paddingBottom: "3rem",
      },
      "& > div:nth-child(2)": {
        display: "grid",
        alignItems: "center",
        gap: "3rem",
        margin: "auto",
        width: "60%",
        "@media (max-width: 750px)": {
          width: "100%",
        },
        "@media (min-width: 770px) and (max-width: 1360px)": {
          width: "100%",
        },
      },
    },
    progressBar: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      gap: "1rem",
      flexWrap: "wrap-reverse",
      "& > p:nth-child(1)": {
        width: "82%",
        fontWeight: "700",
        overflowWrap: "break-word",
      },
      "& > p:nth-child(3)": {
        width: "4rem",
        fontWeight: "700",
      },
    },
    subHeading: {
      color:
      userDetails.themeDark === "dark"
          ? "#dddddd !important"
          : "#4f4f4f",
      fontSize:"1.8rem",
    },
    progressBarContainer: {
      width: "82%",
      "@media (min-width: 400px) and (max-width: 600px)": {
        width: "99%",
      },
    },
  }));
  useEffect(() => {
    data1[0].value = cncount;
    data1[1].value = cmpcount;
  }, [cmpcount, cncount]);
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1.2rem",
    },
  }));
  const classes = useStyles();
  return (
    <div className={classes.mainContainer}>
      <h3 style={{paddingLeft: "2rem",}}>{`${userDetails?.languagejson?.Course_View?.My_Courses_Progress_Tracker || "My Courses Progress Tracker"}`}</h3>
      <div className={classes.mainBox}>
        <p className={classes.subHeading}>{`${userDetails?.languagejson?.Course_View?.Total_Assigned_Courses || "Total Courses"}`}</p>
        <p style={{fontSize:"1.8rem",fontWeight: "700", marginTop: "1rem"}}>{totalCourseCount}</p>
        {(cncount + cmpcount)  === 0 ? (
          <div className={classes.flexStyleResponsive}>
            <div className={classes.pieChartStyle1} style={{position: "relative", marginBottom: "2rem"}}>
              <img src={EmptyChart} alt="Empty Chart" title="Empty Chart" />
              <div style={{position: "absolute", top: "8rem", left: "7rem", textAlign: "center"}}>
                <p className={classes.subHeading}>{`${userDetails?.languagejson?.Home_Page?.No_Data || "No Data"}`}</p>
                <BootstrapTooltip title={`${userDetails?.languagejson?.Home_Page?.Contact_Admin_for_course_information || "Contact Admin for course information"}`}>
                  <InfoOutlinedIcon style={{ fontSize: "2rem", color: "#1974b5", marginTop: "5px"}} />
                </BootstrapTooltip>
              </div>
            </div>
            <div>
              <div className={classes.progressBar}>
                <p>{`${userDetails?.languagejson?.Home_Page?.In_Progress || "In progress"}`}</p>
                <div className={classes.progressBarContainer}><BorderLinearProgressEmpty variant="determinate" value={cncount*100/(cncount + cmpcount)} /></div>
                <p className={classes.subHeading}>-</p>
              </div>
              <div className={classes.progressBar}>
                <p>{`${userDetails?.languagejson?.Home_Page?.Completed || "Completed"}`}</p>
                <div className={classes.progressBarContainer}><BorderLinearProgressEmpty variant="determinate" value={cmpcount*100/(cncount + cmpcount)} /></div>
                <p className={classes.subHeading}>-</p>
              </div>
            </div>
          </div>
        ) : (
          <div className={classes.flexStyleResponsive}>
            <div className={classes.pieChartStyle}>
              <PieChart
                series={[
                  {
                    data: data1,
                    cx: 100,
                    cy: 105,
                    innerRadius: 60,
                    outerRadius: 90,
                  },
                ]}
                height={230}
                legend={{ hidden: true }}
              />
            </div>
            <div>
              <div className={classes.progressBar}>
                <p>{`${userDetails?.languagejson?.Home_Page?.In_Progress || "In progress"}`}</p>
                <div className={classes.progressBarContainer}><BorderLinearProgress1 variant="determinate" value={cncount*100/(cncount + cmpcount)} /></div>
                <p>{cncount}</p>
              </div>
              <div className={classes.progressBar}>
                <p>{`${userDetails?.languagejson?.Home_Page?.Completed || "Completed"}`}</p>
                <div className={classes.progressBarContainer}><BorderLinearProgress2 variant="determinate" value={cmpcount*100/(cncount + cmpcount)} /></div>
                <p>{cmpcount}</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default ProgressTracker;