import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  value: {},
}

export const sessionData = createSlice({
  name: "session",
  initialState,
  reducers: {
    session: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { session } = sessionData.actions

export default sessionData.reducer
