import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Stack, Typography, Breadcrumbs, Box } from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/system";
import { getMyPeerDetails } from "../../api/api";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
function CommonCourses() {
  const userDetails = useSelector(authData);
  const [loader, setLoader] = useState(true);
  const { data: userData, isLoading: spin } = useQuery({
    queryKey: ["peerDet"],
    queryFn: () => getMyPeerDetails(userDetails, false),
    onSuccess: (data) => {
      handlefilter(data);
      setLoader(false); // Data successfully loaded
    },
  });

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    LPMainContainer: {
      width: "100%",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 6px 0 #6666665c",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#272C38 !important"
          : theme.palette.background.paper,
      borderRadius: "5px",
      padding: "2rem",
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
    mainCompareSection: {
      width: "fit-content",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 6px 0 #8787875c",
      padding: "6px 20px 20px",
      margin: "auto",
      borderRadius: "6px",
      backgroundColor: userDetails.themeDark === "dark" ? "#353944" : "#fff",
    },
    mainbodySection: {
      display: "flex",
      alignItems: "center",
      gap: "5rem",
      [theme.breakpoints.down(420)]: {
        gap: "2rem",
      },
    },
    headerSection: {
      marginBottom: "20px",
      textAlign: "center",
    },
    mainCompareSection3: {
      boxShadow:
        userDetails.themeDark === "dark"
          ? "none !important"
          : "0 0 2px 0 #8787875c",
      padding: "20px 55px",
      borderRadius: "6px",
      width: "400px",
      backgroundColor: userDetails.themeDark === "dark" ? "#353944" : "#edf5fa",
      [theme.breakpoints.down(540)]: {
        padding: "20px",
      },
    },
    mainSection: {
      display: "flex",
      gap: "2rem",
      flexWrap: "wrap",
      [theme.breakpoints.down(1050)]: {
        justifyContent: "center",
      },
    },
    mainbodyGraphSection: {
      display: "grid",
      textAlign: "center",
    },
  }));

  const classes = useStyles();

  const [allCommonData, setAllCommonData] = useState([]);

  const handlefilter = (userDataD) => {
    // Filter and match based on tid
    const matchedData = userData?.my_ur_id?.courses
      .map((obj1) => {
        const matchedObj = userData?.compare_ur_id?.courses.find(
          (obj2) => obj2.tid === obj1.tid
        );
        if (matchedObj) {
          const progress =
            ((obj1.cobj + matchedObj.cobj) / (obj1.tobj + matchedObj.tobj)) *
            100;

          let user_One = {
            cobj: obj1?.cobj,
            tobj: obj1?.tobj,
            comp_date: obj1?.comp_date,
          };
          let userOne_prog = (user_One.cobj / user_One.tobj) * 100;

          let user_Two = {
            cobj: matchedObj?.cobj,
            tobj: matchedObj?.tobj,
            comp_date: matchedObj?.comp_date,
          };
          let userTwo_prog = (user_Two.cobj / user_Two.tobj) * 100;
          return {
            tn: obj1.tn,
            tid: obj1.tid,
            user_One: userOne_prog,
            user_Two: userTwo_prog,
            user_One_name: userData?.my_ur_id?.user_details[0]?.first_name,
            user_two_name: userData?.compare_ur_id?.user_details[0]?.first_name,
            user_one_comp: user_One?.comp_date,
            user_two_comp: user_Two?.comp_date,

            // Calculate and format progress
          };
        }
        return null;
      })
      .filter((item) => item !== null);

    //const limitedMatchedData = matchedData.slice(0, 3);

    setAllCommonData(matchedData);
  };

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      placement="left"
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "black",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
      fontSize: "1.2rem",
    },
  }));

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={"/my-peers"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Active_User?.MyPeers || "My Peers"}`}
    </Link>,
    <Link
      underline="hover"
      key="2"
      color="inherit"
      to={"/compare"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Active_User?.Compare || "Compare"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      className={classes.breadcrumbNameStyle}
    >
      {`${userDetails?.languagejson?.Active_User?.Compare || "Common Courses"}`}
    </Typography>,
  ];

  const BlackCircularProgress = styled(CircularProgress)({
    color: "#707070", // for background
  });

  const BlueCircularProgress = styled(CircularProgress)(({ value }) => ({
    color: value === 100 ? "#48bc48" : "#f6c55d", // for the progress
    position: "absolute",
    "& .MuiCircularProgress-circle": {
      strokeLinecap: "round", // rounded caps
    },
  }));

  function CircularProgressWithLabel(props) {
    return (
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          width: 100,
          height: 100,
        }}
      >
        <BlackCircularProgress
          variant="determinate"
          value={100}
          sx={{ width: "100% !important", height: "100% !important" }}
        />
        <BlueCircularProgress
          variant="determinate"
          {...props}
          sx={{ width: "100% !important", height: "100% !important" }}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{
              fontSize: 20,
              color: userDetails.themeDark === "dark" ? "#fff" : "#000",
            }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="large"
              style={{ color: "var(--color-primary)" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div className="flex-between">
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {`${userDetails?.languagejson?.Active_User?.CommonCourses ||
              "Common Courses"}`}
          </h3>
        </div>
      </div>
      {loader ? (
        <>
          <Skeleton variant="rectangular" width={610} height={418} />
        </>
      ) : (
        <div className={classes.root}>
          <div className={classes.LPMainContainer}>
            <div className={classes.mainSection}>
              {allCommonData.map((data) => (
                <div className={classes.mainCompareSection3}>
                  <h4 className={classes.headerSection}>{data.tn}</h4>
                  <div className={classes.mainbodySection}>
                    <div className={classes.mainbodyGraphSection}>
                      <BootstrapTooltip
                        title={`${data.user_One_name}'s course progress`}
                      >
                        <div>
                          <CircularProgressWithLabel value={data.user_One} />
                        </div>
                      </BootstrapTooltip>
                      <h4>{`${userDetails?.languagejson?.Active_User
                        ?.Completion || "Completion"}`}</h4>
                    </div>
                    <div
                      style={{
                        backgroundColor: "#dbe7ee",
                        width: "1px",
                        height: "8rem",
                      }}
                    ></div>
                    <div className={classes.mainbodyGraphSection}>
                      <BootstrapTooltip
                        title={`${data.user_two_name}'s course progress`}
                      >
                        <div>
                          <CircularProgressWithLabel value={data.user_Two} />
                        </div>
                      </BootstrapTooltip>
                      <h4>{`${userDetails?.languagejson?.Active_User
                        ?.Completion || "Completion"}`}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default CommonCourses;
