import React, { useRef, useState, useEffect } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Theme } from "../../aws-exports";
import { Box } from "@mui/system";
import {
  HeadingPrimary,
  HeadingSecondary,
  HeadingThree,
} from "../../components/Styles/Heading";
import { Button, Paper, Skeleton } from "@mui/material";

import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useHistory } from "react-router-dom";
import Dialog from "@mui/material/Dialog";

import { useDispatch, useSelector } from "react-redux";
import RegisterFrom, { StyledButtons } from "./RegisterForm/RegisterFrom";
import FeedbackForm from "./FeedbackForm/FeedbackForm";
import moment from "moment";
import SignInAndSignUp from "../../components/SignInAndSignUp/SignInAndSignUp";
import { useQuery } from "@tanstack/react-query";
import config from "../../aws-exports";
import { authData } from "../../redux/auth/authSlice";
import LoadingButton from "@mui/lab/LoadingButton";

import SpeakerDialog from "./SpeakerDialog";
import CertificatePopUp from "./CertificatePopUp";
import SlidesPopUp from "./SlidesPopUp";
import Swal from "sweetalert2";
import "./liveSession.css";
import { setViewAllArrData } from "../../redux/HomeData/HomeDataSlice";

import { useQueryParams } from "../../Hooks/useQueryParams";
import { Wrapper } from "./LiveSession.Styled";
import { API } from "aws-amplify";
import { sessionDetails } from "../../api/api";

const StyledButtonJoin = {
  background: "#6a5acd",
  color: Theme.color.white,
  borderColor: "#6a5acd",
  textTransform: "unset",
  my: 1,
  "&:hover": {
    background: "#6a5acd",
    color: Theme.color.white,
    borderColor: "#6a5acd",
  },
};
const LiveSession = () => {
  const dispatch = useDispatch();
  const query = useQueryParams();
  const history = useHistory();
  const { viewAllArr } = useSelector((state) => state.home);

  const userDetails = useSelector(authData);
  const [expressInterest, setExpressInterest] = useState(false);

  const [loginOpen, setLoginOpen] = useState(false);
  const onlySignUp = useRef(false);
  const sessionData = useSelector((state) => state?.sessionData?.value);

  const [openSpeaker, setSpeaker] = useState(false);
  const [speakerDetails, setSpeakerDetails] = useState({});
  const [sessionCompleted, setSessionCompleted] = useState(false);
  const [expressInterestLoader, setExpressInterestLoader] = useState(false);
  const [regOpen, setRegOpen] = useState(false);
  const [regButton, setRegButton] = useState(false);
  const [sessionJoin, setSessionJoin] = useState(false);
  const [gray, setGray] = useState(false);
  const [certificate, setCertificate] = useState(false);
  const [slides, setSlides] = useState(false);
  const [slideOpen, setSlideOpen] = useState(false);
  const [feedbackFormOpen, setFeedbackFormOpen] = useState(false);
  const [feedback, setFeedback] = useState(false);

  const [certificateOpen, setCertificateOpen] = useState(false);

  const [checkSessionLimitLoader, setCheckSessionLimitLoader] = useState(false);
  const [joinButtonLoader, setJoinButtonLoader] = useState(false);
  const [paylater, setPaylater] = useState(false);

  const handleClickCertificateOpen = () => {
    setCertificateOpen(true);
  };
  const feedbackFormClose = () => {
    setFeedbackFormOpen(false);
  };
  const handleClickSlidesOpen = () => {
    setSlideOpen(true);
  };

  const handleClickSpeakerOpen = async (details) => {
    setSpeaker(true);
    setSpeakerDetails(details);
  };
  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["sessionDetails", query.get("sid")],
    queryFn: () => sessionDetails(userDetails, query.get("sid")),
  });

  useEffect(() => {
    if (data) {
      const multidayarray = data?.sData?.date_list;
      if (multidayarray !== undefined && multidayarray != null) {
        var startTime = moment(
          new Date(multidayarray[0]?.combineStartTime).getTime()
        );
        var endTime = moment(
          new Date(
            multidayarray[multidayarray.length - 1]?.combineEndTime
          ).getTime()
        );
        var minutesDiff = endTime.diff(startTime, "minutes");
        let currentTime = data.currentTime;
        let sTime = new Date(multidayarray[0].combineStartTime).getTime();
        let sessionData = data?.sData;
        let userData = data?.uData;
        let expInterest = data?.ei;
        let registrationStartDate = parseInt(data?.sData?.regdate);

        let timeDuration = moment(sTime)
          .add(minutesDiff, "m")
          .toDate();

        let JoinTime = moment(currentTime).toDate();
        let joinButton = false;
        for (let item of multidayarray) {
          if (item.date === new Date(currentTime).toISOString().slice(0, 10)) {
            let before10Min = moment(item.combineStartTime)
              .subtract(10, "m")
              .toDate();
            if (
              before10Min.getTime() <= JoinTime.getTime() &&
              JoinTime.getTime() <= new Date(item.combineEndTime).getTime()
            ) {
              joinButton = true;
            }
          }
        }
        if (currentTime < timeDuration.getTime()) {
          // alert("hi");
          if (
            userData !== undefined &&
            Object.keys(userData).length > 0 &&
            userData?.u_type !== 0
          ) {
            if (userData.u_type === 2) {
              setRegButton(false);
              setSessionJoin(false);
            } else if (userData.pstatus === 10) {
              setRegButton(true);
              setPaylater(false);
            } else if (userData.pstatus === 2 && sessionData.paid === "true") {
              setRegButton(true);
              setPaylater(true);
            } else {
              setExpressInterest(false);
              setRegButton(false);
              if (
                userData !== undefined &&
                Object.keys(userData).length > 0 &&
                (userData?.aprstatus === 1 ||
                  sessionData?.paid === "false" ||
                  sessionData?.paid === false)
              ) {
                if (joinButton && sessionData?.weblink !== null) {
                  setSessionJoin(true);
                  setGray(false);
                } else {
                  setSessionJoin(true);
                  setGray(true);
                }
              } else {
                setSessionJoin(true);
                setGray(true);
              }
            }
          } else {
            setExpressInterest(true);
            if (moment(currentTime).isAfter(moment(startTime))) {
              setRegButton(false);
              setExpressInterest(false);
              setSessionJoin(false);
            } else if (sessionData?.reg_start === true) {
              setExpressInterest(false);
              setSessionJoin(false);
              setRegButton(true);
            } else {
              // alert("hi 2");
              if (
                registrationStartDate <= JoinTime.getTime() &&
                JoinTime.getTime() < sTime
              ) {
                setRegButton(true);
                setExpressInterest(false);
              } else {
                // alert("hi 4");
                setRegButton(false);
                if (expInterest !== undefined && expInterest === true) {
                  setExpressInterest(false);
                }
              }
            }
          }
        } else if (
          userData !== undefined &&
          Object.keys(userData).length > 0 &&
          userData?.aprstatus === 1
        ) {
          // timeout and registered
          setSessionCompleted(true);
          setRegButton(false);
          setExpressInterest(false);
          setSessionJoin(false);
          setSlides(false);
          if (
            userData?.attend !== undefined &&
            userData?.attend !== null &&
            userData?.attend &&
            (query.get("feedback") || data?.feedback)
          ) {
            setCertificate(true);
          } else {
            setCertificate(false);
          }
          if (
            sessionData?.slide_tname !== null &&
            sessionData?.slide_tname !== "" &&
            sessionData?.slide_tname !== "undefined" &&
            sessionData?.slide_oname !== null &&
            sessionData?.slide_oname !== "" &&
            sessionData?.slide_oname !== "undefined" &&
            (query.get("feedback") || data?.feedback)
          ) {
            setSlides(true);
          } else {
            setSlides(false);
          }
          setFeedback(true);

          if (query.get("feedback") || data?.feedback) {
            setFeedback(false);
          } else {
            setFeedback(true);
          }
        } else {
          // time not and not registered
          setFeedback(false);
          setRegButton(false);
          if (
            data?.sData?.date_list[0]?.combineStartTime !== undefined ||
            (userData?.aprstatus === null && data.ei)
          ) {
            setExpressInterest(false);
          } else {
            setExpressInterest(true);
          }

          setSessionJoin(false);
          setSlides(false);
          setCertificate(false);
        }
      } else {
        let startTime = moment(parseInt(data?.sData?.startdate));
        let endTime = moment(parseInt(data?.sData?.enddate));
        let minutesDiff = endTime.diff(startTime, "minutes");
        let currentTime = data.currentTime;
        let sTime = parseInt(data?.sData?.startdate);
        let sessionData = data?.sData;
        let userData = data?.uData;
        let expInterest = data?.ei;
        let registrationStartDate = parseInt(data?.sData?.regdate);

        let timeDuration = moment(sTime)
          .add(minutesDiff, "m")
          .toDate();
        let before10Min = moment(sTime)
          .subtract(10, "m")
          .toDate();
        let JoinTime = moment(currentTime).toDate();
        // let joinButton = false;
        if (currentTime < timeDuration.getTime()) {
          // alert("hi 1");

          if (userData !== undefined && Object.keys(userData).length > 0) {
            if (userData.u_type === 2) {
              setRegButton(false);
              setSessionJoin(false);
            } else if (userData.pstatus === 10) {
              setRegButton(true);
            } else {
              setExpressInterest(false);
              setRegButton(false);
              if (
                userData !== undefined &&
                Object.keys(userData).length > 0 &&
                (userData?.aprstatus === 1 ||
                  sessionData?.paid === "false" ||
                  sessionData?.paid === false)
              ) {
                if (
                  before10Min.getTime() <= JoinTime.getTime() &&
                  JoinTime.getTime() <= timeDuration.getTime() &&
                  sessionData?.weblink !== null
                ) {
                  setSessionJoin(true);
                  setGray(false);
                } else {
                  setSessionJoin(true);
                  setGray(true);
                }
              } else {
                setSessionJoin(true);
                setGray(true);
              }
            }
          } else {
            setExpressInterest(true);

            if (moment(currentTime).isAfter(moment(startTime))) {
              setRegButton(false);
              setExpressInterest(false);
              setSessionJoin(false);
            } else if (sessionData?.reg_start) {
              setExpressInterest(false);
              setSessionJoin(false);
              setRegButton(true);
            } else {
              if (
                registrationStartDate <= JoinTime.getTime() &&
                JoinTime.getTime() < sTime
              ) {
                setRegButton(true);
                setExpressInterest(false);
              } else {
                setRegButton(false);
                if (expInterest !== undefined && expInterest === true) {
                  setExpressInterest(false);
                }
              }
            }
          }
        } else if (
          userData !== undefined &&
          Object.keys(userData).length > 0 &&
          userData?.aprstatus === 1
        ) {
          // timeout and registered
          setSessionCompleted(true);
          setRegButton(false);
          setExpressInterest(false);
          setSessionJoin(false);
          setSlides(false);
          if (
            userData?.attend !== undefined &&
            userData?.attend !== null &&
            userData?.attend !== 0 &&
            (query.get("feedback") || data?.feedback)
          ) {
            setCertificate(true);
          } else {
            setCertificate(false);
          }
          if (
            sessionData?.slide_tname !== null &&
            sessionData?.slide_tname !== "" &&
            sessionData?.slide_tname !== "undefined" &&
            sessionData?.slide_oname !== null &&
            sessionData?.slide_oname !== "" &&
            sessionData?.slide_oname !== "undefined" &&
            (query.get("feedback") || data?.feedback)
          ) {
            setSlides(true);
          } else {
            setSlides(false);
          }
          if (query.get("feedback") || data?.feedback) {
            setFeedback(false);
          } else {
            setFeedback(true);
          }
        } else {
          // time not and not registered
          setFeedback(false);
          setRegButton(false);

          setExpressInterest(false);

          setSessionJoin(false);
          setSlides(false);
          setCertificate(false);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    const feedback = query.get("feedback");
    const sid = query.get("sid");
    const userId = query.get("userId");
    if (sid && userId && feedback) {
      const submitFeedback = async () => {
        try {
          API.post(
            config.aws_cloud_logic_custom_name,
            `/submit-feedback?user_id=${userId}&session_id=${sid}&feedback=${feedback}`,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: userDetails?.accessToken,
              },
            }
          );
        } catch (error) {
          console.error(error);
        }
      };

      submitFeedback();
    }

    return () => {};
  }, []);

  const handleClose = () => {
    setRegOpen(false);
    setRegButton(true);
    refetch();
  };
  const handleExpressInterest = async () => {
    setExpressInterestLoader(true);
    try {
      setExpressInterestLoader(true);

      const jsonString = JSON.stringify({
        sid: query.get("sid"),
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
      });
      const base64EncodedString = btoa(jsonString);
      await API.post(config.aws_cloud_logic_custom_name, "/expressInterest", {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      });
      setExpressInterestLoader(false);
      setExpressInterest(false);
      Swal.fire({
        title: "Success",
        text: "You will be notified when the registration starts",
        icon: "success",
        confirmButtonColor: Theme.color.primary,
        confirmButtonText: "Ok",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          refetch();
        }
      });
    } catch (error) {
      console.error(error);
      setExpressInterestLoader(false);
    }
  };

  function formatUrl(url) {
    var httpString = "http://",
      httpsString = "https://";
    if (
      url.substr(0, httpString.length) !== httpString &&
      url.substr(0, httpsString.length) !== httpsString
    )
      url = httpString + url;

    return url;
  }
  const handleJoinSession = async (type) => {
    //     /joinSession
    // oid tenant eid sid
    try {
      setJoinButtonLoader(true);

      const jsonString = JSON.stringify({
        oid: "USP",
        tenant: userDetails.tenant,
        eid: userDetails.sub,
        sessionID: query.get("sid"),
        ur_id: userDetails?.uData?.ur_id,
        type: type,
        schema: config.schema,
      });
      const base64EncodedString = btoa(jsonString);

      await API.post(config.aws_cloud_logic_custom_name, "/joinSession", {
        body: {
          json: base64EncodedString,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken,
        },
      });
      setJoinButtonLoader(false);
      let filteredArr = viewAllArr.filter(
        (obj) => obj?.sid !== query.get("sid")
      );

      dispatch(setViewAllArrData(filteredArr));
      const url = formatUrl(data?.sData?.weblink);
      window.open(url, "_blank");
    } catch (error) {
      console.error(error);
      setJoinButtonLoader(false);
    }
  };
  const checkForSlots = async () => {
    setCheckSessionLimitLoader(true);
    try {
      const jsonString = JSON.stringify({
        ur_id: userDetails?.uData?.ur_id,
        pstatus: 0,
        assign: "self",
        sid: query.get("sid"),
        schema: config.schema,
      });
      const base64EncodedString = btoa(jsonString);
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/checkSessionSlots",
        {
          body: {
            json: base64EncodedString,
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      setCheckSessionLimitLoader(false);

      if (response.slots === false) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: response.message,
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonColor: Theme.color.primary,
        }).then((result) => {
          if (result.isConfirmed) {
            handleClose();
            refetch();
          }
        });
      } else {
        setRegOpen(true);
      }
    } catch (err) {
      setCheckSessionLimitLoader(false);
      console.error(err);
    }
  };
  const breadcrumbs = [
    <Link
      to={sessionData?.path !== "wishlist" ? "/home" : "/wishlist"}
      sx={{
        color: Theme.color.black,
        fontSize: "1.5rem",
        textDecoration: "none",
        cursor: "pointer",
      }}
    >
      {sessionData?.path !== "wishlist"
        ? `${userDetails?.languagejson?.Registered_Sessions?.Home || "Home"}`
        : `${userDetails?.languagejson?.Registered_Sessions?.Wishlist ||
            "Wishlist"}`}
    </Link>,
    <Typography
      key="3"
      color={Theme.color.text}
      style={{
        // color: "rgb(119, 119, 119)",
        fontSize: "1.5rem",
        textDecoration: "none",
        cursor: "pointer",
      }}
    >
      {data?.sData?.sn}
    </Typography>,
  ];
  return (
    <Wrapper>
      {data !== undefined &&
        data?.sData !== undefined &&
        Object.keys(data?.sData).length > 0 && (
          <>
            <Stack spacing={2} margin={{ xs: "1rem 0rem", sm: "3rem 0rem" }}>
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="small"
                    style={{ color: "var(--color-primary)" }}
                  />
                }
                aria-label="breadcrumb"
                sx={{
                  color: Theme.color.black,
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {breadcrumbs}
              </Breadcrumbs>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <HeadingPrimary>{data?.sData?.sn}</HeadingPrimary>
                <Box>
                  {feedback && (
                    <CustomButton
                      style={{
                        marginRight: "0",
                        backgroundColor: "white",
                        fontWeight: "600",
                      }}
                      onClick={() => {
                        setFeedbackFormOpen(true);
                      }}
                    >
                      Feedback
                    </CustomButton>
                  )}
                </Box>
              </Stack>
              <Stack
                direction={{ xs: "column-reverse", md: "row" }}
                spacing={{ xs: 1, md: 4 }}
              >
                <Paper
                  elevation={5}
                  sx={{
                    p: 3,
                    borderRadius: "4px",
                    textAlign: "justify",
                    width: "100%",
                  }}
                >
                  <Stack direction={{ xs: "column", sm: "column", md: "row" }}>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      sx={{ flexGrow: 1 }}
                    >
                      {data?.sData?.startdate && (
                        <HeadingSecondary>
                          Start date & time ;
                          <span style={{ marginLeft: "5px" }}></span>
                          {moment(parseInt(data?.sData?.startdate)).format(
                            "MMMM Do YYYY, h:mm A"
                          )}
                        </HeadingSecondary>
                      )}
                      {data?.sData?.enddate && (
                        <HeadingSecondary>
                          End date & time &#58;
                          <span style={{ marginLeft: "5px" }}></span>
                          {moment(parseInt(data?.sData?.enddate)).format(
                            "MMMM Do YYYY, h:mm A"
                          )}
                        </HeadingSecondary>
                      )}

                      {data?.sData?.date_list && (
                        <table className="tableStyle" id="liveSession">
                          <tbody>
                            <tr>
                              <td style={{ verticalAlign: "top" }}>
                                <HeadingSecondary>
                                  {/* {`${userDetails?.languagejson?.Registered_Sessions?.Session_Date_&_Time || "Session Date & Time"}`} : */}
                                  {`${userDetails?.languagejson
                                    ?.Registered_Sessions?.Session_Date_Time ||
                                    "Session Date & Time"}`}{" "}
                                  :
                                </HeadingSecondary>
                              </td>

                              {data?.sData?.date_list[0]?.combineStartTime !==
                                undefined &&
                                data?.sData?.date_list?.map((item) => {
                                  return (
                                    <tr>
                                      <td>
                                        {moment(item.combineStartTime).format(
                                          "DD/MM/YYYY"
                                        )}
                                        <span
                                          style={{ marginLeft: "3px" }}
                                        ></span>
                                        &nbsp; : &nbsp;
                                        {moment(item.combineStartTime).format(
                                          "LT"
                                        )}
                                        <span
                                          style={{ marginLeft: "2px" }}
                                        ></span>
                                        -
                                        <span
                                          style={{ marginLeft: "2px" }}
                                        ></span>
                                        {moment(item.combineEndTime).format(
                                          "LT"
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              {data?.sData?.date_list[0]?.combineStartTime ===
                                undefined && <td>{"yet to be scheduled"}</td>}
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </Stack>
                    <Stack
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      spacing={2}
                      sx={{ flexGrow: 1 }}
                    >
                      <table id="liveSession">
                        <tbody>
                          <tr>
                            <td>
                              {data?.sData?.speaker_type === null ? (
                                `${userDetails?.languagejson
                                  ?.Registered_Sessions?.Speaker || "Speaker"}`
                              ) : (
                                <span style={{ textTransform: "capitalize" }}>
                                  {data?.sData?.speaker_type}
                                </span>
                              )}
                              &#58;
                            </td>
                            <td>
                              <Link
                                to={`/session?sid=${query.get("sid")}`}
                                onClick={async () => {
                                  handleClickSpeakerOpen(
                                    data?.sData?.speakers[0]
                                  );
                                }}
                              >
                                {data?.sData?.speakers?.length !== 0 &&
                                  data?.sData?.speakers[0].name}
                              </Link>
                            </td>
                          </tr>
                          {data?.sData?.speakers.map((speaker, idx) => {
                            let rightSpeaker = [];
                            if (idx !== 0) {
                              rightSpeaker.push(speaker);
                            }
                            return (
                              <React.Fragment key={idx + "sdfjfgfdg"}>
                                {rightSpeaker.map((sp, id) => (
                                  <tr key={id + "prjwal"}>
                                    <td></td>
                                    <td>
                                      <Link
                                        to={`/session?sid=${query.get("sid")}`}
                                        onClick={() => {
                                          handleClickSpeakerOpen(sp);
                                        }}
                                      >
                                        {sp.name}
                                      </Link>
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            );
                          })}
                          {(data?.sData?.priceinr > 0 ||
                            data?.sData?.priceusd) > 0 &&
                            (data?.sData?.paid === true ||
                              data?.sData?.paid === "true") && (
                              <tr>
                                <td>
                                  {`${userDetails?.languagejson
                                    ?.Registered_Sessions?.Fees ||
                                    "Fees"}`}{" "}
                                  &#58;
                                </td>
                                <td>
                                  <p>
                                    INR {data?.sData?.priceinr} (Inc. of GST){" "}
                                    <Box
                                      component="span"
                                      sx={{ mx: 0.3 }}
                                    ></Box>
                                    <span>
                                      {data?.sData?.priceusd > 0 && (
                                        <>
                                          /{" "}
                                          <Box
                                            component="span"
                                            sx={{ mx: 0.3 }}
                                          >
                                            {" "}
                                            USD{" "}
                                          </Box>
                                          {data?.sData?.priceusd ?? ""}
                                        </>
                                      )}
                                    </span>
                                  </p>
                                </td>
                              </tr>
                            )}

                          {data?.sData?.earlydate &&
                            (data?.sData?.paid === true ||
                              data?.sData?.paid === "true") && (
                              <tr>
                                <td>Early Bird Offer &#58;</td>
                                <td>
                                  <p>
                                    INR {data?.sData?.earlypriceinr ?? "0"}
                                    <Box
                                      component="span"
                                      sx={{ mx: 0.3 }}
                                    ></Box>
                                    (Inc. of GST)
                                    {data?.sData?.earlypriceusd > 0 && (
                                      <>
                                        /{" "}
                                        <Box component="span" sx={{ mx: 0.3 }}>
                                          {" "}
                                          USD{" "}
                                        </Box>
                                        {data?.sData?.earlypriceusd ?? "0"} till
                                      </>
                                    )}
                                    <Box
                                      component="span"
                                      sx={{ mx: 0.3 }}
                                    ></Box>
                                    {moment(
                                      parseInt(data?.sData?.earlydate)
                                    ).format("DD MMM YYYY")}
                                  </p>
                                </td>
                              </tr>
                            )}
                          {data?.sData?.perdis !== null &&
                            data?.sData?.perdis > 0 &&
                            (data?.sData?.paid === true ||
                              data?.sData?.paid === "true") && (
                              <tr>
                                <td>
                                  {`${userDetails?.languagejson
                                    ?.Registered_Sessions
                                    ?.Percentage_Discount ||
                                    "Percentage Discount"}`}{" "}
                                  &#58;
                                </td>
                                <td>
                                  <p>{data?.sData?.perdis} %</p>
                                </td>
                              </tr>
                            )}
                          {data?.sData?.perdis > 0 && (
                            <tr>
                              <td>
                                {`${userDetails?.languagejson
                                  ?.Registered_Sessions?.Price_after_Discount ||
                                  "Price after Discount"}`}{" "}
                                &#58;
                              </td>
                              <td>
                                <p>
                                  INR {data?.sData?.priceafterinr ?? "0"} (Inc.
                                  of GST) /{" "}
                                  <Box component="span" sx={{ mx: 0.3 }}>
                                    {" "}
                                    USD{" "}
                                  </Box>
                                  {data?.sData?.priceafterusd ?? "0"}{" "}
                                </p>
                              </td>
                            </tr>
                          )}
                          {data?.sData?.stype !== null &&
                            (data?.sData?.stype === 2 ||
                              data?.sData?.stype === 3) &&
                            data?.sData?.location_value !== null &&
                            data?.sData?.location_value !== "null" &&
                            data?.sData?.location_value !== "" && (
                              <tr>
                                <td>
                                  {`${userDetails?.languagejson
                                    ?.Registered_Sessions?.location ||
                                    "location"}`}{" "}
                                  &#58;
                                </td>
                                <td>
                                  <p>{data?.sData?.location_value}</p>
                                </td>
                              </tr>
                            )}
                          {data?.sData?.stype !== null &&
                            data?.sData?.note !== "null" &&
                            data?.sData?.note !== "" && (
                              <tr>
                                <td>
                                  {`${userDetails?.languagejson
                                    ?.Registered_Sessions?.Note ||
                                    "Note"}`}{" "}
                                  &#58;
                                </td>
                                <td>
                                  <p>{data?.sData?.note}</p>
                                </td>
                              </tr>
                            )}
                        </tbody>
                      </table>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {sessionJoin && data?.sData.stype !== 2 && (
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <LoadingButton
                          variant="contained"
                          loading={joinButtonLoader ? true : false}
                          sx={StyledButtonJoin}
                          disabled={gray ? true : false}
                          onClick={() => {
                            handleJoinSession("JOIN");
                          }}
                        >
                          Join live session
                        </LoadingButton>
                        {gray &&
                          data?.uData?.aprstatus !== 1 &&
                          data?.uData?.pstatus !== 2 &&
                          (data?.sData?.paid === "true" ||
                            data?.sData?.paid === true) && (
                            <HeadingSecondary>
                              You will be able to join the session once the
                              admin approves you.
                            </HeadingSecondary>
                          )}
                        {gray &&
                          data?.uData?.aprstatus !== 1 &&
                          data?.sData?.paylater === true &&
                          data?.uData?.pstatus === 2 &&
                          (data?.sData?.paid === "true" ||
                            data?.sData?.paid === true) && (
                            <HeadingSecondary>
                              Pay within{" "}
                              {moment(parseInt(data?.sData?.pay_within)).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              for admin to approve you
                            </HeadingSecondary>
                          )}
                        {(data?.sData?.paid === "false" ||
                          data?.sData?.paid === false) && (
                          <HeadingSecondary>
                            Complementary session
                          </HeadingSecondary>
                        )}
                        {data?.uData?.aprstatus === 1 &&
                          data?.sData?.web_id &&
                          data?.sData?.web_pass && (
                            <>
                              <HeadingSecondary style={{ cursor: "copy" }}>
                                Meeting ID : {data?.sData?.web_id}
                              </HeadingSecondary>
                              <HeadingSecondary style={{ cursor: "copy" }}>
                                Password : {data?.sData?.web_pass}
                              </HeadingSecondary>
                            </>
                          )}
                      </Stack>
                    )}
                    {Object.keys(data?.uData).length > 0 &&
                      data?.uData.u_type === 0 &&
                      !regButton && (
                        // data?.currentTime < data?.sData?.startdate &&
                        <HeadingSecondary>
                          You have expressed interest, you will be notified when
                          registration starts
                        </HeadingSecondary>
                      )}
                    {expressInterest && (
                      <LoadingButton
                        loading={expressInterestLoader ? true : false}
                        disabled={expressInterestLoader ? true : false}
                        variant="contained"
                        sx={StyledButtons}
                        onClick={() => handleExpressInterest()}
                      >
                        Express interest
                      </LoadingButton>
                    )}
                    <Stack direction="column" rowGap={2}>
                      {regButton && (
                        <Stack direction="column" alignItems="center">
                          <LoadingButton
                            loading={checkSessionLimitLoader ? true : false}
                            disabled={checkSessionLimitLoader ? true : false}
                            variant="contained"
                            sx={StyledButtons}
                            style={{ margin: "1rem 0rem" }}
                            onClick={() => {
                              if (paylater) {
                                setRegOpen(true);
                              } else {
                                checkForSlots();
                              }
                            }}
                          >
                            {paylater ? "Pay" : "Register"}
                          </LoadingButton>
                          {paylater ? (
                            <HeadingThree>
                              {`Pay within  ${moment(
                                parseInt(data?.sData?.pay_within)
                              ).format("Do MMMM, YYYY")} `}
                            </HeadingThree>
                          ) : (
                            <HeadingThree>
                              Register before the session starts.
                            </HeadingThree>
                          )}
                        </Stack>
                      )}
                      {data?.uData.u_type === 2 && (
                        <HeadingThree>
                          You are now added to the waiting list.You will be
                          notified once the slots are available
                        </HeadingThree>
                      )}
                    </Stack>
                    {sessionCompleted && (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        sx={{ mt: 1 }}
                      >
                        {certificate && (
                          <Button
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => handleClickCertificateOpen()}
                          >
                            View certificate
                          </Button>
                        )}
                        {slides && (
                          <Button
                            variant="contained"
                            sx={StyledButtons}
                            onClick={() => handleClickSlidesOpen()}
                          >
                            Summary slides
                          </Button>
                        )}

                        {!certificate &&
                          (data?.feedback || query.get("feedback")) && (
                            <HeadingThree>
                              Admin should mark attendance to view certificate
                            </HeadingThree>
                          )}
                      </Stack>
                    )}
                  </Stack>
                  {sessionCompleted && (
                    <Stack
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {!(query.get("feedback") || data?.feedback) && (
                        <HeadingThree>
                          Fill out feedback form to
                          <span style={{ display: "block" }}>
                            {" "}
                            View summary slide for this session.
                          </span>
                        </HeadingThree>
                      )}
                    </Stack>
                  )}
                </Paper>

                <img
                  src={`${
                    config.aws_cloudfront_url
                  }${config.aws_org_id.toLowerCase()}-resources/images/session-images/${
                    data?.sData?.timgname
                  }`}
                  alt="Topic_image"
                  height="200"
                  className="topicImage"
                />
              </Stack>

              <>
                <Paper elevation={5} sx={{ p: 3, mt: 2 }}>
                  {data?.sData?.sdesc !== "null" &&
                    data?.sData?.sdesc !== "" &&
                    data?.sData?.sdesc !== null && (
                      <>
                        <HeadingSecondary>{`${userDetails?.languagejson
                          ?.Registered_Sessions?.Overview ||
                          "Overview"}`}</HeadingSecondary>
                        <p>{data?.sData?.sdesc}</p>
                      </>
                    )}

                  {data?.sData?.lobj !== "null" &&
                    data?.sData?.lobj !== "" &&
                    data?.sData?.lobj !== null && (
                      <>
                        <HeadingSecondary style={{ marginTop: "10px" }}>
                          {`${userDetails?.languagejson?.Registered_Sessions
                            ?.Learning_objectives ||
                            "Learning objectives"}`}{" "}
                          :{" "}
                        </HeadingSecondary>
                        {/* <p>Upon completion of this course, you will be able to:</p> */}
                        <ul>
                          {data?.sData?.lobj
                            ?.split("\n")
                            .map(
                              (obj, idx) =>
                                obj.replace(/\s/g, "") !== "" && (
                                  <li key={idx + "sddgd"}>{obj}</li>
                                )
                            )}
                        </ul>
                      </>
                    )}

                  {data?.sData?.taudi !== "null" &&
                    data?.sData?.taudi !== "" &&
                    data?.sData?.taudi !== null && (
                      <>
                        <HeadingSecondary style={{ marginTop: "10px" }}>
                          Target audience:{" "}
                        </HeadingSecondary>
                        <ul>
                          {data?.sData?.taudi
                            ?.split("\n")
                            .map(
                              (obj, id) =>
                                obj.replace(/\s/g, "") !== "" && (
                                  <li key={id + "sdf"}>{obj}</li>
                                )
                            )}
                        </ul>
                      </>
                    )}
                </Paper>
              </>
            </Stack>
            {regOpen && (
              <Dialog
                open={regOpen}
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{
                  sx: {
                    backgroundColor: Theme.backDropOpacity,
                  },
                }}
                scroll="paper"
                maxWidth="lg"
              >
                <RegisterFrom
                  handleClose={handleClose}
                  sessionData={data?.sData}
                  setRegButton={setRegButton}
                  setSessionJoin={setSessionJoin}
                  refetch={refetch}
                  regData={data?.regidtls ?? {}}
                  paylater={paylater}
                />
              </Dialog>
            )}
            {feedbackFormOpen && (
              <Dialog
                open={feedbackFormOpen}
                aria-labelledby="responsive-dialog-title"
                BackdropProps={{
                  sx: {
                    backgroundColor: Theme.backDropOpacity,
                  },
                }}
                scroll="paper"
                maxWidth="lg"
              >
                <FeedbackForm
                  handleClose={feedbackFormClose}
                  refetch={refetch}
                />
              </Dialog>
            )}

            {loginOpen && sessionData.preLogin && (
              <SignInAndSignUp
                loginOpen={loginOpen}
                setLoginOpen={setLoginOpen}
                onlySingUp={onlySignUp}
              />
            )}
            {openSpeaker && (
              <SpeakerDialog
                open={openSpeaker}
                setOpen={setSpeaker}
                speakerDetails={speakerDetails}
              />
            )}
            {certificateOpen && (
              <Dialog
                open={certificateOpen}
                aria-labelledby="responsive-dialog-title-certificateOpen"
                scroll="paper"
                maxWidth="xl"
              >
                <CertificatePopUp
                  open={certificateOpen}
                  setOpen={setCertificateOpen}
                  details={data?.uData}
                  sData={data?.sData}
                />
              </Dialog>
            )}
            {slideOpen && (
              <SlidesPopUp
                open={slideOpen}
                setOpen={setSlideOpen}
                details={data?.sData}
              />
            )}
          </>
        )}
      {data !== undefined && data?.sData !== undefined && (
        <>
          {Object.keys(data?.sData).length === 0 && (
            <div
              style={{
                height: "90vh",
                width: "100vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                style={{
                  width: "400px",
                  height: "200px",
                  background: "#1974b5",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  borderRadius: ".8rem",
                }}
              >
                <div style={{ margin: "2rem 0", textAlign: "center" }}>
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "15px",
                      fontWeight: "600",
                    }}
                  >
                    Content you are looking for could not be found
                  </p>
                  <button
                    style={{
                      border: "none",
                      color: "#1974b5",
                      background: "#fff",
                      padding: ".5rem 2rem",
                      marginTop: "2rem",
                      borderRadius: ".4rem",
                      fontSize: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => history.push("/home")}
                  >
                    Go to dashboard
                  </button>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {isLoading && (
        <Stack spacing={2} margin="3rem" paddingBottom="3rem">
          <Loader />
        </Stack>
      )}
    </Wrapper>
  );
};

function Loader() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", gap: 3, mb: 3 }}>
        {" "}
        <Skeleton variant="rectangular" width="70vw" height={200} />
        <Skeleton variant="rectangular" width="30vw" height={200} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={200} />
    </Box>
  );
}
export default LiveSession;
