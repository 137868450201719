import { Visibility, VisibilityOff } from "@material-ui/icons";
import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import React from "react";
const useHelperTextStyles = makeStyles(() => ({
  root: {
    color: "#D32f2f",
    fontSize: "14px",
  },
}));

const CustomInput = ({
  placeholder,
  type,
  name,
  onChange,
  inputValues,
  value,
  handleClickShowPassword,
  handleMouseDownPassword,
  id,
  error,
  ...props
}) => {
  const helperTextStyles = useHelperTextStyles();
  return (
    <TextField
      error={error && true}
      id={id}
      placeholder={placeholder}
      FormHelperTextProps={{
        classes: {
          root: helperTextStyles.root,
        },
      }}
      InputProps={{
        startAdornment: props.children && (
          <InputAdornment position="start">{props.children}</InputAdornment>
        ),
        endAdornment: type === "password" && (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {inputValues.showPassword ? (
                <VisibilityOff style={{ height: "22px", width: "22px" }} />
              ) : (
                <Visibility style={{ height: "22px", width: "22px" }} />
              )}
            </IconButton>
          </InputAdornment>
        ),
        autoComplete: "new-password",
        form: {
          autoComplete: "off",
        },
        style: { WebkitBoxShadow: "0 0 0 1000px white inset" },
      }}
      type={
        type === "password"
          ? inputValues.showPassword
            ? "text"
            : "password"
          : type
      }
      autoComplete="organization"
      inputProps={{ style: { fontSize: 17 } }}
      name={name}
      value={value}
      onChange={onChange}
      helperText={error}
      variant="standard"
      focused
      fullWidth
    />
  );
};

export default CustomInput;
