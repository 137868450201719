import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { StyledButtons } from "./RegisterForm/RegisterFrom";
import { Theme } from "../../aws-exports";
import { IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import { saveAs } from "file-saver";
import axios from "axios";
import "../../components/Styles/Container";

import config from "../../aws-exports";

export default function SlidesPopUp({ setOpen, open, details }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => {
    setOpen(false);
  };

  // function ImageToPrint(source) {
  //   return (
  //     "<html><head><scri" +
  //     "pt>function step1(){\n" +
  //     "setTimeout('step2()', 10);}\n" +
  //     "function step2(){window.print();window.close()}\n" +
  //     "</scri" +
  //     "pt></head><body onload='step1()'>\n" +
  //     "<img src='" +
  //     source +
  //     "' height='700' width='auto'/></body></html>"
  //   );
  // }

  function downloadPdf() {
    let filename, type, durl;
    type = "application/pdf";
    filename = details?.sn + ".pdf";
    durl = `https://${
      config.aws_cloudfront_url
    }/${config.aws_org_id.toLowerCase()}-resources/documents/session-documents/${
      details?.slide_tname
    }`;

    axios
      .get(durl, {
        responseType: "blob",
        method: "GET",
        headers: {
          "Content-Type": type,
        },
      })
      .then((res) => {
        let file = new Blob([res.data], { type: type });
        saveAs(file, filename);
      });
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">
          {details?.sn}
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <iframe
            src={`${
              config.aws_cloudfront_url
            }${config.aws_org_id.toLowerCase()}-resources/documents/session-documents/${
              details?.slide_tname
            }`}
            alt={details?.sn}
            height="500"
            width="750"
            title={details?.sn}
          />
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{
              color: Theme.color.primary,
              borderColor: Theme.color.primary,
              textTransform: "unset",
              "&:hover": {
                color: Theme.color.primary,
                borderColor: Theme.color.primary,
                textTransform: "unset",
              },
            }}
            variant="outlined"
            onClick={() => downloadPdf()}
          >
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
