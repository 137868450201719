import styled from "styled-components";

export const GridWrapper = styled.div`
  display: grid;

  grid-template-columns: 2fr 1fr;
  // grid-template-rows: minmax(200px, auto) 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 30px;

  .grid-item1 {
    grid-column: 1/3;
    grid-row: 1/2;
    height: max-content;
  }

  .grid-item2 {
    grid-column: 1/2;
    grid-row: 2/ span 3;
    height: max-content;
  }

  .grid-item3 {
    grid-row: 2;
    height: max-content;
  }

  .grid-item4 {
    grid-row: 3;
    height: max-content;
  }
  .grid-item5 {
    grid-row: 2;
    height: max-content;
    width: auto;
  }

`;
