import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import config from "../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import { Box, Breadcrumbs, Grid } from "@material-ui/core";
import "../../components/Cards/CardLarge/CardLarge.scss";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { makeStyles } from "@material-ui/styles";
import { Link, useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import "./bookmark.scss";
import BookmarkContentCarousel from "../../components/Carousel/BookmarkContentCarousel/BookmarkContentCarousel";
import { useQuery } from "@tanstack/react-query";
import { listBookMark } from "../../api/api";
import { Api } from "@mui/icons-material";
import {
  Stack,Typography
} from "@mui/material";
const useStyles = makeStyles({
  outline: {
    fontSize: "3rem",
    fill: "none",
    stroke: "#646464",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  filled: {
    fontSize: "3rem",
    fill: "#8A7316",
    stroke: "#8A7316",
    strokeWidth: "1px",
    strokeLinejoin: "round",
  },
  GifDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      "@media (max-width: 500px)": {
        width: "100%",
      },
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    width: "80vw",
    "@media (max-width: 500px)": {
      display: "block",
    },
  },
  breadcrumbNameStyle: {
    fontSize: "1.5rem!important",
    fontFamily: `myfontregular!important`,
  }
});
function BookmarkView() {
  let userDetails = useSelector(authData);
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const { data, isLoading, isError, error,refetch } = useQuery({
    queryKey: ["listBookmark"],
    queryFn: () => listBookMark(userDetails),
  });
  useEffect(() => {
    if (!userDetails || userDetails === 0) {
      if (config.aws_org_id == "AXIATA") {
        window.location.href = `${config.auth_url}oauth2/authorize?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&scope=email+openid+profile+aws.cognito.signin.user.admin&identity_provider=Cognito User Pool"`;
      } else {
        window.location.href = config.aws_cloudfront_lurl;
      }
    }
    let filterstoredata = { ...userDetails };
    delete filterstoredata.filter;
    dispatch(awsSignIn(filterstoredata));
    if (data) {
      setList(data);
    }
  }, [data,list,setList]);
  const classes = useStyles();
  const history = useHistory();
  function bookMarkFilled() {
    return classes.filled;
  }
  async function deleteBookmark(tid, type) {
    const bdata = {
      ur_id: userDetails.uData.ur_id,
      schema: config.schema,
    }
    if (type === 1) {
      bdata.tid = tid;
    } else {
      bdata.objid = tid;
    }
    bdata.bookmark = false;
    let book = list.filter((prev) => prev.tid !== tid);
    setList(book);
    const jsonString = JSON.stringify(bdata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {json:base64EncodedString},
              headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: userDetails?.accessToken	
                  },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        type === 1 ? "/updateTopicReport" : "/updateContentReport",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  }
  let src = "";
  const handleLocation = (data, btype, tid) => {
    let breadcrumb = { ...userDetails };
    let value = data[0];
    if (btype === 1) {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        courseName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`course?id=${tid}`);
    } else {
      breadcrumb.location = {
        from: breadcrumb.location === undefined ? "Bookmarks" : "Bookmarks",
        contentName: value.title,
      };
      dispatch(awsSignIn(breadcrumb));
      history.push(`content?id=${tid}`);
    }
  };
  if (isError) return <pre>{JSON.stringify(error, null, 2)}</pre>;
  return (
   <>
    <motion.div>
      <div>
        <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize="large" style={{fill: "var(--color-primary)"}} />
          }
          aria-label="breadcrumb"
          style={{ marginBottom: "5rem" }}
        >
          <Link underline="hover" key="1" color="inherit" to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}>
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>
          <Link
            style={{
              color: "#777",
              fontSize: "1.5rem",
              textDecoration: "none",
              cursor: "context-menu",
            }}
            color="inherit"
          >
            {`${userDetails?.languagejson?.header?.Bookmarks || "Bookmarks"}`}
          </Link>
        </Breadcrumbs>
        <div>
          {!isLoading ? (
            <>
              {list?.length === 0 ? (
                <div className={classes.GifDiv}>
                  {" "}
                  <img
                    src={noThingIsHere}
                    alt="Nothing is here yet"
                    title="Nothing is here yet"
                    style={{width:"30rem"}}
                  />
                </div>
              ) : (
                <BookmarkContentCarousel
                  heading={`${userDetails?.languagejson?.header?.My_Bookmarks || "My Bookmarks"}`}
                  cardData={list}
                  setData={setList}
                  loading={isLoading}
                  refetch={refetch}
                />
              )}
            </>
          ) : (
            <div className={classes.mainDiv}>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
              <Box>
                <Skeleton variant="rect" width={350} height={200} />
                <Box pt={0.5}>
                  <Skeleton />
                  <Skeleton width="60%" />
                </Box>
              </Box>
            </div>
          )}
        </div>
      </div>
    </motion.div>
   </>
  );
}
export default BookmarkView;
