export default function validateRegistration(values) {
  let errors = {};

  if (values.regUsername.replace(/\s/g, "") === "") {
    errors.regUsername = "Name is required";
  }
  if (values.designation.replace(/\s/g, "") === "") {
    errors.designation = "Designation is required";
  }
  // if (!values.department) {
  //   errors.department = "Department is required";
  // }
  if (values.organization.replace(/\s/g, "") === "") {
    errors.organization = "Organization is required";
  }
  if (values.officeAddress.replace(/\s/g, "") === "") {
    errors.officeAddress = "Address is required";
  }
  if (values.city.replace(/\s/g, "") === "") {
    errors.city = "City is required";
  }
  if (values.pin.replace(/\s/g, "") === "") {
    errors.pin = "PIN is required";
  }
  if (values.state.replace(/\s/g, "") === "") {
    errors.state = "State is required";
  }
  // if (!values.std) {
  //   errors.std = "STD is required";
  // }
  // if (!values.phone) {
  //   errors.phone = "Phone number is required";
  // }
  // if (!values.ext) {
  //   errors.ext = "is required";
  // }
  if (values.mobile.replace(/\s/g, "") === "") {
    errors.mobile = "Mobile number is required";
  }
  // if (!values.gst) {
  //   errors.gst = "GST number is required";
  // }
  // if (!values.pan) {
  //   errors.pan = "PAN number is required";
  // }

  if (values.email.replace(/\s/g, "") === "") {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }

  return errors;
}
export function validatePayment(values) {
  let errors = {};

  if (!values.acknowledgementNumber) {
    errors.acknowledgementNumber = "Acknowledgement number is required";
  }
  if (!values.dt_ur_comment) {
    errors.dt_ur_comment = "Comment is required";
  }
  if (values?.dt_ur_comment?.trim() === "") {
    errors.dt_ur_comment = "Comment is required";
  }

  return errors;
}
export function validatePayLater(values) {
  let errors = {};

  if (!values.ur_comment) {
    errors.ur_comment = "Comment is required";
  }

  if (values.ur_comment.trim() === "") {
    errors.ur_comment = "Comment is required";
  }

  return errors;
}
