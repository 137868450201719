import React from "react";
import logoimg from "../../../assets/media/EnhanzED.png";
import { useHistory } from "react-router-dom";
import { authData } from "../../../redux/auth/authSlice";
import { useSelector } from "react-redux";
import config from "../../../aws-exports";

export default function SharedHeader() {
  const history = useHistory();
  const userAuthData = useSelector(authData);
  return (
    <header className="header">
      {config.aws_org_id != 'EXCELSOFT' && <img
        src={`https://${
          config.DOMAIN
        }/${config.aws_org_id.toLocaleLowerCase()}-resources/images/org-images/${
          config.aws_org_id
        }.png`}
        alt="app logo"
        className="logo"
        onClick={() => history.push("/home")}
      />}
      {/* {userAuthData === 0 ? (
        <button
          className="loginButton"
          onClick={() => {
            if (userAuthData === 0) {
              //history.push('/');
              window.location.href = config.aws_cloudfront_lurl;
            } else {
              history.push("/home");
            }
          }}
        >
          Login
        </button>
      ) : null} */}
    </header>
  );
}
