import React, { useEffect, useRef, useState } from "react";
import "./RadioObjects.scss";
import StarIcon from "@material-ui/icons/Star";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tippy from "@tippyjs/react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ButtonBGL from "../../assets/media/ButtonBGL.png";
import ButtonBGR from "../../assets/media/ButtonBGR.png";
import LPBGImage from "../../assets/media/LPBGImage.png";
import {
  CardMedia,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import config from "../../aws-exports";
//React router
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import API from "@aws-amplify/api";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { saveAs } from "file-saver";
import axios from "axios";
import CustomizedDialogs from "../../components/Dialogs/Dialogs";
import downloadPdfSvg from "../../components/svg/downloadpdf.png";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import CircularProgress from "@material-ui/core/CircularProgress";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import PlayCircleOutlineRoundedIcon from "@material-ui/icons/PlayCircleOutlineRounded";
import { Skeleton } from "@material-ui/lab";
import PlayerMain from "../../components/ReactPlayerControls/PlayerMain";
import screenfull from "screenfull";
import { Fullscreen } from "@material-ui/icons";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Cookies from "js-cookie";
import SummativeQuiz from "../../components/Quiz/SummativeQuiz";
import FormativeQuiz from "../../components/Quiz/FormativeQuiz";
import FormativeQuizQuestionBank from "../../components/Quiz/FormativeQuizQuestionBank";
import { setViewAllArrData } from "../../redux/HomeData/HomeDataSlice";
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    height: "675px",
    width: "1100px",
    backgroundColor: "white",
    overflowY: "auto",
    overflowX: "hidden",
    padding: "0rem",
    position: "relative",
    textAlign: "center",
    "@media (max-width: 550px)": {
      height: "410px",
    },
  },
  paperVideo: {
    height: "610px",
    width: "1100px",
    backgroundColor: "white",
    overflowY: "hidden",
    overflowX: "hidden",
    position: "relative",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
  quizroot: {
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
  },
  bottomIcons: {
    color: "#000",
    "&:hover": {
      color: "#777",
    },
  },
  volumeButton: {
    color: "white",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "white",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  responsive: {
    [theme.breakpoints.up(1300)]: {
      fontSize: "1rem",
    },
  },
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    "@media (max-width: 550px)": {
      marginBottom: "1rem",
    },
  },
  ratingButton: {
    fontSize: "1.4rem",
    color: "#01498e",
    backgroundColor: "white!important",
    borderColor: "#01498e",
    textTransform: "none",
    "@media (max-width: 550px)": {
      width: "auto",
    },
  },
  downloadButton: {
    background: "transparent",
    border: "none",
    height: "3rem",
    width: "3rem",
    marginRight: "2rem",
    cursor: "pointer",
    "@media (max-width: 550px)": {
      marginRight: "1rem",
    },
  },
  bookmarkIcon: {
    cursor: "pointer",
    marginLeft: "1.5rem",
    border: "none",
    display: "flex",
    "@media (max-width: 550px)": {
      marginLeft: "0.5rem",
    },
    background: "transparent",
    "&:focus": {
      border: "1px solid black",
    },
  },
  WebViewScroll: {
    overflowX: "hidden",
  },
  loaderSkeleton: {
    width: "200px",
    height: "3rem",
    "@media (max-width: 750px)": {
      width: "200px",
    },
  },
  LPMainContainer: {
    width: "100%",
    backgroundColor: "#e1f4ff",
    backgroundImage: `url(${LPBGImage})`,
    backgroundSize: "cover",
    padding: "2rem 0rem",
  },
  TimelineDotStartStyle: {
    padding: "3.5rem 2.5rem!important",
    fontSize: "2.5rem",
    fontWeight: "700",
    backgroundColor: "#2591ce!important",
    border: "6px solid white!important",
    boxShadow: "none!important",
    margin: "0!important",
    "@media (max-width: 550px)": {
      padding: "2rem 1rem!important",
    },
  },
  TimelineDotStyle: {
    fontWeight: "700",
    backgroundColor: "white!important",
    boxShadow: "none!important",
    margin: "0.5rem auto!important",
    "@media (max-width: 900px)": {
      display: "none!important",
    },
  },
  TimelineConnectorStyle: {
    width: "2rem!important",
    borderRadius: "1rem",
    border: "6px solid white",
    height: "12rem",
    color: "white",
    backgroundColor: "#2591CE!important",
    "@media (max-width: 900px)": {
      height: "8rem",
    },
  },
  FligStyle: {
    position: "absolute",
    textAlign: "center",
    bottom: "1rem",
    rotate: "-8deg",
  },
  FlowCountStyle: {
    color: "#2591ce4a",
    fontSize: "2rem!important",
    fontWeight: "800!important",
    "@media (max-width: 1100px)": {
      position: "relative",
      top: "-1rem",
    },
    "@media (max-width: 900px)": {
      position: "relative",
      top: "-2rem",
    },
  },
  PointsStyle: {
    backgroundColor: "#FFDA46",
    borderRadius: "3rem",
    width: "4rem",
    height: "4rem",
    margin: "auto",
    fontSize: "1.8rem",
    fontWeight: "700",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 750px)": {
      width: "2.5rem",
      height: "2.5rem",
      fontSize: "1.3rem",
    },
  },
  ActionSectionLeft: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
    right: "-4rem",
    top: "-2rem",
    "@media (max-width: 550px)": {
      display: "inline-table",
    },
  },
  ActionSectionRight: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
    right: "4rem",
    top: "-2rem",
    "@media (max-width: 550px)": {
      display: "inline-table",
    },
  },
  ButtonSectionL: {
    border: "none",
    // background: "#fff",
    backgroundColor: "transparent",
    borderRadius: ".4rem",
    cursor: "pointer",
    width: "40rem",
    height: "56px",
    boxShadow: "4px 4px 5px 0px #00000017",
    color: "black",
    fontSize: "2rem",
    fontWeight: "700",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${ButtonBGL})`,
    "@media (max-width: 1100px)": {
      width: "30rem",
      fontSize: "1.4rem",
      height: "42px",
    },
    "@media (max-width: 900px)": {
      width: "20rem",
      fontSize: "1.4rem",
      height: "28px",
    },
    "@media (max-width: 670px)": {
      width: "auto",
      fontSize: "1.2rem",
      height: "auto",
      backgroundColor: "white",
    },
  },
  ButtonSectionR: {
    border: "none",
    backgroundColor: "transparent",
    borderRadius: ".4rem",
    cursor: "pointer",
    width: "40rem",
    height: "56px",
    boxShadow: "-4px 4px 5px 0px #00000017",
    color: "black",
    fontSize: "2rem",
    fontWeight: "700",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${ButtonBGR})`,
    "@media (max-width: 1100px)": {
      width: "30rem",
      fontSize: "1.4rem",
      height: "42px",
    },
    "@media (max-width: 900px)": {
      width: "20rem",
      fontSize: "1.4rem",
      height: "28px",
    },
    "@media (max-width: 670px)": {
      width: "auto",
      fontSize: "1.2rem",
      height: "auto",
      backgroundColor: "white",
    },
  },
  modalContent: {
    width: "400px",
    height: "200px",
    background: "#1974b5",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: ".8rem",
    "@media (max-width: 450px)": {
      width: "100%",
      padding: "0rem 1rem",
    },
  },
}));
const defaultTippyProps = {
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom",
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  theme: "light",
  distance: 24,
  inertia: true,
};
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function RadioObject(props) {
  const classes = useStyles();
  let userDetailsL = useSelector(authData);
  const useBookMarkStyles = makeStyles({
    outline: {
      fontSize: "3rem",
      fill: "none",
      strokeWidth: "1px",
      strokeLinejoin: "round",
      stroke: userDetailsL.themeDark === "dark" ? "white" : "#646464",
    },
    filled: {
      fontSize: "3rem",
      fill: "#8A7316",
      stroke: "#8A7316",
      strokeWidth: "1px",
      strokeLinejoin: "round",
    },
  });
  const classBookmark = useBookMarkStyles();
  // const [open, setOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [rateValue, setRateValue] = React.useState(1);
  const [btnLoad, setBtnLoad] = React.useState(false);
  const [sharedObject, setSharedObject] = React.useState({});
  const [sTime, setSTime] = React.useState(new Date().getTime());
  const [isPlaying, setIsPlaying] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [loading, setLoading] = useState(false);
  const sharedReport = useRef({});
  const copyToClipBoard = useRef();
  const full = useRef(null);
  const [objLoad, setObjLoad] = React.useState(true);
  const vid = useRef();
  const [isRated, setIsRated] = useState(true);
  const [mainSetOfQuestions, setMainSetOfQuestion] = useState({});
  const [totalMarks, setTotalMarks] = useState(0);
  const userDetails = useSelector(authData);
  // const filterObject = featuredArr.filter(arr => arr.tid === objId);
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = React.useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [more, setMore] = React.useState("");
  const [question, setQuestion] = useState([]);
  const [perScore, setPerScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [qtype, setQtype] = useState();
  const [totalScore, setTotalScore] = useState("");
  const [report, setReport] = useState({});
  const [currentQuestion,setCurrentQuestion] = useState(0)
  const [startQuiz, setStartQuiz] = useState(false);
  const count = useRef(0);
  const refobj = useRef();
  let query = useQuery();
  const history = useHistory();
  const { viewAllArr } = useSelector((state) => state.home);
  useEffect(() => {
    const interval = setInterval(() => {
      tick();
    }, 1000);
    if (userDetails === 0) {
      var obj = {};
      obj.tid = query.get("id");
      obj.type = "content";
      localStorage.setItem("deeplink", JSON.stringify(obj));
      //history.push(`/`);
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.scrollTo(0, 0);
      getSharedObject();
      return () => {
        if (query.get("id") !== undefined) {
          updateObjectAnalytics(
            query.get("id"),
            sharedReport.current,
            count.current
          );
        }
        clearInterval(interval);
      };
    }
    return () => {
      const userdata = { ...userDetails };
      delete userdata.learningPath;
      dispatch(awsSignIn(userdata));
    };
  }, [isRated]);
  const tick = () => {
    count.current += 1;
  };
  const updateObjectAnalytics = async (objectId, sharedObject, count) => {
    const bodydata = {
      objid: objectId,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
      tspent: count,
    };
    if ((new Date().getTime() - sTime) / 1000 <= 30) {
      bodydata.bounce_cnt = 1;
    } else {
      if (
        sharedObject?.unique_val == null ||
        sharedObject?.unique_val === undefined
      ) {
        bodydata.unique_date = 1;
        bodydata.view_cnt = 1;
      } else {
        bodydata.view_cnt = 1;
      }
    }
    const jsonString = JSON.stringify(bodydata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  };
  async function updateObjectView(res) {
    const jsonString = JSON.stringify({
      tenant: userDetails.locale,
      oid: config.aws_org_id,
      tid: query.get("id"),
      title: res?.rows[0].title,
      eid: userDetails.username,
      otype: res?.rows[0]?.otype,
      type: "object",
      pdate: new Date().getTime(),
      ourl: res.rows[0]?.url,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateRecentViewed",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  }
  function downloadPdf() {
    let filename, type, durl;
    if (
      sharedObject.otype !== undefined &&
      sharedObject.otype.includes("pdf")
    ) {
      type = "application/pdf";
      filename = sharedObject.oname + ".pdf";
      durl = sharedObject.url;
    }
    if (
      sharedObject.otype !== undefined &&
      sharedObject.otype.includes("html")
    ) {
      type = "text/html";
      filename = sharedObject.oname + ".pdf";
      durl = sharedObject.durl;
    }
    if (
      sharedObject.otype !== undefined &&
      sharedObject.otype.includes("Interactivity")
    ) {
      type = "application/pdf";
      if (
        sharedObject.tid === "OaaqWd4CzWMaL6tU" ||
        sharedObject.tid === "GVKKMdARctFMnWdq" ||
        sharedObject.tid === "KcJZnObtwavbEcfL"
      ) {
        filename = sharedObject.oname + ".pptx";
        durl = sharedObject.durl;
      } else {
        filename = sharedObject.oname + ".pdf";
        durl = sharedObject.durl;
      }
    }
    if (
      sharedObject.dtype !== undefined &&
      sharedObject.dtype.includes("ppt")
    ) {
      filename = sharedObject.oname + ".pptx";
      durl = sharedObject.durl;
    } else if (
      sharedObject.dtype !== undefined &&
      sharedObject.dtype === "pdf"
    ) {
      filename = sharedObject.oname + ".pdf";
      durl = sharedObject.durl;
    } else if (
      sharedObject.dtype !== undefined &&
      sharedObject.dtype.includes("docx")
    ) {
      filename = sharedObject.oname + ".docx";
      durl = sharedObject.durl;
      type =
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }
    axios
      .get(durl, {
        responseType: "blob",
        method: "GET",
        headers: {
          "Content-Type": type,
        },
      })
      .then((res) => {
        let file = new Blob([res.data], { type: type });
        saveAs(file, filename);
      });
  }
  function Alert(props) {
    return (
      <MuiAlert
        elevation={6}
        variant="filled"
        {...props}
        style={{ fontSize: "1.6rem" }}
      />
    );
  }
  const handleClick = () => {
    copyToClipBoard.current.select();
    document.execCommand("copy");
    setOpenSnackbar(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };
  const getSharedObject = async () => {
    // const bodyParam = {
    //   body: {
    //     oid: config.aws_org_id,
    //     tenant: userDetails.locale,
    //     objid: query.get("id"),
    //     ur_id: userDetails?.uData?.ur_id,
    //     schema: config.schema,
    //     learningPath: userDetails?.learningPath,
    //     groups: userDetails?.uData?.gid,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getSharedObject",
    //     bodyParam
    //   );
    try {
      const requestBody = {
        oid: config.aws_org_id,
        tenant: userDetails.locale,
        objid: query.get("id"),
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        learningPath: userDetails?.learningPath,
        groups: userDetails?.uData?.gid,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getSharedObject?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      if (parseInt(response.id) === 0) {
        updateObjectView(response);
      }
      setSharedObject(response.rows[0]);
      sharedReport.current = response.report[0];
      if (response.report.length > 0) {
        setReport(response?.report[0]);
      }
      setQtype(
        response.rows !== undefined &&
          response.rows[0] !== undefined &&
          response.rows[0].qtype !== undefined &&
          response.rows[0].qtype !== null
          ? Number(response.rows[0].qtype)
          : 2
      );
      refobj.current = response.otype;
      setIsLoading(false);
      let filteredArr = viewAllArr.filter(
        (obj) => obj?.objid !== response.rows[0]?.objid
      );
      dispatch(setViewAllArrData(filteredArr));
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setObjLoad(false);
    } catch (err) {
      console.error(err);
    }
  };
  const addAndRemoveBookmark = async (val) => {
    const bodydata = {
      objid: query.get("id"),
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    };
    if (val === 0) {
      bodydata.bookmark = true;
      bodydata.bookmark_date = 1;
    } else {
      bodydata.bookmark = false;
    }
    const jsonString = JSON.stringify(bodydata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      getSharedObject();
    } catch (err) {
      console.error(err);
    }
  };
  const syncObjectsData = async () => {
    setBtnLoad(true);
    const bodydata = {
      objid: query.get("id"),
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    };
    bodydata.rating = rateValue;
    bodydata.rating_date = 1;
    const jsonString = JSON.stringify(bodydata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      if (response) {
        setBtnLoad(false);
        setOpen(false);
        getSharedObject();
      }
    } catch (err) {
      setBtnLoad(false);
      console.error(err);
    }
  };
  function shuffleArray(arr) {
    let shuffledArr = [...arr];
    for (let i = shuffledArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
    }
    return shuffledArr;
  }
  async function getQuiz(obj) {
    const jsonString = JSON.stringify({
      quizid: obj,
      oid: config.aws_org_id,
      schema: config.schema,
      action: "get",
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuiz",
        bodyParam
      );
      const { qitems } = response;
      setQuestion(qitems);
      if (obj.qtype !== 4) {
        const { qitems } = response;
        setQuestion(qitems);
        if (obj.qtype == 2) {
          let quest = shuffleArray(qitems);
          setQuestion(quest);
        }
      }
      if (obj.qtype === 4) {
        const {
          qitems,
          totalmarks,
        } = response;
        let questTypeFour = shuffleArray(qitems);
        setQuestion(questTypeFour);
        setMainSetOfQuestion(response);
        setTotalMarks(totalmarks);
      }
      setStartQuiz(true);
    } catch (error) {
      console.error(error);
    }
  }
  function renderObjects() {
    let objType = sharedObject.otype;
    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "Interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "scorm":
        return WebView(); // loadScormView(curObject);
      case "quiz":
        return tempView();
      case "vimeo":
        return youtubeView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }
  function VideoView() {
    return <PlayerMain url={sharedObject.url} objName={sharedObject.title} />;
  }
  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <CardMedia
            image={sharedObject.url}
            height="100%"
            component="audio"
            frameBorder={0}
            controls
          />
        </div>
      </div>
    );
  }
  function youtubeView() {
    return (
      <CardMedia
        image={
          sharedObject.otype !== "vimeo"
            ? `https://www.youtube.com/embed/${sharedObject.ourl}?rel=0&showinfo=0`
            : `https://player.vimeo.com/video/${sharedObject.ourl}?title=0&loop=0`
        }
        height="100%"
        component="iframe"
        frameBorder={0}
        controls
      />
    );
  }
  function PdfView() {
    return (
      <CardMedia
        image={`${sharedObject.url}#toolbar=0`}
        component="iframe"
        height="100%"
        frameBorder={0}
      />
    );
  }
  function WebView() {
    return (
      <CardMedia
        image={sharedObject.url}
        height="100%"
        component="iframe"
        width="100%"
        className={classes.WebViewScroll}
        frameBorder={0}
      />
    );
  }
  function tempView() {
    return (
      <React.Fragment>
        {qtype == 2 && (
          <React.Fragment>
            <FormativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              setCurrentQuestion={setCurrentQuestion}
              currentQuestion={currentQuestion}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              qpage={"object"}
            />
            {!startQuiz && (
              <div className="formative_start_button_container">
                <button
                  className="button-3"
                  onClick={() => {
                    getQuiz(query.get("id"));
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        fontSize: "2.5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    "Start Quiz"
                  )}
                </button>
              </div>
            )}
          </React.Fragment>
        )}
        {qtype === 4 && (
          <React.Fragment>
            <FormativeQuizQuestionBank
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              qpage={"object"}
              mainSetOfQuestions={mainSetOfQuestions}
              totalMarks={totalMarks}
            />
            {!startQuiz && (
              <div className="formative_start_button_container">
                <button
                  className="button-3"
                  onClick={() => {
                    getQuiz(query.get("id"));
                  }}
                >
                  {isLoading ? (
                    <CircularProgress
                      style={{
                        fontSize: "2.5rem",
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                    />
                  ) : (
                    "Start Quiz"
                  )}
                </button>
              </div>
            )}
          </React.Fragment>
        )}
        {qtype === 1 && (
          <React.Fragment>
            <SummativeQuiz
              question={question}
              setQuestion={setQuestion}
              loading={isLoading}
              setLoading={setIsLoading}
              startQuiz={startQuiz}
              setStartQuiz={setStartQuiz}
              getQuiz={getQuiz}
              score={perScore}
              setPerScore={setPerScore}
              setShowScore={setShowScore}
              showScore={showScore}
              totalScore={totalScore}
              setTotalScore={setTotalScore}
              qpage={"object"}
            />
            {!startQuiz && !showScore && (
              <div className="formative_start_button_container-2">
                <div style={{ alignSelf: "center" }}>
                  <button
                    className="button-3"
                    onClick={() => {
                      getQuiz(query.get("id"));
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        style={{
                          fontSize: "2.5rem",
                          height: "2.5rem",
                          width: "2.5rem",
                        }}
                      />
                    ) : (
                      "Start Quiz"
                    )}
                  </button>
                </div>
              </div>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  const handleRateClick = () => {
    setOpen(true);
  };
  function handlePlay() {
    vid.current.play();
  }
  const toggleFullScreen = () => {
    screenfull.toggle(full.current);
  };
  const onFullScreen = () => {
    setFullScreen(!fullScreen);
  };
  return (
    <div>
      {sharedObject.id !== 1 ? (
        <>
          {userDetails !== 0 &&
            userDetails !== undefined &&
            userDetails.location !== undefined && (
              <>
                {isLoading ? (
                  <>
                    <Typography
                      component="h1"
                      variant="h3"
                      style={{ width: "20%" }}
                    >
                      <Skeleton animation="wave" />
                    </Typography>
                  </>
                ) : userDetails.location.from !== undefined &&
                  (userDetails.location.from === "Home" ||
                    userDetails.location.from === "Bookmarks" ||
                    userDetails.location.from === "Explore" ||
                    userDetails.location.from === "Search") ? (
                  <Breadcrumbs
                    separator={
                      <NavigateNextIcon
                        fontSize="large"
                        style={{ fill: "var(--color-primary)" }}
                      />
                    }
                    aria-label="breadcrumb"
                    style={{ marginBottom: "2rem", cursor: "pointer" }}
                  >
                    {userDetails.location.from == "Home" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Home" ? "/home" : "#"
                        }
                        style={{
                          color: `${
                            userDetails.location.from === "Home"
                              ? "#1974b5"
                              : "#777"
                          }`,
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: `${
                            userDetails.location.from === "Home"
                              ? "pointer"
                              : "context-menu"
                          }`,
                        }}
                      >
                        {`${userDetails?.languagejson?.breadcrumbs?.Home ||
                          userDetails.location.from}`}
                      </Link>
                    ) : (
                      ""
                    )}
                    {userDetails.location.lname &&
                      userDetails.location.from == "Home" &&
                      (userDetails?.contentName === "" ||
                        userDetails?.contentName === sharedObject?.oname) && (
                        <Link
                          to={userDetails.location.lname ? `/learning` : "#"}
                          style={{
                            color: `${
                              userDetails.location.lname ? "#1974b5" : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.lname
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                          color="inherit"
                        >
                          {userDetails.location.lname}
                        </Link>
                      )}
                    {userDetails.location.from == "Search" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Search" ? "/home" : "#"
                        }
                        style={{
                          color: "#777",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "context-menu",
                        }}
                      >
                        {`${userDetails?.languagejson?.breadcrumbs?.Search ||
                          userDetails.location.from}`}
                      </Link>
                    ) : (
                      ""
                    )}
                    {userDetails.location.from == "Explore" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Explore"
                            ? "/home"
                            : "#"
                        }
                        style={{
                          color: "#777",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "context-menu",
                        }}
                      >
                        {`${userDetails?.languagejson?.breadcrumbs?.Explore ||
                          userDetails.location.from}`}
                      </Link>
                    ) : (
                      ""
                    )}
                    {userDetails.location.from == "Bookmarks" ? (
                      <Link
                        color="inherit"
                        to={
                          userDetails.location.from === "Bookmarks"
                            ? "/bookmarks"
                            : "#"
                        }
                        style={{
                          color: `${
                            userDetails.location.from === "Bookmarks"
                              ? "#1974b5"
                              : "#777"
                          }`,
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: `${
                            userDetails.location.from === "Bookmarks"
                              ? "pointer"
                              : "context-menu"
                          }`,
                        }}
                      >
                        {`${userDetails?.languagejson?.breadcrumbs?.Bookmarks ||
                          userDetails.location.from}`}
                      </Link>
                    ) : (
                      ""
                    )}
                    {userDetails.location.topicName &&
                      userDetails.location.topicId ===
                        sharedObject?.cgry_id && (
                        <Link
                          to={
                            userDetails.location.contentName
                              ? `/explore/${userDetails.location.topicName.replaceAll(
                                  /\s/g,
                                  "-"
                                )}/${userDetails.location.topicId}`
                              : "#"
                          }
                          style={{
                            color: `${
                              userDetails.location.contentName
                                ? "#1974b5"
                                : "#777"
                            }`,
                            fontSize: "1.5rem",
                            textDecoration: "none",
                            cursor: `${
                              userDetails.location.contentName
                                ? "pointer"
                                : "context-menu"
                            }`,
                          }}
                          color="inherit"
                        >
                          {userDetails.location.topicName}
                        </Link>
                      )}
                    {userDetails.location.viewAll && (
                      <Link
                        to={
                          userDetails.location.viewAllRoute
                            ? userDetails.location.viewAllRoute
                            : "/ExploreCourseView"
                        }
                        color="inherit"
                        style={{
                          color: "#1974b5",
                          fontSize: "1.5rem",
                          textDecoration: "none",
                          cursor: "pointer",
                        }}
                      >
                        {`${userDetails?.languagejson?.content_collateral
                          ?.View_all || "View all"}`}
                      </Link>
                    )}
                    <Link
                      style={{
                        color: "#777",
                        fontSize: "1.5rem",
                        textDecoration: "none",
                        cursor: "context-menu",
                      }}
                      color="inherit"
                    >
                      {sharedObject?.oname}
                    </Link>
                  </Breadcrumbs>
                ) : (
                  ""
                )}
              </>
            )}
          <div className="flex-between">
            <div className={classes.mainContainer}>
              <h3 style={{ fontWeight: "600" }} className="responsive">
                {isLoading ? (
                  <Skeleton className={classes.loaderSkeleton} />
                ) : (
                  <>{sharedObject.oname}</>
                )}
              </h3>
              {isLoading ? (
                <Skeleton
                  style={{ width: "60px", height: "5rem", margin: "10px" }}
                />
              ) : (
                <>
                  {" "}
                  <span className="ml vLine"></span>
                  {config.ratingFeature && (
                    <StarIcon className="icon icon-star" />
                  )}
                  {config.ratingFeature && (
                    <span style={{ margin: "4px" }}>
                      {sharedObject?.star?.toFixed(1) || "0.0"}
                    </span>
                  )}
                </>
              )}
              {sharedObject.otype === "video" ? (
                <Tippy
                  interactive={true}
                  content={
                    <div
                      style={{
                        background: "transparent",
                        color: "black",
                        padding: "1rem",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: sharedObject.odesc
                          ? sharedObject.odesc
                          : "No description",
                      }}
                    ></div>
                  }
                  {...defaultTippyProps}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <InfoOutlinedIcon
                      className="ml"
                      color="primary"
                      style={{
                        fill: "#13599a",
                        color: "#13599a",
                        fontSize: "2.5rem",
                      }}
                    />
                  </div>
                </Tippy>
              ) : null}
              <span>{more}</span>
            </div>
            <div className="flex-container">
              {sharedObject.otype === "pdf" ||
              sharedObject.otype === "Interactivity" ? (
                <>
                  {isLoading ? (
                    <Skeleton style={{ width: "50px", height: "5rem" }} />
                  ) : (
                    <button
                      onClick={() => downloadPdf()}
                      className={classes.downloadButton}
                      download
                    >
                      <img src={downloadPdfSvg} alt="download" height="100%" />
                    </button>
                  )}
                </>
              ) : null}
              {!objLoad &&
                Object.keys(report).length === 0 &&
                config.ratingFeature && (
                  <Button
                    variant="outlined"
                    className={classes.ratingButton}
                    onClick={() => {
                      handleRateClick();
                      setRateValue(1);
                    }}
                  >
                    {`${userDetails?.languagejson?.content_collateral
                      ?.Rate_content || "Rate content"}`}
                  </Button>
                )}
              {report?.rating === null && config.ratingFeature && (
                <Button
                  variant="outlined"
                  className={classes.ratingButton}
                  onClick={() => {
                    handleRateClick();
                    setRateValue(1);
                  }}
                >
                  {`${userDetails?.languagejson?.content_collateral
                    ?.Rate_content || "Rate content"}`}
                </Button>
              )}
              {open && (
                <CustomizedDialogs
                  open={open}
                  setOpen={setOpen}
                  value={rateValue}
                  setValue={setRateValue}
                  syncObjectsData={syncObjectsData}
                  btnLoad={btnLoad}
                  setBtnLoad={setBtnLoad}
                />
              )}
              {sharedObject.shareable && (
                <Button
                  variant="outlined"
                  style={{
                    fontSize: "1.5rem",
                    marginLeft: "2rem",
                    width: "120px",
                    color: "#01498e",
                    borderColor: "#01498e",
                    textTransform: "none",
                    backgroundColor: "white",
                  }}
                  className={classes.responsive}
                  onClick={handleClick}
                >
                  {`${userDetails?.languagejson?.content_collateral
                    ?.Copy_link || "Copy link"}`}
                </Button>
              )}
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={handleClose}
                message="Copied the link..!"
              >
                <Alert onClose={handleClose} severity="success">
                  {`${userDetails?.languagejson?.content_collateral
                    ?.Copied_the_link || "Copied the link!"}`}
                  !
                </Alert>
              </Snackbar>
              {isLoading ? (
                <Skeleton
                  style={{ width: "80px", height: "5rem", margin: "10px" }}
                />
              ) : (
                <>
                  {report?.bookmark ? (
                    <button
                      className={classes.bookmarkIcon}
                      onClick={() => {
                        addAndRemoveBookmark(1);
                      }}
                    >
                      <BookmarkRoundedIcon className={classBookmark.filled} />
                    </button>
                  ) : (
                    <button
                      className={classes.bookmarkIcon}
                      onClick={() => {
                        addAndRemoveBookmark(0);
                      }}
                    >
                      <BookmarkRoundedIcon className={classBookmark.outline} />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
          {/* Video Section */}
          <div className={classes.root}>
            <Paper
              ref={full}
              elevation={8}
              className={
                sharedObject.otype === "video" ||
                sharedObject.otype === "vimeo" ||
                sharedObject.otype === "youtube"
                  ? classes.paperVideo
                  : classes.paper
              }
            >
              {sharedObject.otype === "video" ||
              sharedObject.otype === "vimeo" ||
              sharedObject.otype === "youtube" ? (
                <div
                  onClick={() => handlePlay()}
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer",
                    zIndex: "100000000",
                  }}
                  between
                >
                  {isPlaying === true ? (
                    <PlayCircleOutlineRoundedIcon
                      style={{
                        fill: "#fff",
                        fontSize: "10rem",
                        cursor: "pointer",
                      }}
                    />
                  ) : null}
                </div>
              ) : null}
              {objLoad ? "loading... please wait" : renderObjects()}
              <input
                style={{
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  zIndex: "-1000",
                }}
                type="text"
                id="input"
                ref={copyToClipBoard}
                value={`${config.DOMAIN}#/sharingobject?val1=${query.get(
                  "id"
                )}&val2=${userDetails.locale}`}
              />
              {sharedObject.otype === "pdf" ||
              sharedObject.otype === "Interactivity" ||
              sharedObject.otype === "scorm" ? (
                <IconButton
                  style={{
                    position: "absolute",
                    bottom: "30px",
                    right: "50px",
                  }}
                  onClick={() => {
                    toggleFullScreen();
                    onFullScreen();
                  }}
                  className={classes.bottomIcons}
                >
                  {fullScreen ? (
                    <Fullscreen
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  ) : (
                    <FullscreenExitIcon
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  )}
                </IconButton>
              ) : null}
            </Paper>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "90vh",
            display: "grid",
            placeItems: "center",
          }}
        >
          <div className={classes.modalContent}>
            <div style={{ margin: "2rem 0", textAlign: "center" }}>
              <p
                style={{
                  color: "#fff",
                  fontSize: "1.75rem",
                  fontWeight: "600",
                }}
              >
                {`${userDetails?.languagejson?.content_collateral
                  ?.Content_you_are_looking_for_could_not_be_found ||
                  "Content you are looking for could not be found"}`}
              </p>
              <button
                style={{
                  border: "none",
                  color: "#1974b5",
                  background: "#fff",
                  padding: ".5rem 2rem",
                  marginTop: "2rem",
                  borderRadius: ".4rem",
                  fontSize: "1.45rem",
                  cursor: "pointer",
                }}
                onClick={() => history.push("/home")}
              >
                {`${userDetails?.languagejson?.content_collateral
                  ?.Go_to_dashboard || "Go to dashboard"}`}
              </button>
            </div>
            <div
              style={{
                marginBottom: "2rem",
                color: "#fff",
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}
export default RadioObject;
