import { Box } from "@material-ui/core"
import * as React from "react"

import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import { CircularProgress } from "@material-ui/core"
// import { useForm } from "./useForm";
import { Auth } from "aws-amplify"

import validateFPEmail from "./SignInSignUpValidationRules/ForgotPasswordValidationRules"
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules"
import { useForm } from "../../Hooks/useForm"
import { useState } from "react"
import { useHistory } from "react-router-dom"

import { CustomButton } from "../../components/CustomButton/CustomButton"
import CustomInput from "../../components/CustomInput/CustomInput"
import { HeadingPrimary } from "../../components/Styles/Heading"
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../components/SignInAndSignUp/SignInAndSignUp"
import CustomLoginLayout from "./CustomLoginLayout"

const ForgotPassword = () => {
  const history = useHistory()
  const { values, handleChange } = useForm({ showPassword: false })

  const [errors, setErrors] = useState({})
  const [emailVerifyLoader, setEmailVerifyLoader] = useState(false)

  const handleSubmit = async (event) => {
    event.preventDefault()
    if (Object.keys(validateFPEmail(values)).length !== 0) {
      setErrors(validateFPEmail(values))
      return
    } else {
      setEmailVerifyLoader(true)
      try {
        const forgot = await Auth.forgotPassword(values.email)
localStorage.setItem("email",values.email);
        if (forgot) {
          setEmailVerifyLoader(false)
          setErrors({})
          history.push({
            pathname: "/confirm-forgot-password",
            state: {
              email: values.email,
            },
          })
        }
      } catch (error) {
        setEmailVerifyLoader(false)
        console.error(error)

        setErrors(cognitoValidation(error, "forgotpassword"))
      }
    }
  }
  return (
    <React.Fragment>
      <CustomLoginLayout>
        <React.Fragment>
          <Box style={{ textAlign: "center" }}>
            <HeadingPrimary>Forgot password ?</HeadingPrimary>
          </Box>

          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <React.Fragment>
              <Box style={inputBoxStyle}>
                <CustomInput
                  id="email"
                  placeholder="Enter email address"
                  name="email"
                  type="email"
                  error={errors.email}
                  value={values.email || ""}
                  onChange={handleChange("email")}
                >
                  <PersonOutlineOutlinedIcon sx={inputIconStyle} />
                </CustomInput>
              </Box>
              <label
                    style={{
                      fontSize: "12px",
                      width: "360px",
                      textAlign: "center",
                      marginLeft: "0px",
                      color: "black",
                    }}
                  >
                    *Please enter the registered email-id.
                  </label>
              <Box style={{ textAlign: "center" }}>
                {emailVerifyLoader ? (
                  <CustomButton loading="true" disabled>
                    <CircularProgress size={20} color="inherit" />
                  </CustomButton>
                ) : (
                  <CustomButton primary className="btnlogin">
                    Next
                  </CustomButton>
                )}
                <CustomButton
                  type="button"
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  Cancel
                </CustomButton>
              </Box>
            </React.Fragment>
          </form>
        </React.Fragment>
      </CustomLoginLayout>
    </React.Fragment>
  )
}

export default ForgotPassword
