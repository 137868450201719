import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
import CardSmall from "../../components/Cards/CardSmall/CardSmall";
import {
  Stack, Breadcrumbs,Typography
} from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
const useStyles = makeStyles(() => ({
  "@media (max-width: 770px)": {
    MobileView: {
      "& > div > div > span": {
        display: "none!important",
      },
      "& > div > div > div > div": {
        display: "block",
        "@media (min-width: 770px)": {
          flexWrap: "wrap",
          gap: "1rem",
        },
        "& > div": {
          margin: "1.5rem 0rem",
          marginRight: "0!important",
          width: "auto!important",
        },
      },
    },
  },
  MobileView: {
    "& > div > div > div > div": {
      "@media (min-width: 770px)": {
        flexWrap: "wrap",
        gap: "2rem",
        justifyContent: "center",
      },
    },
    "& > div > div > span": {
      "@media (min-width: 770px)": {
        display: "none!important",
      },
    },
  },
}));
export default function ExploreTopics() {
  const classes = useStyles();
  let userDetails = useSelector(authData);
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}>
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography key="3" color="text.primary" style={{
      color: "#777",
      fontSize: "1.5rem",
      textDecoration: "none",
      cursor: "context-menu",
    }}>
      {`${userDetails?.languagejson?.header?.Explore || "Explore"}`}
    </Typography>,
  ];
  return (
    <div>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="large" style={{ color: "var(--color-primary)" }} />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className={classes.MobileView}>
        <CardSmall
          heading={`${userDetails?.languagejson?.Home_Page?.All_Topics ||
            "All Topics"}`}
        />
      </div>
    </div>
  );
}
