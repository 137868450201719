import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import config, { Theme } from "../../aws-exports";
import CardLarge from "../../components/Cards/CardLarge/CardLarge";
import Header from "../../components/Header/Header";
import { useHistory } from "react-router-dom";
import { Box, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { Breadcrumbs, makeStyles } from "@material-ui/core";
import { useParams, Link, useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: "2rem",
    boxShadow: Theme.boxShadow,
    backgroundColor: "#fff",
    padding: "2rem",
    borderRadius: "5px",
    "& > div > div": {
      margin: "auto",
    },
    "@media (max-width: 600px)": {
      marginTop: 0,
    },
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    "@media (max-width: 500px)": {
      display: "grid",
    },
  },
}));
export default function ViewAllCourses() {
  const userDetails = useSelector(authData);
  const [allCourses, setAllCourses] = useState([]);
  const [load, setLoad] = useState(true);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    getViewAllCourses();
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  const getViewAllCourses = async () => {
    // const bodyParam = {
    //   body: {
    //     oid: config.aws_org_id,
    //     tenant: userDetails.locale,
    //     schema: config.schema,
    //     ur_id: userDetails.uData?.ur_id,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getViewAllCourse",
    //     bodyParam
    //   );
    try {
      const requestBody = {
        oid: config.aws_org_id,
            tenant: userDetails.locale,
            schema: config.schema,
            ur_id: userDetails.uData?.ur_id,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getViewAllCourse?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      const { courses } = response;
      const filterAllCourses = courses.filter(
        (course) =>
          course === undefined ||
          course.pid === undefined ||
          course.pid === "" ||
          course.pid === " " ||
          course.pid === "null " ||
          course.pid === null ||
          course.pid === 0
      );
      setAllCourses(filterAllCourses);
      setLoad(false);
    } catch (err) {
      console.error(err);
    }
  };
  const handleLocation = (TID, TN) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "View All Courses",
      prevLoc: "course",
      courseName: TN,
      courseId: TID,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`/course?id=${TID}`);
  };
  function ProgramView(pid) {
    if (userDetails.location.pFrom !== undefined) {
      return `/ExploreCourseView?from=0&id=${pid}`;
    } else {
      return `/ExploreCourseView?id=${pid}`;
    }
  }
  return (
    <div>
      {userDetails !== 0 &&
      userDetails !== undefined &&
      userDetails.location !== undefined ? (
        <>
          {userDetails.location.from !== undefined &&
          (userDetails.location.from === "Home" ||
            userDetails.location.from === "Explore" ||
            userDetails.location.from === "Search") ? (
            <Breadcrumbs
              separator={
                <NavigateNextIcon
                  fontSize="large"
                  style={{fill: "var(--color-primary)"}}
                />
              }
              aria-label="breadcrumb"
              style={{ marginBottom: "2rem", cursor: "pointer" }}
            >
              <Link
                color="inherit"
                to={userDetails.location.from === "Home" ? "/home" : "#"}
                style={{
                  color: `${
                    userDetails.location.from === "Home" ? "#1974b5" : "#777"
                  }`,
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: `${
                    userDetails.location.from === "Home"
                      ? "pointer"
                      : "context-menu"
                  }`,
                }}
              >
                {userDetails.location.from}
              </Link>
              {userDetails.location.topicName && (
                <Link
                  to={
                    userDetails.location.courseName
                      ? `/explore/${userDetails.location.topicName.replaceAll(
                          /\s/g,
                          "-"
                        )}/${userDetails.location.topicId}`
                      : "#"
                  }
                  style={{
                    color: `${
                      userDetails.location.courseName ? "#1974b5" : "#777"
                    }`,
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: `${
                      userDetails.location.courseName
                        ? "pointer"
                        : "context-menu"
                    }`,
                  }}
                  color="inherit"
                >
                  {userDetails.location.topicName}
                </Link>
              )}
              {userDetails.location.viewAll && (
                <Link
                  to={
                    userDetails.location.viewAllRoute
                      ? userDetails.location.viewAllRoute
                      : "/ExploreViewAll"
                  }
                  color="inherit"
                  style={{
                    color: "#1974b5",
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: "pointer",
                  }}
                >
                  {`${userDetails?.languagejson?.Home_Page?.View_all ||
                    "View all"}`}
                </Link>
              )}
              {userDetails.location.programName && (
                <Link
                  to={ProgramView(userDetails.location.programId)}
                  style={{
                    color: "#1974b5",
                    fontSize: "1.5rem",
                    textDecoration: "none",
                  }}
                  color="inherit"
                >
                  {userDetails.location.programName}
                </Link>
              )}
              {userDetails.location.courseName && (
                <Link
                  style={{
                    color: "#777",
                    fontSize: "1.5rem",
                    textDecoration: "none",
                    cursor: "context-menu",
                  }}
                  color="inherit"
                >
                  {userDetails.location.courseName}
                </Link>
              )}
              <Link
                style={{
                  color: "#777",
                  fontSize: "1.5rem",
                  textDecoration: "none",
                  cursor: "context-menu",
                }}
                color="inherit"
              >
                {`${userDetails?.languagejson?.Home_Page?.View_all ||
                  "View all"}`}{" "}
                {`${userDetails?.languagejson?.Home_Page?.Courses ||
                  "Courses"}`}
              </Link>
            </Breadcrumbs>
          ) : null}
        </>
      ) : (
        <></>
      )}
      {load ? (
        <div className={classes.mainDiv}>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton
              variant="rect"
              width={310}
              height={200}
              animation="wave"
            />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </div>
      ) : (
        <Grid container spacing={3} className={classes.mainGrid}>
          {allCourses.length !== 0 ? (
            <>
              {allCourses.map(
                (
                  {
                    tid,
                    star,
                    ttitle,
                    noofnuggets,
                    sub_date,
                    comp_date,
                    cobj,
                    tobj,
                  },
                  i
                ) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={4}
                    lg={3}
                    onClick={() => handleLocation(tid, ttitle)}
                    key={i + "ajhfdj"}
                  >
                    <CardLarge
                      tid={tid}
                      star={star}
                      title={ttitle}
                      count={noofnuggets}
                      sub_date={sub_date}
                      comp_date={comp_date}
                      cobj={cobj}
                      tobj={tobj}
                    />
                  </Grid>
                )
              )}
            </>
          ) : (
            <h3 style={{ textAlign: "center", margin: "10rem auto" }}>
              Nothing here yet
            </h3>
          )}
        </Grid>
      )}
    </div>
  );
}
