import React, { useState, useEffect, useRef } from "react";
import { API, Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { awsSignOut } from "../../redux/auth/authSlice";
import config, { Theme } from "../../aws-exports";
import Cookies from "js-cookie";
import { StorageHelper } from "@aws-amplify/core";

function SessionTimeOut({ active, setActive }) {
  const historyPath = useHistory();
  const dispatch = useDispatch();
  const storage = new StorageHelper().getStorage();

  const sessionDuration = 30 * 60 * 1000; // 30 minutes in milliseconds

  const [lastActivity, setLastActivity] = useState(Date.now());

  const handleUserActivity = () => {
    setLastActivity(Date.now());
  };

  async function getUserData() {
    Cookies.remove("CloudFront-Policy");
    Cookies.remove("CloudFront-Key-Pair-Id");
    Cookies.remove("CloudFront-Signature");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("id_token");
    var padmin = localStorage.getItem("persist:adminRoot");
    localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("persist:root");
    storage.removeItem("persist:root");
    localStorage.removeItem(config.aws_cloudfront_url);
    window.onbeforeunload = () => {
      localStorage.clear();
    };
    padmin !== null && localStorage.setItem("persist:adminRoot", padmin);
    if (config.aws_org_id != "AXIATA") {
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.location.href = `${config.auth_url}logout?response_type=code&client_id=${config.aws_user_pools_web_client_id}&redirect_uri=${config.aws_cloudfront_lurl}&state=STATE&scope=email+openid+profile+aws.cognito.signin.user.admin`;
      //window.location.href = `https://devexcelsoft.auth.us-east-1.amazoncognito.com/logout?response_type=code&client_id=4kf26chr0acga63b7bjade31mm&redirect_uri=https://www.excelearn.enhanzed.com&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
      //window.location.href = `https://axiata.auth.ap-south-1.amazoncognito.com/logout?response_type=code&client_id=unrg40t8sotki8vngipar91uf&redirect_uri=https://www.axiata.enabled.live&state=STATE&scope=openid+profile+aws.cognito.signin.user.admin`;
    }
    //window.location.href = config.aws_cloudfront_lurl;
  }

  useEffect(() => {
    // Start the session timer when the component mounts
    setActive(active);
    const interval = setInterval(() => {
      const currentTime = Date.now();
      if (currentTime - lastActivity >= sessionDuration) {
        // Perform logout or session timeout actions here
        if (active !== true && config.aws_org_id === "NSECORP") {
          logout();
        }

        // Implement your logout logic here, e.g., dispatch logout action, etc.
      }
    }, 1000);
    // Attach event listeners to reset the session timer on user interaction
    window.addEventListener("focus", handleUserActivity);
    window.addEventListener("blur", handleUserActivity);
    document.addEventListener("touchstart", handleUserActivity);
    document.addEventListener("touchmove", handleUserActivity);
    document.addEventListener("MSPointerDown", handleUserActivity);
    document.addEventListener("MSPointerMove", handleUserActivity);
    document.addEventListener("visibilitychange", handleUserActivity);
    window.addEventListener("mousemove", handleUserActivity);
    /* window.addEventListener("click", handleUserInteraction);
    window.addEventListener("keydown", handleUserInteraction); */
    return () => {
      clearInterval(interval);
      // Clean up event listeners when the component unmounts
      /* window.removeEventListener("click", handleUserInteraction);
      window.removeEventListener("keydown", handleUserInteraction); */
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("focus", handleUserActivity);
      window.removeEventListener("blur", handleUserActivity);

      document.removeEventListener("touchstart", handleUserActivity);
      document.removeEventListener("touchmove", handleUserActivity);
      document.removeEventListener("MSPointerDown", handleUserActivity);
      document.removeEventListener("MSPointerMove", handleUserActivity);
      document.removeEventListener("visibilitychange", handleUserActivity);
    };
  }, [lastActivity]);
  function logout() {
    // Perform logout action here
    Auth.signOut();
    dispatch(awsSignOut());
    //window.location.reload();
    //historyPath.push("/");

    getUserData();
  }

  return null;
}

export default SessionTimeOut;
