import { Box } from "@material-ui/core";
import * as React from "react";
import CustomInput from "../CustomInput/CustomInput";

import {
  inputBoxStyle,
  inputIconStyle,
  displayCenter,
  ButtonIconStyle,
  SignInAndSignUpValues,
} from "./SignInAndSignUp";

import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import { CustomButton } from "../CustomButton/CustomButton";
import { CircularProgress, Typography } from "@material-ui/core";
import { HeadingPrimary } from "../Styles/Heading";
// import { useForm } from "./useForm";
import Auth from "@aws-amplify/auth";
import ResetPassword from "./ResetPassword";
import validateFPEmail from "./SignInSignUpValidationRules/ForgotPasswordValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";

const ForgotPassword = ({
  emailVerifyLoader,
  setEmailVerifyLoader,
  setEmailVerification,
  setForgotPassword,
  setResetPasswordOpenFirst,
  values,
  handleChange,
  passwordResetFC,
  resetPasswordLoader,
  emailVerification,
  setErrors,
  errors,
  setValues,
  handleClickShowPassword,
  handleMouseDownPassword,
  setSignIn,
  setSignUp,
  signIn,
  signUp,
  forgotPassword,
  setSignInForm,
}) => {
  // const { values, handleChange } = useForm({});
  const [emailView, setEmailView] = React.useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validateFPEmail(values)).length !== 0) {
      setErrors(validateFPEmail(values));
      return;
    } else {
      setEmailVerifyLoader(true);
      try {
        const forgot = await Auth.forgotPassword(values.verificationEmail);

        if (forgot) {
          setEmailVerifyLoader(false);
          setEmailVerification(true);
          setResetPasswordOpenFirst(false);
          setEmailView(false);
          setErrors({});
        }
      } catch (error) {
        setEmailVerifyLoader(false);
        console.error(error);

        setErrors(cognitoValidation(error, "forgotpassword"));
      }
    }
  };
  return (
    <React.Fragment>
      {emailView ? (
        <React.Fragment>
          <Box style={{ textAlign: "center" }}>
            {/* <Typography variant="h4" component="div" gutterBottom>
              Forgot password?  
            </Typography> */}

            <HeadingPrimary tabIndex={2}>Forgot password ?</HeadingPrimary>
          </Box>

          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <React.Fragment>
              <Box style={inputBoxStyle}>
                <CustomInput
                  id="verificationEmail-1"
                  placeholder="Please enter email"
                  name="verificationEmail"
                  error={errors.email}
                  value={values.verificationEmail || ""}
                  onChange={handleChange("verificationEmail")}
                >
                  <EmailOutlinedIcon style={inputIconStyle} />
                </CustomInput>
              </Box>
              <Box style={{ textAlign: "center", marginTop: "2rem",}}>
                {emailVerifyLoader ? (
                  <CustomButton loading="true" disabled>
                    <CircularProgress size={20} color="inherit" />
                  </CustomButton>
                ) : (
                  <CustomButton primary className="btnlogin">
                    Next
                  </CustomButton>
                )}
                <CustomButton
                  type="button"
                  className="btnloginCancel"
                  secondary
                  onClick={() => {
                    setSignIn(true);
                    setSignUp(false);
                    setForgotPassword(false);
                    setResetPasswordOpenFirst(false);
                    setSignInForm(true);
                    setErrors({});
                  }}
                >
                  Cancel
                </CustomButton>
              </Box>
            </React.Fragment>
          </form>
        </React.Fragment>
      ) : (
        <ResetPassword
          passwordResetFC={passwordResetFC}
          resetPasswordLoader={resetPasswordLoader}
          emailVerification={emailVerification}
          handleChange={handleChange}
          values={values}
          setValues={setValues}
          handleClickShowPassword={handleClickShowPassword}
          handleMouseDownPassword={handleMouseDownPassword}
          setErrors={setErrors}
          errors={errors}
          setEmailView={setEmailView}
          setSignInForm={setSignInForm}
          setSignIn={setSignIn}
          setForgotPassword={setForgotPassword}
          setResetPasswordOpenFirst={setResetPasswordOpenFirst}
          setSignUp={setSignUp}
          signIn={signIn}
          signUp={signUp}
        />
      )}
    </React.Fragment>
  );
};

export default ForgotPassword;
