import React, { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import config from "../../aws-exports";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { useParams, useLocation } from "react-router-dom";
import API from "@aws-amplify/api";
import SharedHeader from "../../components/Header/SharedHeader/SharedHeader";
import { Backdrop, CircularProgress, makeStyles } from "@material-ui/core";
const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "blue",
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function SharedObject() {
  const userDetails = useSelector(authData);
  const params = useParams();
  let query = useQuery();
  const [sharedObject, setSharedObject] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let val2 = query.get("val2");
  let val1 = query.get("val1");
  let val4 = query.get("val4");
  let val5 = query.get("val5");
  let val6 = query.get("val6");
  let val7 = query.get("val7");
  let val8 = query.get("val8");
  const classes = useStyles();
  useEffect(() => {
    window.scrollTo(0, 0);
    getSharedObject();
    setIsLoading(true);
  }, [val2, val1]);
  const getSharedObject = async () => {
    try {
      const requestBody = {
            val2,
            val1,
            val3: config.aws_org_id,
            val4,
            val5,
            val6,
            groups: val8,
            linkview: 1,
            schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getSharedObject?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      var obj2 = {};
      obj2.objid = val6;
      obj2.ur_id = val4;
      obj2.op =  val7 ?? 1;
      obj2.cid = val1;
      obj2.tid = val1;
      obj2.atype = 0;
      obj2.schema = config.schema;
      if(response?.suspenddata?.length > 0){
        obj2.susdt = response.suspenddata;
      } 
      window.cudetails = obj2;
      setSharedObject(response);
      const expires = new Date().getTime() + 60 * 60 * 1000;
      Cookies.set("CloudFront-Expires", expires);
      Cookies.set("CloudFront-Policy", response.Policy);
      Cookies.set("CloudFront-Signature", response.Signature);
      Cookies.set("CloudFront-Key-Pair-Id", response.KeyPairId);
      setIsLoading(false);
      updateSharedObject();
    } catch (err) {
      console.error(err);
    }
  };
  const updateSharedObject = async () => {
    const jsonString = JSON.stringify({
      tenant: val2,
        id: val1,
        oid: config.aws_org_id,
        schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateSharedAnalytics",
        bodyParam
      );
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <>
      {isLoading ? (
        <div
          style={{
            height: "100vh",
            width: "100vw",
            display: "grid",
            placeItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
          {!val5 && <SharedHeader />}
          {sharedObject.ourl ? (
            <div style={{ width: "100vw", height: val5 ? "100vh" : "92.5vh" }}>
              <iframe
                title="This is a unique title"
                src={sharedObject.ourl}
                allowFullScreen
                height="100%"
                width="100%"
                frameBorder="no"
              />
            </div>
          ) : (
            <div
              style={{
                height: "90vh",
                width: "100vw",
                display: "grid",
                placeItems: "center",
              }}
            >
              <div
                style={{
                  width: "400px",
                  height: "200px",
                  background: "#1974b5",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: ".8rem",
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontSize: "1.75rem",
                    fontWeight: "600",
                  }}
                >
                  There is nothing here to display
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
export default SharedObject;
