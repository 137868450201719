import React from "react";
import "./Footer.scss";
import config from "../../aws-exports";
function Footer() {
  return (
    <div>
      <footer className="footer">v{config.appVersion}</footer>
    </div>
  );
}

export default Footer;
