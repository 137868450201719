import * as React from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Button, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import {
  Typography,
  DialogContent,
  Paper,
  DialogTitle,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  Dialog,
  TableRow,
  TableHead,
  Table,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import avatar1 from "../../assets/male1.png";
import PersonIcon from "@mui/icons-material/Person";
import config from "../../aws-exports";
import { useHistory } from "react-router-dom";
import { getActiveUsers } from "../../api/api";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ActiveUsers({ activeCount }) {
  let userDetails = useSelector(authData);
  const history = useHistory();
  const useStyles = makeStyles((theme) => ({
    responsiveMenu: {
      "@media (max-width: 770px)": {
        display: "none",
      },
    },
    dialogPaper: {
      overflow: "auto",
      padding: "1rem 3rem",
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "2rem!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "bold!important",
      padding: "1rem 0 0 2rem!important",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    dialogSection: {
      "& > div > div": {
        borderRadius: "1rem",
        maxWidth: "80%!important",
        width: "80%!important",
        height: "80vh!important",
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "@media (max-width: 770px)": {
          width: "90%!important",
          maxWidth: "90%!important",
          height: "70vh!important",
        },
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
        },
      },
    },
    avatarIconSection: {
      display: "flex!important",
      alignItems: "center",
      gap: "1.5rem",
      color:
        userDetails.themeDark === "dark"
          ? "#fff !important"
          : "#000 !important",
      justifyContent: "center",
      "& > img": {
        width: "4rem",
      },
    },
    peopleLive: {
      display: "flex",
      gap: "2px",
      fontSize: "1.5rem",
      padding: "4px 6px",
      alignItems: "center",
      border: "1px solid var(--color-primary)",
      width: "fit-content",
      borderRadius: "3rem",
      backgroundColor: "#def3ff",
      color: "var(--color-primary)",
      margin: "0rem 0rem 1rem auto",
    },
    peopleLiveHeader: {
      display: "flex",
      gap: "2px",
      fontSize: "1.2rem",
      padding: "4px 6px",
      alignItems: "center",
      width: "fit-content",
      borderRadius: "3rem",
      backgroundColor: "#fff",
      color: "#525252",
      fontWeight: "600",
      margin: "1rem 0rem 1rem auto",
      textTransform: "none",
    },
    rankSection: {
      fontWeight: "600!important",
      color:
        userDetails.themeDark === "dark"
          ? "#fff !important"
          : "#000 !important",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--color-button-background)",
      color: theme.palette.common.white,
      fontSize: "1.5rem",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1.5rem",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "#3F434D !important",
      borderBottom: "1px solid",
      borderColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#1b212f!important"
          : theme.palette.action.hover,
    },
    "&:nth-of-type(odd)": {
      backgroundColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [users, setUsers] = React.useState([]);

  const handleClickOpen = async () => {
    setOpen(true);
    setLoader(true);
    let users = await getActiveUsers(userDetails, true, false);
    setUsers(users);

    setLoader(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePageTrans = () => {
    setOpen(false);
    history.push({
      pathname: "/my-peers",
    });
  };

  return (
    <>
      <div className={classes.responsiveMenu}>
        <div>
          <Button
            onClick={handleClickOpen}
            style={{ minWidth: "30px", padding: 0 }}
          >
            <div className={classes.peopleLiveHeader}>
              <PersonIcon style={{ fontSize: "2rem", color: "black" }} />
              <span>
                {" "}
                {`${activeCount} ${userDetails?.languagejson?.Active_User
                  ?.Activetoday || "Active today"}`}
              </span>
            </div>
          </Button>
        </div>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className={classes.dialogSection}
        >
          <DialogTitle
            sx={{ m: 0 }}
            id="customized-dialog-title"
            className={classes.dialogHeader}
          >
            {`Team ${userDetails?.uData?.did}`}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {/* <DialogContent dividers> */}
            {loader ? (
              <>
                <Backdrop className={classes.backdrop} open={true}>
                  <CircularProgress color="inherit" />
                </Backdrop>
              </>
            ) : (
              <Typography className={classes.dialogPaper}>
                <div className={classes.peopleLive}>
                  <PersonIcon style={{ fontSize: "2rem" }} />
                  <span style={{ color: "black" }}>{activeCount}</span>
                  <span style={{ fontSize: "9px" }}>&#9864;</span>
                </div>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell
                          align="center"
                          style={{
                            fontFamily: `myfontregular`,
                            fontWeight: "800",
                          }}
                        >{`${userDetails?.languagejson?.Leaderboard?.Name ||
                          "Name"}`}</StyledTableCell>
                        <StyledTableCell
                          align="center"
                          style={{
                            fontFamily: `myfontregular`,
                            fontWeight: "800",
                          }}
                        >{`${userDetails?.languagejson?.Leaderboard?.Rank ||
                          "Rank"}`}</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {users?.map((user) => (
                        <StyledTableRow key={user.ur_id}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                            style={{ padding: "0.5rem" }}
                          >
                            <div
                              // to={`/my-peers`}
                              style={{
                                textDecoration: "none",
                                color: "black",
                                cursor: "pointer",
                              }}
                              onClick={handlePageTrans}
                              className={classes.avatarIconSection}
                            >
                              <img
                                src={
                                  user?.avtid
                                    ? `https://${
                                        config.DOMAIN
                                      }/${config.aws_org_id.toLowerCase()}-resources/images/profile-images/${
                                        user?.avtid
                                      }.png`
                                    : avatar1
                                }
                                alt="Avatar Name"
                                title="Avatar Name"
                              />
                              {user?.first_name}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            <div
                              style={{ textDecoration: "none", color: "black" }}
                              // onClick={handleClose}
                              className={classes.rankSection}
                            >
                              {" "}
                              {user?.rank}
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            )}
          </DialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
}

export default ActiveUsers;
