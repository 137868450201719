export default function cognitoValidation(error, type = "") {
  let errors = {};

  if (error.code === "UserNotFoundException") {
    errors.email = "User does not exist";
  } else if (error.code === "UserNotConfirmedException") {
    errors.email = "User Not Confirmed";
  } else if (error.code === "UsernameExistsException") {
    errors.email = "An account with the given email already exists.";
  }
  if (error.code === "NotAuthorizedException") {
    if (type === "forgotpassword") {
      errors.email = error.message || "";
    } else {
      errors.password = error.message;
      
    }
  } else if (error.code === "InvalidPasswordException") {
    errors.password = "Please match the password type.";
  }
  if (error.code === "InvalidParameterException") {
    errors.verificationCode = "Invalid code entered";
  } else if (error.code === "CodeMismatchException") {
    errors.verificationCode = "Code mismatch";
  }

  if (error.code === "CodeMismatchException") {
    errors.verificationCode =
      "Invalid verification code provided, please try again.";
  }
  if (error.code === "LimitExceededException") {
    errors.verificationCode = error.message;
  }
  

  return errors;
}
