import React, { useEffect, useState } from "react";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box, Button, Popover, Typography, Modal } from "@mui/material";
import { callAPI } from "../../api/api";
import config from "../../aws-exports";
import { ReactComponent as SaveSVG } from "../../assets/svg/Save.svg";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import { useDispatch } from "react-redux";
import { awsSignIn } from "../../redux/auth/authSlice";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Stack from "@mui/material/Stack";
import { sl_getReportData } from "../../../src/api/api";
import { useQuery } from "@tanstack/react-query";
import API from "@aws-amplify/api";
import Swal from "sweetalert2";
import CircularProgress from "@mui/material/CircularProgress";

const PostFooterView = ({
  row: initialRow,
  classes,
  hotRefeth,
  userDetails,
  page,
  setMainLoader,
  handleSaveSuccess,
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("");
  const [report, setReport] = useState("");
  const [loadReport, setLoadReport] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [reported, setReported] = useState(false);
  const [row, setRow] = useState(initialRow);
  const handleModalOpen = (postid) => {
    setLoadReport(true);
    setIsModalOpen(true);
    sl_check_report(postid);
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
    setAnchorEl(false);
  };

  const dispatch = useDispatch();

  const updateReport = async (data) => {
    const jsonString = JSON.stringify(data);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: { json: base64EncodedString },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/sl_post_report",
        bodyParam
      );
      if (response) {
        if (response.statusCode === 200) {
          Swal.fire({
            title:
              response.statusCode === 200 ? "Reported Successfull" : "Error",
            confirmButtonText: "Ok",
          });
        }
      }
    } catch (error) {
      throw error;
    }
    handleModalClose();
  };

  const { data: getReportData, refetch, isLoading: loading } = useQuery({
    queryKey: ["getReportData"],
    queryFn: () => sl_getReportData(),
  });

  const sl_check_report = async (postid) => {
    setLoadReport(true);
    try {
      const requestBody = {
        oid: config.aws_org_id,
        schema: config.SCHEMA_NAME,
        postid: postid,
        ur_id: userDetails.uData.ur_id,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/sl_check_report?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );

      if (response) {
        if (response.body === true) {
          setReported(true);
        }
        setLoadReport(false);
      }
      return response.body;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };



  const callAPIFunction = async (buttonType) => {
    setMainLoader(true);
    try {
      let obj = {};
      obj.method = "post";
      obj.body = {};
      obj.body.ur_id = userDetails?.uData?.ur_id;
      obj.body.schema = config.schema;
      obj.body.postid = row.postid;
      if (buttonType === "like") {
        obj.apiName = `/like`;
        obj.body.like = !row.userlikedpost;
      } else if (!row?.usersavedpost && buttonType === "save") {
        obj.apiName = `/userSavedPost`;
      } else if (row?.usersavedpost && buttonType === "save") {
        obj.apiName = `/deleteUserSavedPost`;
        obj.method = "del";
      }
      const res = await callAPI(obj, userDetails);
      hotRefeth();
      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.data = err;
      return obj;
    }
  };
  const handleButtonClick = async (buttonType) => {
    let updatedRow = { ...row };
    let res;
    switch (buttonType) {
      case "like":
        updatedRow.userlikedpost = !row.userlikedpost;
        updatedRow.likes = row.userlikedpost ? row.likes - 1 : row.likes + 1;
        setMainLoader(true);
        setRow(updatedRow);
        res = await callAPIFunction(buttonType);
        if (res.status === 400) {
          updatedRow.userlikedpost = row.userlikedpost;
          updatedRow.likes = row.userlikedpost ? row.likes + 1 : row.likes - 1;
          setRow(updatedRow);
        }
        setMainLoader(false);
        break;
      case "comment":
        if (page === undefined) {
          let udata = { ...userDetails };

          udata.postDataByid = row;
          dispatch(awsSignIn(udata));
          window.location.href = "#/social-learning/InDetails";
        }
        break;
      case "share":
        break;
      case "save":
        updatedRow.usersavedpost = !row?.usersavedpost;
        setRow(updatedRow);
        setMainLoader(true);
        res = await callAPIFunction(buttonType);
        if (res.status === 400) {
          updatedRow.usersavedpost = row?.usersavedpost;
          setRow(updatedRow);
          console.error("Save action failed, reverting state");
        } else {
          if (handleSaveSuccess) {
            handleSaveSuccess();
          }
        }
        setMainLoader(false);
        break;
      case "more":
        break;
      default:
        break;
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleReport = () => {
    const selectedOption = getReportData?.find(
      (option) => +option.reasonid === +selectedValue
    );
    if (selectedOption) {
      setReport(selectedOption.reasonid);
      setReport(selectedOption.reason);
      let data = {};
      data.postid = row?.postid;
      data.statusid = row?.statusid;
      data.reasonid = selectedOption.reasonid;
      data.schema = config.schema;
      data.userid = userDetails.uData.ur_id;
      updateReport(data);
      setErrorMessage("");
      setSelectedValue("");
      setReport("");
      setAnchorEl(false);
    } else {
      setErrorMessage("Please select a reason to report.");
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "10px",
  };

  return (
    <div className={classes.userMenuItems} style={{ margin: "2rem 0rem 0rem" }}>
      <Button
        style={{ color: "black" }}
        className={`${classes.userMenuButton} ${
          row?.userlikedpost ? classes.footerActiveButton : ""
        }`}
        onClick={() => {
          row?.ur_id !== userDetails?.uData?.ur_id && handleButtonClick("like");
        }}
      >
        <FavoriteBorderOutlinedIcon className={classes.socialIcons} />
        {row?.likes || 0}
      </Button>
      <Button
        style={{ color: "black" }}
        className={classes.userMenuButton}
        onClick={() => handleButtonClick("comment")}
      >
        <ChatBubbleOutlineOutlinedIcon className={classes.socialIcons} />
        {row?.comments_count || row?.json_comments?.length || 0} comm
      </Button>
      <Button
        style={{ color: "black" }}
        className={classes.userMenuButton}
        onClick={() => handleButtonClick("share")}
      >
        <ShareOutlinedIcon className={classes.socialIcons} />
        Share
      </Button>
      <Button
        style={{ color: "black" }}
        className={`${classes.userMenuButton} ${
          row?.usersavedpost ? classes.footerActiveButton : ""
        }`}
        onClick={() => {
          handleButtonClick("save");
        }}
      >
        <SaveSVG className={classes.saveIconsSVG} />
        {row?.usersavedpost ? "Saved" : "Save"}
      </Button>
      <Button onClick={handleClick}>
        <MoreHorizOutlinedIcon className={classes.socialIcons} />
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={1}>
          <Button
            className={classes.userMenuButton}
            onClick={() => handleModalOpen(row?.postid)}
            disabled={row?.ur_id === userDetails?.uData?.ur_id}
            style={{
              color: row?.ur_id === userDetails?.uData?.ur_id ? "grey" : "blue",
            }}
          >
            <FlagOutlinedIcon fontSize="large" />
            Report to
          </Button>
        </Box>
      </Popover>
      <Modal
        open={isModalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            component="p"
            sx={{ fontSize: "1.5rem", fontWeight: 600 }}
          >
            Report
          </Typography>
          <Box id="modal-modal-description" sx={{ mt: 2 }}>
            {loadReport ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <CircularProgress />
              </Box>
            ) : reported ? (
              <Typography sx={{ fontSize: "1.6rem", color: "red" }}>
                Post already reported
              </Typography>
            ) : (
              <FormControl>
                <FormLabel id="demo-radio-buttons-group-label"></FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="radio-buttons-group"
                  value={selectedValue}
                  onChange={handleRadioChange}
                >
                  {loading ? (
                    <p>Loading...</p>
                  ) : (
                    getReportData?.map((option) => (
                      <FormControlLabel
                        value={option.reasonid}
                        control={<Radio />}
                        label={
                          <Typography sx={{ fontSize: "1.2rem" }}>
                            {option.reason}
                          </Typography>
                        }
                      />
                    ))
                  )}
                </RadioGroup>
                {errorMessage && (
                  <span style={{ color: "red" }}>{errorMessage}</span>
                )}
                <Stack direction="row" spacing={2}>
                  <Button variant="outlined" onClick={handleModalClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" onClick={handleReport}>
                    Report
                  </Button>
                </Stack>
              </FormControl>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default PostFooterView;
