import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Box,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import config from "../../aws-exports";
import { Favorite } from "@material-ui/icons";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { ReactComponent as SocialLearningIcon } from "../../assets/svg/SL.svg";

export default function SideNavBar({ handleDrawerOpen, handleDrawerClose }) {
  const location = useLocation();
  let userDetails = useSelector(authData);

  const useStyles = makeStyles((theme) => ({
    MenuTotalList: {
      width: "inherit",
      "& > a ": {
        width: "100%",
      },
    },
    MenuIconList: {
      textDecoration: "none!important",
      "& > li ": {
        padding: "0 0 0 6px!important",
        margin: "1rem 0rem",
        width: "130%",
        "& > button": {
          width: "100%",
          textAlign: "left",
          padding: "15px",
        },
        "& > button > div > svg ": {
          color: userDetails.themeDark === "dark" ? "lightgray" : "gray",
          fill: "gray",
        },
      },
    },
    MenuIconListText: {
      "& > span ": {
        fontSize: "1.4rem!important",
        color: userDetails.themeDark === "dark" ? "white" : "gray",
        fontFamily: `myfontregular!important`,
        textDecoration: "none!important",
        fontWeight: "bold",
      },
    },
    menuLogo: {
      width: "18rem",
      padding: "0.3rem 0rem 0rem",
      margin: "0 auto",
    },
  }));
  const classes = useStyles();

  return (
    <Box sx={{ display: "flex" }}>
      <List
        className={classes.MenuTotalList}
        onMouseOver={handleDrawerOpen}
        onMouseOut={handleDrawerClose}
      >
        <Divider style={{ marginTop: "4.3rem" }} />
        <Link to={`/home`} className={classes.MenuIconList}>
          <ListItem
            disablePadding
            className={
              location.pathname.split("/").indexOf("home") > -1
                ? "side-nav__item side-nav__item--active align"
                : "side-nav__item align"
            }
          >
            <Button>
              <ListItemIcon>
                <DashboardIcon style={{ fontSize: "2.5rem" }} />
              </ListItemIcon>
              <ListItemText
                primary={`${userDetails?.languagejson?.header?.Dashboard ||
                  "Dashboard"}`}
                className={classes.MenuIconListText}
              />
            </Button>
          </ListItem>
        </Link>

        <Link to={`/exploreTopics`} className={classes.MenuIconList}>
          <ListItem
            disablePadding
            className={
              location.pathname.split("/").indexOf("exploreTopics") > -1
                ? "side-nav__item side-nav__item--active align"
                : "side-nav__item align"
            }
          >
            <Button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="30px"
                  viewBox="0 0 36 36"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="0.5"
                      x2="0.657"
                      y2="1"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#31a0df" />
                      <stop offset="1" stop-color="#037cc2" />
                    </linearGradient>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_1824"
                        data-name="Rectangle 1824"
                        width="36"
                        height="36"
                      />
                    </clipPath>
                  </defs>
                  <g id="explore" clip-path="url(#clip-path)">
                    <g
                      id="explore-2"
                      data-name="explore"
                      clip-path="url(#clip-path)"
                    >
                      <g id="Multimedia" transform="translate(2 171)">
                        <path
                          id="Path_123"
                          data-name="Path 123"
                          d="M7.077,8.157H28.933a4.952,4.952,0,0,1,1.175.149A3.286,3.286,0,0,0,26.983,6H8.918A3.286,3.286,0,0,0,5.785,8.33a4.97,4.97,0,0,1,1.291-.173ZM4.9,11.8H31a4.319,4.319,0,0,1,1.254.186,3.458,3.458,0,0,0-3.322-2.379H7.077a3.516,3.516,0,0,0-3.1,1.849,3.467,3.467,0,0,0-.215.5A4.319,4.319,0,0,1,4.9,11.8ZM31,13.25H4.9A2.9,2.9,0,0,0,2,16.15V29.926a2.9,2.9,0,0,0,2.9,2.9H31a2.9,2.9,0,0,0,2.9-2.9V16.15a2.9,2.9,0,0,0-2.9-2.9ZM22.964,24.922l-6.525,3.767A2.175,2.175,0,0,1,13.176,26.8V19.271a2.175,2.175,0,0,1,3.263-1.884l6.525,3.767a2.175,2.175,0,0,1,0,3.767Z"
                          transform="translate(-2 -172)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={`${userDetails?.languagejson?.header?.Explore ||
                  "Explore"}`}
                className={classes.MenuIconListText}
              />
            </Button>
          </ListItem>
        </Link>

        <Link to={`/calender`} className={classes.MenuIconList}>
          <ListItem
            disablePadding
            className={
              location.pathname.split("/").indexOf("calender") > -1
                ? "side-nav__item side-nav__item--active align"
                : "side-nav__item align"
            }
          >
            <Button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="30px"
                  viewBox="0 0 36 36"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="0.5"
                      y1="1"
                      x2="0.5"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#31a0df" />
                      <stop offset="1" stop-color="#037cc2" />
                    </linearGradient>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_1823"
                        data-name="Rectangle 1823"
                        width="36"
                        height="36"
                      />
                    </clipPath>
                  </defs>
                  <g id="calendar" clip-path="url(#clip-path)">
                    <g
                      id="calendar-2"
                      data-name="calendar"
                      clip-path="url(#clip-path)"
                    >
                      <g
                        id="Group_1616"
                        data-name="Group 1616"
                        transform="translate(-3598 -4843)"
                      >
                        <path
                          id="Path_1221"
                          data-name="Path 1221"
                          d="M3602.84,4849.08h2.937v2.848a1.145,1.145,0,0,0,2.289,0v-2.85h3.513v2.848a1.145,1.145,0,0,0,2.289,0v-2.848h3.513v2.848a1.145,1.145,0,0,0,2.289,0v-2.849h3.513v2.848a1.145,1.145,0,1,0,2.289,0v-2.849h2.937a2.856,2.856,0,0,1,2.848,2.848v3.343H3600v-3.343a2.855,2.855,0,0,1,2.848-2.848Zm4.571,2.848a.49.49,0,1,1-.98,0v-4.434a.49.49,0,1,1,.98,0Zm5.8,0a.49.49,0,1,1-.98,0v-4.434a.49.49,0,1,1,.98,0Zm5.8,0a.49.49,0,1,1-.98,0v-4.434a.49.49,0,1,1,.98,0Zm5.8,0a.49.49,0,1,1-.98,0v-4.434a.49.49,0,1,1,.98,0Zm6.438,4.322v15.487a2.856,2.856,0,0,1-2.848,2.849h-25.565a2.854,2.854,0,0,1-2.848-2.849V4856.25h31.261Zm-16.669,1.979h2.076a.491.491,0,0,1,.49.491v2.076a.49.49,0,0,1-.49.49h-2.076a.49.49,0,0,1-.49-.49v-2.076a.489.489,0,0,1,.5-.489Zm0,5.1h2.076a.49.49,0,0,1,.49.49v2.076a.488.488,0,0,1-.49.488h-2.076a.489.489,0,0,1-.49-.488v-2.077a.49.49,0,0,1,.5-.5Zm-5.33,0h2.076a.49.49,0,0,1,.49.49v2.076a.488.488,0,0,1-.49.488h-2.076a.488.488,0,0,1-.49-.488v-2.077a.49.49,0,0,1,.5-.5Zm-5.33,0H3606a.49.49,0,0,1,.49.49v2.076a.488.488,0,0,1-.49.488h-2.076a.489.489,0,0,1-.49-.488v-2.077a.49.49,0,0,1,.5-.5Zm12.736,8.15h-2.076a.49.49,0,0,1-.49-.49V4868.9a.49.49,0,0,1,.49-.49h2.076a.49.49,0,0,1,.49.49v2.076a.489.489,0,0,1-.476.494Zm8.584-13.245h2.076a.491.491,0,0,1,.49.492v2.076a.49.49,0,0,1-.49.491h-2.076a.49.49,0,0,1-.49-.49v-2.077a.489.489,0,0,1,.5-.489Zm0,5.1h2.076a.49.49,0,0,1,.49.49v2.076a.488.488,0,0,1-.49.488h-2.076a.488.488,0,0,1-.49-.488v-2.077a.49.49,0,0,1,.5-.5Zm-19.245,8.15h-2.076a.49.49,0,0,1-.49-.49V4868.9a.49.49,0,0,1,.49-.49H3606a.49.49,0,0,1,.49.49v2.076a.489.489,0,0,1-.475.494Zm13.915-13.245H3622a.491.491,0,0,1,.49.492v2.076a.49.49,0,0,1-.49.49h-2.076a.49.49,0,0,1-.49-.49v-2.076a.489.489,0,0,1,.486-.489Zm0,5.1H3622a.49.49,0,0,1,.49.49v2.076a.487.487,0,0,1-.49.489h-2.076a.488.488,0,0,1-.49-.488v-2.077a.489.489,0,0,1,.486-.5Zm-8.584,8.15h-2.076a.49.49,0,0,1-.49-.49v-2.1a.49.49,0,0,1,.49-.49h2.076a.49.49,0,0,1,.49.49v2.076a.49.49,0,0,1-.476.494Z"
                          fill-rule="evenodd"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={`${userDetails?.languagejson?.header?.Calendar ||
                  "Calendar"}`}
                className={classes.MenuIconListText}
              />
            </Button>
          </ListItem>
        </Link>

        {config.aws_org_id !== "NSECORP" && (
          <Link to={`/leaderboard`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("leaderboard") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25px"
                    height="30px"
                    viewBox="0 0 36 36"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.59"
                        y1="1"
                        x2="0.5"
                        y2="0.128"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#31a0df" />
                        <stop offset="1" stop-color="#037cc2" />
                      </linearGradient>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_1825"
                          data-name="Rectangle 1825"
                          width="36"
                          height="36"
                        />
                      </clipPath>
                    </defs>
                    <g id="leaderboard" clip-path="url(#clip-path)">
                      <g
                        id="leaderboard-2"
                        data-name="leaderboard"
                        clip-path="url(#clip-path)"
                      >
                        <g
                          id="Group_1618"
                          data-name="Group 1618"
                          transform="translate(-0.708 1.073)"
                        >
                          <path
                            id="Path_1222"
                            data-name="Path 1222"
                            d="M32.89,30.364v1.57H5.327v-1.57a.885.885,0,0,1,.885-.884H8.7V20.815h6.119V29.48h1.072V12.88h6.275v16.6H23.24V24.835h6.129V29.48h2.637a.885.885,0,0,1,.884.884ZM16.7,7.86l-.4,2.084a.544.544,0,0,0,.2.531.535.535,0,0,0,.563.053l1.9-.954,1.86,1.029a.541.541,0,0,0,.787-.552l-.316-2.1L22.843,6.5a.537.537,0,0,0,.15-.547.558.558,0,0,0-.429-.375L20.469,5.23l-.9-1.918A.545.545,0,0,0,19.092,3a.535.535,0,0,0-.488.289L17.63,5.175l-2.111.263a.55.55,0,0,0-.439.359.539.539,0,0,0,.128.552Z"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </ListItemIcon>
                <ListItemText
                  className={classes.MenuIconListText}
                  primary={`${userDetails?.languagejson?.header?.Leaderboard ||
                    "Leaderboard"}`}
                />
              </Button>
            </ListItem>
          </Link>
        )}

        {config.aws_org_id !== "NSECORP" && (
          <Link to={`/myCoins`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("myCoins") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <LocalGroceryStoreIcon style={{ fontSize: "2.5rem" }} />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.My_Coins ||
                    "My Coins"}`}
                  className={classes.MenuIconListText}
                />
              </Button>
            </ListItem>
          </Link>
        )}

        {config.header.bookmark && (
          <Link to={`/bookmarks`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("bookmarks") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <BookmarkIcon style={{ fontSize: "2.5rem" }} />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Bookmarks ||
                    "Bookmarks"}`}
                  className={classes.MenuIconListText}
                />
              </Button>
            </ListItem>
          </Link>
        )}

        {config?.socialLearning && (
          <Link to={`/social-learning`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              onMouseOver={handleDrawerOpen}
              onMouseOut={handleDrawerClose}
              className={
                location.pathname.split("/").indexOf("social-learning") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <SocialLearningIcon
                    style={{ height: "3rem", width: "2.5rem" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header
                    ?.Social_Learning || "Social Learning"}`}
                  className={classes.MenuIconListText}
                />
              </Button>
            </ListItem>
          </Link>
        )}
        {config.header.wishlist && (
          <Link to={`/wishlist`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("wishlist") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <Favorite style={{ fontSize: "2.5rem" }} />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Wishlist ||
                    "Wishlist"}`}
                  className={classes.MenuIconListText}
                />
              </Button>
            </ListItem>
          </Link>
        )}

        <Link to={`/contactus`} className={classes.MenuIconList}>
          <ListItem
            disablePadding
            className={
              location.pathname.split("/").indexOf("contactus") > -1
                ? "side-nav__item side-nav__item--active align"
                : "side-nav__item align"
            }
          >
            <Button>
              <ListItemIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25px"
                  height="30px"
                  viewBox="0 0 36 36"
                >
                  <defs>
                    <linearGradient
                      id="linear-gradient"
                      x1="0.29"
                      y1="1.561"
                      x2="0.843"
                      y2="0.127"
                      gradientUnits="objectBoundingBox"
                    >
                      <stop offset="0" stop-color="#31a0df" />
                      <stop offset="1" stop-color="#037cc2" />
                    </linearGradient>
                    <clipPath id="clip-path">
                      <rect
                        id="Rectangle_1820"
                        data-name="Rectangle 1820"
                        width="36"
                        height="36"
                      />
                    </clipPath>
                  </defs>
                  <g id="Contact_us" clip-path="url(#clip-path)">
                    <g
                      id="Contact_us-2"
                      data-name="Contact_us"
                      clip-path="url(#clip-path)"
                    >
                      <g
                        id="Group_1845"
                        data-name="Group 1845"
                        transform="translate(-3.502 -4.203)"
                      >
                        <path
                          id="Path_1229"
                          data-name="Path 1229"
                          d="M46.408,13.541A9.4,9.4,0,0,0,27.79,15.415v9.4h9.4a9.492,9.492,0,0,0,9.219-11.273Zm-2.614,1.892a1.557,1.557,0,1,1-1.556-1.5,1.557,1.557,0,0,1,1.556,1.5Zm-13.2,0a1.556,1.556,0,1,1,1.555,1.558,1.556,1.556,0,0,1-1.555-1.558Zm5.042-.019a1.556,1.556,0,1,1,1.555,1.558,1.556,1.556,0,0,1-1.555-1.558Z"
                          transform="translate(-8.228)"
                        />
                        <path
                          id="Path_1230"
                          data-name="Path 1230"
                          d="M24.984,42.867a3.73,3.73,0,0,0,3.455-.977,18.76,18.76,0,0,0,2-2.322,1.887,1.887,0,0,0-.635-2.751l-3.623-1.911a3.742,3.742,0,0,0-3.766.162l-2.683,1.724a21.4,21.4,0,0,1-7.569-7.569l1.724-2.683a3.742,3.742,0,0,0,.162-3.766l-1.911-3.623a1.882,1.882,0,0,0-2.751-.635,18.759,18.759,0,0,0-2.322,2,3.73,3.73,0,0,0-.977,3.455,25.2,25.2,0,0,0,18.9,18.9Z"
                          transform="translate(0 -4.583)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </ListItemIcon>
              <ListItemText
                primary={`${userDetails?.languagejson?.header?.Contact_Us ||
                  "Contact Us"}`}
                className={classes.MenuIconListText}
              />
            </Button>
          </ListItem>
        </Link>
      </List>
    </Box>
  );
}
