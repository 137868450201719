import React, { useState } from "react";
import "./Poll.css"; // Import the styles
import AddIcon from "@mui/icons-material/Add";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authData } from "../../../../redux/auth/authSlice";

const App = ({ options, setOptions, setPollDuration, pollDuration }) => {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    containerpoll: {
      padding: "2rem",
      border: "1px solid lightgray",
      borderRadius: "4px",
      background: userDetails.themeDark === "dark" ? "#1c2027" : "#e4e4e4",
    },
    inputField: {
      width: "100%",
      boxSizing: "border-box",
      margin: "1rem 0rem",
      "& > input": {
        background: userDetails.themeDark === "dark" ? "#353944" : "white",
        color: userDetails.themeDark === "dark" ? "white" : "black",
        width: "100%",
        padding: "1.5rem 0.8rem",
        borderRadius: "4px",
        border: "1px solid gray",
      },
    }
  }));
  const classes = useStyles();

  const handleAddOption = () => {
    if (options.length < 4) {
      setOptions([...options, ""]);
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value.substr(0, 30); // Limit text to 30 characters
    setOptions(newOptions);
  };

  const handlePollDurationChange = (event) => {
    setPollDuration(event.target.value);
  };

  return (
    <div className={classes.containerpoll}>
      {options.map((option, index) => (
        <div key={index} className={classes.inputField}>
          <input
            type="text"
            value={option}
            placeholder="Enter Option (max 30 characters)"
            onChange={(e) => handleOptionChange(index, e.target.value)}
          />
        </div>
      ))}
      <button onClick={handleAddOption} className="addButton">
        <AddIcon style={{ fontSize: "2.5rem" }} />
        Add Option
      </button>
      <div style={{ width: "100%", textAlign: "left", paddingTop: "1rem", borderTop: "2px solid lightgray", }}>
        <label htmlFor="pollDuration" style={{ fontSize: "1.5rem" }}>Poll Duration:</label>
        <select
          id="pollDuration"
          value={pollDuration}
          onChange={handlePollDurationChange}
          className="selectField"
        >
          <option value="">Select duration</option>
          <option value="1day">1 Day</option>
          <option value="1week">1 Week</option>
          <option value="1month">1 Month</option>
        </select>
      </div>
    </div>
  );
};

export default App;
