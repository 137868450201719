import { Box } from "@mui/material";
import React, { useState } from "react";
import { API } from "aws-amplify";
import { awsSignIn } from "../../redux/auth/authSlice";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { CircularProgress } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "../../Hooks/useForm";
import { useDispatch } from "react-redux";
import config from "../../aws-exports";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import CustomInput from "../../components/CustomInput/CustomInput";
import { HeadingPrimary } from "../../components/Styles/Heading";
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../components/SignInAndSignUp/SignInAndSignUp";
import validate from "./SignInSignUpValidationRules/SignInValidationRules";
import CustomLoginLayout from "./CustomLoginLayout";
const Login = () => {
  const history = useHistory();
  const location = useLocation();
  const query = useQueryParams();
  const dispatch = useDispatch();
  const { values, handleChange } = useForm({
    showPassword: false,
    username: location?.state?.username || "",
    password: "qwerty@1",
  });
  const [errors, setErrors] = useState({});
  const [btnLoading, setBtnLoading] = useState(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validate(values)).length !== 0) {
      setErrors(validate(values));
      return;
    } else {
      setBtnLoading(true);
      try {
        try {
          let userData = {};
          let authkey = localStorage.getItem("accessToken"); 
          const tokenrequestBody = {
            client_key: values.username,
            client_secret: config.secretKeyAfter, 
            schema: config.schema
          };
          const tokenjsonString = JSON.stringify(tokenrequestBody);
          const tokenbase64EncodedString = btoa(tokenjsonString);
          const tokenreportInfo = `/getUserToken?json=${encodeURIComponent(
            tokenbase64EncodedString
          )}`;
          const tokenresponse = await API.get(
            config.aws_cloud_logic_custom_name,
            tokenreportInfo,
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: authkey,
              },
            }
          );
          userData.accessToken = tokenresponse.accessToken;
          userData.refreshToken = tokenresponse.refreshToken;
          userData.beforeAuth = authkey;
          localStorage.setItem(
            "after_logIn_accessToken",
            tokenresponse?.accessToken
          );
          window.after_logIn_accessToken = tokenresponse?.accessToken
          const getusersInfo = await getUsers({
            username: values.username,
            code: "login",
          });
          //token gen
          userData.username = getusersInfo.uid;
          if (!userData.locale) {
            userData.locale = getusersInfo.oid;
          }
          userData.tenant = userData.locale;
          if (userData) {
            userData.uData = getusersInfo;
            analyticsWebApp(userData);
          }
        } catch (error) {
          setBtnLoading(false);
        }
      } catch (error) {
        console.error(error);
        const code = error.code;
        switch (code) {
          case "UserNotFoundException":
            return await getUsers({ username: values.username });
          case "NotAuthorizedException":
            return await getUsers({
              username: values.username,
              code: "NotAuthorizedException",
            });
          case "NetworkError":
            return await getUsers({
              username: values.username,
              code: "NotAuthorizedException",
            });
          case "PasswordResetRequiredException":
            setBtnLoading(false);
            return history.push({
              pathname: "/forgot-password",
              state: { username: values.username },
            });
          case "UserNotConfirmedException":
            return await getUsers({
              username: values.username,
              code: "UserNotConfirmedException",
            });
          default:
            return false;
        }
      }
    }
  };
  const getUsers = async (userdata) => {
    try {
      const requestBody = {
        username: userdata.username,
        schema: config.schema,
      };
      let authT = localStorage.getItem("after_logIn_accessToken");
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/users?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: authT,
          },
        }
      );
      if (response && response.body) {
        localStorage.setItem(
          "userdata",
          JSON.stringify({ ...response.body, username: values.username })
        );
        setBtnLoading(false);
        if (userdata.code === "login") {
          setBtnLoading(true);
          return response.body;
        }
        return response.body;
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          username:
            "You are not registered with us. Please contact your FEIA faculty member.",
        }));
        setBtnLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };
  async function analyticsWebApp(userDetails) {
    const jsonString = JSON.stringify({
      oid: config.aws_org_id,
      eventtype: "AuthenticatedViaCognito",
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      email: userDetails.username,
      name: userDetails.name,
      emailid: userDetails.email,
      tenant: userDetails.locale,
      ur_id: userDetails.uData?.ur_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      setBtnLoading(false);
      userDetails.themeDark = "light";
      dispatch(awsSignIn(userDetails));
      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.push("/home");
      }
    } catch (err) {
      history.push("/home");
    }
  }
  return (
    <React.Fragment>
      <CustomLoginLayout>
        <React.Fragment>
          <Box sx={{ textAlign: "center" }}>
            <HeadingPrimary>Login to your account</HeadingPrimary>
          </Box>
          <form
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            style={displayCenter}
          >
            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-username"
                placeholder="UUCMS ID -
                (U03AA21C0006)"
                type="text"
                value={values.username || ""}
                error={errors.username}
                onChange={handleChange("username")}
              >
                <PersonOutlineOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <Box component="div" sx={{ textAlign: "center", mb: 3 }}>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton type="submit" primary>
                  Login
                </CustomButton>
              )}
            </Box>
          </form>
        </React.Fragment>
      </CustomLoginLayout>
    </React.Fragment>
  );
};
export default Login;
