import styled, { css } from "styled-components";
import { Theme } from "../../aws-exports";

export const CustomButton = styled("button")`
  background: transparent;
  border-radius: 5px;
  /* border: 2px solid ${Theme.color.primary}; */
  border: 2px solid #0c354d;
  color: ${Theme.color.primary};
  margin: 0 1em;
  padding: 0.35em 1em;
  font-size: 1.5rem;
  width: max-content;
  text-transform: capitalize;
  cursor: pointer;
  font-weight: 500;
  ${({ primary, secondary, join }) =>
    (primary || secondary || join) &&
    css`
      &:focus {
        transform: scale(0.85);
      }
    `}

  ${(props) =>
    props.primary &&
    css`
      background: ${Theme.color.primary};
      color: #ffff;
      border: 2px solid "#ffff";
      font-size: 1.5rem;
    `}
  ${(props) =>
    props.secondary &&
    css`
      background: #ffff;
      color: ${Theme.color.primary};
      border: 1px solid #ffff;
    `}
  ${(props) =>
    props.join &&
    css`
      background: #6a5acd;
      border: 2px solid #6a5acd;
      color: #ffff;
      padding: 0.4em 1em;
    `}
  ${(props) =>
    props.loading &&
    css`
      &:disabled {
        background: transparent;
        border-radius: 3px;
        border: 2px solid ${Theme.color.primary};
        color: ${Theme.color.primary};
        margin: 0 1em;
        padding: 0.35em 2em;
      }
    `}
`;
