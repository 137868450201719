import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import {
  Stack, Typography, Breadcrumbs, Box,
} from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/system';
import CardLarge from "../Cards/CardLarge/CardLarge";

function DifferentCourses() {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    LPMainContainer: {
      width: "100%",
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "0 0 6px 0 #6666665c",
      backgroundColor: userDetails.themeDark === "dark" ? "#272C38 !important" : theme.palette.background.paper,
      borderRadius: "5px", padding: "2rem",
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color: userDetails.themeDark === "dark" ? "white !important" : "black !important",
      fontFamily: `myfontregular!important`,
    },
    mainSection: {
      display: "flex",
      gap: "2rem",
      flexWrap: "wrap",
    },
  }));

  const classes = useStyles();

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}>
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={"/my-peers"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}>
      {`${userDetails?.languagejson?.Active_User?.MyPeers || "My Peers"}`}
    </Link>,
    <Link underline="hover" key="2" color="inherit" to={"/compare"}
    style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}>
    {`${userDetails?.languagejson?.Active_User?.Compare || "Compare"}`}
  </Link>,
    <Typography key="3" color="text.primary" className={classes.breadcrumbNameStyle}>
      {`${userDetails?.languagejson?.Active_User?.Compare || "Different Courses"}`}
    </Typography>,
  ];

  const BlackCircularProgress = styled(CircularProgress)({
    color: '#707070', // for background
  });

  const BlueCircularProgress = styled(CircularProgress)(({ value }) => ({
    color: value === 100 ? '#48bc48' : '#f6c55d', // for the progress
    position: 'absolute',
    '& .MuiCircularProgress-circle': {
      strokeLinecap: 'round', // rounded caps
    },
  }));

  function CircularProgressWithLabel(props) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex', width: 100, height: 100 }}>
        <BlackCircularProgress variant="determinate" value={100} sx={{ width: '100% !important', height: '100% !important' }} />
        <BlueCircularProgress variant="determinate" {...props} sx={{ width: '100% !important', height: '100% !important' }} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div" color="text.secondary"
            sx={{ fontSize: 20, color: userDetails.themeDark === "dark" ? "#fff" : "#000", }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }

  CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
  };

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="large" style={{ color: "var(--color-primary)" }} />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div className="flex-between">
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {`${userDetails?.languagejson?.Active_User?.DifferentCourses || "Different Courses"}`}
          </h3>
        </div>
      </div>

      <div className={classes.root}>
        <div className={classes.LPMainContainer}>
          <div className={classes.mainSection}>
          {userDetails?.alldifferentData.map((diffr,idx) =>  
                 
          <CardLarge
          title={diffr?.tn}
          tid={diffr.tid}
          count={diffr.noofnuggets}
 
          star={diffr.ravg}
          comp_date={diffr.comp_date}
          sub_date={diffr.sub_date}
          bookmark={diffr.bk}
          heading={"Different Courses"}
          cobj={diffr.cobj}
          tobj={diffr.tobj}
     
          index={idx}
          elseWhere={true}
        />)}
          </div>
        </div>
      </div>
    </>
  );
}
export default DifferentCourses;
