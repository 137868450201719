export const breakpoints = {
  0: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 0,
  },
  300: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 5,
  },
  640: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 5,
  },
  770: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 5,
  },
  1030: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 5,
  },
  1740: {
    slidesPerView: 5,
    slidesPerGroup: 5,
    spaceBetween: 5,
  },
};

export const breakpoints2 = {
  300: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  770: {
    slidesPerView: 3,
    slidesPerGroup: 3,
    spaceBetween: 20,
  },
  1040: {
    slidesPerView: 4,
    slidesPerGroup: 4,
    spaceBetween: 20,
  },
};

export const breakpoints3 = {
  300: {
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 10,
  },
  640: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  770: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
  1040: {
    slidesPerView: 2,
    slidesPerGroup: 2,
    spaceBetween: 20,
  },
};
