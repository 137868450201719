// src/components/StatusViewer.js
import React, { useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import Stories from 'react-insta-stories';
import config from "../../aws-exports";
import { LinkedinShareButton, WhatsappShareButton, WhatsappIcon, LinkedinIcon } from "react-share";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import {

    IconButton,

    styled,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "@mui/icons-material/Download";

const StatusViewer = ({ media, handleCloseposters }) => {
    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: "rgba(0, 0, 0, 0.87)",
            boxShadow: theme.shadows[1],
            fontSize: 11,
        },
    }));
    const useStyles = makeStyles((theme) => ({
        mainDiv: {
            marginBottom: "2.5rem",
            gap: "2rem",
            display: "grid",
            gridTemplateColumns: "30% auto 23% 20%",
            "@media (max-width: 1460px)": {
                gridTemplateColumns: "auto auto",
            },
            "@media (max-width: 600px)": {
                gridTemplateColumns: "auto",
            },
        },

        shareButton: {
            padding: "0.9rem 1rem!important",
            display: "flex",
            color: "#0274B3!important",
            alignItems: "center",
            gap: "1rem",

            float: "right",
        },
    }));
    const classes = useStyles();
    const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
    const [stories, setStories] = useState([]);
    const [load, setLoad] = useState(false);
    useEffect(() => {
        if (media) {

            const storyObjects = media.map(item => ({
                url: `https://${config.DOMAIN}/${config.aws_org_id.toLowerCase()}-resources/images/bnr-images/${item.filename}`,
                type: 'image'
            }));
            console.log("Formatted stories:", storyObjects);
            setStories(storyObjects);
            setLoad(true);
        }
    }, [media]);
    const handleStoryChange = (index) => {
        console.log('Story index changed:', index); // Debugging line
        setCurrentStoryIndex(index);
    };
    
    return (<> <div style={{ display:"flex",justifyContent:"end" }}>
        <button
            onClick={handleCloseposters}
            style={{
                border: "none",
                background: "transparent",
                cursor: "pointer",
                color: "white"
            }}
        >
            <CloseIcon fontSize="large" />
        </button>
    </div> 
    {load && <div style={{display:"flex",justifyContent:"center"}}><Stories stories={stories}
                width={"100%"}
                 height={"80vh"}
                 
       keyboardNavigation={true}
        onStoryStart={handleStoryChange}
      
         defaultInterval={25000} 
       
         /></div>}
        {load && <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px', position: "absolute", bottom: -40, width: "100%" }}>
            <LinkedinShareButton
                url={stories[currentStoryIndex]?.url}
                className={classes.shareButton}
                windowWidth={800}
            >
                <LightTooltip title="Share on linkedin">
                    <IconButton >
                        <LinkedinIcon fontSize="large" />
                    </IconButton>
                </LightTooltip>{" "}
            </LinkedinShareButton>
            <WhatsappShareButton
                title="Hey there is a Poster!"
                url={stories[currentStoryIndex]?.url}
                className={classes.shareButton}
            >
                <LightTooltip title="Share on WhatsApp">
                    <IconButton>
                        <WhatsappIcon fontSize="large" />
                    </IconButton>
                </LightTooltip>
            </WhatsappShareButton>
            <a
                download={`Posters.png`}
                href={stories[currentStoryIndex]?.url}
                style={{
                    border: "0",
                    margin: "0",

                    float: "right",
                    textDecoration: "none",
                    margin: "auto 1rem",



                    border: "1px solid",
                    borderRadius: "8px",
                    backgroundColor: "black",
                    color: "white",
                    padding: "5px",
                    height: "6rem",
                    alignItems: "center",
                    display: "flex"
                }}
            >
                <DownloadIcon fontSize="large" />
                Download Now

            </a>
        </div>}
    </>);
}

export default StatusViewer;
