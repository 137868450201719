import React from "react";

import ListItem from "@material-ui/core/ListItem";

import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import "./ExploreFilter.scss";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import filtericon from "../../../assets/filter.png";
import { Checkbox, FormGroup, Typography } from "@mui/material";

import { sticky } from "tippy.js";

import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";
import "tippy.js/animations/shift-away-subtle.css";
import { motion } from "framer-motion";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  makeStyles,
} from "@material-ui/core";
import Tippy from "@tippyjs/react";

const defaultTippyProps = {
  trigger: "mouseenter click",
  arrow: true,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom",

  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  plugins: [sticky],
  theme: "light",
  distance: 24,
  inertia: true,
};
const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};

export default function ExploreFilter({
  clear,
  type,
  objectType,
  selectedOptions,
  setSelectedOptions,
  progressType,
  setProgressType,
  getCategoryData,
  setCategoryType,
  categoryType,
  duration,
  setDuration,
}) {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(3),
      height: "600px",
      width: "1100px",
      backgroundColor: "white",
    },
    heading: {
      fontSize: theme.typography.pxToRem(25),
      fontWeight: theme.typography.fontWeightRegular,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      fontSize: "12px",
    },
    filterBody: {
      width: "200px",
      border: "none",
      "@media (max-width: 500px)": {
        width: "100%",
      },
    },
    onFilterHover: {
      cursor: "pointer",
      margin: "1rem 0.5rem",
      backgroundColor: "transparent",
      border: "1px solid transparent",
      display: "flex",
      "&:focus": {
        border: "1px solid black",
      },
    },
    mainTippy: {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#3F434D !important"
          : theme.palette.background.paper,
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "auto",
    },
    radioStyle: {
      '& .MuiSvgIcon-root': {
        borderRadius: '2px',
        border: '2px solid gray',
        backgroundColor: 'transparent',
        width: '12px',
        height: '12px',
        color: 'transparent',
      },
      '&.Mui-checked': {
        '& .MuiSvgIcon-root': {
          backgroundColor: 'var(--color-primary)',
          color: 'transparent',
          border: "2px solid var(--color-primary)",
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          top: '33%',
          left: '53%',
          rotate: "315deg",
          transform: 'translate(-50%, -50%)',
          width: '9px',
          height: '4px',
          borderBottom: '2px solid white',
          borderLeft: '2px solid white',
        },
      },
    },
  }));
  const classes = useStyles();

  const handleCheckboxChange = (option) => {
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const handleCheckboxChangeCourse = (option) => {
    setCategoryType((prevSelectedOptions) => {
      if (prevSelectedOptions.includes("all")) {
        // If "all" is present, remove it and add the new item
        return [option];
      } else {
        // If "all" is not present, update the array as before
        if (prevSelectedOptions.includes(option)) {
          return prevSelectedOptions.filter((item) => item !== option);
        } else {
          return [...prevSelectedOptions, option];
        }
      }
    });
  };

  const handleCheckboxProgType = (option) => {
    setProgressType((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(option)) {
        return prevSelectedOptions.filter((item) => item !== option);
      } else {
        return [...prevSelectedOptions, option];
      }
    });
  };

  const handleDur = (e) => {
    setDuration(e.target.value);
  };

  return (
    <motion.div
      variants={containerVarients}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <Tippy
        className={classes.mainTippy}
        interactive={true}
        content={
          <div style={{ maxHeight: "290px", overflowY: "auto" }}>
            <ListItem>
              <div className={classes.filterBody}>
                {type !== "course" ? (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{ fontSize: "12px" }}
                      >
                        Content Type
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormControl component="fieldset">
                          <FormGroup>
                            {objectType.map((option, index) => {
                              const isOptionChecked = selectedOptions.includes(
                                option.type
                              );

                              return (
                                <FormControlLabel
                                  classes={{ label: classes.label }}
                                  key={index}
                                  control={
                                    <Checkbox
                                      checked={isOptionChecked}
                                      onChange={() =>
                                        handleCheckboxChange(option.type)
                                      }
                                      disabled={option?.disabled === false}
                                    />
                                  }
                                  label={option.Dname}
                                />
                              );
                            })}
                            {type === undefined ? (
                              <>
                                <FormControlLabel
                                  classes={{ label: classes.label }}
                                  value="coursetype"
                                  control={
                                    <Checkbox
                                      checked={
                                        Array.isArray(categoryType)
                                          ? categoryType.includes("courses")
                                          : false
                                      }
                                      onChange={(e) => {
                                        handleCheckboxChangeCourse("courses");
                                      }}
                                    />
                                  }
                                  label="Courses"
                                />
                                <FormControlLabel
                                  classes={{ label: classes.label }}
                                  value="learningPath"
                                  control={
                                    <Checkbox
                                      checked={
                                        Array.isArray(categoryType)
                                          ? categoryType.includes(
                                            "learningPath"
                                          )
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleCheckboxChangeCourse(
                                          "learningPath"
                                        )
                                      }
                                    />
                                  }
                                  label="Learning Path"
                                />
                              </>
                            ) : null}
                          </FormGroup>
                        </FormControl>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                {type !== "content" ? (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography
                        className={classes.heading}
                        style={{ fontSize: "12px" }}
                      >
                        Completion Status
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        <FormGroup>
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            control={
                              <Checkbox
                                checked={progressType.includes("CountL")}
                                onChange={() =>
                                  handleCheckboxProgType("CountL")
                                }
                              />
                            }
                            label="Continue Learning"
                          />

                          <FormControlLabel
                            classes={{ label: classes.label }}
                            control={
                              <Checkbox
                                checked={progressType.includes("CompL")}
                                onChange={() => handleCheckboxProgType("CompL")}
                              />
                            }
                            label="Completed Courses"
                          />

                          <FormControlLabel
                            classes={{ label: classes.label }}
                            control={
                              <Checkbox
                                checked={progressType.includes("NotStart")}
                                onChange={() =>
                                  handleCheckboxProgType("NotStart")
                                }
                              />
                            }
                            label="Not Started"
                          />
                        </FormGroup>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ) : null}
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3a-content"
                    id="panel3a-header"
                  >
                    <Typography
                      className={classes.heading}
                      style={{ fontSize: "12px" }}
                    >
                      Duration
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="Duration"
                          name="Duration"
                          value={duration}
                        >
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="300"
                            control={<Radio onClick={(e) => handleDur(e)} className={classes.radioStyle} />}
                            label="<5 m"
                          />
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="900"
                            control={<Radio onClick={(e) => handleDur(e)} className={classes.radioStyle} />}
                            label="<15 m"
                          />
                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="1800"
                            control={<Radio onClick={(e) => handleDur(e)} className={classes.radioStyle} />}
                            label="<30 m"
                          />

                          <FormControlLabel
                            classes={{ label: classes.label }}
                            value="3660"
                            control={<Radio onClick={(e) => handleDur(e)} className={classes.radioStyle} />}
                            label="<60 m"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "2rem",
                  }}
                >
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    onClick={clear}
                    style={{
                      width: "100px",
                      background: "white",
                      color: "#01498e",
                      marginTop: "1rem",
                      border: "1px solid #01498e",
                    }}
                  >
                    Clear
                  </Button>
                  <Button
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    variant="contained"
                    color="primary"
                    style={{
                      width: "100px",
                      background: "#01498e",
                      color: "#fff",
                      marginTop: "1rem",
                    }}
                    onClick={() => {
                      getCategoryData({ funcCall: "apply" });
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </ListItem>
          </div>
        }
        {...defaultTippyProps}
      >
        <button title="Filter" className={classes.onFilterHover}>
          <img src={filtericon} height={"30px"} width={"40px"} alt="Filter" />
        </button>
      </Tippy>
    </motion.div>
  );
}
