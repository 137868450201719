import React, { useCallback } from "react";
//React router
import { Link } from "react-router-dom";
// Swiper
import "swiper/swiper.scss";
import { Swiper } from "swiper/react";
import { breakpoints ,breakpoints3} from "./utils/Breakpoints";
import "swiper/components/navigation/navigation.scss";
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { motion, AnimatePresence } from "framer-motion";
import noThingIsHere from "../../assets/Nothing here yet.gif";
import Loading from "../../components/Loading/Loading";

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);
const containerVarients = {
  hidden: { opacity: 0 },
  visible: { opacity: 1, transition: { duration: 0.5, ease: "easeInOut" } },
  exit: { opacity: 0, transition: { duration: 0.5, ease: "easeInOut" } },
};
function Slick(props) {
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const sliderRef = React.useRef(null);
  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);
  return (
    <motion.div
      className="carousel-container"
      variants={containerVarients}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="carousel-container--between">
        <h2 className="carousel-heading">{props.heading}</h2>
         <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
          </div>
          {props.viewall !== "" && (
            <Link
              to="#"
              style={{
                marginRight: "2rem",
                fontSize: "1.7rem",
                marginTop: "1rem",
                fontWeight: "600",
              }}
              className="viewAllActive"
              onClick={props.viewall}
            >
              View all
            </Link>
          )}
        </div>
       
      </div>
      <AnimatePresence>
        <motion.div
          className="swiper_wrapper"
          variants={containerVarients}
          initial="hidden"
          animate="visible"
        >
          {!props.loading && props.data.length > 0 && (
            <Swiper
              ref={sliderRef}
              breakpoints={props.classDefine === "item-2" ? breakpoints3 : breakpoints}
              spaceBetween={8}
              slidesPerGroup={4}
              slidesPerView={4}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onInit={() => {}}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: false }}
              className="slide-container"
            >
              {props.children}
            </Swiper>
          )}
          <span
            id="swiper-right"
            className={`arrow arrow-right ${
              props.data.length === 0 ? "hide" : ""
            }`}
            onClick={handleNext}
          ></span>
          <span
            id="swiper-left"
            className={`arrow arrow-left ${
              props.data.length === 0 ? "hide" : ""
            }`}
            onClick={handlePrev}
          ></span>
          <div className="flex-loading">
            {props.loading && (
              <React.Fragment>
                {[...Array(4).fill(0)].map((_, index) => (
                  <Loading />
                ))}
              </React.Fragment>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
      <AnimatePresence>
        {!props.loading && props.data.length === 0 && (
          <div>
            <AnimatePresence>
              {!props.loading && props.oCData.length === 0 && (
                <motion.div
                  className="slick-center"
                  style={{
                    color: "Grey",
                  }}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "easeOut" },
                  }}
                >
                  <img
                    src={noThingIsHere}
                    alt="Nothing is here yet"
                    title="Nothing is here yet"
                    style={{ width: "30rem" }}
                  />
                 
                </motion.div>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {!props.loading && props.oCData.length !== 0 && (
                <motion.div
                  className="slick-center"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1, transition: { duration: 1 } }}
                  exit={{
                    opacity: 0,
                    transition: { duration: 1, ease: "easeInOut" },
                  }}
                >
                  <img
                    src={noThingIsHere}
                    alt="Nothing is here yet"
                    title="Nothing is here yet"
                    style={{ width: "30rem" }}
                  />
                  <h2 className="text">Nothing here yet!</h2>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
      </AnimatePresence>
    </motion.div>
  );
}
export default React.memo(Slick);