import React from "react";
import { Paper } from "@material-ui/core";
import "./Discussion.scss";
import backIcon from "../../assets/svg/back.svg";

export default function Discussion() {
  return (
    <div className="mainContainer">
      <h3>Discussion forum</h3>
      <Paper elevation={5} style={{ marginTop: "2rem" }}>
        <div style={{ padding: "1rem" }}>
          <div className="messageContainer">
            <div>
              <img
                className="circlePic"
                alt="123"
                src="https://www.w3schools.com/howto/img_avatar2.png"
              />
            </div>
            <div className="nameContainer">
              <div className="nameMessageBox">
                <div style={{ fontWeight: "bold" }}>Name</div>
                <div>Message</div>
              </div>
              <div className="backIconContainer">
                <img className="backIcon" src={backIcon} alt="backIcon" />
              </div>
            </div>
          </div>
          <div className="messageContainer">
            <div>
              <img
                className="circlePic"
                alt="123"
                src="https://www.w3schools.com/howto/img_avatar2.png"
              />
            </div>
            <div className="nameContainer">
              <div className="nameMessageBox">
                <div style={{ fontWeight: "bold" }}>Name</div>
                <div>Message</div>
              </div>
              <div className="backIconContainer">
                <img className="backIcon" src={backIcon} alt="backIcon" />
              </div>
            </div>
          </div>
          <div className="messageContainer">
            <div>
              <img
                className="circlePic"
                alt="123"
                src="https://www.w3schools.com/howto/img_avatar2.png"
              />
            </div>
            <div className="nameContainer">
              <div className="nameMessageBox">
                <div style={{ fontWeight: "bold" }}>Name</div>
                <div>Message</div>
              </div>
              <div className="backIconContainer">
                <img className="backIcon" src={backIcon} alt="backIcon" />
              </div>
            </div>
          </div>
          <div className="messageContainer">
            <div>
              <img
                className="circlePic"
                alt="123"
                src="https://www.w3schools.com/howto/img_avatar2.png"
              />
            </div>
            <div className="nameContainer">
              <div className="nameMessageBox">
                <div style={{ fontWeight: "bold" }}>Name</div>
                <div>Message</div>
              </div>
              <div className="backIconContainer">
                <img className="backIcon" src={backIcon} alt="backIcon" />
              </div>
            </div>
          </div>

          <div style={{ width: "100%" }}>
            <textarea
              className="textBox"
              placeholder="Type your comment here"
            ></textarea>
            <div className="buttonContainer">
              <button className="postButton">Post</button>
            </div>
          </div>
        </div>
      </Paper>
    </div>
  );
}
