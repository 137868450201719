import React,{ useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import SearchHeader from "../../components/SocialLearning/SearchHeader";
import image1 from "../../assets/media/homepage.jpg";
import image2 from "../../assets/male3.png";
import { Button, CircularProgress, Backdrop } from "@mui/material";
import {   useInfiniteQuery } from "@tanstack/react-query";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import { callAPI } from "../../api/api";
import Swal from "sweetalert2";
import { Theme } from "../../aws-exports";
import { useDebounce } from "../../Hooks/useDebounce"; 

function FollowPeople( ) {
  const userDetails = useSelector(authData);

  const [mainLoader, setMainLoader] = useState(false);
  const [searchQuery, setSearchQuery] = useState(1 || "");
  const debouncedSearchQuery = useDebounce(searchQuery, 5);

  const useStyles = makeStyles((theme) => ({
    mainSection: {
      margin: "3rem 0rem",
      display: "flex",
      flexWrap: "wrap",
      gap: "3rem",
    },
    bannerLayout: {
      backgroundImage: `url(${image1})`,
      height: "8rem",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    mainLayout: {
      boxShadow: userDetails.themeDark === "dark" ? "none!important" : "0px 1px 0px 1px rgba(102, 102, 102, 0.3)!important",
      border: userDetails.themeDark === "dark" ? "1px solid #565B64" : "none",
      backgroundColor: userDetails.themeDark === "dark" ? "#282D37" : "white",
      fontSize: "1.6rem!important",
      width: "25rem",
      height: "24.5rem",
      borderRadius: "1.5rem",
      overflow: "hidden",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
    },
    profileContainer: {
      display: "grid",
      textAlign: "center",
      gap: "1rem",
      padding: "0rem 2rem",
      margin: "-1rem 0rem 0rem",
      "& > p": {
        color: userDetails.themeDark === "dark" ? "#CECECE" : "#676A70",
      },
    },
    aboutProfile: {
      width: "6rem",
      borderRadius: "7rem",
      margin: "auto",
      height: "6rem",
      boxShadow: "0px 4px 6px 1px rgba(102, 102, 102, 0.3)",
    },
    actionButtonFollowAbout: {
      borderRadius: "3rem!important",
      border: "1px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: userDetails.themeDark === "dark" ? "#282D37" : "white",
      margin: "0!important",
      padding: "0px 15px!important",
      fontSize: "1.7rem!important",
      textTransform: "capitalize!important",
      "&:active": {
        color: "var(--color-button-background)!important!important",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }));
  const fetchunfollowusers = async (debouncedSearchQuery, page) => { 
    try { 
      if (userDetails === 0) {
        window.location.href = config.aws_cloudfront_lurl;
      }
      const bodyParam = {
        queryStringParameters: {
          schema: config.schema,
          offset: page,
          limit: 100,
          ur_id: userDetails?.uData?.ur_id,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      };

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        `/getUnFollowUsers`,
        bodyParam
      ); 
      return response;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.body = [];
      return obj;
    }
  };
  const callAPIFunction = async (ur_id) =>{ 
    try{
    let obj = {};
    obj.method = 'post'; 
    obj.body = {};
    obj.body.ur_id =  userDetails?.uData?.ur_id ;
    obj.body.schema = config.schema; 
    obj.body.follow_ur_id = ur_id ; 
    obj.apiName = `/createUserFollow`;
    const res = await callAPI(obj,userDetails);   
    return res;
  } catch (err) { 
    let obj = {};
    obj.status = 400;
    obj.message = err.message;
    return obj;
  }
  }

  
  const {
    isLoading,
    refetch,
    isFetchingMore,
    data,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: ["tenants", "infinite", { debouncedSearchQuery }, userDetails?.uData?.ur_id],
    getNextPageParam: (prevData) => prevData.nextPage,
    queryFn: ({ pageParam = 1 }) => fetchunfollowusers(debouncedSearchQuery, pageParam),
  });
   
  const targetRef = useRef(null);
  const handleScroll = async () => { 
    if (window.innerHeight + document.documentElement.scrollTop+3 >= document.documentElement.offsetHeight) {
      console.log(window.innerHeight + document.documentElement.scrollTop+3 ,document.documentElement.offsetHeight)
      await fetchNextPage();
    }
  };

 useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0];
        if (target.isIntersecting && hasNextPage && !isFetchingMore) {
          fetchNextPage();
        }
      },
      { threshold: 1 }
    );
    if (targetRef.current) {
      observer.observe(targetRef.current);
    }
    return () => {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef.current, hasNextPage, isFetchingMore, fetchNextPage]);
  const handleFollow = async (ur_id) => {
    setMainLoader(true);
    let  res = await callAPIFunction(ur_id);
    Swal.fire({
     title: res.statusCode === 200 ? "Success" : "Error",
     text:  res.message,
     icon: res.statusCode === 200 ? "success" : "error",
     confirmButtonColor: Theme.color.primary,
     confirmButtonText: "Ok",
   });
   refetch();
   setMainLoader(false);
  }


  const classes = useStyles();

  return (
    <>
     {mainLoader ? (
          <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color="inherit" />
          </Backdrop>
        ) : null}
      <SearchHeader />
      <h2 style={{ fontFamily: "2.3rem" }}>People you can follow</h2>
     
      {!isLoading && <div className={classes.mainSection}   >
          { data && data?.pages
          .flatMap((data) => data?.body)
          .map((item)=>( <div className={classes.mainLayout}>
            <div className={classes.bannerLayout}>{/* Banner Section*/}</div>
            <div className={classes.profileContainer}>
              <img className={classes.aboutProfile} src={image2} alt="Avatar" />
              <h4>{item?.first_name}</h4>
              <p>{item?.full_name}</p>
              <Button className={classes.actionButtonFollowAbout}
              onClick={()=>handleFollow(item.ur_id)}>Follow</Button>
            </div>
          </div>
          ))}
       
      </div>}
    </>
  );
}
export default FollowPeople;
