import React, { useState } from "react";
import { StyledCard } from "./LearningPathCard.styles";
import { ReactComponent as LPIcon } from "../../../assets/svg/LP_icon.svg";
import {
  Bookmark,
  BookmarkBorderOutlined,
  StarRounded,
} from "@material-ui/icons";
import config from "../../../aws-exports";

export default function ContentCard({
  otype,
  title,
  dur,
  tid,
  count,
  ...rest
}) {
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
  };
  const divColorCodes = [
    "#E2D2FE!important",
    "#FDE1AB!important",
    "#CCF0BF!important",
    "#FFD27E!important",
    "#BAE5F4!important",
    "#EECFCF!important",
  ];

  return (
    <StyledCard
      style={{ color: "black", height: "22rem" }}
      color={divColorCodes[rest.index % divColorCodes.length]}
      onClick={() => {
        const color = divColorCodes[rest.index % divColorCodes.length];
        rest.handleLocation(color);
      }}
    >
      {!imgError ? (
        <img
          src={`https://${
            config.DOMAIN
          }/${config.aws_org_id.toLowerCase()}-resources/images/learningpath-images/${tid}.png`}
          alt="Learning Path Image"
          style={{ width: "100%", height: "16rem" }}
          onError={handleImageError}
        />
      ) : (
        <LPIcon />
      )}
      <div className="card-actions" style={{ height: "6rem" }}>
        <div className="card-actions--footer">
          <div>
            <div style={{ fontWeight: 600 }}>{title}</div>
            <div></div>
            <div> {count} courses</div>
          </div>
          {rest?.bookmarkPage && (
            <div className="bookmark_date-container">
              <p className="bookmark_date-text">Bookmark Date</p>
              <span>:</span>
              <p className="bookmark_date-date">{rest.date}</p>
            </div>
          )}
          {rest?.bookmarkPage && (
            <Bookmark className="bookmark-icon fill" onClick={rest.onClick} />
          )}
          {rest?.bookmark && !rest?.bookmarkPage && (
            <Bookmark
              className="bookmark-icon fill"
              onClick={() => rest.handleBookmark(1, tid, rest.index)}
            />
          )}
          {!rest?.bookmark && !rest?.bookmarkPage && (
            <BookmarkBorderOutlined
              style={{ color: "black" }}
              className="bookmark-icon"
              onClick={() => rest.handleBookmark(0, tid, rest.index)}
            />
          )}
        </div>
      </div>
    </StyledCard>
  );
}
