import { Box } from "@mui/material";
import React, { useState } from "react";

import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { CircularProgress } from "@mui/material";

// import "./phoneInput.styles.css";
// import "react-phone-number-input/style.css";
import { useForm } from "../../Hooks/useForm";

import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { API, Auth } from "aws-amplify";
import config from "../../aws-exports";
import CustomInput from "../../components/CustomInput/CustomInput";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { HeadingPrimary } from "../../components/Styles/Heading";
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../components/SignInAndSignUp/SignInAndSignUp";

import { useHistory, useLocation } from "react-router-dom";
import validateSignUp from "./SignInSignUpValidationRules/SignUpValidationRules";
import cognitoValidation from "./SignInSignUpValidationRules/SignInCognitoValidationRules";
import CustomLoginLayout from "./CustomLoginLayout";
const SignUp = () => {
  const accesToken =  localStorage.getItem('accesToken')
  const { state } = useLocation();
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [signUpBtnLoader, setSignUpBtnLoader] = useState(false);
  const {
    values,
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
  } = useForm({ showPassword: false });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (Object.keys(validateSignUp(values)).length !== 0) {
      setErrors(validateSignUp(values));
      return;
    } else {
      const { password, email, phoneNumber } = values;
      email.toLowerCase();
      setSignUpBtnLoader(true);
      try {
        /* let phoneNum = undefined;
        if(values.phoneNumber){
          phoneNum = "+91"+values.phoneNumber
        } */
        let userdata = JSON.parse(localStorage.getItem("userdata"));
        let username = userdata?.username,
          ur_id = userdata?.ur_id;

        const response = await Auth.signUp({
          username: state?.username?.toUpperCase() || username.toUpperCase(),
          password,
          attributes: {
            email, // optional
            phone_number: values.phoneNumber, // optional - E.164 number convention
            // other custom attributes
          },
          autoSignIn: {
            // optional - enables auto sign in after user is confirmed
            enabled: true,
          },
        });

        let phone;
        phone =
          values?.phoneNumber &&
          parseInt(values?.phoneNumber?.replace("+", ""));

        if (response) {
          let body = {
            type: "signup",
            eid: response.userSub,
            emailid: email,
            cno: phone,
            ur_id: state?.ur_id || ur_id,
            schema: config.schema,
          };

          addUser(body);
        }
      } catch (error) {
        setErrors(cognitoValidation(error));
        setSignUpBtnLoader(false);
      }
    }
  };

  const addUser = async (user) => {
    const bodyParam = {
      body: user,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accesToken	

        
      },
    };

    try {
      const res = await API.put(
        config.aws_cloud_logic_custom_name,
        "/updateUser",
        bodyParam
      );
      let body = { ...res.body, username: res?.body?.uid || state?.username };
      localStorage.setItem("userdata", JSON.stringify(body));
      history.push({
        pathname: "/confirm-sign-up",
        state: body,
      });
    } catch (err) {
      setSignUpBtnLoader(false);
    }
  };

  return (
    <React.Fragment>
      <CustomLoginLayout>
        <Box style={{ textAlign: "center" }}>
          <HeadingPrimary>Create an account</HeadingPrimary>
        </Box>

        <form
          onSubmit={handleSubmit}
          noValidate
          autoComplete="off"
          style={displayCenter}
        >
          <React.Fragment>
            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-name-1"
                placeholder="Email*"
                name="email"
                type="email"
                value={values.email || ""}
                error={errors.email}
                onChange={handleChange("email")}
              >
                <EmailOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-phone"
                placeholder="Enter phone number (+91XXXXXXXXXX)"
                name="phoneNumber"
                value={values.phoneNumber || ""}
                error={errors.phoneNumber}
                type="text"
                onChange={handleChange("phoneNumber")}
              >
                <PhoneIphoneIcon style={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-Password"
                placeholder="Password*"
                type="password"
                name="password"
                inputValues={values}
                value={values.password || ""}
                error={errors.password}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("password")}
              >
                <LockOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>

            <Box component="div" style={inputBoxStyle}>
              <CustomInput
                id="input-confirmPassword"
                placeholder="Confirm Password*"
                type="password"
                name="confirmPassord"
                inputValues={values}
                value={values.confirmPassword || ""}
                error={errors.confirmPassword}
                handleClickShowPassword={handleClickShowPassword}
                handleMouseDownPassword={handleMouseDownPassword}
                onChange={handleChange("confirmPassword")}
              >
                <LockOutlinedIcon style={inputIconStyle} />
              </CustomInput>
            </Box>
            <label
              style={{
                fontSize: "10px",
                width: "360px",
                textAlign: "center",
                marginLeft: "70px",
                color: "black",
              }}
            >
              *Password should be at least 8 characters, contain at least one
              number & one special character.
            </label>
            <Box component="div" style={{ textAlign: "center", mb: 3 }}>
              <CustomButton
                type="button"
                onClick={() => {
                  history.goBack();
                }}
              >
                Back
              </CustomButton>
              {signUpBtnLoader ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton primary type="submit">
                  SignUp
                </CustomButton>
              )}
            </Box>
          </React.Fragment>
        </form>
      </CustomLoginLayout>
    </React.Fragment>
  );
};

export default SignUp;
