import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import {
  Paper,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  TableHead,
  Table,
  Typography,
  Stack,
  Breadcrumbs,
} from "@mui/material";
import avatar1 from "../../assets/male1.png";
import { Link, useHistory } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Skeleton } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import config from "../../aws-exports";

import SearchIcon from "@material-ui/icons/Search";
import { getActiveUsers } from "../../api/api";

function MyPeers() {
  let userDetails = useSelector(authData);
  const history = useHistory();
  let dispatch = useDispatch();
  const [search, setSearch] = React.useState("");
  const { data: users, isLoading: spin } = useQuery({
    queryKey: ["users_act"],
    queryFn: () => getActiveUsers(userDetails, false, false),
  });

  const useStyles = makeStyles((theme) => ({
    responsiveMenu: {
      overflow: "auto",
    },
    dialogPaper: {
      overflow: "auto",
      padding: "1rem 3rem",
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "2rem!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "bold!important",
      padding: "1rem 0 0 2rem!important",
    },
    dialogSection: {
      "& > div > div": {
        borderRadius: "1rem",
        maxWidth: "80%!important",
        width: "80%!important",
        height: "80vh!important",
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "@media (max-width: 770px)": {
          width: "90%!important",
          maxWidth: "90%!important",
          height: "70vh!important",
        },
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
        },
      },
    },
    peopleLive: {
      display: "flex",
      gap: "2px",
      fontSize: "1.5rem",
      padding: "4px 6px",
      alignItems: "center",
      border: "1px solid var(--color-primary)",
      width: "fit-content",
      borderRadius: "3rem",
      backgroundColor: "#def3ff",
      color: "var(--color-primary)",
      margin: "0rem 0rem 1rem auto",
    },
    peopleLiveHeader: {
      display: "flex",
      gap: "2px",
      fontSize: "1.2rem",
      padding: "4px 6px",
      alignItems: "center",
      width: "fit-content",
      borderRadius: "3rem",
      backgroundColor: "#fff",
      color: "#525252",
      fontWeight: "600",
      margin: "1rem 0rem 1rem auto",
      textTransform: "none",
    },
    rankSection: {
      fontWeight: "600!important",
      padding: "4px 1rem!important",
      "& > a": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "black !important",
      },
      "& > span": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "var(--color-primary) !important",
      },
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
    loaderSkeleton: {
      width: "150px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    mainDiv: {
      gap: "2rem",
      display: "grid",
      marginBottom: "2.5rem",
      gridTemplateColumns: "10% auto 10%",
      [theme.breakpoints.down(1420)]: {
        gridTemplateColumns: "auto",
      },
    },
    searchSection: {
      margin: "0 0 2rem auto",
      position: "relative",
      width: "fit-content",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    searchInput: {
      fontSize: "1.8rem",
      backgroundColor: "#fff!important",
      color:
        userDetails.themeDark === "dark" ? "#fff!important" : "#000!important",
      borderRadius: "1rem",
      border: "1px solid lightgray",
      transition: "all 0.2s",
      padding: "6px 10px 6px 43px",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "var(--color-button-background)",
      color: theme.palette.common.white,
      fontSize: "1.5rem",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: "1.5rem",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "#3F434D !important",
      borderBottom: "1px solid",
      borderColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
  }));
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(even)": {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#1b212f!important"
          : theme.palette.action.hover,
    },
    "&:nth-of-type(odd)": {
      backgroundColor:
        userDetails.themeDark === "dark" ? "#0b111d!important" : "white",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const classes = useStyles();

  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      className={classes.breadcrumbNameStyle}
    >
      {`${userDetails?.languagejson?.Active_User?.MyPeers || "My Peers"}`}
    </Typography>,
  ];

  const handlePageTrans = (recv) => {
    if (recv.ur_id !== userDetails.uData.ur_id) {
      let sdata = { ...userDetails };
      let comp_peer = {
        ur_id: recv.ur_id,
        gid: recv.gid,
      };
      sdata.myPeer = comp_peer;

      dispatch(awsSignIn(sdata));
      history.push("/compare");
    }
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const filteredUsers = users?.filter((user) =>
    user?.first_name?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <>
      <Stack spacing={2} style={{ marginBottom: "2rem" }}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="large"
              style={{ color: "var(--color-primary)" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>

      <div className={classes.mainDiv}>
        <div>
          <div className={classes.mainContainer}>
            <h3 style={{ fontWeight: "600" }} className="responsive">
              {spin ? (
                <Skeleton className={classes.loaderSkeleton} />
              ) : (
                <>{`${userDetails?.languagejson?.Active_User?.MyPeers ||
                  "My Peers"}`}</>
              )}
            </h3>
          </div>
        </div>

        <div className={classes.responsiveMenu}>
          <div>
            <div className={classes.searchSection}>
              <button
                className="search__button"
                title="Search"
                style={{ position: "absolute", top: "0.5rem", left: "1rem" }}
              >
                <SearchIcon
                  style={{
                    fontSize: "3rem",
                    color: "var(--color-primary)",
                  }}
                />
              </button>
              {spin ? (
                <> </>
              ) : (
                <input
                  type="search"
                  className={classes.searchInput}
                  placeholder={`${userDetails?.languagejson?.Active_User
                    ?.FindPeers || "Find Peers..."}`}
                  value={search}
                  onChange={handleSearchChange}
                />
              )}
            </div>
          </div>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    align="Right"
                    style={{ fontFamily: `myfontregular`, fontWeight: "800" }}
                  ></StyledTableCell>
                  <StyledTableCell
                    align="left"
                    style={{ fontFamily: `myfontregular`, fontWeight: "800" }}
                  >{`${userDetails?.languagejson?.Leaderboard?.Name ||
                    "Name"}`}</StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ fontFamily: `myfontregular`, fontWeight: "800" }}
                  >{`${userDetails?.languagejson?.Active_User?.Level ||
                    "Level"}`}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {spin ? (
                  <> </>
                ) : (
                  filteredUsers?.map((user) => (
                    <StyledTableRow>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="right"
                        className={classes.rankSection}
                      >
                        <div
                          // to={`/compare`}

                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <img
                            src={
                              user?.avtid
                                ? `https://${
                                    config.DOMAIN
                                  }/${config.aws_org_id.toLowerCase()}-resources/images/profile-images/${
                                    user?.avtid
                                  }.png`
                                : avatar1
                            }
                            alt="Avatar Name"
                            title="Avatar Name"
                            style={{ width: "4rem" }}
                          />
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="left"
                        className={classes.rankSection}
                      >
                        <div
                          onClick={() => handlePageTrans(user)}
                          style={{
                            textDecoration: "none",
                            color: "black",
                            cursor: "pointer",
                          }}
                        >
                          {user?.first_name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        component="th"
                        scope="row"
                        align="center"
                        className={classes.rankSection}
                      >
                        <div style={{ textDecoration: "none", color: "black" }}>
                          {user?.levels}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default MyPeers;
