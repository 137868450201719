import { createSlice } from '@reduxjs/toolkit';

export const homeDataSlice = createSlice({
  name: 'home',
  initialState: {
    viewAllArr: [],
    finalArr: [],
  },
  reducers: {
    setViewAllArrData: (state, action) => {
      state.viewAllArr = [...action.payload];
    },
    setFinalArrData: (state, action) => {
      state.finalArr = [...action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setViewAllArrData, setFinalArrData } = homeDataSlice.actions;

export default homeDataSlice.reducer;
