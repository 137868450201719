import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import config from "../../../aws-exports";
import { useSelector, useDispatch } from "react-redux";
import { awsSignIn, authData } from "../../../redux/auth/authSlice";
import { Box, Breadcrumbs, makeStyles } from "@material-ui/core";

//React router
// import { useParams } from 'react-router-dom';
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import CardLarge from "../../../components/Cards/CardLarge/CardLarge";
import { Skeleton } from "@material-ui/lab";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    padding: theme.spacing(3),
    height: "600px",
    width: "1100px",
    backgroundColor: "white",
  },
  mainDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "1rem",
    marginTop: "4rem",
  },
}));
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
function CourseView() {
  const classes = useStyles();
  const { value } = useSelector((state) => state.counter);
  const userDetails = useSelector(authData);
  const query = useQuery();
  const [courses, setCourses] = useState([]);
  const course = useRef([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const filterPID = value.filter((v) => v.pid === id);
  const handleLocation = (tid, ttitle) => {
    let breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: breadcrumb.location.from,
      programName: breadcrumb.location.programName,
      courseName: ttitle,
      programId: breadcrumb.location.programId,
    };
    if (userDetails.location.viewAll && userDetails.location.viewAllRoute) {
      breadcrumb.location.viewAll = userDetails.location.viewAll;
      breadcrumb.location.viewAllRoute = userDetails.location.viewAllRoute;
    }
    dispatch(awsSignIn(breadcrumb));
    history.push(`/course?id=${tid}`);
  };
  useEffect(() => {
    if (userDetails === 0) {
      let obj = {};
      obj.type = "program";
      obj.pid = query.get("id");
      localStorage.setItem("deeplink", JSON.stringify(obj));
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      listPrograms();
    }
  }, []);

  const listPrograms = async () => {
    setIsLoading(true);
    // const bodyParam = {
    //   body: {
    //     tenant: userDetails.locale,
    //     oid: config.aws_org_id,
    //     pid: query.get("id"),
    //     schema: config.schema,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // // alert(JSON.stringify(bodyParam, null, 2));
    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/listPrograms",
    //     bodyParam
    //   );
    try {
      const requestBody = {
        tenant: userDetails.locale,
        oid: config.aws_org_id,
        pid: query.get("id"),
        schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/listPrograms?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      const { programs } = response;
      let courses = programs[0].courses;

      course.current = [...courses];
      if (query.get("from") == 0) {
        getUserDataWeb(courses);
      } else {
        setCourses(courses);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  async function getUserDataWeb(coursedt) {
    // const jwttoken = (await Auth.currentSession()).idToken.jwtToken;
    // const bodyParam = {
    //   body: {
    //     id: userDetails.id,
    //     iid: config.aws_cognito_identity_pool_id,
    //     tenant: userDetails.locale,
    //     schema: config.schema,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //   },
    // };
    // //, Authorization: jwttoken,

    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getUserDataWeb",
    //     bodyParam
    //   );
    try {
      const requestBody = {
        id: userDetails.id,
            iid: config.aws_cognito_identity_pool_id,
            tenant: userDetails.locale,
            schema: config.schema,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDataWeb?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	    Accept: "application/json",	
      Authorization: userDetails?.accessToken	
          },
        }
      );
      var json = JSON.stringify(response);
      var topic = json.toString();

      if (topic.substring(1, 10) == "No Topics") {
        setCourses(coursedt);
        setIsLoading(false);
      } else {
        const { Records } = JSON.parse(topic);

        let result = coursedt.filter((o1, index) =>
          Records.some((o2) => {
            if (o1.tid === o2.Key) {
              let value = JSON.parse(o2.Value);
              var obj = o1;
              obj.tp = value.tp;
              obj.tobj = value.tobj;
              obj.cobj = value.cobj;
              coursedt[index] = obj;
            }
          })
        );
        setCourses(coursedt);
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  }
  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "var(--color-primary)" }} />
        }
        aria-label="breadcrumb"
        style={{ marginBottom: "3rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
        </Link>
        {userDetails.location !== undefined && userDetails.location.viewAll ? (
          <Link
            color="inherit"
            to="/viewAllMyPrograms"
            style={{
              color: "#1974b5",
              fontSize: "1.5rem",
              textDecoration: "none",
            }}
          >
            {`${userDetails?.languagejson?.Home_Page?.View_all || "View all"}`}
          </Link>
        ) : (
          ""
        )}
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "context-menu",
          }}
          color="inherit"
          to="#"
        >
          {userDetails.location.programName}
        </Link>
      </Breadcrumbs>
      <div className="flex-between">
        <div className="flex-container">
          <h3 style={{ fontWeight: "600" }}>
            {userDetails.location.programName}
          </h3>
        </div>
      </div>

      {isLoading ? (
        <div className={classes.mainDiv}>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
          <Box>
            <Skeleton variant="rect" width={350} height={200} />
            <Box pt={0.5}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Box>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginTop: "2.5rem",
          }}
        >
          <div
            className="posFilter"
            style={courses.length === 0 ? { right: ".5%" } : { right: "8.5%" }}
          ></div>
          {courses.map(({ tid, ttitle, noofnuggets, starAvg, cobj, tobj }) => (
            <div onClick={() => handleLocation(tid, ttitle)}>
              <CardLarge
                tid={tid}
                ttitle={ttitle}
                course={noofnuggets}
                star={starAvg}
                cobj={cobj ? cobj : 0}
                tobj={tobj ? tobj : 0}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CourseView;
