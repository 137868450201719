import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
// import BookmarkIcon from '@material-ui/icons/Bookmark';
// import FastRewindIcon from '@material-ui/icons/FastRewind';
// import FastForwardIcon from '@material-ui/icons/FastForward';
import Forward10Icon from "@material-ui/icons/Forward10";
import Replay10Icon from "@material-ui/icons/Replay10";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import VolumeUp from "@material-ui/icons/VolumeUp";
import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeMute from "@material-ui/icons/VolumeOff";
import FullScreen from "@material-ui/icons/Fullscreen";
import SpeedIcon from "@material-ui/icons/Speed";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";

import Tippy from "@tippyjs/react";
import "./Controls.scss";

const useStyles = makeStyles((theme) => ({
  controlsWrapper: {
    visibility: "hidden",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: "100%",
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  button: {
    margin: theme.spacing(1),
  },
  controlIcons: {
    color: "#fff",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },

  bottomIcons: {
    color: "#fff",
    "&:hover": {
      color: "#fff",
    },
  },
  volumeButton: {
    color: "#fff",
    fontSize: 40,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1.03)",
      transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
    },
  },
  volumeSlider: {
    height: 100,
  },

  playPauseOverlay: {
    position: "absolute",
    height: "85%",
    top: "60%",
    left: "60%",
    transform: "translate(-60%, -60%)",
  },

  sliderStyle: {
    track: {
      color: "#fff",
      width: 20,
    },
    rail: {
      borderRadius: 4,
      width: 20,
    },
  },
}));

const PrettoSlider = withStyles({
  root: {
    height: 8,
    padding: 0,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "1px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    color: "#13599a",
    height: 7,
    borderRadius: 4,
  },
  rail: {
    height: 7,
    borderRadius: 4,
  },
})(Slider);
const VolSlider = withStyles({
  root: {
    color: "#13599a",
    height: 8,
    "&$vertical": {
      width: 8,
    },
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover": {
      boxShadow: "0px 0px 0px 8px #85c4ffbf",
    },
    "&$active": {
      boxShadow: "0px 0px 0px 12px #85c4ffbf",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
  vertical: {
    "& $rail": {
      width: 8,
    },
    "& $track": {
      width: 8,
    },
    "& $thumb": {
      marginLeft: -6,
      marginBottom: -13,
    },
  },
})(Slider);

function ValueLabelComponent(props) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

const defaultTippyProps = {
  // animation: "shift-away-subtle",
  arrow: false,
  interactive: true,

  placement: "top-end",
  // delay: [0, 0],
  // duration: [200, 150],
  maxWidth: 500,

  // boundary: "viewport",
  theme: "light",
  distance: 24,
  inertia: true,
};
const Controls = forwardRef(
  (
    {
      onSeek,
      onSeekMouseDown,
      onSeekMouseUp,
      onDuration,
      onRewind,
      onPlayPause,
      onFullScreen,
      onFastForward,
      playing,
      played,
      elapsedTime,
      totalDuration,
      onMute,
      muted,
      onVolumeSeekDown,
      onChangeDispayFormat,
      playbackRate,
      onPlaybackRateChange,
      onToggleFullScreen,
      hideSeekBarOnEnter,
      showSeekBarOnLeave,
      volume,
      onVolumeChange,
      fullScreen,
      onBookmark,
      objName,
      hideAndShowSeekBar,
    },
    ref
  ) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    // const [redioValue, setRedioValue] = React.useState(1);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
      <div ref={ref} className={classes.controlsWrapper}>
        <Grid
          container
          direction="column"
          justify="space-between"
          style={{ flexGrow: 1 }}
        >
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{ padding: 16 }}
          >
            <Grid item>
              <Typography variant="h5" style={{ color: "#fff" }}>
                {objName}
              </Typography>
            </Grid>
            {/* <Grid item>
              <Button
                onClick={onBookmark}
                variant='contained'
                color='primary'
                startIcon={<BookmarkIcon />}
              >
                Bookmark
              </Button>
            </Grid> */}
          </Grid>
          <Grid
            className={classes.playPauseOverlay}
            onClick={onPlayPause}
            container
            direction="row"
            alignItems="center"
            justify="center"
          >
            <IconButton className={classes.controlIcons} aria-label="play">
              {playing ? (
                <PauseIcon fontSize="inherit" style={{ fontSize: "10rem" }} />
              ) : (
                <PlayArrowIcon
                  fontSize="inherit"
                  style={{ fontSize: "10rem" }}
                />
              )}
            </IconButton>
          </Grid>
          {/* bottom controls */}
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ padding: 16 }}
          >
            <Grid item xs={12}>
              {hideAndShowSeekBar ? null : (
                <PrettoSlider
                  min={0}
                  max={100}
                  ValueLabelComponent={(props) => (
                    <ValueLabelComponent {...props} value={elapsedTime} />
                  )}
                  aria-label="custom thumb label"
                  value={played * 100}
                  onChange={onSeek}
                  onMouseDown={onSeekMouseDown}
                  onChangeCommitted={onSeekMouseUp}
                  onDuration={onDuration}
                />
              )}
            </Grid>

            <Grid item>
              <Grid container alignItems="center">
                <IconButton
                  onClick={onPlayPause}
                  className={classes.bottomIcons}
                >
                  {playing ? (
                    <PauseIcon
                      className={classes.controlIcons}
                      fontSize="large"
                    />
                  ) : (
                    <PlayArrowIcon
                      className={classes.controlIcons}
                      fontSize="large"
                    />
                  )}
                </IconButton>
                <IconButton
                  onClick={onRewind}
                  className={classes.controlIcons}
                  aria-label="rewind"
                >
                  <Replay10Icon
                    className={classes.controlIcons}
                    fontSize="inherit"
                  />
                </IconButton>
                <IconButton
                  onClick={onFastForward}
                  className={classes.controlIcons}
                  aria-label="forward"
                >
                  <Forward10Icon fontSize="inherit" />
                </IconButton>
                <div style={{ position: "relative", zIndex: "10000000" }}>
                  <Tippy
                    content={
                      <div
                        style={{
                          padding: ".5rem 0",
                          // background: "#fff",
                          borderRadius: ".4rem",
                          marginBottom: "-5px",
                        }}
                        onMouseEnter={hideSeekBarOnEnter}
                        onMouseLeave={showSeekBarOnLeave}
                      >
                        <div
                          style={{
                            height: "160px",
                          }}
                        >
                          <VolSlider
                            orientation="vertical"
                            min={0}
                            max={100}
                            value={muted ? 0 : volume * 100}
                            onChange={onVolumeChange}
                            aria-label="vertical-slider"
                            onMouseDown={onSeekMouseDown}
                            onChangeCommitted={onVolumeSeekDown}
                          />
                        </div>
                      </div>
                    }
                    {...defaultTippyProps}
                  >
                    <IconButton
                      // onClick={() => setState({ ...state, muted: !state.muted })}
                      onClick={onMute}
                      onMouseEnter={hideSeekBarOnEnter}
                      onMouseLeave={showSeekBarOnLeave}
                    >
                      {muted ? (
                        <VolumeMute
                          fontSize="large"
                          className={classes.volumeButton}
                        />
                      ) : volume > 0.5 ? (
                        <VolumeUp
                          fontSize="large"
                          className={classes.volumeButton}
                        />
                      ) : (
                        <VolumeDown
                          fontSize="large"
                          className={classes.volumeButton}
                        />
                      )}
                    </IconButton>
                  </Tippy>
                </div>
                <Button variant="text" onClick={onChangeDispayFormat}>
                  <Typography
                    variant="h5"
                    style={{ color: "#fff", marginLeft: 16 }}
                  >
                    {elapsedTime}/{totalDuration}
                  </Typography>
                </Button>
              </Grid>
            </Grid>

            <Grid item>
              <Tippy
                content={
                  <div
                    style={{
                      marginBottom: "1rem",
                      width: "425px",
                      height: "140px",
                    }}
                    onMouseEnter={hideSeekBarOnEnter}
                    onMouseLeave={showSeekBarOnLeave}
                  >
                    <Typography variant="h5" style={{ padding: "1.4rem" }}>
                      Playback Speed
                    </Typography>
                    <div id="form-wrapper">
                      <form action="/p/quote.php" method="GET">
                        <div id="debt-amount-slider">
                          <input
                            type="radio"
                            name="debt-amount"
                            id="1"
                            value={0.5}
                            onChange={onPlaybackRateChange}
                            required
                          />
                          <label for="1" data-debt-amount="0.5x"></label>
                          <input
                            type="radio"
                            name="debt-amount"
                            id="2"
                            value={0.75}
                            onChange={onPlaybackRateChange}
                            required
                          />
                          <label for="2" data-debt-amount="0.75x"></label>
                          <input
                            type="radio"
                            name="debt-amount"
                            id="3"
                            value={1}
                            onChange={onPlaybackRateChange}
                            required
                          />
                          <label for="3" data-debt-amount="1x(Normal)"></label>
                          <input
                            type="radio"
                            name="debt-amount"
                            id="4"
                            value={1.25}
                            onChange={onPlaybackRateChange}
                            required
                          />
                          <label for="4" data-debt-amount="1.25x"></label>
                          <input
                            type="radio"
                            name="debt-amount"
                            id="5"
                            value={2}
                            onChange={onPlaybackRateChange}
                            required
                          />
                          <label for="5" data-debt-amount="2x"></label>
                          <div id="debt-amount-pos"></div>
                        </div>
                      </form>
                    </div>
                  </div>
                }
                {...defaultTippyProps}
              >
                <Button
                  onClick={handleClick}
                  className={classes.bottomIcons}
                  variant="text"
                  onMouseEnter={hideSeekBarOnEnter}
                  onMouseLeave={showSeekBarOnLeave}
                >
                  <SpeedIcon className={classes.volumeButton} />
                  {playbackRate === "1" || playbackRate === 1 ? null : (
                    <Typography>{playbackRate}X</Typography>
                  )}
                </Button>
              </Tippy>

              <IconButton
                onClick={() => {
                  onToggleFullScreen();
                  onFullScreen();
                }}
                className={classes.bottomIcons}
              >
                {fullScreen ? (
                  <FullScreen
                    fontSize="large"
                    className={classes.volumeButton}
                  />
                ) : (
                  <FullscreenExitIcon
                    fontSize="large"
                    className={classes.volumeButton}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
);

Controls.propTypes = {
  onSeek: PropTypes.func,
  onSeekMouseDown: PropTypes.func,
  onSeekMouseUp: PropTypes.func,
  onDuration: PropTypes.func,
  onRewind: PropTypes.func,
  onPlayPause: PropTypes.func,
  onFullScreen: PropTypes.func,
  onFastForward: PropTypes.func,
  onVolumeSeekDown: PropTypes.func,
  hideSeekBarOnEnter: PropTypes.func,
  showSeekBarOnLeave: PropTypes.func,
  onChangeDispayFormat: PropTypes.func,
  onPlaybackRateChange: PropTypes.func,
  onToggleFullScreen: PropTypes.func,
  onMute: PropTypes.func,
  playing: PropTypes.bool,
  played: PropTypes.number,
  elapsedTime: PropTypes.string,
  totalDuration: PropTypes.string,
  muted: PropTypes.bool,
  playbackRate: PropTypes.number,
  fullScreen: PropTypes.bool,
  hideSeekBar: PropTypes.bool,
};
export default Controls;

{
  // {[0.5, 0.75, 1, 1.5, 2].map((rate, id) => (
  //   ))}
  /* <Button
                        key={rate}
                        onClick={() => onPlaybackRateChange(rate)}
                        variant='text'
                      >
                        <Typography
                          color={
                            rate === playbackRate ? 'secondary' : 'inherit'
                          }
                        >
                          {rate}X
                        </Typography>
                      </Button> */
}
