import React, { useEffect } from "react";
import Header from "../../../components/Header/Header";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory, Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import config from "../../../aws-exports";
import CardSmall from "../../../components/Cards/CardSmall/CardSmall";

const useStyles = makeStyles((theme) => ({
  "@media (max-width: 770px)": {
    MobileView: {
      "& > div > div > span": {
        display: "none!important",
      },
      "& > div > div > div > div": {
        display: "block",
        "@media (min-width: 770px)": {
          flexWrap: "wrap",
          gap: "1rem",
        },
        "& > div": {
          margin: "1.5rem 0rem",
          marginRight: "0!important",
          width: "auto!important",
        },
      },
    },
  },
  MobileView: {
    "& > div > div > div > div": {
      "@media (min-width: 770px)": {
        flexWrap: "wrap",
        gap: "2rem",
        justifyContent: "center",
      },
    },
    "& > div > div > span": {
      "@media (min-width: 770px)": {
        display: "none!important",
      },
    },
  },
}));

export default function AllTopics() {
  const classes = useStyles();
  const { viewAllArr } = useSelector((state) => state.home);
  let userDetails = useSelector(authData);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLocation = (name, id) => {
    const breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Home",
      topicName: name,
      topicId: id,
      viewAll: false,
    };
    dispatch(awsSignIn(breadcrumb));
    history.push(`explore/${name.replace(/\s/g, "-")}/${id}`);
  };
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  return (
    <div>
      {/* <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "var(--color-primary)" }} />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
        >
          {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
        </Link>
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          View all
        </Link>
      </Breadcrumbs> 
      
      <div style={{ display: "flex", gap: "1rem", marginTop: "2rem" }}>
        {viewAllArr.map(({ name, id }) => (
          <div className="card-sm" onClick={() => handleLocation(name, id)}>
            <div
              style={{
                backgroundImage: `linear-gradient(to right bottom, rgba(0,0,0,.6), rgba(0,0,0,.6)), url(${config.aws_cloudfront_url}marketron-resources/images/category-images/${id}.png)`,
              }}
              className="back-img"
            >
              <div className="card-img-overlay text-white d-flex justify-content-center align-items-end">
                <h3>{name}</h3>
              </div>
            </div>
          </div>
        ))}
      </div> */}

      <div className={classes.MobileView}>
        <CardSmall
          heading={`${userDetails?.languagejson?.Home_Page?.All_Topics ||
            "All Topics"}`}
          search={false}
        />
      </div>
    </div>
  );
}
