import React from "react";
import "./LearningPath.scss";
import { ReactComponent as Flag } from "../../assets/svg/learner flagg.svg";
import { ReactComponent as Arrow } from "../../assets/svg/LearningPath arrow.svg";
import { Button } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSelector } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { authData } from "../../redux/auth/authSlice";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const LearningPathSnake = ({
  isLoading,
  sharedObject,
  handleitemClick,
  color,
}) => {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    topicTitle: {
      fontSize: "11px",
      width: "15rem",
      position: "absolute",
      top: "-6rem",
      left: "-4.5rem",
      display: "flex",
      gridGap: "0.3rem",
      gap: "0.3rem",
      justifyContent: "center",
      alignItems: "end",
      lineHeight: "normal",
      height: "2.5rem",
      color: userDetails.themeDark === "dark" ? "white" : "black",
    },
    topicTitleBottom: {
      fontSize: "11px",
      width: "15rem",
      position: "absolute",
      top: "9rem",
      left: "-4.5rem",
      display: "flex",
      gridGap: "0.3rem",
      gap: "0.3rem",
      justifyContent: "center",
      alignItems: "center",
      lineHeight: "normal",
      color: userDetails.themeDark === "dark" ? "white" : "black",
      zIndex: 9,
    },
  }));
  const classes = useStyles();
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1.2rem",
    },
  }));

  return (
    <section>
      <div className="container11">
        <div id="demo-list" className="steps-list-looped">
          <div className="step-wrapper" style={{ height: "24rem" }}>
            <div
              className="step-content1"
              style={{
                backgroundColor: color,
                padding: "9px 15px 9px 15px",
              }}
            >
              <div className="step-content" style={{ margin: 0 }}>
                <h2
                  className="step-number"
                  style={{
                    width: "8rem",
                    height: "8rem",
                    backgroundColor: "white",
                  }}
                >
                  {`${userDetails?.languagejson?.Learning_path?.Start ||
                    "Start"}`}
                </h2>
              </div>
            </div>
          </div>
          {isLoading ? (
            <></>
          ) : (
            sharedObject?.res.map(
              ({ type, id, lpid, idtls, cmpstatus, cobj, tobj }, index) => (
                <div key={index} className="step-wrapper">
                  <div className="step-content1">
                    <div className="step-content">
                      {index % 2 === 0 ? (
                        // Odd index, display header below the step-number
                        <>
                          <Button
                            className="button-style"
                            onClick={() => {
                              handleitemClick(idtls, id, type, lpid);
                            }}
                          >
                            <BootstrapTooltip title={`${idtls?.title}`}>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: "12rem",
                                  height: "4rem",
                                  display: "grid",
                                  marginLeft: "10px",
                                  borderRadius: "5px",
                                }}
                                className={classes.topicTitleBottom}
                              >
                                <h3 style={{ fontSize: "11px" }}>
                                  {idtls?.title.length > 20
                                    ? `${idtls?.title.slice(0, 20)}...`
                                    : idtls?.title}
                                </h3>
                                {type === 1 && (
                                  <span>{`${cobj}/${tobj} completed`}</span>
                                )}

                                {type === 2 &&
                                  (cmpstatus ? (
                                    <span>1/1 completed</span>
                                  ) : (
                                    <span>0/1 completed</span>
                                  ))}

                                {type === 3 && <span>0/0 completed</span>}
                              </div>
                            </BootstrapTooltip>

                            <h2
                              className="step-number"
                              style={{
                                backgroundColor: cmpstatus
                                  ? "#00b865"
                                  : "#ffe063",
                              }}
                            >
                              {cmpstatus ? (
                                <DoneTwoToneIcon
                                  fontSize="large"
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <AccessTimeIcon fontSize="large" />
                              )}
                            </h2>

                            <div className="ArrowBottom">
                              <Arrow
                                style={{ width: "7.5rem", height: "7.5rem" }}
                              />
                            </div>

                            <div className="ArrowRightIconStyle">
                              <ArrowRightIcon fontSize="large" />
                            </div>
                          </Button>
                        </>
                      ) : (
                        // Even index, display header above the step-number
                        <>
                          <Button
                            className="button-style"
                            onClick={() => {
                              handleitemClick(idtls, id, type, lpid);
                            }}
                          >
                            <div className="ArrowTop">
                              <Arrow
                                style={{ width: "7.5rem", height: "7.5rem" }}
                              />
                            </div>

                            <h2
                              className="step-number"
                              style={{
                                backgroundColor: cmpstatus
                                  ? "#00b865"
                                  : "#ffe063",
                              }}
                            >
                              {cmpstatus ? (
                                <DoneTwoToneIcon
                                  fontSize="large"
                                  style={{ color: "white" }}
                                />
                              ) : (
                                <AccessTimeIcon fontSize="large" />
                              )}
                            </h2>

                            <BootstrapTooltip title={`${idtls?.title}`}>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: "12rem",
                                  height: "4rem",
                                  display: "grid",
                                  marginLeft: "10px",
                                  borderRadius: "5px",
                                }}
                                className={classes.topicTitle}
                              >
                                <h3 style={{ fontSize: "11px" }}>
                                  {idtls?.title.length > 20
                                    ? `${idtls?.title.slice(0, 20)}...`
                                    : idtls?.title}
                                </h3>
                                {type === 1 && (
                                  <span>{`${cobj}/${tobj} completed`}</span>
                                )}

                                {type === 2 &&
                                  (cmpstatus ? (
                                    <span>1/1 completed</span>
                                  ) : (
                                    <span>0/1 completed</span>
                                  ))}

                                {type === 3 && <span>0/0 completed</span>}
                              </div>
                            </BootstrapTooltip>

                            <div className="ArrowRightIconStyle">
                              <ArrowRightIcon fontSize="large" />
                            </div>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )
            )
          )}
          <div className="step-wrapper" style={{ height: "24rem" }}>
            <div
              className="step-content1"
              style={{
                backgroundColor: color,
                padding: "9px 15px 9px 15px",
              }}
            >
              <div className="step-content" style={{ margin: 0 }}>
                <h2
                  className="step-number"
                  style={{
                    width: "8rem",
                    height: "8rem",
                    backgroundColor: "white",
                  }}
                >
                  {`${userDetails?.languagejson?.Learning_path?.End || "End"}`}
                </h2>
              </div>
              <div className="FlagStyle">
                <Flag style={{ width: "35px", height: "35px" }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LearningPathSnake;
