import React from "react";
import { motion } from "framer-motion";
import "./Svg.styles.css";
import { Bars } from "react-loader-spinner";

const svgVariants = {
  hidden: { rotate: -180 },
  visible: {
    rotate: 0,
    transition: { duration: 1 },
  },
};

const pathVariants = {
  hidden: {
    opacity: 0,
    pathLength: 0,
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1.5,
      ease: "easeInOut",
    },
  },
};
const loadingVarients = {
  exit: {
    y: "100vh",
    transition: { ease: "easeInOut" },
  },
};
const PageLoading = () => {
  return (
    <motion.div className="page-loading" variants={loadingVarients} exit="exit">
      <Bars
        height="140"
        width="140"
        color="#fff"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </motion.div>
  );
};

export default PageLoading;
