import React, { useEffect, useState } from "react";
import {
CircularProgress, Divider, Paper, Typography, styled
} from "@mui/material";
import RadarChart from "./RadarChart";
import { ReactComponent as Badge } from "../../assets/svg/Badge-4.svg";
import { ReactComponent as ProfilePic } from "../../assets/svg/DP.svg";
import { ReactComponent as Marker } from "../../assets/svg/Marker.svg";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { deepPurple } from "@mui/material/colors";
import { withStyles } from '@mui/styles';
import { makeStyles } from "@material-ui/core";
import { Breadcrumbs } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { getSkillAnalyticsGraphData } from "../../api/api";


export const options = {
  chart: {
    subtitle: "No. of people",
  },
  colors: ['#c391d8'],
  bars: 'vertical',
  bar: { groupWidth: '20%' },
};

const CustomLinearProgress = withStyles(() => ({
  root: {
    height: 11,
    width: "9vw",
    "& > span": {
      backgroundColor: "transparent!important",
    },
  },
  colorPrimary: {
    backgroundColor: "red",
  },
  bar: {},

}))(LinearProgress);

const CompetencyUserProfile = (props) => {
  const [remainingSkills, setRemainingSkills]= useState([]);
  const location = useLocation();
  const userDetails = useSelector(authData);
  const [competency, setCompetency]= useState([]);
const [skillsDataGraph, setSkillsDataGraph]= useState([]);
  const competencies = location?.state?.competency;
  const [leftValue, setLeftValue]= useState();
  const[ skillsdata, setSkillsdata]= useState([]);



  const getProficiencyStatus = (percent) => {
    if (percent === 0 || percent < 25) {
      return "Novice";
    } else if (percent < 50) {
      return "Beginner";
    } else if (percent < 75) {
      return "Proficient";
    } else {
      return "Excellent";
    }
  };


  useEffect(() => {
    const fetchData = async () => {
       if (skillsdata && skillsdata.length > 0) {
           const graphData = await getSkillAnalyticsGraphData(
          userDetails,
          skillsdata
        );
        setSkillsDataGraph(graphData);
      }
    };

    fetchData();
  }, [skillsdata]);

  useEffect(()=>{
    setCompetency(competencies);
    setRemainingSkills(competencies?.skill && competencies?.skill.filter(skill => !competencies.comskills.includes(skill.title)) || []);
    setLeftValue(`calc(${competencies?.progress}% - 3%)`);
    setSkillsdata(competencies?.skill && competencies?.skill?.filter(skill => skill.title).map(skill => skill.title));
    }, [competencies])


  const useStyles = makeStyles(() => ({
    stackStyle1: {
      alignItems: "center",
      borderBottom: "1px solid #dfdfdf",
      paddingBottom: "0.5rem",
      justifyContent: "space-between",
    },
    stackStyleContainer: {
      width: '100%',
      display: "grid",
      gap: "10px",
      alignItems: "center",
    },
    avatarSection: {
      alignItems: "center",
      width: "30%",
      "& > div": {
        backgroundColor: deepPurple[500],
        width: "30px",
        height: 30,
      },
    },
    stackRatingStyle: {
      "& > span > span > svg": {
        width: "2rem",
        height: "2rem",
        color: "#fed813",
      },
      "& > span > span > span > svg": {
        width: "2rem",
        height: "2rem",
        color: "#fed813",
      },
    },
    skillsCount: {
      width: "fit-content",
      padding: "0.1rem 1rem",
      border: "1px solid var(--color-primary)",
      color: "var(--color-primary)",
      fontWeight: "800", borderRadius: "1rem",
      backgroundColor: "white",
    },
    main: {
      height: "100%",
      width: "100%",
      padding: "0.5rem",
      overflowY: "auto",
      overflowX: "auto",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#1C2027 !important" : "white",
    },
    field: {
      display: "flex",
      justifyContent: "flex-start",
      alignContent: "center",
      margin: "1rem auto 0rem",
      gap: "20px",
    },
    paperStyle: {
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "0px 0px 10px 0px #0003 !important",
      overflow: "auto",
      margin: 0,
      padding: "2rem 4rem",
      backgroundColor: userDetails.themeDark === "dark" ? "#282d37 !important" : "#eeeeee",
    },
    labelStyle: {
      display: "flex",
      backgroundColor: "#506172",
      padding: "3px 12px",
      borderRadius: "1rem",
      margin: "0.1rem",
      color: "white",
      fontSize: "12px",
      alignItems: "center",
      gap: "5px",
      width: "fit-content",
    },
    statusBarStyle: {
      display: "flex", gap: "2px",
      justifyContent: "center",
      alignItems: "center",
      margin: "6rem auto 2rem",
      width: "fit-content",
      position: "relative",
      "& > div": {
        display: "grid", gap: "2px", justifyContent: "center", textAlign: "center",
      },
    },
    fourSectionStyle: {
      gridTemplateColumns: "auto auto",
      display: "grid",
      gap: "3rem",
      "@media (max-width: 900px)": {
        gridTemplateColumns: "auto",
      },
    },
    summaryStyles: {
      width: '100%', display: "grid", gridTemplateColumns: "50% 50%", gap: "10px",
      "@media (max-width: 770px)": {
        gridTemplateColumns: "auto",
      },
      "& > div": {
        margin: "2rem 4rem",
        "@media (max-width: 770px)": {
          margin: "1rem 0rem",
        },
        "& > div:nth-child(1)": {
          display: "flex", gap: "0.5rem", margin: "1rem 0rem",
          "& > div": {
            backgroundColor: "black", color: "white", borderRadius: "4px", padding: "1px 6px",
          },
          "& > p": {
            margin: "0"
          },
        }
      },
    },
    progressSection: {
      display: "flex", gap: "1rem", justifyContent: "space-around", alignItems: "center", margin: "2rem 0rem 1rem",
      "@media (max-width: 1200px)": {
        display: "grid",
      },
      "& > div:nth-child(1)": {
        position: 'relative', width: '150px', height: '100px',
        "@media (max-width: 1200px)": {
          width: '100px',
          margin: "auto",
        },
      },
    },
    progressGraphContainer: {
      display: "flex", gap: "3rem", justifyContent: "space-around",
      alignItems: "center", margin: "1rem 0rem 0rem",
      "@media (max-width: 500px)": {
        display: "ruby-text",
      },
    },
    skillSectionStyle1: {
      width: '100%', display: "grid", gridTemplateColumns: "22% 79%", gap: "10px", alignItems: "center",
      "@media (max-width: 500px)": {
        gridTemplateColumns: "auto",
      },
    },
    skillSectionStyle2: {
      width: '100%', display: "grid", gridTemplateColumns: "30% 68%", gap: "10px", alignItems: "center",
      "@media (max-width: 500px)": {
        gridTemplateColumns: "auto",
      },
    },
    profileSection: {
      display: "flex", gap: "1rem", justifyContent: "space-between", alignItems: "center", margin: "0.5rem 5rem",
      "@media (max-width: 700px)": {
        display: "grid",
        margin: "0.5rem 0rem",
        justifyContent: "center",
      },
    },
    borderVStyle: {
      backgroundColor: "var(--color-primary)", width: "1.5px", height: "7rem",
      "@media (max-width: 700px)": {
        display: "none",
      },
    },
    borderHStyle: {
      backgroundColor: "var(--color-primary)", width: "7rem", height: "1.5px", margin: "auto",
      "@media (min-width: 700px)": {
        display: "none",
      },
    },
    profileImageSection: {
      display: "flex", gap: "3rem", alignItems: "center",
      "@media (max-width: 700px)": {
        display: "grid",
      },
    },
  }));

  const classes = useStyles();

  const { progress1, progress2, progress3, progress4 } = props;

  useEffect(() => {
    return () => { };
  }, []);

  const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 11,
    borderRadius: 10,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      background: "linear-gradient(1deg, #ededed 0%, #e1e1e1 51%)",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 10,
      backgroundColor: "var(--color-button-background)",
    },
  }));

  return (
    <div className={classes.main}>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "var(--color-primary)" }} />
        }
        aria-label="breadcrumb"
        style={{ marginBottom: "3rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
       Home
        </Link>
      
        <Link
          to="/profile"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
          color="inherit"
        >
           Profile
        </Link>
      
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          {competency?.title}
        </Link>
      </Breadcrumbs>
     
      <div style={{ display: "flex", gap: "2rem", alignItems: "center", marginBottom: "2rem" }}>
        <p>{`${userDetails?.languagejson?.Competency?.Competency || "Competency"}`} : </p>
        <h3>{competency?.title}</h3>
      </div>
      <div className={classes.field} style={{ display: "grid", gap: 0, justifyContent: "normal", }}>
        <Paper className={classes.paperStyle} style={{ marginBottom: "3rem" }}>
          <div className={classes.profileSection}>
            <div className={classes.profileImageSection}>
              <div style={{ display: "grid", justifyContent: "center" }}>
                <ProfilePic style={{ width: "10rem", height: "10rem" }} />
                <Badge style={{ width: "3rem", height: "3rem", margin: "-1rem auto 0rem" }} />
              </div>
              <div style={{ display: "grid", gap: "2rem" }}>
                <h3>User Name</h3>
                <h4>{userDetails?.name}</h4>
              </div>
            </div>

            <div className={classes.borderVStyle}></div>
            <div className={classes.borderHStyle}></div>
            <div style={{ display: "grid", gap: "2rem", textAlign: "center" }}>
              <h4>{`${userDetails?.languagejson?.Competency?.Designation || "Designation"}`}</h4>
              <p style={{ fontWeight: "700" }}>{userDetails?.uData?.regi_dtls?.designation}</p>
            </div>
            <div className={classes.borderVStyle}></div>
            <div className={classes.borderHStyle}></div>
            <div style={{ display: "grid", gap: "2rem", textAlign: "center" }}>
              <h4>{`${userDetails?.languagejson?.Competency?.Reports_to || "Reports to"}`}</h4>
              <p style={{ color: userDetails.themeDark === "dark" ? "white" : "var(--color-primary)", fontWeight: "700" }}>{userDetails?.uData?.regi_dtls?.department || '--'}</p>
            </div>
          </div>
        </Paper>

        <div className={classes.fourSectionStyle}>
          <Paper className={classes.paperStyle}>
            <h4 style={{ margin: "0" }}>
              {`${userDetails?.languagejson?.Competency?.Overall_progress_and_total_number_of_Skills || "Overall progress and total number of Skills"}`}
            </h4>
            <div className={classes.progressGraphContainer}>
              <div style={{ position: 'relative', width: '100px', height: '100px' }}>
                <CircularProgress
                  variant="determinate"
                  value={competency?.progress}
                  size={"10rem"}
                  thickness={3}
                  style={{ position: 'absolute', top: '0', left: '0', color: "#dfdfdf" }}
                />
                <CircularProgress
                  variant="determinate"
                  value={competency?.progress}
                  size={"10rem"}
                  thickness={4}
                  style={{ position: 'absolute', top: '0', left: '0', color: "#48bc48" }}
                />
                <div style={{ position: 'absolute', top: '0', width: '10rem', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h4" component="div" color="textSecondary" style={{ color: userDetails.themeDark === "dark" ? "white" : "black", }}>
                    {`${competency?.progress}%`}
                  </Typography>
                </div>
              </div>
              <Divider orientation="vertical" style={{ height: "10rem" }} />
              <div style={{ width: '100%', display: "grid", gridTemplateColumns: "90% 10%", gap: "10px", alignItems: "center" }}>
                <BorderLinearProgress variant="determinate" value={competency?.progress} /> <b>{competency?.skill && competency?.skill.length || 0}</b>
                <p style={{ margin: 0 }}>{`${userDetails?.languagejson?.Competency?.Skills || "Skills"}`}</p>
              </div>
            </div>
          </Paper>
          <Paper className={classes.paperStyle}>
            
            <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
              <h4 style={{ margin: "0" }}>{`${userDetails?.languagejson?.Competency?.Competency_Status_Tracker || "Competency Status Tracker"}`}</h4>
              <div style={{ width: "fit-content", padding: "0.3rem 1rem", background: "#e8ffe9", border: "1px solid #1d8000", color: "#1d8000", fontWeight: "800" }}>
                {getProficiencyStatus(competency?.progress)}
              </div>
            </div>
            <div className={classes.statusBarStyle}>
              <div>
                <CustomLinearProgress variant="determinate" value={progress1} style={{ backgroundColor: '#f54545', borderRadius: "2rem 0 0 2rem" }} />
                <span>{`${userDetails?.languagejson?.Competency?.Novice || "Novice"}`}</span>
              </div>
              <div>
                <CustomLinearProgress variant="determinate" value={progress2} style={{ backgroundColor: '#fea347' }} />
                <span>{`${userDetails?.languagejson?.Competency?.Beginner || "Beginner"}`}</span>
              </div>
              <div>
                <CustomLinearProgress variant="determinate" value={progress3} style={{ backgroundColor: '#00be0c' }} />
                <span>{`${userDetails?.languagejson?.Competency?.Proficient || "Proficient"}`}</span>
              </div>
              <div>
                <CustomLinearProgress variant="determinate" value={progress4} style={{ backgroundColor: '#006ad3', borderRadius: "0 2rem 2rem 0" }} />
                <span>{`${userDetails?.languagejson?.Competency?.Excellent || "Excellent"}`}</span>
              </div>
              <div style={{ position: "absolute", top: "-4rem", left: leftValue }}><Marker style={{ width: "3.2rem" }} /></div>
            </div>
          </Paper>
          <Paper className={classes.paperStyle}>
            <div className={classes.skillSectionStyle1}>
              <div>
                <p style={{ margin: "0 0 1rem" }}>{`${userDetails?.languagejson?.Competency?.Skills_Acquired || "Skills Acquired "}`} :</p>
                <label className={classes.skillsCount}>{competency?.comskills?.length}</label>
              </div>
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap", }}>
                {competency?.comskills?.map((skill, index) => (
                  <label key={index} className={classes.labelStyle}> {skill}</label>
                ))}

              </div>
            </div>
          </Paper>
          <Paper className={classes.paperStyle}>
            <div className={classes.skillSectionStyle2}>
              <div>
                <p style={{ margin: "0 0 1rem" }}> {`${userDetails?.languagejson?.Competency?.Skills_Yet_to_Achieve || "Skills Yet to Achieve"}`} :</p>
                <label className={classes.skillsCount}>{remainingSkills?.length}</label>
              </div>
              <div style={{ display: "flex", gap: "8px", flexWrap: "wrap", }}>

                {remainingSkills.map((skill, index) => (
                  <label key={index} className={classes.labelStyle}> {skill.title}</label>
                ))}
                
              </div>
            </div>
          </Paper>

        </div>
        <Paper className={classes.paperStyle} style={{ marginTop: "3rem" }}>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <h4 style={{ margin: "0" }}>{`${userDetails?.languagejson?.Competency?.Competency_Diagram || "Competency Diagram"}`}</h4>
          </div>
          <div style={{ width: "48rem", margin: "auto" }}>
            <RadarChart skillsDataGraph={skillsDataGraph} />
          </div>
        </Paper>

      </div >
    </div >
  );
};

export default CompetencyUserProfile;
