import React, { useEffect, useRef, useState } from "react";
import "./FormativeQuiz.scss";
import config from "../../aws-exports";
import { CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { useQueryParams } from "../../Hooks/useQueryParams";
import styled from "styled-components";

const StyledScoreContainer = styled.div`
  border-radius: 3%;
  background-color: #ffffff;
  width: 46rem;
  margin: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3); 
  height: 60%;
  display: flex;
  align-items: center;
  flex-direction: column;
 
  .show-score-header { 
    width: 170px;
    height: 140px;
    background-color: #f1f5f8;
    border-radius: 4%;
    align-items: center;
    text-align: center;
    margin-top: 3rem;
    justify-content: center;
  }

`;




const SummativeQuiz = ({
  question,
  setQuestion,
  loading,
  setLoading,
  startQuiz,
  curObject,
  setProgress,
  setMarkComplete,
  syncUserProgresswhilequiz,
  courseDetails,
  qpage,
  ...rest
}) => {

  const userDetails = useSelector(authData);

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [unAnsweredPopup, setUnAnsweredPopup] = useState(false);

  const retake = useRef(false);
  const query = useQueryParams();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 150,
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  };

  useEffect(() => { }, []);


  const scoreAchieved = (totalscore) => {
    if ((totalscore / question.length) * 100 >= parseInt(rest.cutOff)) return true;

    return false;
  };


  function handleQuizSubmit() {
    if (
      question.filter((e) => e.youAnswered === undefined || false).length > 0
    ) {
      //newly added
      setUnAnsweredPopup(true);
    } else {
      let totalscore = question.filter((item) => item.answeredOption).length;

      let objsr = {};
      objsr.oid = curObject.oid;
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var date1 = date.getDate();
      var sdate = date1 + "." + month + "." + year;
      objsr.name = curObject.otitle;
      objsr.cdate = sdate;
      objsr.score = totalscore;
      objsr.tquestions = question.length;

      rest.setPerScore((totalscore / question.length) * 100);
      rest.setTotalScore(totalscore);
      if (curObject.op === 1) {
        rest.setShowScore(true);
        setMarkComplete(true);
        syncUserProgresswhilequiz(totalscore, "sumative");
        rest.lastobject.current = true;
      }

      rest.setTimerPalyPause("pause");
    }
  }



  const handleAnswerClick = (idx) => {
    const currentAnsquestion = [...question];
    currentAnsquestion[currentQuestion].iopts.map((item) => {
      item.youAnswered = false;
      return item;
    });

    currentAnsquestion[currentQuestion].iopts[idx].youAnswered = true;

    if (currentAnsquestion[currentQuestion].iopts[idx].youAnswered === true) {
      delete currentAnsquestion[currentQuestion].skip;
    }
    currentAnsquestion[currentQuestion].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx].correct) {
      currentAnsquestion[currentQuestion].answeredOption = true;
    } else {
      currentAnsquestion[currentQuestion].answeredOption = false;
    }

    setQuestion(currentAnsquestion);
  };

  const handleNextQuestion = () => {
    setQuestion((prevQues) => {
      let yourAns = prevQues[currentQuestion]?.youAnswered;
      if (yourAns === undefined || yourAns == null) {
        prevQues[currentQuestion].skip = true;
      }
      return prevQues;
    });
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };

  const gotoQuestion = (idx) => {
    setCurrentQuestion(idx);
  };

  return (
    <React.Fragment>
      {!loading && startQuiz && !rest.showScore && (
        <div className="quiz_container">
          <div className="quiz_wrapper">
            <div className="quiz_wrapper_ques_ans">
              <div
                className="quiz_wrapper_ques_ans--question"
                style={{ marginTop: "2rem" }}
              >
                <div>
                  Q {currentQuestion + 1}/{question?.length}
                </div>
                <div>
                  <p>{question[currentQuestion]?.istem}</p>
                </div>
              </div>

              {question[currentQuestion]?.imageurl && (
                <div className="quiz_wrapper_ques_ans_img-container">
                  <img
                    src={`https://${config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                        "id"
                      )}/${question[currentQuestion]?.imageurl}`}
                    alt=""
                    style={{ width: "30rem" }}
                  />
                </div>
              )}
              <div className="quiz_wrapper_ques_ans--answers">
                {question[currentQuestion]?.iopts.map((item, index) => (
                  <div
                    style={{ cursor: "pointer" }}
                    key={index}
                    className={`ans-btn ${item.youAnswered ? "selected-answer" : ""
                      }
                    ${question[currentQuestion].checkAnswer && item.correct
                        ? "green"
                        : ""
                      }
                    `}
                    onClick={() => handleAnswerClick(index)}
                  >
                    {item.content}
                  </div>
                ))}
              </div>
            </div>

            <div className="quiz_wrapper_grow" />

            <div className="quiz_wrapper--actions">
              <button
                className="btn btn-outline primary"
                onClick={handlePreviousQuestion}
                disabled={currentQuestion === 0 ? true : false}
              >
                Previous
              </button>
              <div>
                {currentQuestion !== question?.length - 1 && (
                  <button
                    className="btn btn-contained primary"
                    onClick={handleNextQuestion}
                    disabled={
                      currentQuestion === question?.length - 1 ? true : false
                    }
                  >
                    Next
                  </button>
                )}
                {currentQuestion === question?.length - 1 && (
                  <button
                    className="btn btn-contained primary"
                    onClick={() => {
                      if (qpage !== undefined && qpage === "object") {
                        rest.setStartQuiz(false);
                        setCurrentQuestion(0);
                      } else {
                        handleQuizSubmit();
                      }
                    }}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          </div>

          <hr className="divider" />

          <div className="quiz_container--footer">
            {question?.map((item, index) => (
              <button
                key={item.iid}
                style={{ cursor: "pointer" }}
                className={`btn contained ${item.skip ? "border" : ""} ${item.youAnswered ? "green" : ""
                  }`}
                onClick={() => gotoQuestion(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>

        </div>
      )}

      {rest.showScore && (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}>
          <StyledScoreContainer primary={scoreAchieved(rest?.score)}>

            <div className="show-score-header">
              <h1 style={{ marginTop: "3rem" }}>
                {rest?.totalScore}
                /
                {question?.length
                  ? question.length
                  : curObject?.quiz_length}
              </h1>


              <h4 style={{ marginBottom: "1rem", marginTop: "1rem", color: "black" }}>Your Score</h4>

            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#007bff" }}>&#8226; {Math.round(rest?.totalScore
                /
                (question?.length
                  ? question.length
                  : curObject?.quiz_length) * 100 )}%</li>
                  
                </ul>
                <div>Total Percentage</div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginLeft: "8rem" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#000000" }}>&#8226; {question?.length ? question?.length : curObject?.quiz_length}</li>
                </ul>
                <div>Total Questions</div>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "3rem" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#00ff00" }}>&#8226; {rest?.totalScore}</li>
                </ul>
                <div>Correct Answers</div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginLeft: "8rem" }}>
                <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                  <li style={{ color: "#ff0000" }}>&#8226; {(question?.length ? question?.length : curObject?.quiz_length) - rest?.totalScore}</li>
                </ul>
                <div>Wrong Answers</div>
              </div>
            </div>

          </StyledScoreContainer>

          <div>

            {loading ? (
              <CircularProgress color="default" size={30} />
            ) : (
              <div className="flex-center" style={{ marginBottom: "2rem" }}>
                {rest.curNugget?.objects[rest.curObjIndex + 1] == undefined ||
                  (rest.curNugget?.objects[rest.curObjIndex + 1].op == 2 &&
                    (curObject.op == 2 ||
                      rest.nextCourseref.current == true)) ? (
                  <button
                    className="button-3"
                    onClick={() => {
                      rest.setTimerPalyPause("pause");
                      setLoading(true);
                      const lastObj =
                        courseDetails.nuggets[
                          courseDetails.nuggets.length - 1
                        ].objects[
                        courseDetails.nuggets[
                          courseDetails.nuggets.length - 1
                        ].objects.length - 1
                        ];
                      const curObj =
                        rest.curNugget?.objects[rest.curObjIndex];
                      if (
                        courseDetails.certification === true &&
                        rest.lastobject.current &&
                        curObj !== undefined &&
                        lastObj.oid === curObj.oid
                      ) {
                        rest.getUserCertificate(userDetails);
                        rest.lastobject.current = false;
                      } else if (
                        rest.lastobject.current &&
                        curObj !== undefined &&
                        lastObj.oid !== curObj.oid
                      ) {
                        rest.nextcourse();
                      } else {
                        rest.nextObject();

                      }
                    }}
                  >
                    {rest.curNugget?.objects[rest.curObjIndex] !==
                      undefined &&
                      courseDetails.nuggets[courseDetails.nuggets.length - 1]
                        .objects[
                        courseDetails.nuggets[courseDetails.nuggets.length - 1]
                          .objects.length - 1
                      ].oid !==
                      rest.curNugget?.objects[rest.curObjIndex].oid ? (
                      <>Next</>
                    ) : (
                      <>Cancel</>
                    )}
                  </button>
                ) : (
                  <button
                    className="button-3"
                    onClick={() => {
                      rest.setTimerPalyPause("pause");
                      setLoading(true);
                      rest.setStartQuiz(true);
                      retake.current = false;
                      setQuestion([]);
                      rest.getQuiz(
                        rest.curNugget?.objects[rest.curObjIndex + 1]
                      );
                      rest.nextObject();
                    }}
                  >
                    {rest.curNugget?.objects[rest.curObjIndex] !==
                      undefined &&
                      courseDetails.nuggets[courseDetails.nuggets.length - 1]
                        .objects[
                        courseDetails.nuggets[courseDetails.nuggets.length - 1]
                          .objects.length - 1
                      ].oid !==
                      rest.curNugget?.objects[rest.curObjIndex].oid ? (
                      <>Take survey</>
                    ) : (
                      <>Next</>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>

        </div>
      )}
      <Modal open={unAnsweredPopup}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            You have some unanswered questions
          </Typography>
          <button
            className="okButton"
            onClick={() => setUnAnsweredPopup(false)}
          >
            OK
          </button>
        </Box>
      </Modal>

    </React.Fragment>
  );
};

export default SummativeQuiz;
