import React, { useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";

import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  Grid,
  Select,
  FormControl,
  MenuItem,
  Box,
  Autocomplete,
  TextField,
} from "@mui/material";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SearchIcon from "@material-ui/icons/Search";
import axios from "axios";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButtonI from "@mui/material/IconButton";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import PollIcon from "@mui/icons-material/Poll";
import Button from "@mui/joy/Button";
import Textarea from "@mui/joy/Textarea";
import IconButton from "@mui/joy/IconButton";
import Menu from "@mui/joy/Menu";
import MenuItemI from "@mui/joy/MenuItem";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import FormatBold from "@mui/icons-material/FormatBold";
import FormatItalic from "@mui/icons-material/FormatItalic";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Check from "@mui/icons-material/Check";

import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createPost,
  createUpdateAttachmentAPI,
  getTopics,
  getUsers,
  getLabels,
  callAPI,
} from "../../../api/api";
import FileUploader from "../../../components/FileUploader/FileUploader";
import Poll from "./Poll/Poll";
import SearchHeader from "../../../components/SocialLearning/SearchHeader";
import { styled } from "@mui/material/styles";
import {
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { ReactComponent as FormSVG } from "../../../assets/svg/form_line.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import Swal from "sweetalert2";
import { Theme } from "../../../aws-exports";
import config from "../../../aws-exports";
import { useHistory } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const useStyles = (props) =>
  makeStyles((theme) => ({
    root: {
      display: "grid",
      gap: "2rem",
      margin: "2rem 0rem",
    },
    mainLayout: {
      margin: "-2rem 20rem",
      display: "flex",
      gap: "2rem",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
      "& > div > div": {
        background:
          props.themeDark === "dark" ? "#282D37!important" : "#fff!important",
        marginBottom: "1rem",
        padding: "2rem 5rem!important",
        boxShadow:
          props.themeDark === "dark"
            ? "0px 0px 2px 1px #b8b8b84d!important "
            : "0px 0px 2px 1px rgba(102, 102, 102, 0.3)!important",
        "@media (max-width: 550px)": {
          padding: "12px!important",
        },
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      borderBottom: "2px solid darkgrey",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    loaderSkeleton: {
      width: "500px",
      height: "5rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    loaderSkeletonSlider: {
      width: "100%",
      height: "25rem",
    },
    searchInput: {
      fontSize: "1.8rem",
      backgroundColor:
        props.themeDark === "dark" ? "#353944!important" : "#fff",
      color: props.themeDark === "dark" ? "lightGray!important" : "#fff",
      border: "none",
      borderRadius: "5px",
      transition: "all 0.2s",
      marginRight: "-3.25rem",
      padding: "7px 7px 7px 40px",
      width: "100%",
      boxShadow:
        props.themeDark === "dark"
          ? "none"
          : "0px 0px 1px 1px rgba(102, 102, 102, 0.3607843137)",
    },
    secondaryButton: {
      fontSize: "1.7rem!important",
      textTransform: "capitalize!important",
      color: props.themeDark === "dark" ? "#fff!important" : "gray!important",
      border: "1px solid lightgray!important",
      padding: "1rem!important",
      width: "15vw",
      gap: "1rem",
      textAlign: "center",
      backgroundColor:
        props.themeDark === "dark"
          ? "#353944!important"
          : "transparent!important",
      "@media (max-width: 1000px)": {
        width: "fit-content",
      },
      "&:hover": {
        color: "white",
        backgroundColor: "var(--color-button-background)!important",
      },
    },
    secondaryButtonDraft: {
      fontSize: "1.7rem!important",
      textTransform: "capitalize!important",
      color:
        props.themeDark === "dark"
          ? "#fff!important"
          : "var(--color-button-background)!important",
      border: "none!important",
      gap: "1rem",
      textAlign: "center",
      backgroundColor:
        props.themeDark === "dark" ? "#353944!important" : "#fff!important",
      "@media (max-width: 1000px)": {
        width: "fit-content",
      },
    },
    primaryButton: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.7rem!important",
      textTransform: "capitalize!important",
      color: "white!important",
      padding: "1rem!important",
      width: "15vw",
      gap: "1rem",
      textAlign: "center",
      "@media (max-width: 1000px)": {
        width: "fit-content",
      },
    },
    secondaryButtonLabel: {
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      width: "fit-content!important",
      color:
        props.themeDark === "dark"
          ? "#fff!important"
          : "var(--color-button-background)!important",
      border: "1px solid var(--color-button-background)!important",
      padding: "0.5rem 1rem!important",
      backgroundColor:
        props.themeDark === "dark" ? "#353944!important" : "#fff!important",
    },
    secondaryButtonClear: {
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      width: "fit-content!important",
      color:
        props.themeDark === "dark"
          ? "#fff!important"
          : "var(--color-button-background)!important",
      border: "none!important",
      padding: "0.5rem 1rem!important",
      backgroundColor: "transparent!important",
    },
    primaryButtonLabel: {
      backgroundColor: "var(--color-button-background)!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: "white!important",
      padding: "0.5rem 1rem!important",
    },
    subBoxSection: {
      padding: "1.5rem",
      background: props.themeDark === "dark" ? "#1C2027" : "#f2f2f2",
      borderRadius: "5px",
      boxShadow: "0px 0px 1px 1px rgba(102, 102, 102, 0.3607843137)",
    },
    inputLabelStyle: {
      background: props.themeDark === "dark" ? "#3F434D" : "#fff",
      borderRadius: "5px",
      width: "24rem",
      marginTop: "2rem",
      "& > label": {
        fontSize: "1.8rem!important",
        color: props.themeDark === "dark" ? "#fff!important" : "black",
      },
    },
    formControlStyle: {
      width: "100%",
      marginTop: "2rem!important",
      "& > div:nth-child(1)": {
        background: props.themeDark === "dark" ? "#1C2027" : "#fff",
        color: props.themeDark === "dark" ? "#fff!important" : "black",
        width: "100%",
        minWidth: "100%",
      },
    },
    labelSectionStyle: {
      display: "flex",
      flexWrap: "wrap",
      gap: "2rem",
      padding: "1rem 0rem",
      "& > span": {
        padding: "0rem",
      },
    },
    labelSectionStyle2: {
      borderBottom: "2px solid lightgray",
      display: "grid",
      gap: "2rem",
      padding: "1rem 0rem",
    },
    dialogSection: {
      "& > div > div": {
        borderRadius: "5px",
        maxWidth: "60%!important",
        width: "60%!important",
        height: "60vh!important",
        margin: 0,
        backgroundColor:
          props.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color: props.themeDark === "dark" ? "white" : "#1C2027 !important",
        "@media (max-width: 770px)": {
          width: "90%!important",
          maxWidth: "90%!important",
          height: "60vh!important",
        },
        "& > h2 > button ": {
          color:
            props.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
        },
      },
    },
    dialogFooter: {
      justifyContent: "right!important",
    },
    dialogFooterButton: {
      background: "var(--color-primary)!important",
      color: "white",
      borderRadius: "5px",
      padding: "0rem 1rem!important",
      fontSize: "1.5rem!important",
      fontWeight: "bold",
      margin: "auto",
    },
    dialogPaper: {
      color:
        props.themeDark === "dark" ? "white !important" : "#272C38 !important",
      overflow: "auto",
      margin: "1rem 0rem",
      "& > div": {
        display: "flex",
        justifyContent: "left",
        borderBottom: "1px solid #8a8a8a !important",
        padding: "1rem",
        margin: "1rem 0",
        fontFamily: `myfontregular`,
      },
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "2rem!important",
      fontFamily: `myfontregular!important`,
      backgroundColor:
        props.themeDark === "dark" ? "#272C38 !important" : "#F2F2F2",
      fontWeight: "bold!important",
    },
    actionButtonFollow: {
      borderRadius: "3rem!important",
      border: "2px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: "transparent!important",
      margin: "0!important",
      padding: "0px 10px!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      "&:active": {
        color: "var(--color-button-background)!important",
      },
    },
    autocompleteStyle: {
      width: "100%",
      backgroundColor:
        props.themeDark === "dark" ? "#3f434d !important" : "white !important",
    },
    labelTextStyle: {
      "& > label": {
        fontSize: "1.5rem",
        backgroundColor:
          props.themeDark === "dark"
            ? "#3f434d !important"
            : "white !important",
        lineHeight: "1.5rem",
      },
      "& > div": {
        fontSize: "1.5rem",
        "& > div": {
          fontSize: "1.5rem",
          color:
            props.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
          "& > svg": {
            color:
              props.themeDark === "dark"
                ? "white !important"
                : "#1C2027 !important",
          },
          "& > button:first-child": {
            backgroundColor: "#C70000",
            "& > svg": {
              fontSize: "2rem",
              color: "white",
            },
          },
          "& > button > svg": {
            fontSize: "2rem",
          },
        },
      },
    },
    InputLabelStylemain: {
      background: props.themeDark === "dark" ? "#3f434d !important" : "#fff",
      padding: "0rem 0.5rem!important",
    },
    selectStyle: {
      fontSize: "1.8rem!important",
      color: props.themeDark === "dark" ? "white !important" : "#000",
    },
    iconStyle: {
      fontSize: "2.5rem!important",
      color: props.themeDark === "dark" ? "white !important" : "#000",
    },
  }));
const getSeconds = (type) => {
  const currentDate = new Date();

  const startOfDay = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate()
  );

  const startOfWeek = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - currentDate.getDay()
  );

  const startOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  const currentDayDuration = Math.floor((currentDate - startOfDay) / 1000);
  const currentWeekDuration = Math.floor((currentDate - startOfWeek) / 1000);
  const currentMonthDuration = Math.floor((currentDate - startOfMonth) / 1000);
  if (type === "1day") {
    return currentDayDuration;
  }
  if (type === "1week") {
    return currentWeekDuration;
  }
  if (type === "1month") {
    return currentMonthDuration;
  }
};
function CreatePost() {
  const userDetails = useSelector(authData);
  const [value, setValue] = useState("");
  const [files, setFiles] = useState([]);
  const classes = useStyles(userDetails)();
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [italic, setItalic] = React.useState(false);
  const [fontWeight, setFontWeight] = React.useState("normal");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectTopic, setSelectTopic] = React.useState("");
  const [options, setOptions] = useState(["", ""]);
  const [pollDuration, setPollDuration] = useState("1day");
  const [uploadProgress, setUploadProgress] = useState(0);
  const [peopleTags, setPeopleTags] = useState([]);
  const [labelTags, setLabelTags] = useState([]);
  const history = useHistory();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = useState({});
  const [displayText, setDisplayText] = useState("");
  const [showText, setShowText] = useState(false);

  const handleButtonClick = () => {
    setDisplayText("Text displayed on top!");
    setShowText(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { data, isLoading } = useQuery({
    queryKey: ["/topics"],
    queryFn: () => getTopics(userDetails),
  });
  const { data: peopleList, isLoading: userLoad } = useQuery({
    queryKey: ["getusersList", userDetails.uData.ur_id],
    queryFn: () => getUsers(userDetails),
  });

  const { data: labelData, isLoading: labelLoad } = useQuery({
    queryKey: ["/getLabels", userDetails],
    queryFn: () => getLabels(userDetails),
  });
  function getIdsUsingForLoop(arrayOfObjects, attr) {
    const ids = [];
    for (let i = 0; i < arrayOfObjects.length; i++) {
      if (arrayOfObjects[i][attr] !== undefined) {
        ids.push(Number(arrayOfObjects[i][attr]));
      }
    }
    return ids;
  }
  const callAPIFunction = async (Type, ids, postid) => {
    try {
      let obj = {};
      obj.method = "post";
      obj.body = {};
      obj.body.postid = postid;
      obj.body.schema = config.schema;
      if (Type === "peopletag") {
        obj.apiName = `/createPostTagPeople`;
        obj.body.urid_values = ids;
      } else if (Type === "labeltag") {
        obj.apiName = `/createLabelByPost`;
        obj.body.in_labelid_values = ids;
      }
      const res = await callAPI(obj, userDetails);

      return res;
    } catch (err) {
      let obj = {};
      obj.status = 400;
      obj.message = err.message;
      return obj;
    }
  };
  const { mutate, isLoading: postLoading } = useMutation({
    mutationKey: ["/post"],
    mutationFn: (postData) => createPost(postData, userDetails),
    onSuccess: async (postData) => {
      const data = { postid: parseInt(postData[0].out_id) };

      if (value === "1" && options.length > 0) {
        data.pollquestion = options;
        createUpdateAttachment(data);
        setOptions(["", ""]);
      }
      if (value === "2" || (value === "3" && files.length > 0)) {
        data.posttypeid = 3;
        const userid = userDetails?.uData?.ur_id;
        const postid = data.postid;
        data.filepath = files.map(
          (file) =>
            `s3://${config.aws_org_id.toLowerCase()}-data/userdata/sl/post/${userid}/${postid}/${file.name.replace(
              / /g,
              ""
            )}`
        );
        data.filetype = files.map((file) => file.type);
        data.filename = files.map((file) => file.name.replace(/ /g, ""));
        setUploadProgress(0);
        uploadFiles(data);
      }
      if (peopleTags && peopleTags.length > 0 && postData[0]?.out_id) {
        const urids = getIdsUsingForLoop(peopleTags, "ur_id");
        await callAPIFunction("peopletag", urids, parseInt(postData[0].out_id));
        setPeopleTags([]);
      }
      if (labelTags && labelTags.length > 0 && postData[0]?.out_id) {
        const ids = getIdsUsingForLoop(labelTags, "id");
        await callAPIFunction("labeltag", ids, parseInt(postData[0].out_id));
        setLabelTags([]);
      }
      if (
        (value === "2" || value === "3") &&
        files.length > 0 &&
        postData[0].statusid !== 1
      ) {
        Swal.fire({
          title: "Success",
          text: `Your post will be visible once admin approves it.`,
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        });
      } else {
        Swal.fire({
          title: "Success",
          text: `Your ${
            postData[0].statusid === 1 ? "draft" : ""
          }  post created successfully! `,
          icon: "success",
          confirmButtonColor: Theme.color.primary,
          confirmButtonText: "Ok",
        });
      }
      setSelectTopic("");
      setTitle("");
      setContent("");
      setValue("");
      history.push("/social-learning");
    },
    onError: (err) => {
      console.error(err);
    },
  });
  const uploadFiles = async (data) => {
    let preapi;
    if (config.aws_org_id === "ENABLED" || config.aws_org_id === "EXCELSOFT") {
      preapi = "/presign";
    } else {
      preapi = "/presign_south";
    }
    try {
      const response = await axios.post(
        `${config.aws_api_endpoint}/${preapi}`,
        {
          files: files.map((file) => ({
            name: file.name.replace(/ /g, ""),
            type: file.type,
          })),
          ur_id: userDetails?.uData?.ur_id,
          postid: data.postid,
          oid: config.aws_org_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );
      const parseData = JSON.parse(response.data.body);
      const presignedUrls = parseData.urls;

      await Promise.all(
        files.map(async (file, index) => {
          const onUploadProgress = (progressEvent) => {
            const progress = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );

            setUploadProgress(progress);
          };

          await axios.put(presignedUrls[index], file, {
            headers: { "Content-Type": file.type },
            onUploadProgress,
          });
        })
      );

      createUpdateAttachment(data);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  const {
    mutate: createUpdateAttachment,
    isLoading: attachmentLoading,
  } = useMutation({
    mutationKey: ["/postAttachment"],
    mutationFn: (postData) => createUpdateAttachmentAPI(postData, userDetails),
    onSuccess: (data) => {},
    onError: (err) => {
      console.error(err);
    },
  });

  const handleChange = (event) => {
    setSelectTopic(event.target.value);
  };
  const clearAll = () => {
    setOptions(["", ""]);
    setSelectTopic("");
    setTitle("");
    setContent("");
    setValue("");
    setPeopleTags([]);
    setLabelTags([]);
    setPeopleTags([]);
    setErrors({});
    setLabelTags([]);
    setFiles([]);
  };
  const validateForm = () => {
    let isValid = true;
    let errorsMsg = {};
    if (!selectTopic) {
      errorsMsg.selectTopic = "Please select Topic";
      isValid = false;
    } else if (!title || title.trim() === "") {
      errorsMsg.title = "Please enter title";
      isValid = false;
    } else if (!content || content.trim() === "") {
      errorsMsg.title = "Please enter content";
      isValid = false;
    } else if ((value === "2" || value === "3") && files.length === 0) {
      errorsMsg.filesError = "Please attach at least one file";
      isValid = false;
    }

    setErrors(errorsMsg);
    return isValid;
  };
  const submitPost = (type) => {
    if (!validateForm()) {
      console.log("Form validation failed. Please correct errors.");
    } else {
      const data = {
        topicid: parseInt(selectTopic),
        ur_id: userDetails?.uData?.ur_id,
        title: title,
        content: content,
        posttypeid: 2,
      };
      if (type) {
        data.statusid = 1;
      }
      if (value === "1" && options.length > 0) {
        data.posttypeid = Number(value);
        data.pollduration = getSeconds(pollDuration);
      }
      if ((value === "2" || value === "3") && files.length > 0) {
        data.posttypeid = 3;
      }

      mutate(data);
    }
  };

  const getOptionLabel = (option) => option.title;

  return (
    <>
      <SearchHeader />
      <div className={classes.mainLayout}>
        <Grid container spacing={2} style={{ margin: "0", padding: "0" }}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            style={{ padding: "1rem" }}
          >
            <div className={classes.mainContainer}>
              <h3
                style={{
                  fontWeight: "600",
                  padding: "0rem 0rem 1.5rem",
                  width: "100%",
                }}
              >
                {isLoading ? (
                  <Skeleton className={classes.loaderSkeleton} />
                ) : (
                  <>Create a post</>
                )}
              </h3>
              <Button className={classes.secondaryButtonDraft}>
                <div
                  style={{ display: "flex", gap: "1rem", alignItems: "center" }}
                >
                  <h4>Drafts</h4>
                  <div style={{ backgroundColor: "gray", padding: "0px 6px" }}>
                    <h5 style={{ color: "white" }}>1</h5>
                  </div>
                </div>
              </Button>
            </div>

            {isLoading ? (
              <Skeleton className={classes.loaderSkeletonSlider} />
            ) : (
              <div className={classes.root}>
                <FormControl className={classes.inputLabelStyle}>
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.InputLabelStylemain}
                  >
                    Choose a Topic
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectTopic}
                    label="topic"
                    onChange={handleChange}
                    className={classes.selectStyle}
                  >
                    {data?.map((topic) => (
                      <MenuItem value={topic.ct_id} key={topic.ct_id}>
                        {topic.ct_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div
                  style={{
                    borderRadius: "5px",
                    width: "50%",
                    position: "relative",
                  }}
                >
                  <button
                    className="search__button"
                    title="Search"
                    style={{
                      position: "absolute",
                      top: "1.5rem",
                      left: "1rem",
                    }}
                  >
                    <SearchIcon
                      style={{
                        fontSize: "2.6rem",
                        color: "gray",
                      }}
                    />
                  </button>

                  {!userLoad && peopleList && (
                    <Autocomplete
                      multiple
                      limitTags={3}
                      id="multiple-limit-tags"
                      options={peopleList}
                      value={peopleTags}
                      onChange={(event, newValue) => {
                        setPeopleTags(newValue);
                      }}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Tag people here"
                          className={classes.labelTextStyle}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: "1.5rem" }}>
                          {getOptionLabel(option)}
                        </li>
                      )}
                      className={classes.searchInput}
                    />
                  )}
                </div>
                <div className={classes.subBoxSection}>
                  <Box sx={{ width: "100%", typography: "body1" }}>
                    <div>
                      <Paper
                        component="form"
                        sx={{
                          p: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <InputBase
                          sx={{ ml: 1, flex: 1, fontSize: "1.5rem" }}
                          value={title}
                          name="title"
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="* Title"
                          inputProps={{ "aria-label": "search google maps" }}
                        />
                        <Divider
                          sx={{ height: 28, m: 0.5 }}
                          orientation="vertical"
                        />
                        <IconButtonI
                          color="primary"
                          sx={{ p: "10px" }}
                          aria-label="directions"
                        >
                          0/150
                        </IconButtonI>
                      </Paper>
                      <div className={classes.formControlStyle}>
                        <Textarea
                          placeholder="* Type here…"
                          minRows={3}
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                          startDecorator={
                            <Box
                              sx={{
                                display: "flex",
                                gap: "var(--Textarea-paddingBlock)",
                                pt: "var(--Textarea-paddingBlock)",
                                borderbottom: "1px solid",
                                borderColor: "divider",
                                flex: "auto",
                              }}
                            >
                              <IconButton
                                variant="plain"
                                color="neutral"
                                onClick={(event) =>
                                  setAnchorEl(event.currentTarget)
                                }
                              >
                                <FormatBold className={classes.iconStyle} />
                                <KeyboardArrowDown
                                  fontSize="md"
                                  className={classes.iconStyle}
                                />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={() => setAnchorEl(null)}
                                size="sm"
                                placement="bottom-start"
                                sx={{ "--ListItemDecorator-size": "24px" }}
                              >
                                {["200", "normal", "bold"].map((weight) => (
                                  <MenuItemI
                                    key={weight}
                                    selected={fontWeight === weight}
                                    onClick={() => {
                                      setFontWeight(weight);
                                      setAnchorEl(null);
                                    }}
                                    sx={{ fontWeight: weight }}
                                  >
                                    <ListItemDecorator>
                                      {fontWeight === weight && (
                                        <Check fontSize="sm" />
                                      )}
                                    </ListItemDecorator>
                                    {weight === "200" ? "lighter" : weight}
                                  </MenuItemI>
                                ))}
                              </Menu>
                              <IconButton
                                variant={italic ? "soft" : "plain"}
                                color={italic ? "primary" : "neutral"}
                                aria-pressed={italic}
                                onClick={() => setItalic((bool) => !bool)}
                              >
                                <FormatItalic className={classes.iconStyle} />
                              </IconButton>
                            </Box>
                          }
                          sx={{
                            minWidth: 300,
                            fontWeight,
                            fontSize: "1.5rem",
                            fontStyle: italic ? "italic" : "initial",
                          }}
                        />
                        <div style={{ background: "white", margin: "2rem 0" }}>
                          {(value === "2" || value === "3") && (
                            <FileUploader
                              files={files}
                              setFiles={setFiles}
                              value={value}
                            />
                          )}
                        </div>

                        {value === "1" && (
                          <Poll
                            options={options}
                            setOptions={setOptions}
                            pollDuration={pollDuration}
                            setPollDuration={setPollDuration}
                          />
                        )}

                        <div className={classes.labelSectionStyle}>
                          <Checkbox
                            {...label}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue("2");
                                setFiles([]);
                              }
                            }}
                            checked={"2" === value ? true : false}
                            icon={
                              <Button className={classes.secondaryButton}>
                                <TextSnippetIcon style={{ fontSize: "2rem" }} />
                                Documents
                              </Button>
                            }
                            checkedIcon={
                              <Button className={classes.primaryButton}>
                                <TextSnippetIcon style={{ fontSize: "2rem" }} />
                                Documents
                              </Button>
                            }
                          />
                          <Checkbox
                            {...label}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue("3");
                                setFiles([]);
                              }
                            }}
                            checked={"3" === value ? true : false}
                            icon={
                              <Button className={classes.secondaryButton}>
                                <AddPhotoAlternateIcon
                                  style={{ fontSize: "2rem" }}
                                />
                                Image & Video
                              </Button>
                            }
                            checkedIcon={
                              <Button className={classes.primaryButton}>
                                <AddPhotoAlternateIcon
                                  style={{ fontSize: "2rem" }}
                                />
                                Image & Video
                              </Button>
                            }
                          />
                          <Checkbox
                            {...label}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setValue("1");
                                setFiles([]);
                              }
                            }}
                            checked={"1" === value ? true : false}
                            icon={
                              <Button className={classes.secondaryButton}>
                                <PollIcon style={{ fontSize: "2rem" }} />
                                Poll
                              </Button>
                            }
                            checkedIcon={
                              <Button className={classes.primaryButton}>
                                <PollIcon style={{ fontSize: "2rem" }} />
                                Poll
                              </Button>
                            }
                          />
                        </div>
                        <div className={classes.labelSectionStyle2}>
                          {showText && !labelLoad && labelData && (
                            <div
                              style={{
                                marginTop: "20px",
                                fontSize: "18px",
                                fontWeight: "bold",
                              }}
                            >
                              <Autocomplete
                                multiple
                                limitTags={3}
                                id="multiple-limit-tags"
                                options={labelData}
                                value={labelTags}
                                getOptionLabel={(option) => option.title}
                                onChange={(event, newValue) => {
                                  setLabelTags(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Add labels"
                                    placeholder="Add labels"
                                    className={classes.labelTextStyle}
                                  />
                                )}
                                renderOption={(props, option) => (
                                  <li {...props} style={{ fontSize: "1.5rem" }}>
                                    {getOptionLabel(option)}
                                  </li>
                                )}
                                className={classes.autocompleteStyle}
                              />
                            </div>
                          )}
                          <Button
                            className={classes.secondaryButtonLabel}
                            onClick={handleButtonClick}
                            style={{ borderRadius: "2rem" }}
                          >
                            <AddIcon style={{ fontSize: "2rem" }} />
                            Add Label
                          </Button>

                          {errors.selectTopic && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {errors.selectTopic}
                            </p>
                          )}
                          {errors.title && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {errors.title}
                            </p>
                          )}
                          {errors.filesError && (
                            <p style={{ color: "red", fontSize: "14px" }}>
                              {errors.filesError}
                            </p>
                          )}
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "right",
                            gap: "2rem",
                            margin: "2.5rem 0rem 1rem",
                          }}
                        >
                          <Button
                            className={classes.secondaryButtonClear}
                            onClick={clearAll}
                          >
                            Clear All
                          </Button>
                          <Button
                            className={classes.secondaryButtonLabel}
                            onClick={() => submitPost(1)}
                          >
                            Save as draft
                          </Button>
                          <Button
                            className={classes.primaryButtonLabel}
                            type="submit"
                            disabled={postLoading || attachmentLoading}
                            onClick={() => submitPost()}
                          >
                            {postLoading || attachmentLoading
                              ? "Loading..."
                              : "Post"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className={classes.dialogSection}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className={classes.dialogHeader}
          >
            <div style={{ display: "flex", gap: "1rem" }}>
              {`${userDetails?.languagejson?.languageSelect?.Drafts ||
                "Drafts"}`}
              <div className={classes.dialogFooterButton}>1</div>
            </div>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon style={{ fontSize: "2.5rem" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers style={{ padding: "0" }}>
            <Typography
              style={{
                display: "flex",
                justifyContent: "space-between",
                backgroundColor: "#EDF5FF",
                padding: "1rem 2rem",
              }}
            >
              <div style={{ display: "flex", gap: "1rem" }}>
                <FormSVG style={{ width: "3.5rem", height: "3.5rem" }} />
                <div>
                  <h4
                    style={{ fontSize: "1.8rem", color: "black" }}
                  >{`${userDetails?.languagejson?.languageSelect?.Heading ||
                    "Heading"}`}</h4>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      padding: "1rem 0 0",
                    }}
                  >
                    <AccessTimeFilledIcon
                      style={{ color: "#838383", fontSize: "1.5rem" }}
                    />
                    <p style={{ fontSize: "1.5rem", color: "black" }}>
                      Drafted on 10-01-2024
                    </p>
                  </div>
                </div>
              </div>
              <div className={classes.dialogPaper}>
                <DeleteIcon
                  style={{
                    width: "3.5rem",
                    height: "3.5rem",
                    color: "#838383",
                  }}
                />
              </div>
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            <Button
              autoFocus
              className={classes.actionButtonFollow}
              onClick={handleClose}
            >
              {`${userDetails?.languagejson?.languageSelect?.Close || "Close"}`}
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default CreatePost;
