import React, { useState } from "react";
import { Drawer, List } from "@material-ui/core";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DisabledByDefaultOutlinedIcon from "@mui/icons-material/DisabledByDefaultOutlined";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Switch, FormControlLabel, FormGroup, Paper } from "@mui/material";
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import config from "../../../aws-exports";
import { configUpdateWidgets } from "../../../api/api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";

const Settings = ({
  fetchedWidgets,
  setFetchedWidgets,
  switchAction,
  setSwitchAction,
}) => {
  const queryClient = useQueryClient();

  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    mainContainer: {
      width: "38rem",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#282D37 !important" : "#F3F6F8",
      height: "100%",
      "@media (max-width: 500px)": {
        width: "30rem",
      },
    },
    headerIcon: {
      color: "white",
      fontSize: "2.5rem!important",
      cursor: "pointer",
      margin: "1rem",
      "@media (max-width: 500px)": {
        fontSize: "2.5rem!important",
      },
      "&:focus": {
        backgroundColor: "black",
        borderRadius: 0,
      },
    },
    searchInput: {
      fontSize: "1.5rem",
      color: "inherit",
      backgroundColor: "white",
      borderRadius: "3rem",
      transition: "all 0.2s",
      marginRight: "-3.25rem",
      padding: "5px 36px 5px 15px",
      width: "100%",
      height: "3rem",
      border: "2px solid lightgray",
    },
    switchContainer: {
      padding: "2rem",
      display: "grid",
      gap: "1rem",
      height: "94vh",
      overflow: "auto",
    },
    switchContainerPaper: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0.5rem 1rem",
      paddingRight: "0",
      alignItems: "center",
      borderRadius: "8px!important",
      boxShadow:
        userDetails.themeDark === "dark"
          ? "0px 0px 1px 1px rgb(255 255 255)!important"
          : "0px 0px 4px 0px rgba(102, 102, 102, 0.30)!important",
      backgroundColor:
        userDetails.themeDark === "dark" ? "#3F434D !important" : "white",
      color: userDetails.themeDark === "dark" ? "white !important" : "black",
    },
    headerSection: {
      backgroundColor: `var(--color-primary)`,
      color: "white",
      boxShadow: "0px 4px 4px 0px rgba(102, 102, 102, 0.30)!important",
    },
  }));
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [listOptions, setListOptions] = useState({});

  const keysDesc = {
    all_courses: {
      displayname: `${userDetails?.languagejson?.Home_Page?.All_Courses ||
        "All Courses"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_all_the_courses_that_are_available_in_the_platform ||
        "This widget displays all the courses that are available in the platform."}`,
      enable: config?.home?.all_courses,
    },
    assigned: {
      displayname: `${userDetails?.languagejson?.Home_Page?.assigned ||
        "Assigned Content"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_all_the_assigned_that_are_available_in_the_platform ||
        "This widget displays all the assigned that are available in the platform."}`,
      enable: config?.home?.assigned,
    },
    content_repository: {
      displayname: `${userDetails?.languagejson?.Home_Page
        ?.Content_Repository || "Content Repository"}`,
      desc: `${userDetails?.languagejson?.General_Terms
        ?.This_widget_displays_all_the_individual_content_materials_that_are_available_in_the_platform ||
        "This widget displays all the individual content materials that are available in the platform."}`,
      enable: config?.home?.content_repository,
    },
    completed_course: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Completed_Courses ||
        "Completed Courses"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_the_courses_that_are_completed_by_the_learner ||
        "This widget displays the courses that are completed by the learner."}`,
      enable: config?.home?.completed_course,
    },
    completed_session: {
      displayname: `${userDetails?.languagejson?.Home_Page
        ?.Completed_Sessions || "Completed Sessions"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_all_the_live_sessions_that_completed_by_the_Learner ||
        "This widget displays all the live sessions that completed by the Learner."}`,
      enable: config?.home?.completed_session,
    },
    all_topics: {
      displayname: `${userDetails?.languagejson?.Home_Page?.All_Topics ||
        "All Topics"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_all_the_topics_that_are_in_the_platform ||
        "This widget displays all the topics that are in the platform."}`,
      enable: config?.home?.all_topics,
    },
    continue_learning: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Continue_Learning ||
        "Continue Learning"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_all_the_courses_that_are_in_progress_by_the_Learner ||
        "This widget displays all the courses that are in progress by the Learner."}`,
      enable: config?.home?.continue_learning,
    },
    dashboard_banner: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Dashboard_Banner ||
        "Dashboard Banners"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_important_announcements_branding_elements_marketing_and_sales_updates ||
        "This widget displays important announcements, branding elements, marketing and sales updates."}`,
      enable: config?.home?.dashboard_banner,
    },
    featured_courses: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Featured_Courses ||
        "Featured Courses"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_the_courses_that_are_highlighted_or_emphasized_by_the_organization ||
        "This widget displays the courses that are highlighted or emphasized by the organization."}`,
      enable: config?.home?.featured_courses,
    },
    learning_path: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Learning_path ||
        "Learning Path"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_provides_the_Learner_a_sequential_series_of_courses_and_content_materials ||
        "This widget provides the Learner a sequential series of courses and content materials, that are designed to lead the learner from a starting point to a defined proficiency in specific skills."}`,
      enable: config?.home?.learning_path,
    },
    my_progress_tracker: {
      displayname: `${userDetails?.languagejson?.Course_View
        ?.My_Courses_Progress_Tracker || "My Progress Tracker"}`,
      desc: `${userDetails?.languagejson?.Course_View
        ?.This_widget_displays_the_Learner_Progression_Analytics_in_the_form_of_Pie_and_bar_graphs ||
        "This widget displays the Learner's Progression Analytics in the form of Pie and bar graphs."}`,
      enable: config?.home?.my_progress_tracker,
    },
    popular_session: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Popular_Session ||
        "Popular Sessions"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_the_Popular_Live_sessions_that_are_created_in_the_platform ||
        "This widget displays the Popular Live sessions that are created in the platform."}`,
      enable: config?.home?.popular_session,
    },
    recently_viewed: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Recently_Viewed ||
        "Recently Viewed"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_all_the_courses_and_content_viewed_by_the_Learner_recently ||
        "This widget displays all the courses and content viewed by the Learner recently."}`,
      enable: config?.home?.recently_viewed,
    },
    registered_session: {
      displayname: `${userDetails?.languagejson?.Home_Page
        ?.Registered_Sessions || "Registered Sessions"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_the_Live_sessions_that_are_registered_by_the_learner ||
        "This widget displays the Live sessions that are registered by the learner."}`,
      enable: config?.home?.registered_session,
    },
    upcoming_session: {
      displayname: `${userDetails?.languagejson?.Home_Page?.Upcoming_Session ||
        "Upcoming Sessions"}`,
      desc: `${userDetails?.languagejson?.Home_Page
        ?.This_widget_displays_the_upcoming_live_sessions_on_the_platform ||
        "This widget displays the upcoming live sessions on the platform."}`,
      enable: config?.home?.upcoming_session,
    },
  };

  useEffect(() => {
    let list = {};
    for (const key in keysDesc) {
      if (keysDesc[key].enable) {
        // Add the key with name and desc properties
        list[key] = {
          name: key,
          displayname: keysDesc[key].displayname,
          desc: keysDesc[key].desc,
        };
      }
    }
    setListOptions(list);
  }, [fetchedWidgets]);

  const { mutate: updateConfig } = useMutation({
    mutationFn: (data) => configUpdateWidgets(data, userDetails),
    onSuccess: (response) => {
      queryClient.setQueryData(["configUpdateWidgets"], (old) => {
        return {
          ...old,
          oid: config.aws_org_id,
          widgetJSON: fetchedWidgets,
          ur_id: userDetails?.uData?.ur_id,
        };
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["configDetails"] });
    },
  });

  const toggleDrawer = (open) => () => {
    setIsOpen(open);
    if (!open) {
      if (switchAction) {
        setSwitchAction(false);
      } else {
        setSwitchAction(true);
      }
    }
  };

  const Android12Switch = styled(Switch)(({ theme }) => ({
    padding: 8,
    height: "40px",
    width: "61px",
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      "&:before, &:after": {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&:before": {
        content: '"ON"',
        left: 10,
        top: "22px",
        fontSize: "10.5px",
        color: "white",
      },
      "&:after": {
        right: "12.5px",
        top: "22px",
        content: '"OFF"',
        fontSize: "10px",
        color: "white",
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 22,
      height: 22,
    },
  }));

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      classes={{ popper: className }}
      placement="left"
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1.2rem",
    },
  }));

  const handleSwitchChange = (label) => async (event) => {
    const tempObject = {
      ...fetchedWidgets,
      [label]: event.target.checked,
    };
    if (tempObject) {
      await updateConfig({
        oid: config.aws_org_id,
        widgetJSON: tempObject,

        ur_id: userDetails?.uData?.ur_id,
      });
    }
    setFetchedWidgets(tempObject);
  };

  return (
    <div>
      <BootstrapTooltip
        title={`${userDetails?.languagejson?.Home_Page
          ?.Personalized_dashboard || "Personalize Dashboard"}`}
      >
        <SettingsOutlinedIcon
          onClick={toggleDrawer(true)}
          className={classes.headerIcon}
        />
      </BootstrapTooltip>
      <Drawer anchor="right" open={isOpen} onClose={toggleDrawer(false)}>
        <div role="presentation" className={classes.mainContainer}>
          <List className={classes.headerSection}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0rem 1rem",
              }}
            >
              <p style={{ fontSize: "2rem" }}>{`${userDetails?.languagejson
                ?.Home_Page?.Personalized_dashboard ||
                "Personalize Dashboard"}`}</p>
              <DisabledByDefaultOutlinedIcon
                onClick={toggleDrawer(false)}
                style={{ fontSize: "2.2rem", cursor: "pointer" }}
              />
            </div>
          </List>

          <List className={classes.switchContainer}>
            {Object.keys(listOptions).map((key) => (
              <Paper className={classes.switchContainerPaper} key={key}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <p>{listOptions[key].displayname}</p>
                  <BootstrapTooltip title={listOptions[key].desc}>
                    <InfoOutlinedIcon
                      style={{
                        fontSize: "1.8rem",
                        color: "var(--color-button-background)",
                      }}
                    />
                  </BootstrapTooltip>
                </div>
                <FormGroup>
                  <FormControlLabel
                    style={{ margin: "0" }}
                    control={
                      <Android12Switch
                        checked={fetchedWidgets[key]}
                        onChange={handleSwitchChange(key)}
                      />
                    }
                  />
                </FormGroup>
              </Paper>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
};

export default Settings;
