export default function validateSignUp(values) {
  let errors = {};
  if (!values.name) {
    errors.name = "First name is required";
  }
  if (!values.last_name) {
    errors.last_name = "Last name is required";
  }
  if (!values.phoneNumber) {
    errors.phoneNumber = "Phone Number is required";
  }
  if (values.phoneNumber) {
    /* if (values.phoneNumber.length < 13 || values.phoneNumber.length > 15) {
      errors.phoneNumber = "Enter valid phone number";
    } */ /* else */

    if (!/^[6-9]\d{9}$/.test(values.phoneNumber)) {
      errors.phoneNumber = "Enter valid phone number";
    }
  }

  if (!values.email) {
    errors.email = "Email address is required";
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = "Email address is invalid";
  }

  if (!values.password) {
    errors.password = "Password is required";
  } else if (values.password.length < 8) {
    errors.password = "Password must be 8 or more characters";
  } else if (!/\d/.test(values.password)) {
    errors.password = "Password must contain atleast 1 number";
  } else if (!/[!@#$%&?]/g.test(values.password)) {
    errors.password = "Password must contain atleast 1 special character";
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = "Password is required";
  } else if (values.confirmPassword.length < 8) {
    errors.confirmPassword = "Password must be 8 or more characters";
  } else if (!/\d/.test(values.confirmPassword)) {
    errors.confirmPassword = "Password must contain atleast 1 number";
  } else if (!/[!@#$%&?]/g.test(values.confirmPassword)) {
    errors.confirmPassword =
      "Password must contain atleast 1 special character";
  }
  // else if (!/[A-Z]/g.test(values.password)) {
  //   errors.password = "Password must contain atleast 1 capitol letter";
  // }
  if (values.password !== values.confirmPassword) {
    errors.password = "Password does't match";
  }

  return errors;
}
