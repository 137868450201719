import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import { Skeleton } from "@material-ui/lab";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { makeStyles } from "@material-ui/core/styles";
import { Button, Grid, Paper, Box, Link } from "@mui/material";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import Person2Icon from '@mui/icons-material/Person2';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import UserBanner from "../../assets/Balance Available Coins.png";
//AboutBanner.png
//AboutProfile.png
import avatar from "../../assets/Balance Available Coins.png";
import ReplyIcon from '@mui/icons-material/Reply';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';

import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

function TeamsProfile({setShow}) {
  const userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    root: {
      // flexGrow: 1,
    },
    mainLayout: {
      margin: "0rem 12rem",
      width: "auto!important",
      fontSize: "1.6rem!important",
      "@media (max-width: 900px)": {
        margin: "0rem",
      },
    },
    loaderSkeletonSlider: {
      width: "100%",
      height: "25rem",
    },
    actionButton: {
      backgroundColor: userDetails.themeDark === "dark"? "#353944": "#EBEBEB",
      borderRadius: "1.5rem!important",
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
      margin: "1rem!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      color: userDetails.themeDark === "dark"? "lightgray!important": "black!important",
      gap: "1rem",
      "&:active": {
      color: "var(--color-button-background)!important!important",
      },
      "& > svg": {
        fontSize: "3rem", color: userDetails.themeDark === "dark"? "lightgray!important": "black!important",
        "&:active": {
          color: "var(--color-button-background)!important",
        },
      },
    },
    actionButtonFollow: {
      borderRadius: "3rem!important",
      border: "2px solid!important",
      borderColor: "var(--color-button-background)!important",
      backgroundColor: "transparent!important",
      margin: "0!important",
      padding: "0px 10px!important",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      "&:active": {
        color: "var(--color-button-background)!important",
      },
    },
    profileSection: {
      display: "flex",
      alignItems: "center",
      gap: "3rem",
      margin: "-1rem 12rem 0rem",
      "@media (max-width: 900px)": {
        margin: "-1rem 0rem 0rem",
        flexWrap: "wrap",
        gap: "2rem",
      },
    },
    firstTrend: {
      color: userDetails.themeDark === "dark"? "white!important": "black!important",
      padding: "1rem",
      backgroundColor: userDetails.themeDark === "dark"? "#353944": "#EBEBEB",
      borderRadius: "4px",
    },
    socialIcons: {
      fontSize:"2.5rem!important",
      color: userDetails.themeDark === "dark"? "white!important": "black!important",
    },
    leftPaper: {
      width:"100%",
      margin: "1rem 0rem!important",
      boxShadow: userDetails.themeDark === "dark"? "none!important": "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    rightPaper: {
      width:"100%",
      margin: "1rem 0rem!important",
      boxShadow: userDetails.themeDark === "dark"? "none!important": "0px 0px 0px 1px rgba(102, 102, 102, 0.3)!important",
    },
    bannerLayout: {
      backgroundImage: `url(${UserBanner})`,
      height: "12rem",
      width: "100vw",
      margin: "-2.5rem -8rem 0rem",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
    aboutProfile: {
      width: "9rem",
      borderRadius: "7rem",
      height: "9rem",
    },
    tabButton: {
      textTransform: "capitalize!important",
      fontSize: "2rem!important",
      fontFamily: `myfontregular!important`,
      fontWeight: "800!important",
      color: userDetails.themeDark === "dark"? "white!important": "black",
      "@media (max-width: 450px)": {
        padding: "0.5rem!important",
        maxWidth: "18rem!important",
      },
    },
    searchInput: {
      borderRadius: "4px!important", 
      boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)",
      width: "70%",
      fontSize: "1.5rem!important",
      textTransform: "capitalize!important",
      justifyContent: "flex-start!important",
      backgroundColor: userDetails.themeDark === "dark"? "#1c2027!important": "#fff!important",
      color: userDetails.themeDark === "dark"? "#fff!important": "#000!important",
    },
    userMenuItems: {
      display: "flex",
      justifyContent: "space-between",
      margin: "2rem",
      flexWrap: "wrap",
    },
    userMenuButton: {
      fontSize: "1.4rem!important",
      textTransform: "capitalize!important",
      gap: "0.5rem",
      color: userDetails.themeDark === "dark"? "lightgray!important" : "gray!important",
    },
    headerSection: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      flexWrap: "wrap",
    },
  }));

  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(true);

  // PRE S
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.scrollTo(0, 0);
      getlearningContent();
      return () => {};
    }
  }, []);

  const getlearningContent = async () => {
    // const bodyParam = {
    //   body: {
    //     ur_id: userDetails?.uData?.ur_id,
    //     schema: config.schema,
    //     lpid: userDetails?.learning?.lpid,
    //   },
    //   headers: {
    //     "Content-Type": "application/json",
    //     Accept: "application/json",
    //     Authorization: userDetails?.accessToken	
    //   },
    // };

    // try {
    //   const response = await API.post(
    //     config.aws_cloud_logic_custom_name,
    //     "/getLearningContent",
    //     bodyParam
    //   );
    try {
      const requestBody = {
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        lpid: userDetails?.learning?.lpid,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getLearningContent?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
	         Accept: "application/json",	
           Authorization: userDetails?.accessToken
          },
        }
      );
      if (response && response.statusCode === 200) {
        response.body.lname = userDetails?.learning?.lname;
        console.log("response", response);
        setIsLoading(false);
      } else {
        response.body.lname = userDetails?.learning?.lname;
        response.body.res = [];
        setIsLoading(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };

  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  

  return (
    <>
      <div className={classes.bannerLayout}>{/* Banner Section*/}</div>
      <div className={classes.profileSection}>
        <img className={classes.aboutProfile} src={avatar} alt="Avatar" />
        <p style={{fontSize: "2.6rem", fontWeight: "900"}}>Team number1</p>
        <Button className={classes.actionButtonFollowAbout}>Join</Button>
        <ReplyIcon style={{transform: "scaleX(-1)",fontSize: "3rem"}}/>
      </div>
      <Box sx={{ width: '100%', typography: 'body1'}} className={classes.mainLayout}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Posts" value="1" className={classes.tabButton} />
              <Tab label="Media" value="2" className={classes.tabButton} />
            </TabList>
          </Box>
          <TabPanel value="1" style={{padding: "1rem 0rem"}}>
            <div>
              {isLoading ? (
                <Skeleton className={classes.loaderSkeletonSlider} />
              ) : (
                <div className={classes.root}>
                  <div>
                    <Grid container spacing={2} style={{margin: '0', padding: '0'}}>
                      <Grid item xs={12} sm={12} md={8} lg={8} style={{ padding: '1rem'}}>
                        <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              height: "auto",
                            },
                          }}
                        >
                          <Paper elevation={3} className={classes.leftPaper}>
                            <Button style={{backgroundColor: "white", borderRadius: "3rem", boxShadow: "0px 0px 3px 1px rgba(102, 102, 102, 0.3)", margin: "1rem", minWidth: "3rem"}}>
                              <PersonOutlineOutlinedIcon style={{fontSize: "3rem", color: "black"}} />
                            </Button>
                            <Button
                              className={classes.searchInput}
                              onClick={()=>setShow(true)}>
                              <AddOutlinedIcon style={{fontSize: "2.5rem", color: "gray"}} />
                              Create Post
                            </Button>
                          </Paper>
                          <Paper elevation={3} className={classes.leftPaper}>
                            <Button className={classes.actionButton}>
                              <WhatshotIcon /> Hot
                            </Button>
                            <Button className={classes.actionButton}>
                              Topics <KeyboardArrowDownIcon />
                            </Button>
                            <Button className={classes.actionButton}>
                              <ArrowCircleUpIcon /> New
                            </Button>
                            <Button className={classes.actionButton}>
                              Today <KeyboardArrowDownIcon />
                            </Button>
                          </Paper>
                          <Paper elevation={3} className={classes.leftPaper}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: "2rem",}}>
                              <div>
                                <div className={classes.headerSection}>
                                  <Person2Icon style={{fontSize:"2.5rem",}} />
                                  <h3>User_Name</h3>
                                  <FiberManualRecordIcon style={{fontSize:"0.8rem",color: "gray"}} />
                                  <p style={{color: "gray"}} >Posted by Liju 10 hours ago</p>
                                </div>
                                <h2 style={{margin: "1rem 0rem",}}>Heading of the post</h2>
                                <p>Some random text Some random text Some random text Some random text Some random text Some random text
                                Some random text Some random text Some random text Some random text Some random textSome random text
                                </p>
                              </div>
                            </div>
                            <div className={classes.userMenuItems}>
                              <Button className={classes.userMenuButton}><FavoriteBorderOutlinedIcon className={classes.socialIcons} />10k</Button>
                              <Button className={classes.userMenuButton}><ChatBubbleOutlineOutlinedIcon className={classes.socialIcons} />1k comm</Button>
                              <Button className={classes.userMenuButton}><ShareOutlinedIcon className={classes.socialIcons} />Share</Button>
                              <Button className={classes.userMenuButton}><SaveOutlinedIcon className={classes.socialIcons} />Save</Button>
                              <Button><MoreHorizOutlinedIcon className={classes.socialIcons} /></Button>
                            </div>
                          </Paper>
                          <Paper elevation={3} className={classes.leftPaper}>
                            <div style={{display: "flex", justifyContent: "space-between", alignItems: "baseline", margin: "2rem",}}>
                              <div>
                                <div className={classes.headerSection}>
                                  <Person2Icon style={{fontSize:"2.5rem",}} />
                                  <h3>User_Name</h3>
                                  <FiberManualRecordIcon style={{fontSize:"0.8rem",color: "gray"}} />
                                  <p style={{color: "gray"}} >Posted by Liju 10 hours ago</p>
                                </div>
                                <h2 style={{margin: "1rem 0rem",}}>Heading of the post</h2>
                                <p>Some random text Some random text Some random text Some random text Some random text Some random text
                                Some random text Some random text Some random text Some random text Some random textSome random text
                                </p>
                              </div>
                            </div>
                            <div className={classes.userMenuItems}>
                              <Button className={classes.userMenuButton}><FavoriteBorderOutlinedIcon className={classes.socialIcons} />10k</Button>
                              <Button className={classes.userMenuButton}><ChatBubbleOutlineOutlinedIcon className={classes.socialIcons} />1k comm</Button>
                              <Button className={classes.userMenuButton}><ShareOutlinedIcon className={classes.socialIcons} />Share</Button>
                              <Button className={classes.userMenuButton}><SaveOutlinedIcon className={classes.socialIcons} />Save</Button>
                              <Button><MoreHorizOutlinedIcon className={classes.socialIcons} /></Button>
                            </div>
                          </Paper>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4} lg={4} style={{ padding: '1rem'}}>
                      <Box
                          sx={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            '& > :not(style)': {
                              m: 1,
                              height: "auto",
                            },
                          }}
                        >
                          <Paper elevation={3} className={classes.rightPaper}>
                            <div className={classes.firstTrend}>
                              <h4>About Team</h4>
                            </div>
                            <div style={{padding: '1rem',}}>
                              <h4>Welcome to the team1!</h4>
                            </div>
                            <div style={{padding: '1rem',display: "flex", gap: "1rem", alignItems: "center"}}>
                              <AccessTimeIcon style={{fontSize:"3rem",}} />
                              <p>Created on Dec 31, 2021</p>
                            </div>
                            <div style={{display: "flex", gap: "4rem", padding: "1rem", borderTop: "1px solid lightgray"}}>
                              <div>
                                <h4>1.1m</h4>
                                <p>Members</p>
                              </div>
                              <div>
                                <div style={{display: "flex", gap: "0.5rem", alignItems: "center"}}>
                                  <FiberManualRecordIcon style={{fontSize:"1rem",color: "green"}} /><h4>7.5k</h4>
                                </div>
                                <p>Online</p>
                              </div>
                            </div>
                          </Paper>
                          <Paper elevation={3} className={classes.rightPaper}>
                            <div className={classes.firstTrend}>
                              <h4>Team1 rules</h4>
                            </div>
                            <div style={{padding: '1rem',}}>
                              <h4>1. Role one</h4>
                              <p style={{padding: '0.5rem 1.8rem',}}>No sharing of FB/Instagram pages or links that disclose the personal information</p>
                              <h4>2. Role two</h4>
                              <p style={{padding: '0.5rem 1.8rem',}}>No sharing of FB/Instagram pages or links that disclose the personal information</p>
                            </div>
                          </Paper>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel value="2" style={{padding: "1rem 0rem"}}>Item Two</TabPanel>
        </TabContext>
      </Box>
    </>
  );
}
export default TeamsProfile;
