import React, { useState, useRef } from "react";
import "./FormativeQuiz.scss";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import config from "../../aws-exports";
import { useQueryParams } from "../../Hooks/useQueryParams";
import styled from "styled-components";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
const StyledScoreContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .show-score-header {
    box-shadow: ${({ primary }) => (primary ? "green" : "red")} 0px 0px 0px 5px;
    width: 150px;
    height: 150px;
    color: ${({ primary }) => (primary ? "green" : "red")};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const FormativeQuizQuestionBank = ({
  question,
  setQuestion,
  loading,
  setLoading,
  startQuiz,
  courseDetails,
  qpage,
  ...rest
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [unAnsweredPopup, setUnAnsweredPopup] = useState(false);
  const userDetails = useSelector(authData);
  const gotScore = useRef(rest?.curObject?.score ?? []);
  const query = useQueryParams();
  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 150,
    width: 350,
    bgcolor: "background.paper",
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  };
  const handleAnswerClick = (idx) => {
    const currentAnsquestion = [...question];
    currentAnsquestion[currentQuestion].iopts.map((item) => {
      item.youAnswered = false;
      return item;
    });
    currentAnsquestion[currentQuestion].iopts[idx].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx].youAnswered === true) {
      delete currentAnsquestion[currentQuestion].skip;
    }
    currentAnsquestion[currentQuestion].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx].correct) {
      currentAnsquestion[currentQuestion].answeredOption = true;
      currentAnsquestion[currentQuestion].marksGot =
        currentAnsquestion[currentQuestion].marks;
    } else {
      currentAnsquestion[currentQuestion].answeredOption = false;
      currentAnsquestion[currentQuestion].marksGot = 0;
    }
    setQuestion(currentAnsquestion);
  };
  const handleNextQuestion = () => {
    setQuestion((prevQues) => {
      let yourAns = prevQues[currentQuestion].youAnswered;
      if (yourAns === undefined || yourAns == null) {
        prevQues[currentQuestion].skip = true;
      }
      return prevQues;
    });
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };
  const handleNextQuestionAns = () => {
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestionAns = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };
  const gotoQuestion = (idx) => {
    setCurrentQuestion(idx);
  };
  const handleSubmitQuiz = (question) => {
    if (
      question.filter((e) => e.youAnswered === undefined || false).length > 0
    ) {
      //newly added
      setUnAnsweredPopup(true);
    } else {
      let score = 0;
      let rightAnswers = question.filter((item) => item.answeredOption);
      score = rightAnswers.reduce((sum, obj) => sum + obj.marksGot, 0);
      rest.setScore(score);
      if (scoreAchieved(score)) {
        setCurrentQuestion(0);
        rest.setShowScore(true);
        rest.setMarkComplete(true);
        if (rest.curObject.op !== 2) {
          gotScore.current.push(score);
          let questionsJSON = {};
          questionsJSON.question = question;
          questionsJSON.cutoff = rest.cutOff;
          questionsJSON.totalmarks = rest.totalMarks;
          let obj = {};
          obj.json = questionsJSON;
          let CourseQuiz = { ...courseDetails };
          CourseQuiz.nuggets[rest.curNugIndex].objects[rest.curObjIndex].score =
            gotScore.current;
          CourseQuiz.nuggets[rest.curNugIndex].objects[
            rest.curObjIndex
          ].quiz_json = obj;
          CourseQuiz.nuggets[rest.curNugIndex].objects[rest.curObjIndex].op = 2;
          rest.setCourseDetails(CourseQuiz);
          rest.syncUserProgresswhilequiz(score, undefined, questionsJSON);
        }
      } else {
        if (rest.curObject.op !== 2) {
          handleScoreSubmit(score, 1);
        }
        rest.setMarkComplete(true);
        rest.setShowScore(true);
        setCurrentQuestion(0);
      }
      rest.setTimerPalyPause("pause");
    }
  };
  async function handleScoreSubmit(score, op) {
      setLoading(true);
    const jsonString = JSON.stringify({
      emailid: userDetails.email,
      pid: courseDetails.cid,
      key: courseDetails.tid,
      email: userDetails.username,
      oid: config.aws_org_id,
      uname: userDetails.name,
      tenant: userDetails.locale,
      op: op,
      score,
      obj_id: rest.curObject.oid,
      obtime: (new Date().getTime() - rest.startTime) / 1000,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      );
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }
  const scoreAchieved = (score) => {
    if ((score / rest.totalMarks) * 100 >= parseInt(rest.cutOff)) return true;
    return false;
  };
  function shuffleArray(arr) {
    let shuffledArr = [...arr];
    for (let i = shuffledArr.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArr[i], shuffledArr[j]] = [shuffledArr[j], shuffledArr[i]];
    }
    return shuffledArr;
  }
  async function handleReTry(obj) {
    const jsonString = JSON.stringify( {
      quizid: obj.oid,
      oid: config.aws_org_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/getQuiz",
        bodyParam
      );
      const {
        qitems,
      } = response;
      let questTypeFour = shuffleArray(qitems);
      setQuestion(questTypeFour);
      setCurrentQuestion(0);
      rest.setScore(0);
      rest.setShowScore(false);
    } catch (error) {
      console.error(error);
    }
  }
  return (
    <React.Fragment>
      {!loading && startQuiz && !rest.showScore && (
        <div className="quiz_container">
          <div className="quiz_wrapper">
            <div className="quiz_wrapper_ques_ans">
              <div className="quiz_wrapper_ques_ans--question">
                <div>
                  Q {currentQuestion + 1}/{question?.length}
                </div>
                <div>
                  <p>{question[currentQuestion]?.istem}</p>
                </div>
              </div>
              {question[currentQuestion].imageurl && (
                <div className="quiz_wrapper_ques_ans_img-container">
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                      "id"
                    )}/${question[currentQuestion].imageurl}`}
                    alt=""
                    style={{ width: "30rem" }}
                  />
                </div>
              )}
              <div className="quiz_wrapper_ques_ans--answers">
                {question[currentQuestion]?.iopts.map((item, index) => (
                  <div
                    key={index}
                    style={{ cursor: "pointer" }}
                    className={`ans-btn ${
                      item.youAnswered && !item.wrong ? "selected-answer" : ""
                    }
                    ${item.youAnswered && item.wrong ? "error" : ""}
                    ${
                      question[currentQuestion].checkAnswer && item.correct
                        ? "ans_green"
                        : ""
                    }
                    `}
                    onClick={() => handleAnswerClick(index)}
                  >
                    {item.content}
                  </div>
                ))}
              </div>
              <div>
                {`${userDetails?.languagejson?.Course_View?.Marks_alloted ||
                  "Marks alloted"}`}{" "}
                : {question[currentQuestion]?.marks}
              </div>
            </div>
            <div className="quiz_wrapper_grow" />
            <div className="quiz_wrapper--actions">
              <button
                className="btn btn-outline primary"
                onClick={handlePreviousQuestion}
                disabled={currentQuestion === 0 ? true : false}
              >
                {`${userDetails?.languagejson?.Course_View?.Previous ||
                  "Previous"}`}
              </button>
              <div className="left-actions">
                {currentQuestion !== question?.length - 1 &&
                  question[currentQuestion].youAnswered && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestion}
                      disabled={
                        currentQuestion === question?.length - 1 ? true : false
                      }
                    >
                      {`${userDetails?.languagejson?.Course_View?.Next ||
                        "Next"}`}
                    </button>
                  )}
                {currentQuestion !== question?.length - 1 &&
                  !question[currentQuestion].next &&
                  !question[currentQuestion].youAnswered && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestion}
                      disabled={
                        currentQuestion === question?.length - 1 ? true : false
                      }
                    >
                      {`${userDetails?.languagejson?.Course_View?.Skip ||
                        "Skip"}`}
                    </button>
                  )}
                {currentQuestion === question?.length - 1 && (
                  <button
                    className="btn btn-contained primary"
                    onClick={() => {
                      if (qpage !== undefined && qpage === "object") {
                        rest.setStartQuiz(false);
                        setCurrentQuestion(0);
                      } else {
                        handleSubmitQuiz(question);
                      }
                    }}
                  >
                    {`${userDetails?.languagejson?.Course_View?.Submit ||
                      "Submit"}`}
                  </button>
                )}
              </div>
            </div>
          </div>
          <hr className="divider" />
          <div className="quiz_container--footer">
            {question?.map((item, index) => (
              <button
                key={item.iid}
                style={{ cursor: "pointer" }}
                className={`btn contained ${item.skip ? "border" : ""} ${
                  item.youAnswered ? "green" : ""
                }`}
                onClick={() => gotoQuestion(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}
      {rest.showScore && (
        <StyledScoreContainer primary={scoreAchieved(rest.score)}>
          <h1 className="show-score-header">
            <span>
              {rest?.curObject &&
              rest?.curObject?.scores &&
              rest?.curObject?.scores[rest?.curObject?.score?.length - 1]
                ? rest?.curObject?.scores[rest?.curObject?.score?.length - 1]
                : rest?.score}
              /
              {rest.totalMarks
                ? rest.totalMarks
                : courseDetails.nuggets[rest.curNugIndex].objects[
                    rest.curObjIndex
                  ]?.quiz_json?.json?.totalmarks}
            </span>
          </h1>
          {scoreAchieved(rest.score) && (
            <div style={{ fontWeight: "600", padding: "20px" }}>{`${userDetails
              ?.languagejson?.Course_View?.Congratulations_on_course ||
              "Congratulation on Successfully completing the"} ${
              courseDetails.ttitle
            } `}</div>
          )}
          {(!scoreAchieved(rest.score) || rest.curObject.op === 1) && (
            <div style={{ margin: "1rem 0" }}>
              <button
                className="button-3"
                onClick={() => handleReTry(rest.curObject)}
              >
                {`${userDetails?.languagejson?.Course_View?.Retake_exam ||
                  "Retake exam"}`}
              </button>
            </div>
          )}
          {scoreAchieved(rest.score) && rest.curObject.op === 2 && (
            <div style={{ margin: "1rem 0" }}>
              <button
                className="button-3"
                onClick={() => {
                  rest.nextObject();
                  rest.syncUserProgress();
                }}
              >
                {`${userDetails?.languagejson?.Course_View?.Next || "Next"}`}
              </button>
            </div>
          )}
        </StyledScoreContainer>
      )}
      {rest.showAnswers && rest.curObject?.quiz_json !== null && (
        <div>
          <div className="quiz_container">
            <div className="quiz_wrapper">
              <div className="quiz_wrapper_ques_ans">
                <div className="quiz_wrapper_ques_ans--question">
                  <div>
                    Q {currentQuestion + 1}/
                    {
                      courseDetails.nuggets[rest.curNugIndex].objects[
                        rest.curObjIndex
                      ]?.quiz_json?.json?.question?.length
                    }
                  </div>
                  <div>
                    <p>
                      {
                        courseDetails.nuggets[rest.curNugIndex].objects[
                          rest.curObjIndex
                        ]?.quiz_json?.json?.question[currentQuestion]?.istem
                      }
                    </p>
                  </div>
                </div>
                {courseDetails.nuggets[rest.curNugIndex].objects[
                  rest.curObjIndex
                ]?.quiz_json?.json?.question[currentQuestion]?.imageurl && (
                  <div className="quiz_wrapper_ques_ans_img-container">
                    <img
                      src={`https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                        "id"
                      )}/${
                        courseDetails.nuggets[rest.curNugIndex].objects[
                          rest.curObjIndex
                        ]?.quiz_json?.json?.question[currentQuestion]?.imageurl
                      }`}
                      alt=""
                      style={{ width: "30rem" }}
                    />
                  </div>
                )}
                <div className="quiz_wrapper_ques_ans--answers">
                  {courseDetails.nuggets[rest.curNugIndex].objects[
                    rest.curObjIndex
                  ]?.quiz_json?.json?.question[currentQuestion]?.iopts.map(
                    (item, index) => (
                      <>
                        <div
                          key={index}
                          style={{ cursor: "pointer" }}
                          className={`ans-btn 
                          ${item.correct ? "ans_green" : ""}
                          ${item.youAnswered && item.correct ? "ans_green" : ""}
                          ${item.youAnswered && !item.correct ? "error" : ""}
                          `}
                        >
                          {item.content}
                        </div>
                      </>
                    )
                  )}
                </div>
                <div>
                  {`${userDetails?.languagejson?.Course_View?.Marks_alloted ||
                    "Marks alloted"}`}{" "}
                  : {question[currentQuestion]?.marks} :{" "}
                  {
                    courseDetails.nuggets[rest.curNugIndex].objects[
                      rest.curObjIndex
                    ]?.quiz_json?.json?.question[currentQuestion]?.marks
                  }
                </div>
              </div>
              <div className="quiz_wrapper_grow" />
              <div className="quiz_wrapper--actions">
                <button
                  className="btn btn-outline primary"
                  onClick={handlePreviousQuestionAns}
                  disabled={currentQuestion === 0 ? true : false}
                >
                  {`${userDetails?.languagejson?.Course_View?.Previous ||
                    "Previous"}`}
                </button>
                <div className="left-actions">
                  {currentQuestion !==
                    courseDetails.nuggets[rest.curNugIndex].objects[
                      rest.curObjIndex
                    ]?.quiz_json?.json?.question?.length -
                      1 && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestionAns}
                      disabled={
                        currentQuestion ===
                        courseDetails.nuggets[rest.curNugIndex].objects[
                          rest.curObjIndex
                        ]?.quiz_json?.json?.question?.length -
                          1
                          ? true
                          : false
                      }
                    >
                      {`${userDetails?.languagejson?.Course_View?.Next ||
                        "Next"}`}
                    </button>
                  )}
                  {rest.showAnswers && (
                    <button
                      className="btn btn-contained primary"
                      onClick={() => {
                        rest.setShowAnswers(false);
                        setCurrentQuestion(0);
                        rest.score && rest.setShowScore(true);
                      }}
                    >
                      {`${userDetails?.languagejson?.Course_View
                        ?.Close_Answers || "Close Answers"}`}
                    </button>
                  )}
                </div>
              </div>
            </div>
            <hr className="divider" />
          </div>
        </div>
      )}
      <Modal open={unAnsweredPopup}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {`${userDetails?.languagejson?.Course_View?.un_answered ||
              "You have some unanswered questions"}`}
          </Typography>
          <button
            className="okButton"
            onClick={() => setUnAnsweredPopup(false)}
          >
            {`${userDetails?.languagejson?.Course_View?.ok || "Ok"}`}
          </button>
        </Box>
      </Modal>
    </React.Fragment>
  );
};
export default FormativeQuizQuestionBank;
