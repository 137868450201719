import React, { useEffect } from "react";
import Header from "../../../components/Header/Header";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { useHistory, Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import CardLarge from "../../../components/Cards/CardLarge/CardLarge";
import config from "../../../aws-exports";
function AllPrograms() {
  const { viewAllArr } = useSelector((state) => state.home);
  let userDetails = useSelector(authData);
  const history = useHistory();
  const dispatch = useDispatch();
  const handleLocation = (pid, cid, pname, courses) => {
    let breadcrumb = { ...userDetails };
    breadcrumb.location = {
      from: "Home",
      programName: pname,
      viewAll: true,
      viewAllRoute: "/viewAllPrograms",
    };
    breadcrumb.allCourses = courses;
    dispatch(awsSignIn(breadcrumb));
    history.push("/programview");
  };
  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon fontSize="large" style={{ fill: "var(--color-primary)" }} />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
        </Link>
        <Link
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          {`${userDetails?.languagejson?.Home_Page?.View_all || "View all"}`}
        </Link>
      </Breadcrumbs>
      <div className="flex-between">
        <div className="flex-container"></div>
      </div>
      <div className="featureList">
        {viewAllArr.map(({ pname, pid, cname, cid, pcount, courses }) => (
          <div
            style={{ marginBottom: "2rem" }}
            onClick={() => handleLocation(pid, cid, pname, courses)}
            key={cid + pid + "abd"}
          >
            <CardLarge ttitle={pname} pid={pid} course={pcount} pcount={true} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllPrograms;
