import { createSlice } from '@reduxjs/toolkit';

export const categoryDataSlice = createSlice({
  name: 'category',
  initialState: {
    data: [],
  },
  reducers: {
    setCategoryData: (state, action) => {
      state.data = [...action.payload];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCategoryData } = categoryDataSlice.actions;

export default categoryDataSlice.reducer;
