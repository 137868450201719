import React from 'react';
import { Button } from "@mui/material";
import Person2Icon from "@mui/icons-material/Person2";
import { callAPI } from "../../api/api";
import config from "../../aws-exports";

const PostHeaderView = ({ row, classes, ind, userDetails, refetch, hotRefeth, setMainLoader }) => {
  const callAPIFunction = async (buttonType) =>{
     
    try{
    let obj = {};
    obj.method = 'post'; 
    obj.body = {};
    obj.body.ur_id =  userDetails?.uData?.ur_id;
    obj.body.schema = config.schema; 
    obj.body.follow_ur_id = row.ur_id;
    if(buttonType === 'follow'){
      obj.apiName = `/createUserFollow`; 
    }  else  if(buttonType === 'unfollow'){
      obj.method = 'del'; 
      obj.apiName = `/deleteUserFollow`; 
    } 
    const res = await callAPI(obj,userDetails);   
    return res;
  } catch (err) { 
    let obj = {};
    obj.status = 400;
    obj.message = err.message;
    return obj;
  }
  }
  const handleCommonAction = async (actionType) => {
    setMainLoader(true);
    let  res = await callAPIFunction(actionType);
        
        hotRefeth();
        refetch();
        setMainLoader(false);
        console.log('Follow button clicked');
       
  };
  const handleUserProfile =()=>{

  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
      }}
    >
      <div key={ind + 'header'}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Person2Icon style={{ fontSize: '2.5rem' }} />
          <h4 onClick={handleUserProfile}>{row?.full_name || ''}</h4>
        </div>
        <h2 style={{ margin: '1rem 0rem' }}>{row?.post_title || ''}</h2>
        
          {row?.json_postlabel &&  row?.json_postlabel.map(obj => (
              <Button className={classes.actionButtonTag}>
                {obj.title}
              </Button>
          )) }
        
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '1rem',
        }}
      >
        <p>{row?.postage || 0}d</p>
        {row?.ur_id !== userDetails?.uData?.ur_id && row?.json_userfollow && <Button
          className={classes.actionButtonFollow}
          onClick={() => handleCommonAction((row?.json_userfollow && row.json_userfollow.findIndex(item => item.follow_ur_id !== undefined && item.follow_ur_id === userDetails?.uData?.ur_id) !== -1) ? "unfollow" : "follow")}
        >
 
          {(row.json_userfollow && row.json_userfollow.findIndex(item => item.follow_ur_id !== undefined && item.follow_ur_id === userDetails?.uData?.ur_id) !== -1) ? "Following" : "Follow" }
        </Button>}
      </div>
    </div>
  );
};

export default PostHeaderView;
