import { Box } from "@mui/material";
import React, { useState } from "react";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { CircularProgress, Stack } from "@mui/material";

// import "./phoneInput.styles.css";
// import "react-phone-number-input/style.css";

import { API, Auth } from "aws-amplify";
import CustomInput from "../../components/CustomInput/CustomInput";
import { HeadingPrimary } from "../../components/Styles/Heading";
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../components/SignInAndSignUp/SignInAndSignUp";

import { useHistory, useLocation } from "react-router-dom";
import CustomLoginLayout from "./CustomLoginLayout";
import { CustomButton } from "../../components/CustomButton/CustomButton";
import { useDispatch } from "react-redux";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { useSelector } from "react-redux";
import config from "../../aws-exports";
import { awsSignIn } from "../../redux/auth/authSlice";
const SignUp = () => {
 const accesToken =  localStorage.getItem('accesToken')

  const location = useLocation();
  const history = useHistory();

  const query = useQueryParams();

  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [resendLoad, setResendLoad] = useState(false);

  async function confirmSignUp(e) {
    if (code.length === 0) {
      setErrors({ code: "Please enter the code" });
    } else {
      setErrors({ code: "" });
    }

    e.preventDefault();
    setBtnLoading(true);
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let username = userdata?.username;

    try {
      const response = await Auth.confirmSignUp(
        location?.state?.username.toUpperCase() || username.toUpperCase(),
        code,
        {
          forceAliasCreation: false,
        }
      );

      const res = location.state || userdata;
      if (res) {
        let body = {
          type: "signup",

          ur_id: res?.ur_id,
          is_verified: true,
          scheme: config.schema,
        };
        await addUser(body);
      }
    } catch (error) {
      setBtnLoading(false);
      let code = error.code;
      switch (code) {
        case "CodeMismatchException":
          return setErrors({ code: error.message });

        default:
          return false;
      }
    }
  }
  const addUser = async (user) => {
    const bodyParam = {
      body: user,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: accesToken
      },
    };

    try {
      const response = await API.put(
        config.aws_cloud_logic_custom_name,
        "/updateUser",
        bodyParam
      );
      if (response && response.body) {
        let res = response.body;
        let body = {
          id: res.eid,
          username: res.emailid,
          name: res.first_name + " " + res?.last_name,
          email: res.emailid,
          locale: res.oid,
          tenant: res.oid,
          uData: {
            ...res,
            ur_id: res.ur_id,
          },
        };
        localStorage.removeItem("userdata");
        localStorage.setItem("userdata", JSON.stringify(body));
        analyticsWebApp(body);
      }
    } catch (err) {
      setBtnLoading(false);
    }
  };
  async function analyticsWebApp(userDetails) {
   const bdata = {
    oid: config.aws_org_id,
    eventtype: "AuthenticatedViaCognito",
    id: userDetails.id,
    iid: config.aws_cognito_identity_pool_id,
    email: userDetails.username,
    name: userDetails.name,
    emailid: userDetails.email,
    tenant: userDetails.locale,
    ur_id: userDetails.uData?.ur_id,
    schema: config.schema
  }
  bdata.emailid = userDetails.emailid;
    const jsonString = JSON.stringify(bdata);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      dispatch(awsSignIn(userDetails));
      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.push("/home");
      }
    } catch (err) {
      history.push("/home");
      console.error(err);
    }

    // window.location.href = homeurl;
  }
  async function resendConfirmationCode() {
    let userdata = JSON.parse(localStorage.getItem("userdata"));
    let username = userdata?.username;
    try {
      setResendLoad(true);
      await Auth.resendSignUp(
        location?.state?.username.toUpperCase() || username?.toUpperCase()
      );
      setResendLoad(false);
    } catch (err) {
      setResendLoad(false);
    }
  }

  return (
    <React.Fragment>
      <CustomLoginLayout>
        <Box sx={{ textAlign: "center" }}>
          <HeadingPrimary>Confirm code</HeadingPrimary>
        </Box>

        <form
          onSubmit={confirmSignUp}
          noValidate
          autoComplete="off"
          style={displayCenter}
        >
          <React.Fragment>
            <Box component="div" sx={inputBoxStyle}>
              <CustomInput
                id="input-confirmPassword"
                placeholder="Enter the Verification Code sent to your Email ID"
                type="text"
                name="code"
                value={code}
                error={errors.code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
              >
                <LockOutlinedIcon sx={inputIconStyle} />
              </CustomInput>
              <Stack direction="row" justifyContent="flex-end">
                <button
                  style={{
                    fontSize: "1.4rem",
                    textAlign: "right",
                    cursor: "pointer",
                    fontFamily: `myfontregular`,
                    background: "transparent",
                    border: 0,
                  }}
                  type="button"
                  onClick={resendConfirmationCode}
                  disabled={resendLoad}
                >
                  Resend
                </button>
              </Stack>
            </Box>
            <Box component="div" sx={{ textAlign: "center", mb: 3 }}>
              <CustomButton
                type="button"
                onClick={() => {
                  history.push("/");
                }}
              >
                Back
              </CustomButton>
              {btnLoading ? (
                <CustomButton disabled loading="true">
                  <CircularProgress color="inherit" size={20} />
                </CustomButton>
              ) : (
                <CustomButton type="submit" primary>
                  Submit
                </CustomButton>
              )}
            </Box>
          </React.Fragment>
        </form>
      </CustomLoginLayout>
    </React.Fragment>
  );
};

export default SignUp;
