import React, { useEffect, useState } from "react";
import "../ViewAll.scss";
import { useSelector, useDispatch } from "react-redux";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useHistory, Link } from "react-router-dom";
import { Breadcrumbs } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { authData, awsSignIn } from "../../../redux/auth/authSlice";
import config from "../../../aws-exports";
import LearningPathCard from "../../../components/Cards/LearningPathCard/LearningPathCard";
function LearningPathViewAll() {
  const { viewAllArr } = useSelector((state) => state.home);
  const userDetails = useSelector(authData);
  const [themecolor, setColors] = useState("");
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      marginTop: "2rem",
      display: "flex",
      justifyContent: "center",
    },
    paper: {
      padding: theme.spacing(3),
      height: "600px",
      width: "1100px",
      backgroundColor: "white",
    },
    gridContainer: {
      marginTop: "2rem",
      boxShadow: "rgba(0, 0, 0, 0.25) 0px 5px 41px -2px",
      background:
        userDetails.themeDark === "dark" ? "#282D37 !important" : "#fff",
      padding: "2rem",
      borderRadius: "5px",
      "& > div": {
        display: "flex",
      },
      "@media (max-width: 430px)": {
        marginTop: 0,
        padding: "1rem",
        "& > div > div": {
          margin: "0.8rem",
        },
      },
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    }
  }, []);
  const addAndRemoveBookmarkContent = async (val, id, idx) => {};
  const handlelearningLocation = (lpid, lname, color) => {
    const breadcrumbs = { ...userDetails };
    breadcrumbs.location = {
      from: "ViewAll",
      topicName: breadcrumbs.location.topicName,
      topicId: breadcrumbs.location.topicId,
      lname: lname,
      lpid: lpid,
    };
    breadcrumbs.learning = {
      lname: lname,
      lpid: lpid,
    };
    breadcrumbs.learningPath = true;
    dispatch(awsSignIn(breadcrumbs));
    setColors(color);
    localStorage.setItem("themecolor", color);
    history.push({
      pathname: `/learning`,
      state: { themecolor: color },
    });
  };
  const history = useHistory();
  return (
    <div>
      <Breadcrumbs
        separator={
          <NavigateNextIcon
            fontSize="large"
            style={{ fill: "var(--color-primary)" }}
          />
        }
        aria-label="breadcrumb"
        style={{ marginTop: "2rem" }}
      >
        <Link
          color="inherit"
          to="/home"
          style={{
            color: "#1974b5",
            fontSize: "1.5rem",
            textDecoration: "none",
            cursor: "pointer",
          }}
        >
          {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
        </Link>
        <Link
          to="/viewallpaths"
          style={{
            color: "#777",
            fontSize: "1.5rem",
            textDecoration: "none",
          }}
          color="inherit"
        >
          {`${userDetails?.languagejson?.Home_Page?.View_all || "View all"}`}
        </Link>
      </Breadcrumbs>
      <div
        className="flex-between"
        style={{ marginTop: "2rem", marginBottom: "2rem" }}
      >
        <div className="flex-container">
          <Typography component="h1" variant="h4">
            Learning Paths
          </Typography>
        </div>
      </div>
      <Grid container spacing={3} className={classes.mainGrid}>
        {viewAllArr?.map(
          ({ lp_id, lname, rate, bookmark, points, count }, idx) => (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <LearningPathCard
                key={lp_id + "lpid"}
                star={rate}
                title={lname}
                bookmark={bookmark}
                tid={lp_id}
                handleBookmark={addAndRemoveBookmarkContent}
                handleLocation={(color) =>
                  handlelearningLocation(lp_id, lname, color)
                }
                index={idx}
                points={points}
                dur={123}
                count={count}
              />
            </Grid>
          )
        )}
      </Grid>
    </div>
  );
}
export default LearningPathViewAll;
