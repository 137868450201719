import * as React from "react";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import { Button, CircularProgress, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { styled } from "@mui/material/styles";
import { languageChange } from "../../api/api";
import CloseIcon from "@mui/icons-material/Close";
import {
  Radio,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { CustomButton } from "../CustomButton/CustomButton";
import Tooltip from '@mui/material/Tooltip';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
function LanguageSelect({ setTheme, theme }) {
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    responsiveMenu: {
      "@media (max-width: 770px)": {
        display: "none",
      },
    },
    dialogPaper: {
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "#272C38 !important",
      overflow: "auto",
      margin: "1rem 0rem",
      "& > div": {
        display: "flex",
        justifyContent: "left",
        borderBottom: "1px solid #8a8a8a !important",
        padding: "1rem",
        margin: "1rem 0",
        fontFamily: `myfontregular`,
      },
    },
    dialogHeader: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      fontSize: "2rem!important",
      fontFamily: `myfontregular!important`,
      backgroundColor:
        userDetails.themeDark === "dark" ? "#272C38 !important" : "#F2F2F2",
      fontWeight: "bold!important",
    },
    dialogSection: {
      "& > div > div": {
        borderRadius: "2rem",
        maxWidth: "40%!important",
        width: "40%!important",
        height: "80vh!important",
        margin: 0,
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#1C2027 !important"
            : theme.palette.background.paper,
        color:
          userDetails.themeDark === "dark" ? "white" : "#1C2027 !important",
        "@media (max-width: 770px)": {
          width: "90%!important",
          maxWidth: "90%!important",
          height: "70vh!important",
        },
        "& > h2 > button ": {
          color:
            userDetails.themeDark === "dark"
              ? "white !important"
              : "#1C2027 !important",
        },
      },
    },
    dialogFooter: {
      justifyContent: "center!important",
    },
    dialogFooterButton: {
      background: "var(--color-primary)!important",
      color: "white",
      width: "60%",
      borderRadius: "25px",
      height: "3.5rem!important",
      fontSize: "1.5rem",
      fontWeight: "bold",
    },
    dialogFooterButtonLoader: {
      background: "var(--color-primary)!important",
      color: "white!important",
      width: "60%!important",
      borderRadius: "25px!important",
      height: "3.5rem!important",
    },
  }));
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  let languages = [
    { id: "1", lang_name: "English", lang_translation: "English" },
    { id: "2", lang_name: "Kannada", lang_translation: "ಕನ್ನಡ" },
    { id: "3", lang_name: "Hindi", lang_translation: "हिन्दी" },
    { id: "4", lang_name: "Tamil", lang_translation: "தமிழ்" },
    { id: "5", lang_name: "Gujarati", lang_translation: "ગુજરાતી ભાષા" },
    { id: "6", lang_name: "Marathi", lang_translation: "मराठी" },
    { id: "7", lang_name: "Telugu", lang_translation: "తెలుగు" },
    { id: "8", lang_name: "Bengali", lang_translation: "বাংলা" },
    { id: "9", lang_name: "Assamese", lang_translation: "অসমীয়া" },
    { id: "10", lang_name: "Oriya", lang_translation: "ଓଡ଼ିଆ" },
    { id: "11", lang_name: "Malayalam", lang_translation: "മലയാളം" },
    { id: "12", lang_name: "Punjabi", lang_translation: "ਪੰਜਾਬੀ" },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [selectedValue, setSelectedValue] = React.useState("a");
  const dispatch = useDispatch();
  const [selectedOptionLang, setSelectedOptionLang] = useState(
    userDetails?.uData?.lang
  );
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };
  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: "size-radio-button-demo",
    inputProps: { "aria-label": item },
  });
  const handleChangeLang = (event) => {
    setSelectedOptionLang(event.target.value);
  };
  const handleSelectChange = async () => {
    try {
      setLoading(true);
      let lang = await languageChange(userDetails, selectedOptionLang);
      let updatedUserData = {
        ...userDetails,
        languagejson: lang?.languagejson,
        uData: {
          ...userDetails.uData,
          lang: selectedOptionLang,
        },
      };
      dispatch(awsSignIn(updatedUserData));
       window.location.reload();
    } catch (error) {
      console.error("Error in handleSelectChange:", error);
    }
  };
  return (
    <>
      <div className={classes.responsiveMenu}>
        <div>
        <Tooltip
        title="Language Preference"
        arrow
        placement="top"
        enterDelay={500}
        leaveDelay={200}
        disableInteractive
        PopperProps={{
          sx: {
            '& .MuiTooltip-tooltip': {
              backgroundColor: 'black', // Set the background color here
              color: 'white', // Set the text color to ensure readability
              fontSize:'0.9rem'
            },
            '& .MuiTooltip-arrow': {
              color: 'black', // Match the arrow color with the tooltip background
            },
          },
        }}
      >
          <Button
            onClick={handleClickOpen}
            style={{ minWidth: "30px", padding: 0 }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 54 54"
            >
              <defs>
                <clipPath id="clip-Translate">
                  <rect width="54" height="54" />
                </clipPath>
              </defs>
              <g id="Translate" clip-path="url(#clip-Translate)">
                <g
                  id="Group_1660"
                  data-name="Group 1660"
                  transform="translate(4 4)"
                >
                  <path
                    id="Path_1069"
                    data-name="Path 1069"
                    d="M86.815,120.643,84.1,107.09A1.355,1.355,0,0,0,82.775,106H80.064a1.355,1.355,0,0,0-1.329,1.09l-2.711,13.554a1.355,1.355,0,1,0,2.658.532l.866-4.332H83.29l.866,4.332a1.355,1.355,0,1,0,2.658-.532Zm-6.724-6.511,1.084-5.422h.488l1.084,5.422Zm28.436,2.711h-4.066v-1.355a1.355,1.355,0,0,0-2.711,0v1.355H97.684a1.355,1.355,0,1,0,0,2.711h.328a16.956,16.956,0,0,0,3.215,5.945c-1.041.953-2.1,1.734-3.034,2.485a1.355,1.355,0,1,0,1.693,2.117c.944-.755,2.075-1.594,3.219-2.644,1.145,1.051,2.278,1.891,3.219,2.644a1.355,1.355,0,1,0,1.693-2.117c-.936-.749-1.991-1.531-3.034-2.485a16.956,16.956,0,0,0,3.215-5.945h.328a1.355,1.355,0,0,0,0-2.711Zm-5.422,6.664a14.278,14.278,0,0,1-2.23-3.962h4.461A14.276,14.276,0,0,1,103.106,123.507Z"
                    transform="translate(-69.131 -96.422)"
                    fill="#fff"
                  />
                  <path
                    id="Path_1070"
                    data-name="Path 1070"
                    d="M42.2,8.223H22.644l-.581-4.661A4.072,4.072,0,0,0,18.028,0H4.066A4.071,4.071,0,0,0,0,4.066V33.975a4.071,4.071,0,0,0,4.066,4.066H15.495l.574,4.661A4.075,4.075,0,0,0,20.1,46.264H42.2A4.071,4.071,0,0,0,46.264,42.2V12.289A4.071,4.071,0,0,0,42.2,8.223ZM4.066,35.33a1.357,1.357,0,0,1-1.355-1.355V4.066A1.357,1.357,0,0,1,4.066,2.711H18.028A1.357,1.357,0,0,1,19.373,3.9L23.291,35.33Zm14.613,6.393-.453-3.682h3.636Zm24.874.474A1.357,1.357,0,0,1,42.2,43.553H20.681l5.16-5.968a1.353,1.353,0,0,0,.328-1.086L22.982,10.933H42.2a1.357,1.357,0,0,1,1.355,1.355Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </Button>
                </Tooltip>
        </div>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          className={classes.dialogSection}
        >
          <DialogTitle
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
            className={classes.dialogHeader}
          >
            {`${userDetails?.languagejson?.Select_Languages?.Select_language ||
              "Language Preference"}`}
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <h4 style={{ fontSize: "1.8rem" }}>{`${userDetails?.languagejson
              ?.Select_Languages?.Available_Languages ||
              "Available Languages"}`}</h4>
            <Typography className={classes.dialogPaper}>
              {languages.map((lang) => (
                <div>
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Radio
                      {...controlProps(lang.id)}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: 18,
                          color:
                            userDetails.themeDark === "dark"
                              ? "white"
                              : "var(--color-primary)!important",
                        },
                      }}
                      onChange={handleChangeLang}
                      checked={selectedOptionLang === lang.id}
                    />
                    <p style={{ fontSize: "1.5rem" }}>{lang.lang_name}</p>
                  </div>
                  <div style={{ margin: "auto 1rem" }}>
                    <p style={{ fontSize: "1.5rem" }}>
                      {lang.lang_translation}
                    </p>
                  </div>
                </div>
              ))}
            </Typography>
          </DialogContent>
          <DialogActions className={classes.dialogFooter}>
            {loading ? (
              <CustomButton
                disabled
                loading="true"
                className={classes.dialogFooterButtonLoader}
              >
                <CircularProgress color="white" size={20} />
              </CustomButton>
            ) : (
              <Button
                autoFocus
                onClick={handleSelectChange}
                className={classes.dialogFooterButton}
              >
                {`${userDetails?.languagejson?.Select_Languages?.Done ||
                  "Done"}`}
              </Button>
            )}
          </DialogActions>
        </BootstrapDialog>
      </div>
    </>
  );
}
export default LanguageSelect;
