import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import {
  Stack,
  Typography,
  Breadcrumbs,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Link } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { makeStyles } from "@material-ui/core/styles";
import { Skeleton } from "@material-ui/lab";
import { useQuery } from "@tanstack/react-query";
import "../../components/Gizmos/GizmosCSS.css";
import { ReactComponent as CoinsSpent } from "../../assets/svg/Coins_spent.svg";
import { ReactComponent as TotalCoins } from "../../assets/svg/Total_coins.svg";
import { ReactComponent as LevelProgress } from "../../assets/svg/Level_progress.svg";
import Image from "../../assets/male1.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Paper } from "@mui/material";
import config from "../../aws-exports";
import { API } from "aws-amplify";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import LevelProgressBar from "./LearnerProgressbar";
function LearnerProgress() {
  const userDetails = useSelector(authData);
  const [chartdata, setChartdata] = React.useState([]);
  const useStyles = makeStyles(() => ({
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    breadcrumbNameStyle: {
      fontSize: "1.5rem!important",
      color:
        userDetails.themeDark === "dark"
          ? "white !important"
          : "black !important",
      fontFamily: `myfontregular!important`,
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    mainDiv: {
      marginBottom: "2.5rem",
      gap: "2rem",
      display: "flex",
      justifyContent: "space-between",
      flexWrap: "wrap",
    },
    subBlock: {
      border: "1px solid",
      borderColor: userDetails.themeDark === "dark" ? "transparent" : "#9FCEEA",
      background:
        userDetails.themeDark === "dark"
          ? "linear-gradient(180deg, #39404D , #272C38)"
          : "linear-gradient(180deg, white , #EAF7FF)",
      padding: "2rem",
      textDecoration: "none",
      borderRadius: "5px",
      color: userDetails.themeDark === "dark" ? "white" : "black",
      alignItems: "center",
    },
    subBlockHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "& > div:first-child": {
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        "& > div": {
          padding: "1rem",
          borderRadius: "3rem",
          boxShadow: "0px 2px 5px 0px rgba(102, 102, 102, 0.3)",
          height: "4.5rem",
          background: "linear-gradient(180deg, white , #EAF7FF)",
        },
        "& > p": {
          fontSize: "2rem",
        },
      },
    },
    subBlockBody: {
      margin: "3.5rem 0rem 1rem",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 450px)": {
        display: "grid",
        gap: "1rem",
      },
    },
    rightSection: {
      border: "1px solid",
      borderColor: userDetails.themeDark === "dark" ? "transparent" : "#9FCEEA",
      background:
        userDetails.themeDark === "dark"
          ? "linear-gradient(180deg, #39404D , #272C38)"
          : "linear-gradient(180deg, white , #EAF7FF)",
      borderRadius: "5px",
      width: "50rem",
      padding: "3rem 4rem",
      display: "flex",
      gap: "2rem",
      justifyContent: "center",
      "@media (max-width: 420px)": {
        display: "grid",
        gap: "1rem",
      },
      "& > div": {
        display: "grid",
        gap: "2rem",
        "& > div": {
          display: "flex",
          alignItems: "center",
          gap: "2rem",
        },
        "& > p": {
          fontSize: "1.9rem",
        },
      },
    },
    mainBlock: {
      margin: "0rem 0rem 2rem",
      boxShadow:
        "0px 2px 14px -1px rgb(0 0 0 / 10%), 0px 4px 5px 0px rgb(0 0 0 / 4%), 0px 1px 290px 0px rgb(0 0 0 / 2%)",
    },
    accordionSummaryStyle: {
      background:
        userDetails.themeDark === "dark"
          ? "linear-gradient(180deg, #39404D , #272C38)"
          : "linear-gradient(180deg, white , #EAF7FF)",
      border:
        userDetails.themeDark === "dark"
          ? "none"
          : "1px solid #9FCEEA!important",
      borderBottom: "none!important",
      "& > div": {
        margin: "10px 0!important",
      },
    },
    mainBlockContent: {
      background:
        userDetails.themeDark === "dark"
          ? "linear-gradient(180deg, #39404D , #272C38)"
          : "linear-gradient(180deg, white , #EAF7FF)",
    },
    rightLabel: {
      padding: "6px",
      background: "#333333",
      color: "white",
      marginLeft: "auto",
      width: "fit-content",
      borderRadius: "5px",
    },
    flexClass: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      color: "var(--color-primary)",
      "& > h3": {
        margin: "0",
        fontSize: "2rem",
      },
    },
    tableRoot: {
      width: "100%",
      borderRadius: "1rem",
      overflow: "hidden",
      margin: "4rem 0",
    },
    mainHeaderStyle: {
      display: "flex",
      alignItems: "center",
      padding: "2rem 4rem",
      background:
        userDetails.themeDark === "dark"
          ? "linear-gradient(180deg, #39404D , #272C38)"
          : "#f0f0f0",
      justifyContent: "space-between",
      "@media (max-width: 770px)": {
        display: "grid",
        gap: "1rem",
      },
    },
    dataGridStyle: {
      "& > div": {
        fontSize: "1.5rem",
        "& > div > div:nth-child(1)": {
          backgroundImage: "linear-gradient(to bottom, #2490CC, #026AA4)",
          color: "white",
        },
        "& > div > div:nth-child(2)": {
          height: "36rem",
          margin: "1rem",
          borderRadius: "5px",
          "& > div > p": {
            fontSize: "1.5rem",
            color: userDetails.themeDark === "dark" ? "white" : "black",
          },
        },
        "& > div > div:nth-child(2) > div > div > div": {
          margin: "1rem 0rem",
          borderRadius: "5px",
          border: userDetails.themeDark === "dark" ? "none" : "1px solid #F3F4F6",
          color: userDetails.themeDark === "dark" ? "white" : "black",
        },
      },
      "& .MuiDataGrid-colHeader, .MuiDataGrid-colCell": {
        background: "blue",
        color: "white",
        fontSize: "1.5rem",
      },
      "& .MuiDataGrid-row:nth-child(even)": {
        background: userDetails.themeDark === "dark" ? "#2e3033" : "#F3F4F6",
      },
    },
  }));
  const { isLoading, data } = useQuery(
    ["getmdata"],
    async () => {
      const requestBody = {
        ur_id: userDetails?.uData?.ur_id,
        tenant: userDetails?.uData?.oid,
        schema:config?.schema
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getLeaderboardData?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken
          },
        }
      );
      return response;
    }
  );
  const {
    isLoading: coinsLoader,
    data: coinsData,
  } = useQuery(["getmdataD"], async () => {
    const requestBody = {
      schema:`${config.schema}`,
      ur_id: userDetails?.uData?.ur_id,
    };
    const jsonString = JSON.stringify(requestBody);
    const base64EncodedString = btoa(jsonString);
    const reportInfo = `/getMystoreAndMyCoins?json=${encodeURIComponent(
      base64EncodedString
    )}`;
    const response = await API.get(
      config.aws_cloud_logic_custom_name,
      reportInfo,
      {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: userDetails?.accessToken
        },
      }
    );
    return response;
  });
  const classes = useStyles();
  const [totalCoinsSpent, setTotalCoinsSpent] = useState(0);
  const [userLevel, setUserLevel] = useState("");
  const [pointsToReach, setPointsToReach] = useState(0);
  const [rows, setRows] = useState([]);
  const [levelReach, setLevelReach] = useState(0);
  const [maxLevel, setMaxLevel] = useState(true);
  const [currentlevelstat, setCurrentlevelstat] = useState({});
  const [levelsArray, setLevelsArray] = useState([]);
  const [curLevel, setCurLevel] = useState(0);
  useEffect(() => {
    getLearnerProgress();
  }, []);
  async function getLearnerProgress() {
    const jsonData = {
      schema: config.schema,
      oid: config.aws_org_id,
      ur_id: userDetails?.uData?.ur_id,
    };
    // Convert JSON to a string and encode it
    const jsonString = encodeURIComponent(JSON.stringify(jsonData));
    // Append the encoded string to the base URL
    const baseUrl = `/getLearnerProgress`;
    const fullUrl = `${baseUrl}?queryjson=${jsonString}`;
    try {
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        fullUrl,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken
          },
        }
      );
      if (response) {
        let learningpro = response.learnerProgress;
        mapCourses(learningpro);
        setLevelsArray(response?.levels);
        setCurLevel(response?.currentLevel[0]?.levels);
        let ChartArray = getDatesAndLevelsChanges(learningpro);
        setCurrentlevelstat(ChartArray[ChartArray.length - 1]);
        setChartdata(ChartArray);
        const levelToReach = response?.currentLevel[0]?.levels + 1;
        const pointsToReach =
        response?.levels[levelToReach - 1]?.lpoints- response?.currentLevel[0]?.points;
        setPointsToReach(pointsToReach);
        setLevelReach(levelToReach);
        if (
          response?.currentLevel[0]?.levels ===
          response?.levels[response?.levels.length - 1].level
        ) {
          setMaxLevel(false);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  const getDatesAndLevelsChanges = (data) => {
    // Sort the data by date and level in ascending order
    const sortedData = data.slice().sort((a, b) => {
      const dateComparison = new Date(a.date) - new Date(b.date);
      if (dateComparison !== 0) {
        return dateComparison;
      }
      return a.level - b.level;
    });
    const changes = [];
    // Add information for the first item
    changes.push({
      month: new Intl.DateTimeFormat("en", {
        year: "numeric",
        month: "long",
      }).format(new Date(sortedData[0].date)),
      level: sortedData[0].level,
    });
    // Iterate through the sorted data to find dates and levels changes
    for (let i = 1; i < sortedData.length; i++) {
      const currentDate = new Date(sortedData[i].date);
      const prevDate = new Date(sortedData[i - 1].date);
      if (
        currentDate.toDateString() !== prevDate.toDateString() ||
        sortedData[i].level !== sortedData[i - 1].level
      ) {
        // Date or level change detected, add the information to the array
        changes.push({
          month: new Intl.DateTimeFormat("en", {
            year: "numeric",
            month: "long",
          }).format(currentDate),
          level: sortedData[i].level,
        });
      }
    }
    return changes;
  };
  function mapCourses(value) {
    let allAct = value;
    let trows = [];
    if (allAct?.length > 0) {
      for (let i = 0; i < allAct?.length; i++) {
        let obj = {};
        obj.id = i;
        obj.activity =
          allAct[i].additional_data === null
            ? allAct[i].action
            : allAct[i].additional_data;
        obj.learningType = allAct[i].action;
        obj.coins = allAct[i].points || "0";
        obj.level = allAct[i].level || "";
        trows.push(obj);
      }
    }
    setRows(trows);
  }
  useEffect(() => {
    if (coinsData) {
      const sumOfPointsForEachObject = coinsData?.myorders.map(
        (item) => item.points
      );
      // Calculate the total sum of points
      const totalSumOfPoints = sumOfPointsForEachObject.reduce(
        (total, points) => total + points,
        0
      );
      setTotalCoinsSpent(totalSumOfPoints);
    }
  }, [coinsData?.myorders]);
  useEffect(() => {
    if (data) {
      const rankuser = data.iusers?.filter(
        (user) => userDetails.uData.ur_id === user.ur_id
      );
      setUserLevel(rankuser[0]);
    }
  }, [data?.iusers]);
  const columns = [
    {
      field: "activity",
      headerName: "Activity",
      width: 400,
      editable: true,
    },
    {
      field: "learningType",
      headerName: "Learning Type",
      width: 350,
      editable: true,
    },
    {
      field: "level",
      headerName: "Level",
      width: 150,
      editable: true,
    },
    {
      field: "coins",
      headerName: "Coins",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 150,
    },
  ];
  const breadcrumbs = [
    <Link
      underline="hover"
      key="1"
      color="inherit"
      to={"/home"}
      style={{ fontSize: "1.5rem", cursor: "pointer", textDecoration: "none" }}
    >
      {`${userDetails?.languagejson?.Home_Page?.Home || "Home"}`}
    </Link>,
    <Typography
      key="3"
      color="text.primary"
      className={classes.breadcrumbNameStyle}
    >
      {`${userDetails?.languagejson?.LearnerProgress?.Learner_Progress ||
        "Learner Progress"}`}
    </Typography>,
  ];
  return (
    <>
      <Stack spacing={2}>
        <Breadcrumbs
          separator={
            <NavigateNextIcon
              fontSize="large"
              style={{ color: "var(--color-primary)" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Stack>
      <div className="flex-between" style={{ margin: "2rem 0rem 3rem" }}>
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }}>
            {isLoading ? (
              <Skeleton className={classes.loaderSkeleton} />
            ) : (
              <>{`${userDetails?.languagejson?.LearnerProgress
                ?.Learner_Progress || "Learner Progress"}`}</>
            )}
          </h3>
        </div>
      </div>
      <div className={classes.mainDiv}>
        <div>
          <div
            className={classes.subBlock}
            to={`/learnerProgress`}
            style={{ display: "flex", gap: "3rem" }}
          >
            <div className="hexagon">
              <div className="hexagon-inner">
                <img src={Image} alt="Hexagon Image" className="round-image" />
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div className={classes.subBlockHeader}>
                <div>
                  <p>{`Hi, ${userDetails?.uData?.first_name} ${userDetails?.uData?.last_name}`}</p>
                </div>
              </div>
              <div className={classes.subBlockBody}>
                <h3>{`${userDetails?.languagejson?.LearnerProgress?.Level || "Level"}`}
                  {userLevel?.levels}</h3>
                {maxLevel && (
                  <p style={{ fontSize: "1.3rem" }}>
                    {pointsToReach} {`${userDetails?.languagejson?.LearnerProgress?.points_to_reach_Level || "points to reach Level"}`} {levelReach}
                  </p>
                )}
                {!maxLevel && (
                  <p style={{ fontSize: "1.3rem" }}>{`${userDetails?.languagejson?.LearnerProgress?.maximum_level_reached || "maximum level reached"}`}</p>
                )}
              </div>
              <LevelProgressBar curLevel={curLevel} levelsArray={levelsArray} />
            </div>
          </div>
        </div>
        {coinsLoader ? (
          <Skeleton variant="rectangular" width={210} height={60} />
        ) : (
          <div className={classes.rightSection}>
            <div>
              <div>
                <TotalCoins style={{ width: "40px", height: "40px" }} />
                <h2 style={{ color: "#008516" }}>
                  {coinsData?.myavailablecoin}
                </h2>
              </div>
              <p>{`${userDetails?.languagejson?.LearnerProgress
                ?.Total_Coins_Available || "Total Coins Available"}`}</p>
            </div>
            <div
              style={{ width: "1px", background: "var(--color-primary)" }}
            ></div>
            <div>
              <div>
                <CoinsSpent style={{ width: "40px", height: "40px" }} />
                <h2 style={{ color: "#CD5200" }}>{totalCoinsSpent}</h2>
              </div>
              <p>{`${userDetails?.languagejson?.LearnerProgress
                ?.Total_Coins_Spent || "Total Coins Spent"}`}</p>
            </div>
          </div>
        )}
      </div>
      <Accordion defaultExpanded={false} className={classes.mainBlock}>
        <AccordionSummary
          expandIcon={
            <ExpandMoreIcon
              style={{ fontSize: "4rem", color: "var(--color-primary)" }}
            />
          }
          aria-controls="panel2a-content"
          id="panel2a-header"
          className={classes.accordionSummaryStyle}
        >
          <Typography className={classes.flexClass}>
            <h2>{`${userDetails?.languagejson?.LearnerProgress
              ?.Level_Progress_in_detail_graphical_view ||
              "Level Progress in detail graphical view"}`}</h2>
            <LevelProgress style={{ width: "40px", height: "40px" }} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.mainBlockContent}>
          <div className={classes.rightLabel}>
            <label>
              {`${userDetails?.languagejson?.LearnerProgress?.Level || "Level"}`} {currentlevelstat?.level} {currentlevelstat?.month}
            </label>
          </div>
          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={chartdata}
              margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" />
              <YAxis ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]} />
              <YAxis tick={{ dy: -20 }} />
              <Tooltip />
              <Legend />
              <Line
                type="linear"
                dataKey="level"
                stroke="#32A002"
                dot={{ r: 6, fill: "#32A002" }}
                activeDot={{ r: 10, fill: "#32A002" }}
              />
            </LineChart>
          </ResponsiveContainer>
        </AccordionDetails>
      </Accordion>
      <Paper className={classes.tableRoot}>
        <div className={classes.mainHeaderStyle}>
          <h2>{`${userDetails?.languagejson?.LearnerProgress
            ?.Transaction_Details_of_Levels_and_coins_earned ||
            "Transaction Details of Levels and coins earned"}`}</h2>
        </div>
        <Box sx={{ height: "52rem", width: "100%" }}>
          <div className={classes.dataGridStyle}>
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              pageSizeOptions={[5]}
              disableRowSelectionOnClick
            />
          </div>
        </Box>
      </Paper>
    </>
  );
}
export default LearnerProgress;
