import { useState } from "react";
export const useForm = (initialState = {}) => {
  const [values, setValues] = useState(initialState);

  const handleChange = (prop) => (event) => {
    if (prop === "name") {
      setValues((values) => ({
        ...values,
        [prop]: event.target.value.trim(),
      }));
    } else if (prop === "verificationEmail") {
      setValues((values) => ({
        ...values,
        [prop]: event.target.value.trim(),
      }));
    } else {
      setValues((values) => ({
        ...values,
        [prop]: event.target.value,
      }));
    }
  };

  const handleClickShowPassword = () => {
    setValues((values) => ({
      ...values,
      showPassword: !values.showPassword,
    }));
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return {
    values,
    setValues,
    handleChange,
    handleClickShowPassword,
    handleMouseDownPassword,
  };
};
