import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Rating } from "@material-ui/lab";
// import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from "@material-ui/icons/StarRounded";
import { Box, makeStyles, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
// import { authData } from "../../redux/auth/authSlice";
import API from "@aws-amplify/api";
import config from "../../aws-exports";
import { useParams, useLocation } from "react-router";
import { setIsRated } from "../../redux/RateContent/RateContent";

import { awsSignIn, authData } from "../../redux/auth/authSlice";



const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: "theme.palette.grey[500]!important",
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);


export default function CustomizedDialogs({
  open,
  setOpen,
  value,
  setValue,
  syncObjectsData,
  btnLoad,
  setBtnLoad,
  setTheme,
  theme,
}) {
  let userDetails = useSelector(authData);
  const useStyles1 = makeStyles((theme) => ({
    root: {
      width: "500px !important",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      marginLeft: "3rem",
    },
    fontSize: {
      "& .MuiInputBase-input": {
        fontSize: "1.5rem",
        padding: "1rem 1rem",
      },
    },
    star: {
      "& .MuiRating-root": {
        marginLeft: "0",
      },
    },
    svgSize: {
      "& .MuiSvgIcon-root": {
        width: "1.7rem",
        height: "1.7rem",
      },
    },
    DialogStyle: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      "@media (max-width: 550px)": {
        marginLeft: 0,
        width: "fit-content",
      },
    },
    heading: {
      width: "360px",
      fontWeight: "600",
      fontSize: "1.5rem",
      "@media (max-width: 550px)": {
        width: "fit-content",
      },
    },
    mainDialog: {
      "& > div > div": {
        margin: 0,
        backgroundColor: userDetails.themeDark === "dark"? "#272C38 !important": theme.palette.background.paper,
        color: userDetails.themeDark === "dark"? "white" : "#272C38 !important",
        "& > div > button > span": {
          color: userDetails.themeDark === "dark"? "white" : "#272C38 !important",
        },
      },
    },
  }));
  const classes1 = useStyles1();

  const [hover, setHover] = React.useState(-1);
  // const [isRated, setIsRated] = React.useState(false);

  // const dispatch = useDispatch();
  // const userDetails = useSelector(authData);
  // let { objId } = useParams();
  // let query = useQuery();
  // const { featuredArr } = useSelector(state => state.home);

  // const filterObject = featuredArr.filter(arr => arr.tid === objId);
  // alert(JSON.stringify(filterObject));

  const dispatch = useDispatch();
  

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const loadBtn = () => setBtnLoad(true);

  return (
    <div>
      {/* {!isRated ? (
        
      ) : null} */}
      {/* <Button variant='outlined' color='primary' onClick={handleClickOpen}>
        Open dialog
      </Button> */}
      <Dialog
        aria-labelledby="customized-dialog-title"
        open={open}
        className={classes1.mainDialog}
      >
        <DialogTitle
          className={classes1.svgSize}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <h3 className={classes1.heading}>Please rate this content</h3>
        </DialogTitle>
        <form>
          <DialogContent className={classes1.DialogStyle}>
            <div className={(classes1.root, classes1.star)}>
              <Rating
                name="hover-feedback"
                value={value}
                icon={<StarRoundedIcon style={{ fontSize: "5rem" }} />}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                onChangeActive={(event, newHover) => {
                  setHover(newHover);
                }}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                {value == null && (
                  <span style={{ color: "red", fontSize: "12px" }}>
                    You must select a rating before you can click Submit.
                  </span>
                )}
              </div>
            </div>
            {/* <TextField
              className={classes1.fontSize}
              id='outlined-multiline-static'
              multiline
              rows={4}
              placeholder='Please provide your feedback here...'
              variant='outlined'
              style={{ width: '400px', marginTop: '2rem' }}
            /> */}
            <DialogActions style={{ margin: "2rem 3.5rem 1rem 5rem" }}>
              {btnLoad ? (
                <Button
                  autoFocus
                  style={{
                    background: "#1C6D9C",
                    color: "#fff",
                    fontSize: "1.4rem",
                    padding: "1 2.5rem",
                  }}
                  // onClick={() => {
                  //   syncObjectsData();
                  // }}
                >
                  Loading....
                </Button>
              ) : (
                <Button
                  autoFocus
                  style={{
                    background: "#1C6D9C",
                    color: "#fff",
                    fontSize: "1.4rem",
                    padding: "1 2.5rem",
                  }}
                  onClick={() => {
                    if (value !== null) {
                      syncObjectsData();
                      loadBtn();
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </DialogActions>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
