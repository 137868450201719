import React from "react";
import "./progressBar.scss"; // import CSS file for styling

const ProgressBar = ({ progress }) => {
  return (
    <div className="progress-bar">
      <div className="progress-bar-fill" style={{ width: `${progress}%` }}>
        <span className="progress-bar-text"></span>
      </div>
    </div>
  );
};

export default ProgressBar;
