import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: { status: true },
};

export const regFormStatus = createSlice({
  name: "regStatus",
  initialState,
  reducers: {
    regForm: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { regForm } = regFormStatus.actions;

export default regFormStatus.reducer;
