import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { StyledButtons } from "./RegisterForm/RegisterFrom";
import { Theme } from "../../aws-exports";
import { CircularProgress, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
import { saveAs } from "file-saver";
import config from "../../aws-exports";
import "../../components/Styles/clearfix.css";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import "./certificate.css";
import moment from "moment";
import Pdf from "react-to-pdf";
import { API, loadingBar } from "aws-amplify";
import { Box } from "@mui/system";
import { getUserDetails } from "../../api/api";
import { useQuery } from "@tanstack/react-query";
export default function CertificatePopUp({ setOpen, open, sData, ...rest }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const userDetails = useSelector(authData);
  const darkmodeStyle = {
    "& > div > div": {
      backgroundColor: userDetails.themeDark === "dark"? "#000000 !important" : "#fff",
      color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
      "h2 > button > svg": {
        color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
      },
    },
  };
  const content = React.useRef();
  const certificateDiv = React.useRef();
  const handleClose = () => {
    setOpen(false);
  };
  const { data: user, isLoading: spin } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserDetails(userDetails),
  });
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        aria-labelledby="responsive-dialog-title"
        maxWidth="lg"
        sx={darkmodeStyle}
      >
        <DialogTitle id="responsive-dialog-title" className="clearfix">
          {sData?.sn}
          <IconButton
            onClick={() => {
              handleClose();
            }}
            sx={{ position: "absolute", right: 10, top: 10 }}
          >
            <Close sx={{ fontSize: "2rem" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent ref={content} className="overall-container">
          {spin ? (
            <Box
              sx={{
                height: "700px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className={{ height: "50vh" }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <>
              <div className="container" ref={certificateDiv}>
                <div className="img-c">
                  <img alt="logo" height="100" />
                </div>
                <div className="org-container">
                  <div class="img-c">
                    <img
                      src={`https://${
                        config.DOMAIN
                      }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/${
                        config.aws_org_id
                      }.png`}
                      alt="USP"
                      height="50"
                      width="auto"
                    />
                  </div>
                  <p>Certificate awarded to</p>
                </div>
                <h1 className="name">
                  {(user?.first_name ?? "") + " " + (user?.last_name ?? "")}
                </h1>
                <p className="complete">for successfully completing</p>
                <div className="title-container">
                  <h2 className="title">{sData?.sn}</h2>
                  <h2 className="title">
                    {sData?.stype === 1 && "(Live Webinar)"}
                    {sData?.stype === 2 && "(Classroom)"}
                    {sData?.stype === 3 && "(Hybrid)"}
                  </h2>
                </div>
                <p className="date">
                  Date : &nbsp;
                  {sData?.date_list[0]?.combineStartTime &&
                    moment(sData?.date_list[0]?.combineStartTime).format(
                      "DD MMM YYYY"
                    )}
                  {sData?.date_list &&
                    sData?.date_list?.length > 1 &&
                    sData?.date_list?.[0]?.combineEndTime &&
                    " - " +
                      moment(
                        sData?.date_list?.[sData?.date_list?.length - 1]
                          ?.combineEndTime
                      ).format("DD MMM YYYY")}
                </p>
                <div className="img-c">
                  <img
                    // src={signature}
                    alt="signature"
                    height="130"
                  />
                </div>
              </div>
            </>
          )}
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Pdf
            targetRef={content}
            filename={
              sData?.sn?.length >= 90 ? sData?.sn.substring(0, 90) : sData?.sn
            }
          >
            {({ toPdf }) => (
              <Button
                sx={{
                  color: Theme.color.primary,
                  borderColor: Theme.color.primary,
                  textTransform: "unset",
                  "&:hover": {
                    color: Theme.color.primary,
                    borderColor: Theme.color.primary,
                    textTransform: "unset",
                  },
                }}
                variant="outlined"
                onClick={toPdf}
              >
                Download
              </Button>
            )}
          </Pdf>
        </DialogActions>
      </Dialog>
    </div>
  );
}
