import * as React from "react"
// import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog"
// import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"
import config from "../../aws-exports"
// import pdf from "./postgresql-14-A4.pdf";
import { IconButton, Stack, Box, CircularProgress } from "@mui/material"
import { Close } from "@mui/icons-material"
import { Theme } from "../../aws-exports"
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

export default function ResponsiveDialog({ open, setOpen, speakerDetails }) {
  const theme = useTheme()
  const userDetails = useSelector(authData);
  const modalStyle = {
    "& > div > div": {
      backgroundColor: userDetails.themeDark === "dark"? "#000000 !important" : "#fff",
      color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
      "h2 > button > svg": {
        color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
      },
    },
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"))

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        // onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        maxWidth="md"
        sx={modalStyle}
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            fontSize: "2rem",
            fontFamily: "myfontregular",
          }}
          id="responsive-dialog-title"
        >
          {`${speakerDetails?.name ? speakerDetails?.name : ""}`}
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Stack direction="row" alignItems="center">
            <img
              src={`${
                config.aws_cloudfront_url
              }${config.aws_org_id.toLowerCase()}-resources/images/speaker-images/${
                speakerDetails.timgname
              }`}
              style={{height: "20%",width: "45%",maxWidth: "23rem",}}
              alt={speakerDetails?.name}
            />
            <Box
              component="span"
              sx={{
                height: 200,
                width: "1px",
                ml: 2,
                borderRight: `3px solid ${Theme.color.primary}`,
              }}
            />
            <Box sx={{ flexGrow: 1, ml: 2 }}>{speakerDetails?.designation}</Box>
          </Stack>
          <Box component="p" sx={{ mt: 3, minWidth: 750 }}>
            {speakerDetails?.bio}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}
