import React, { useMemo, useState, useEffect } from "react";

import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import {
  CardMedia,
  CircularProgress,
  Backdrop,
  makeStyles,
} from "@material-ui/core";

import { Box } from "@material-ui/core";

import "./phoneInput.styles.css";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Theme } from "../../aws-exports";
import config from "../../aws-exports";
import logincss from "../../pages/Login/Login.module.scss";
import { motion } from "framer-motion";

import AWS from "aws-sdk";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import axios from "axios";
import { StorageHelper } from "@aws-amplify/core";
import { useQueryParams } from "../../Hooks/useQueryParams";
import { useDispatch, useSelector } from "react-redux";
import { awsSignIn } from "../../redux/auth/authSlice";
import { API, Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom";
import { languageChange } from "../../api/api";

export const inputBoxStyle = {
  width: "450px",
  margin: "1rem",
  fontFamily: "myfontregular",
  "@media (max-width: 760px)": {
    width: "100%",
  },
};
export const displayCenter = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export const inputIconStyle = {
  color: Theme.color.primary,
  height: "22px",
  width: "22px",
};

export const ButtonIconStyle = {
  color: Theme.color.primary,
};

export const SignInAndSignUpValues = React.createContext();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: "2rem",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    height: "500px",
    width: "600px",
    borderRadius: "4px",
    border: "none",
    padding: theme.spacing(2, 4, 3),
  },
  logoImage: {
    width: "200px",
    "@media (min-width: 760px)": {
      width: "45%",
    },
  },
}));
const SignInAndSignUp = ({ loginOpen, setLoginOpen, onlySingUp }) => {
  //Responsive Dialog
  const userDetails = useSelector(awsSignIn);
  const theme = useTheme();
  const storage = new StorageHelper().getStorage();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  //Bool State for SignInAndSignUp
  const history = useHistory();
  const query = useQueryParams();
  const location = useLocation();

  const dispatch = useDispatch();

  const [signIn, setSignIn] = useState(true);
  const [login, setLogin] = useState(true);
  const [signUp, setSignUp] = useState(false);
  const classes = useStyles();
  const SignInAndSignUpProvider = useMemo(
    () => ({
      signIn,
      setSignIn,
      signUp,
      setSignUp,
    }),
    [signIn, setSignIn, signUp, setSignUp]
  );
  let code = "";
  useEffect(() => {
    if (config.aws_org_id == "AXIATA") {
      setLogin(true);
      checkUserLogin();
    } else {
      setLogin(false);
    }
  }, []);
  //Dialog close Function
  const handleClose = () => {
    setLoginOpen(false);
  };
  const containerVarients = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.5, duration: 0.5 } },
    exit: {
      x: "-100vw",
      transition: { ease: "easeInOut" },
    },
  };
  async function checkUserLogin() {
    let accToken = Cookies.get("access_token");
    var refToken = Cookies.get("refresh_token");
    var idToken = Cookies.get("id_token");
    if (
      accToken === undefined ||
      refToken === undefined ||
      accToken === null ||
      refToken === null ||
      accToken === "" ||
      refToken === ""
    ) {
      if (
        (query.get("code") === undefined || query.get("code") === null) &&
        (new URLSearchParams(window.location.search).get("code") ===
          undefined ||
          new URLSearchParams(window.location.search).get("code") === null)
      ) {
        pageload();
      } else {
        code =
          query.get("code") === undefined || query.get("code") === null
            ? new URLSearchParams(window.location.search).get("code")
            : query.get("code");

      

        axios({
          method: "POST",
          url: config.auth_url + "oauth2/token",
          params: {
            grant_type: "authorization_code",
            client_id: config.aws_user_pools_web_client_id,
            redirect_uri: config.aws_cloudfront_lurl,
            code: code,
          },
          data: {},
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
        })
          .then((resp) => {
            var decoded = jwt_decode(resp.data.id_token);
            fetchuser(resp, decoded);
          })
          .catch((error) => {
            pageload();
          });
      }
    } else {
      var decoded = jwt_decode(idToken);

      if (config.aws_org_id != "AXIATA") {
        if (!AWS.config.region) {
          AWS.config.update({
            region: "us-east-1",
          });
        }
      } else {
        if (!AWS.config.region) {
          AWS.config.update({
            region: "ap-south-1",
          });
        }
      }

      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.aws_cognito_identity_pool_id,
        Logins: {
          [`cognito-idp.${
            config.aws_org_id != "AXIATA" ? "us-east-1" : "ap-south-1"
          }.amazonaws.com/${config.aws_user_pools_id}`]: idToken,
        },
      });
      var resp = {};
      resp.data = {};
      resp.data.id_token = idToken;
      resp.data.access_token = accToken;
      resp.data.refresh_token = refToken;
      fetchuser(resp, decoded);
    }
  }
  async function fetchuser(resp, decoded) {
    if (config.aws_org_id != "AXIATA") {
      AWS.config.region = "us-east-1";
    } else {
      AWS.config.region = "ap-south-1";
    }

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: config.aws_cognito_identity_pool_id,
      Logins: {
        [`cognito-idp.${
          config.aws_org_id != "AXIATA" ? "us-east-1" : "ap-south-1"
        }.amazonaws.com/${config.aws_user_pools_id}`]: resp.data.id_token,
      },
    });

    await AWS.config.credentials.refresh(function(error, data) {
      if (error) {
        Cookies.remove("CloudFront-Policy");
        Cookies.remove("CloudFront-Key-Pair-Id");
        Cookies.remove("CloudFront-Signature");
        Cookies.remove("access_token");
        Cookies.remove("refresh_token");
        Cookies.remove("id_token");
        localStorage.clear();
        pageload();
      } else {
        Cookies.set("access_token", resp.data["access_token"]);
        Cookies.set("id_token", resp.data["id_token"]);
        Cookies.set("refresh_token", resp.data["refresh_token"]);
        Cookies.set("sessionToken", AWS.config.credentials.sessionToken);
        const idTokenData = decodePayload(resp.data["id_token"]);
        const accessTokenData = decodePayload(resp.data["access_token"]);
        const refreshtoken = decodePayload(resp.data["refresh_token"]);
        let userdata = {};
        userdata.id = AWS.config.credentials.identityId;
        storage.setItem(
          "CognitoIdentityServiceProvider." +
            config.aws_user_pools_web_client_id +
            ".LastAuthUser",
          idTokenData["cognito:username"]
        );
        storage.setItem(
          "CognitoIdentityServiceProvider." +
            config.aws_user_pools_web_client_id +
            "." +
            idTokenData["cognito:username"] +
            ".idToken",
          resp.data["id_token"]
        );
        storage.setItem(
          "CognitoIdentityServiceProvider." +
            config.aws_user_pools_web_client_id +
            "." +
            idTokenData["cognito:username"] +
            ".accessToken",
          resp.data["access_token"]
        );
        storage.setItem(
          "CognitoIdentityServiceProvider." +
            config.aws_user_pools_web_client_id +
            "." +
            idTokenData["cognito:username"] +
            ".refreshToken",
          resp.data["refresh_token"]
        );
        storage.setItem(
          "CognitoIdentityServiceProvider." +
            config.aws_user_pools_web_client_id +
            "." +
            idTokenData["cognito:username"] +
            ".clockDrift",
          "" +
            calculateClockDrift(accessTokenData["iat"], idTokenData["iat"]) +
            ""
        );
        storeData(decoded, userdata);
      }
    });
  }
  async function storeData(decoded, userdata) {
    userdata.cid = config.aws_user_pools_web_client_id;
    userdata.emailid = decoded.email;
    userdata.iid = config.aws_cognito_identity_pool_id;
    userdata.uid = config.aws_user_pools_id;
    userdata.email = decoded.sub;
    userdata.username = decoded.sub;

    if (decoded?.locale && config.aws_org_id != "AXIATA") {
      userdata.locale = decoded.locale.toUpperCase();
    } else if (config.aws_org_id == "AXIATA") {
      userdata.locale = "Axiata_team_mand_courses";
    }
    var checkindex = decoded.email.indexOf("@");
    var checkemailname = decoded.email?.substring(0, checkindex);
    if (decoded.given_name !== undefined && decoded.family_name !== undefined) {
      userdata.name = decoded.given_name + " " + decoded.family_name;
    } else if (decoded.given_name !== undefined) {
      userdata.name = decoded.given_name;
    } else if (decoded.family_name !== undefined) {
      userdata.name = decoded.family_name;
    } else {
      userdata.name =
        decoded.name === undefined ? checkemailname : decoded.name;
    }
    userdata.family_name = decoded.family_name;
    userdata.given_name = decoded.given_name;
    userdata.cgroups = decoded["cognito:groups"];
    userdata.groups = decoded["custom:groups"];

    await getUserDetails(userdata);
  }
  function pageload() {
    Cookies.remove("CloudFront-Policy");
    Cookies.remove("CloudFront-Key-Pair-Id");
    Cookies.remove("CloudFront-Signature");
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    Cookies.remove("id_token");
    localStorage.clear();
    sessionStorage.clear();
   
    window.location.href = `${config.auth_url}login?client_id=${config.aws_user_pools_web_client_id}&response_type=code&scope=email+openid+profile+aws.cognito.signin.user.admin&redirect_uri=${config.aws_cloudfront_lurl}`;
  }
  const decodePayload = (jwtToken) => {
    const payload = jwtToken.split(".")[1];
    try {
      return JSON.parse(Buffer.from(payload, "base64").toString("utf8"));
    } catch (err) {
      return {};
    }
  };
  const calculateClockDrift = (iatAccessToken, iatIdToken) => {
    const now = Math.floor(new Date() / 1000);
    const iat = Math.min(iatAccessToken, iatIdToken);
    return now - iat;
  };
  const getUserDetails = async (userdata) => {
   
    try {
      const requestBody = {
        emailid: userdata.emailid,
            schema: config.schema,
            email: userdata.email,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getUserDetails?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",	
            Authorization: userDetails?.accessToken
          },
        }
      );
      if (response && response.body?.length > 0 && response.body[0].enable) {
        userdata.uData = response.body[0];
        if (response?.body[0]?.oid !== undefined) {
          userdata.locale = response?.body[0]?.oid;
        }
        if (
          response?.body[0]?.first_name !== undefined &&
          response?.body[0]?.first_name !== null
        ) {
          userdata.name = response?.body[0]?.first_name;
        }

        await analyticsWebApp(userdata);
      } else {
        await addgetUser(userdata);
      }
    } catch (err) {
      await addgetUser(userdata);
    }
  };
  async function addgetUser(userdata) {
    try {
      const bodydata = {
        emailid: userdata.emailid,
        first_name: userdata.given_name,
        last_name: userdata.family_name,
        schema: config.schema,
        email: userdata.email,
        oid: userdata.company_name,
      }

      if (userdata.oid) {
        bodydata.oid = userdata.oid;
      }
      if (
        userdata.given_name !== undefined &&
        userdata.family_name !== undefined &&
        userdata.given_name !== "undefined" &&
        userdata.family_name !== "undefined"
      ) {
        bodydata.first_name = userdata.given_name;
        bodydata.last_name = userdata.family_name;
      } else if (
        userdata.given_name !== undefined &&
        userdata.given_name !== "undefined"
      ) {
        bodydata.first_name = userdata.given_name;
      } else if (
        userdata.family_name !== undefined &&
        userdata.family_name !== "undefined"
      ) {
        bodydata.last_name = userdata.family_name;
        bodydata.first_name = userdata.family_name.split(" ")[0];
      } else {
        var checkindex = userdata.email.indexOf("@");
        var checkemailname = userdata.email?.substring(0, checkindex);
        bodydata.first_name =
          userdata.name === undefined ? checkemailname : userdata.name;
      }
      if (userdata.groups !== undefined && userdata.groups !== "undefined") {
        bodydata.groups = userdata.groups;
        
        const jsonString = JSON.stringify(bodydata);
        const base64EncodedString = btoa(jsonString);

        const bodyParam = {
          body: {
            json:base64EncodedString
          },
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userdata?.accessToken	
          },
        };
        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          "/addGetUser",
          bodyParam
        );

        
          
        if (response.body?.length > 0 && response.body[0].enable) {
          userdata.uData = response.body[0];
          analyticsWebApp(userdata);
        } else {
          pageload();
        }
      } else {
        pageload();
      }
    } catch (errr) {
      pageload();
    }
  }
  async function analyticsWebApp(userDetails) {

    const jsonString = JSON.stringify({oid: config.aws_org_id,
      eventtype: "AuthenticatedViaCognito",
      id: userDetails.id,
      iid: config.aws_cognito_identity_pool_id,
      email: userDetails.username,
      name: userDetails.name,
      emailid: userDetails.email,
      tenant: userDetails.locale,
      ur_id: userDetails.uData?.ur_id,
      schema: config.schema,});
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/analyticsWebApp",
        bodyParam
      );
      let lang = await languageChange(userDetails, userDetails.uData?.lang);
      userDetails.languagejson = lang?.languagejson;
      dispatch(awsSignIn(userDetails));
      setLogin(false);
      let id = query.get("id");
      if (location.pathname === "/course" && id) {
        history.push(`/course?id=${id}`);
      } else if (location.pathname === "/content" && id) {
        history.push(`/content?id=${id}`);
      } else {
        history.replace({
          pathname: '/home',
          state: { poster: true }
        });
    
      }
    } catch (err) {
    }

  }
  return (
    <motion.div
      className={
        config.aws_org_id === "NKH"
          ? logincss.loginformdivColor
          : logincss.loginformdiv
      }
      style={{
        height: "100vh",
        display: "grid",
        placeItems: "center",
      }}
    >
      {login ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <motion.div
          className={logincss.loignformdiv3}
          style={{
            maxWidth: "750px",
          }}
          role="main"
          aria-label="Login Section"
          initial={{ x: "-100vw", opacity: 1 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{
            delay: 1,
            x: { type: "spring", stiffness: 100 },
            default: { duration: 2 },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={`https://${
                config.DOMAIN
              }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/logo-dark.png`}
              alt="logo"
              height="100px"
              tabIndex={1}
            />
            {config.aws_org_id?.toLowerCase() === "icici" && (
              <img
                src={`https://${
                  config.DOMAIN
                }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/App-icon.png`}
                alt="logo"
                height="100px"
              />
            )}
          </Box>

          <SignInAndSignUpValues.Provider value={SignInAndSignUpProvider}>
            {signIn && <SignIn />}

            {signUp && <SignUp />}
          </SignInAndSignUpValues.Provider>
        </motion.div>
      )}
    </motion.div>
  );
};

export default SignInAndSignUp;
