import styled from "styled-components";

export const Wrapper = styled.div`
  .tableStyle {
    th {
      background-color: ${({ theme }) => theme.color.secondary};
      color: #fff;
    }
    td,
    th {
      /* border: 1px solid black; */
      border-collapse: collapse;
      padding: 5px;
    }
    tr {
      @media screen and (max-width: 500px) {
        display: block;
      }
    }
    /* border: 1px solid black; */
    border-collapse: collapse;
  }
`;
export const StyledTable = styled.table`
  border-collapse: separate;
  border-spacing: 10px 15px;
  width: 100%;
  tr > :first-child {
    text-align: right;
  }
  tr > :first-child > span {
    color: red;
  }
  @media screen and (max-width: 500px){
    tr {
      display: grid;
      margin: 1rem 0rem;
    }
    tr > :first-child {
      text-align: left;
    }
    tr > td > div {
      display: grid;
      margin: 1rem 0rem;
    }
  }
`;
