import React, { useState, useEffect } from "react";
import "../../components/Gizmos/GizmosCSS.css";
import styled, { keyframes, css } from "styled-components";

const cambioColor = keyframes`
  0% {
    background-position-x: 99%;
  }

  100% {
    background-position-x: 0%;
  }
`;

const StyledSteps = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 9%);
  grid-column-gap: 2px;
  margin-top: 2rem;
  overflow: hidden;
`;

const Step = styled.div`
  border-radius: 1px;
  background-color: #319f002e;
  height: 12px;
  background-size: 200% 100%;

  ${({ colored }) =>
    colored &&
    css`
      background-image: linear-gradient(to right, #319f00 50%, #319f002e 50%);
      animation: ${cambioColor} 1s linear;
    `}
`;

const LevelProgressBar = ({ curLevel, levelsArray }) => {
  const totalSteps= 10;

 
  const [steps, setSteps] = useState(
    Array.from({ length: totalSteps }, () => false)
  );

  useEffect(() => {
   
    let value = Math.round(curLevel / (curLevel+1) * 10);

    const progressSteps = steps.map(
      (level, index) => index < value);
    setSteps(progressSteps);
  }, [curLevel]);

  return (
    <StyledSteps columns={totalSteps}>
      {steps.map((isColored, index) => (
        <Step key={index} colored={isColored} />
      ))}
    </StyledSteps>
  );
};

export default LevelProgressBar;
