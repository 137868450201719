import React, { useEffect, useState } from "react";
import "./FormativeQuiz.scss";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
// import Button from "@material-ui/core/Button";
import config from "../../aws-exports";
import { useQueryParams } from "../../Hooks/useQueryParams";
import styled from "styled-components";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

const StyledScoreContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .show-score-header {
    box-shadow: ${({ primary }) => (primary ? "green" : "red")} 0px 0px 0px 5px;
    width: 150px;
    height: 150px;
    color: ${({ primary }) => (primary ? "green" : "red")};
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const FormativeQuiz = ({
  question,
  setQuestion,
  loading,
  setLoading,
  startQuiz,
  courseDetails,
  qpage,
  ...rest
}) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [unAnsweredPopup, setUnAnsweredPopup] = useState(false);
  const [enableNext, setEnableNext] = useState(false);
  const userDetails = useSelector(authData);

  // const [showScore, setShowScore] = useState(false);
  // const [score, setScore] = useState(0);
  const query = useQueryParams();

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: 150,
    width: 350,
    bgcolor: "background.paper",
    // border: '2px solid #000',
    borderRadius: "8px",
    boxShadow: 24,
    p: 4,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
  };

  const handleAnswerClick = (idx) => {
    const currentAnsquestion = [...question];
    currentAnsquestion[currentQuestion].iopts.map((item) => {
      item.youAnswered = false;
      return item;
    });

    currentAnsquestion[currentQuestion].iopts[idx].youAnswered = true;

    if (currentAnsquestion[currentQuestion].iopts[idx].youAnswered === true) {
      delete currentAnsquestion[currentQuestion].skip;
    }
    currentAnsquestion[currentQuestion].youAnswered = true;
    if (currentAnsquestion[currentQuestion].iopts[idx].correct) {
      currentAnsquestion[currentQuestion].answeredOption = true;
    } else {
      currentAnsquestion[currentQuestion].answeredOption = false;
    }

    setQuestion(currentAnsquestion);
  };

  const handleNextQuestion = () => {
    setQuestion((prevQues) => {
      let yourAns = prevQues[currentQuestion].youAnswered;
      if (yourAns === undefined || yourAns == null) {
        prevQues[currentQuestion].skip = true;
      }
      return prevQues;
    });
    if (currentQuestion !== question?.length - 1)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
  };
  const handlePreviousQuestion = () => {
    if (currentQuestion !== 0)
      setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion - 1);
  };

  const handleCheckAnswer = () => {
    const questions = [...question];
    questions[currentQuestion].checkAnswer = true;
    for (let item of question[currentQuestion].iopts) {
      if (item.youAnswered) {
        questions[currentQuestion].next = true;
        if (!item.correct) {
          item.wrong = true;
        }
      }
    }

    setQuestion(questions);
  };

  const gotoQuestion = (idx) => {
    setCurrentQuestion(idx);
  };

  const handleSubmitQuiz = (question) => {
    if (
      question.filter((e) => e.youAnswered === undefined || false).length > 0
    ) {
      //newly added
      setUnAnsweredPopup(true);
    } else {
      let score = 0;
      score = question.filter((item) => item.answeredOption).length;
      // for (let item of question) {
      //   let options = item.iopts;
      //   for (let option of options) {
      //     if (option.youAnswered === option.correct) {
      //       score += 1;
      //       break;
      //     } else {
      //       continue;
      //     }
      //   }
      // }
      rest.setScore(score);
      if (rest.curObject.op !== 2) {
        rest.syncUserProgresswhilequiz(score);
      }
      setCurrentQuestion(0);
      rest.setShowScore(true);
      rest.setMarkComplete(true);

      //   if (scoreAchieved(score)) {
      //     setCurrentQuestion(0);
      //     rest.setShowScore(true);
      //     rest.setMarkComplete(true);
      //     /* if (rest.curObject.op !== 2) {
      //       rest.syncUserProgresswhilequiz(score);
      //     } */
      //     // rest.nextObject();
      //   } else {
      //     /*  if (rest.curObject.op !== 2) {
      //       handleScoreSubmit(score, 1);
      //     } */
      //     rest.setMarkComplete(true);
      //    // rest.setShowScore(true);
      //     setCurrentQuestion(0);
      //   }
      rest.setTimerPalyPause("pause");
    }
  };

  async function handleScoreSubmit(score, op) {
    //alert(JSON.stringify(scorejson))
    setLoading(true);
    const jsonString = JSON.stringify({
      emailid: userDetails.email,
      pid: courseDetails.cid,
      key: courseDetails.tid,
      email: userDetails.username,
      oid: config.aws_org_id,
      uname: userDetails.name,
      tenant: userDetails.locale,
      op: op,
      score,

      obj_id: rest.curObject.oid,
      obtime: (new Date().getTime() - rest.startTime) / 1000,
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
    });
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json:base64EncodedString
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken	
      }
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/syncUserDataWeb",
        bodyParam
      ); 
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  }

  /*   {"oid":"SPANDANA","eid":"0433cc60-7b0a-4900-aba0-cd63aaf6a551","tenant":"TeamOne","topicid":"ED00063",
  "urid":1,"schema":"spandana"} */

  const scoreAchieved = (score) => {
    if ((score / question.length) * 100 >= parseInt(rest.cutOff)) return true;
    return false;
  };

  const handleReTry = () => {
    // rest.setStartQuiz(false);
    const questions = [...question];
    const resetArray = [];
    questions.forEach((element) => {
      const obj = { ...element };
      delete obj.answeredOption;
      delete obj.youAnswered;
      obj.iopts.forEach((item) => {
        // const obj2 = { ...item };
        delete item.youAnswered;
      });
      resetArray.push(obj);
    });
    setQuestion(resetArray);
    setCurrentQuestion(0);
    rest.setScore(0);
    rest.setShowScore(false);
  };

  return (
    <React.Fragment>
      {!loading && startQuiz && !rest.showScore && (
        <div className="quiz_container">
          <div className="quiz_wrapper">
            <div className="quiz_wrapper_ques_ans">
              <div className="quiz_wrapper_ques_ans--question">
                <div>
                  Q {currentQuestion + 1}/{question?.length}
                </div>
                <div>
                  <p>{question[currentQuestion]?.istem}</p>
                </div>
              </div>
              {question[currentQuestion].imageurl && (
                <div className="quiz_wrapper_ques_ans_img-container">
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${query.get(
                      "id"
                    )}/${question[currentQuestion].imageurl}`}
                    alt=""
                    style={{ width: "30rem" }}
                  />
                </div>
              )}
              <div className="quiz_wrapper_ques_ans--answers">
                {question[currentQuestion]?.iopts.map((item, index) => (
                  <>
                    <div
                      key={index}
                      style={{ cursor: "pointer" }}
                      className={`ans-btn ${
                        item.youAnswered && !item.wrong ? "selected-answer" : ""
                      }
                    ${item.youAnswered && item.wrong ? "error" : ""}
                    ${
                      question[currentQuestion].checkAnswer && item.correct
                        ? "ans_green"
                        : ""
                    }
                    `}
                      onClick={() => handleAnswerClick(index)}
                    >
                      {item.content}
                    </div>

                    {question[currentQuestion].checkAnswer &&
                      item.youAnswered &&
                      item?.feedback !== undefined &&
                      item?.feedback !== "" && (
                        <span style={{ display: "flex", alignItems: "center" }}>
                          <div>{`${userDetails?.languagejson?.Course_View
                            ?.Feedback || "Feedback"} : `}</div>
                          <div className="feedback_answers">{` ${item.feedback}`}</div>
                        </span>
                      )}
                  </>
                ))}
              </div>
            </div>

            <div className="quiz_wrapper_grow" />

            <div className="quiz_wrapper--actions">
              <button
                className="btn btn-outline primary"
                onClick={handlePreviousQuestion}
                disabled={currentQuestion === 0 ? true : false}
              >
                {`${userDetails?.languagejson?.Course_View?.Previous ||
                  "Previous"}`}
              </button>

              {currentQuestion !== question?.length && (
                <button
                  className="btn btn-outline primary"
                  onClick={handleCheckAnswer}
                  disabled={
                    question[currentQuestion].youAnswered ? false : true
                  }
                >
                  {`${userDetails?.languagejson?.Course_View?.Check_Answer ||
                    "Check Answer"}`}
                </button>
              )}

              <div className="left-actions">
                {currentQuestion !== question?.length - 1 &&
                  question[currentQuestion].youAnswered &&
                  question[currentQuestion].checkAnswer && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestion}
                      disabled={
                        currentQuestion === question?.length - 1 ? true : false
                      }
                    >
                      {`${userDetails?.languagejson?.Course_View?.Next ||
                        "Next"}`}
                    </button>
                  )}
                {/* {currentQuestion !== question?.length - 1 &&
                  !question[currentQuestion].next &&
                  !question[currentQuestion].youAnswered && (
                    <button
                      className="btn btn-contained primary"
                      onClick={handleNextQuestion}
                      disabled={
                        currentQuestion === question?.length - 1 ? true : false
                      }
                    >
                      Skip
                    </button>
                  )} */}

                {/*    {currentQuestion === question?.length - 1 && (
                  <button
                    className="btn btn-outline primary"
                    onClick={handleCheckAnswer}
                    disabled={
                      question[currentQuestion].youAnswered ? false : true
                    }
                  >
                    Check Answer1
                  </button>
                )} */}
                {currentQuestion === question?.length - 1 &&
                  question[currentQuestion].youAnswered &&
                  question[currentQuestion].checkAnswer && (
                    <button
                      className="btn btn-contained primary"
                      onClick={() => {
                        if (qpage !== undefined && qpage === "object") {
                          rest.setStartQuiz(false);
                          setCurrentQuestion(0);
                        } else {
                          handleSubmitQuiz(question);
                        }
                      }}
                    >
                      {`${userDetails?.languagejson?.Course_View?.Submit ||
                        "Submit"}`}
                    </button>
                  )}
              </div>
            </div>
          </div>

          <hr className="divider" />

          <div className="quiz_container--footer">
            {question?.map((item, index) => (
              <button
                key={item.iid}
                style={{ cursor: "pointer" }}
                className={`btn contained ${item.skip ? "border" : ""} ${
                  item.youAnswered ? "green" : ""
                }`}
                onClick={() => gotoQuestion(index)}
              >
                {index + 1}
              </button>
            ))}
          </div>
        </div>
      )}

      {rest.showScore && (
        <StyledScoreContainer primary={scoreAchieved(rest.score)}>
          <div style={{ margin: "1rem 0" }}>
            <button className="button-3" onClick={() => rest.nextObject()}>
              {`${userDetails?.languagejson?.Course_View?.Next || "Next"}`}
            </button>
          </div>
        </StyledScoreContainer>
      )}

      <Modal open={unAnsweredPopup}>
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            {`${userDetails?.languagejson?.Course_View?.un_answered ||
              "You have some unanswered questions"}`}
          </Typography>
          <button
            className="okButton"
            onClick={() => setUnAnsweredPopup(false)}
          >
            {`${userDetails?.languagejson?.Course_View?.ok || "Ok"}`}
          </button>
        </Box>
      </Modal>
      {/* <pre>{JSON.stringify(question, null, 2)}</pre> */}
    </React.Fragment>
  );
};

export default FormativeQuiz;
