import React from 'react';
import { makeStyles } from '@mui/styles';
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import SearchIcon from "@material-ui/icons/Search";
import { Button } from '@material-ui/core';
import { useHistory } from "react-router-dom";

const SearchHeader = () => {
    const userDetails = useSelector(authData);
    const history = useHistory();
    const useStyles = makeStyles((themeStyle) => ({
        root: {
            display: "flex",
            gap: "2rem",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: userDetails.themeDark === "dark" ? "0px 10px 11px -9px #0000004d" : "0px 10px 11px -9px rgba(102, 102, 102, 0.3)",
            padding: "1rem",
            margin: "-2rem -6rem 2rem -6rem",
            backgroundColor: userDetails.themeDark === "dark" ? "#282d37!important" : "#fff!important",
            zIndex: "1",
            position: "relative",
            "@media (max-width: 600px)": {
                margin: "-1rem -3rem 1rem -3rem",
                display: "grid",
                gap: "1rem",
            },
            "& > button": {
                background: userDetails.themeDark === "dark" ? "transperent!important" : "white!important",
                textTransform: "capitalize",
                fontSize: "2rem",
                fontWeight: "600",
                color: "var(--color-primary)",
            },
        },
        searchSection: {
            width: "40%",
            position: "relative",
            "@media (max-width: 600px)": {
                width: "100%",
            },
        },
        searchInput: {
            fontSize: "1.8rem",
            backgroundColor: "#fff!important",
            color: userDetails.themeDark === "dark" ? "#fff!important" : "#000!important",
            borderRadius: "3rem",
            border: "1px solid gray",
            transition: "all 0.2s",
            padding: "10px 10px 10px 50px",
            width: "100%",
        },
    }));
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <Button onClick={() => { history.push("/social-learning"); }}>Social Learning</Button>
            <div className={classes.searchSection}>
                <button
                    className="search__button"
                    title="Search"
                    style={{ position: "absolute", top: "0.8rem", left: "1rem" }}
                // onClick={() => { }}
                >
                    <SearchIcon
                        style={{
                            fontSize: "3rem",
                            color: "black",
                        }}
                    />
                </button>
                <input
                    type="search"
                    className={classes.searchInput}
                    placeholder="Search"
                // value={search}
                // onChange={(e) => { }}
                // onKeyPress={(event) => { }}
                />
            </div>
        </div>
    );
};

export default SearchHeader;