import { Container } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import "./Layout.scss";
import { motion } from "framer-motion";

import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import SideNavBar from '../SideNavBar/SideNavBar';
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);


const mainVarients = {
  hidden: {
    x: "100vw",
  },
  visible: {
    x: 0,
    transition: { delay: 0.5, duration: 0.5, type: "spring", stiffness: 100 },
  },
  exit: {
    x: "-100vw",
    transition: { delay: 1, duration: 1, ease: "easeInOut" },
  },
};

function Layout({ children, setTheme, theme }) {

  //const theme = useTheme();
  const [opacity, setOpacity] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((theme) => ({
    SideNavBarStyleDarkmode: {
      display: "contents!important",
      "@media (max-width: 770px)": {
        display: "none!important",
      },
      "& > div" : {
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "#282D37 !important"
            : theme.palette.background.paper,
        color: userDetails.themeDark === "dark" ? "white !important" : "black",
        scrollbarWidth: "thin",
        scrollbarColor: "transparent transparent",
        "& > ul" : {
          // width: "100%!important",
        },
      },
      "& > div::-webkit-scrollbar" : {
        width: "8px",
      },
      "& > div::-webkit-scrollbar-thumb" : {
        backgroundColor: "transparent",
      }
    },
    SideNavBarStyle: {
      "@media (max-width: 770px)": {
        display: "none!important",
      },
    },
    HeaderStyle: {
      boxShadow: "none!important",
      "@media (min-width: 770px)": {
        width: "100vw!important",
      },      
    },
    SideNavBarRightContent: {
      flexGrow: 1, 
      padding: 0,
      width:"95%",
      margin: 0,
      marginLeft: "6rem",
      "@media (max-width: 770px)": {
        marginLeft: 0,
      },
    },
  }));
  const classes = useStyles();

  const handleDrawerOpen = () => {
    setOpacity(true);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpacity(false);
    setOpen(false);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} className={classes.HeaderStyle}>
        <Toolbar style={{padding: "0"}}>
          {/* <IconButton
            className={classes.SideNavBarStyle}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Typography variant="h6" noWrap component="div" style={{width: "100%"}}>
            <Header setTheme={setTheme} theme={theme}/>
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open} className={classes.SideNavBarStyleDarkmode}>
        {/* <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader> */}
        <Divider />
        <List>
          <SideNavBar handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}/>
        </List>
      </Drawer>
      <Box component="main" className={classes.SideNavBarRightContent} 
      // style={{backgroundColor:`${opacity ? "#00000080" : ""}`,inset:`${opacity ? 0 : ""}` }}
      >
        <DrawerHeader />
        <div>
          <div className="Layoutmain">
             <div
               className={`mycontainer ${
                 userDetails.themeDark === "light" ? "lightMode" : "darkMode"
              }`}
            >
              <motion.main
                 className="main"
                 variants={mainVarients}
                 // initial="hidden"
                 // animate="visible"
                 exit="exit"
               >
                 <Container maxWidth="xl">
                   {/* <Container maxWidth="xl" className="layout_container"> */}
                   {children}
                   {/* </Container> */}
                 </Container>
               </motion.main>
             </div>
             <Footer />
           </div>
        </div>
      </Box>
    </Box>
  );
}

export default Layout;
