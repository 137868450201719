import { styled } from "@mui/material/styles";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { authData } from "../../redux/auth/authSlice";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Skeleton from "@material-ui/lab/Skeleton";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const BorderLinearProgressEmpty = styled(LinearProgress)(() => ({
  height: 5,
  borderRadius: 1,
  "& > span": {},
  [`&.${linearProgressClasses.colorPrimary}`]: {
    borderRadius: 5,
  },
}));

function MyCourseTrackerWidget(props) {
  const completedCount = props.completedCount;
  const totalCourseCount = props.totalCourseCount;

  const userDetails = useSelector(authData);

  const useStyles = makeStyles((theme) => ({
    progressBar: {
      width: "100%",
      justifyContent: "space-between",
      display: "flex",
      "& > p:nth-child(1)": {
        width: "100%",
        fontWeight: "700",
        overflowWrap: "break-word",
      },
      "& > p:nth-child(3)": {
        width: "4rem",
        fontWeight: "700",
      },
    },
    progressBarContainer: {
      width: "100%",
      "@media (min-width: 400px) and (max-width: 600px)": {
        width: "99%",
      },
    },
    outerDiv: {
      width: "fit-content",
      padding: "0rem",
      borderRadius: "5px",
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#282D37 !important"
          : theme.palette.background.paper,
      border:
        userDetails.themeDark === "dark"
          ? "1px solid transparent !important"
          : "1px solid #83838380",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      marginLeft: "auto",
      minHeight: "6.3rem",
    },
    innerDiv: {
      display: "flex",
      alignItems: "center",
      gap: "1rem",
      padding: "6px",
    },
    iconBlock: {
      width: "65px",
      background:
        "linear-gradient(90deg, rgba(181,214,238,1) 0%, rgba(137,176,205,1) 51%);",
      clipPath: "polygon(0 0, 0 100%, 75% 100%, 100% 50%, 75% 0)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& > svg": {
        marginRight: "0.5rem",
      },
    },
    courseCountStyle: {
      display: "flex",
      gap: "1rem",
      alignItems: "center",
      "& > span": {
        color: "var(--color-primary)",
        fontWeight: "bold",
      },
    },
  }));
  const classes = useStyles();
  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      fontSize: "1.2rem",
    },
  }));

  return (
    <>
      <BootstrapTooltip
        title={`${userDetails?.languagejson?.Home_Page?.Learning_Analytics ||
          "Learning Analytics"}`}
      >
        <div className={classes.outerDiv}>
          <div className={classes.iconBlock}>
            <svg width="30" height="30" viewBox="0 0 36 36">
              <defs>
                <clipPath id="clip-Analytics">
                  <rect width="36" height="36" />
                </clipPath>
              </defs>
              <g id="Analytics" clip-path="url(#clip-Analytics)">
                <g
                  id="Group_1906"
                  data-name="Group 1906"
                  transform="translate(3.125 2.245)"
                >
                  <path
                    id="Path_1091"
                    data-name="Path 1091"
                    d="M15.755,0a.923.923,0,0,0-.923.923V4.616a.923.923,0,0,0,.923.923A10.216,10.216,0,1,1,5.539,15.755a.923.923,0,0,0-.923-.923H.923A.923.923,0,0,0,0,15.755,15.755,15.755,0,1,0,15.755,0Z"
                    fill="#f7fcff"
                  />
                  <path
                    id="Path_1092"
                    data-name="Path 1092"
                    d="M15.755,63.693a.923.923,0,0,0-.923.923v3.693a.923.923,0,0,0,.923.923,2.831,2.831,0,1,1-2.831,2.831A.923.923,0,0,0,12,71.139H8.308a.923.923,0,0,0-.923.923,8.37,8.37,0,1,0,8.37-8.37ZM4.616,61.846h7.447a.923.923,0,1,0,0-1.846H4.616a.923.923,0,1,0,0,1.846Zm-3.693,0A.923.923,0,1,0,0,60.923.923.923,0,0,0,.923,61.846Zm12.062,2.769a.923.923,0,0,0-.923-.923H.923a.923.923,0,0,0,0,1.846H12.062A.923.923,0,0,0,12.985,64.616Z"
                    transform="translate(0 -56.307)"
                    fill="#f7fcff"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className={classes.innerDiv}>
            {props.loading ? (
              <div>
                <Skeleton
                  variant="text"
                  style={{ fontSize: "2rem", width: "20rem" }}
                />
                <div style={{ gap: "8px", display: "flex", marginTop: "6px" }}>
                  <div
                    className={classes.progressBar}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <div className={classes.progressBarContainer}>
                      <Skeleton variant="rect" height={5} width="100%" />
                    </div>
                    <Skeleton
                      variant="text"
                      style={{ fontSize: "12px", width: "4rem" }}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <p className={classes.courseCountStyle}>
                  <span>{`${completedCount}/${totalCourseCount}`}</span>
                  {`${userDetails?.languagejson?.LearningAnalytics
                    ?.courses_completed_till_now ||
                    "courses completed till now"}`}
                </p>
                <div style={{ gap: "8px", display: "flex", marginTop: "6px" }}>
                  <div
                    className={classes.progressBar}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <div className={classes.progressBarContainer}>
                      <BorderLinearProgressEmpty
                        variant="determinate"
                        value={
                          totalCourseCount !== 0
                            ? parseFloat(
                                (
                                  (completedCount / totalCourseCount) *
                                  100
                                ).toFixed(1)
                              )
                            : 0
                        }
                      />
                    </div>
                    <p style={{ fontSize: "12px" }}>{`${
                      totalCourseCount !== 0
                        ? parseFloat(
                            ((completedCount / totalCourseCount) * 100).toFixed(
                              1
                            )
                          )
                        : 0
                    }%`}</p>
                  </div>
                </div>
              </div>
            )}
            <ArrowForwardIosIcon
              style={{ fontSize: "2rem", color: "var(--color-primary)" }}
            />
          </div>
        </div>
      </BootstrapTooltip>
    </>
  );
}

export default MyCourseTrackerWidget;
