import { Box } from "@material-ui/core"
import React from "react"
import {
  displayCenter,
  inputBoxStyle,
  inputIconStyle,
} from "../../../components/SignInAndSignUp/SignInAndSignUp"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"

import { CircularProgress, Typography } from "@material-ui/core"
import CloseIcon from "@material-ui/icons/Close";

// import ReactCodeInput from "react-verification-code-input";
import Modal from '@mui/material/Modal';
import { validateResetPassword } from "./ChangePasswordValidation"
import { useState } from "react"
import { useForm } from "../../../Hooks/useForm"
import swal from "sweetalert";
import cognitoValidation from "./CognitoValidationRules"
import { Theme } from "../../../aws-exports"
import { Auth } from "aws-amplify"
import { useHistory, useLocation } from "react-router-dom"
import { CustomButton } from "../../../components/CustomButton/CustomButton"
import CustomInput from "../../../components/CustomInput/CustomInput"
import { HeadingPrimary } from "../../../components/Styles/Heading"
import { useSelector } from "react-redux";
import { authData } from "../../../redux/auth/authSlice";


    const ConfirmPassword = ({open,handleClose,handleOpen,userId}) => {
    //const [state, setState] = useState('');
    const {
        values,
        handleChange,
        handleClickShowPassword,
        handleMouseDownPassword,
      } = useForm({ showPassword: false })
      const [errors, setErrors] = useState({})
      const history = useHistory()
      const { state } = useLocation()
      const [resetPasswordLoader, setResetPasswordLoader] = useState(false)
      const userDetails = useSelector(authData);
      const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 569,
        bgcolor: 'background.paper',
        border: '1px solid #000',
        boxShadow: 24,
        backgroundColor: userDetails.themeDark === "dark"? "#272C38 !important" : "#fff",
        color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
        p: 4,
        borderRadius:"6px",
        "@media (max-width: 600px)": {
          width: "90%!important",
        },
        "& > button": {
          color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
        },
        "& > form > div > div > div": {
          color: userDetails.themeDark === "dark"? "#fff !important" : "#000",
          boxShadow: "none!important",
        },
      };
    const resetPasswordFC = async (event) => {
        event.preventDefault()
    
        if (Object.keys(validateResetPassword(values)).length !== 0) {
          setErrors(validateResetPassword(values))
          return
        } else {
          setResetPasswordLoader(true) 
          try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.changePassword(
                user,
                values.currentPassword,
                values.confirmPassword
              );
              setResetPasswordLoader(false) 
            swal({
              title: "Success",
              text: "Password Changed Successfully",
              icon: "success",
              confirmButtonColor: Theme.color.primary,
              confirmButtonText: "Ok",

            }).then((willDelete) => {
              if (willDelete) {
                handleClose();
              
              }
            })
          } catch (error) {
            console.error(error)
            setResetPasswordLoader(false) 
            setErrors(cognitoValidation(error))
          }
        }
      }
  

    return (
        <>
           <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={modalStyle}>
        <button
          onClick={handleClose}
          style={{
            border: "none",
            background: "transparent",
            cursor: "pointer",
            float:"right"
          }}
        >
          <CloseIcon fontSize="large" />
        </button>
        <Box style={{ textAlign: "center" }}>
          <HeadingPrimary>Change Password</HeadingPrimary>
        </Box>
        
        <form
          onSubmit={resetPasswordFC}
          noValidate
          autoComplete="off"
          style={{width:"100%",display: "flex", flexDirection: "column", alignItems: "center"}}
        >
          <Box sx={inputBoxStyle}>
            <CustomInput
              placeholder="Current Password"
              name="currentPassword"
              type="password"
              inputValues={values}
              value={values.currentPassword || ""}
              error={errors?.currentPassword}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
              onChange={handleChange("currentPassword")}
            >
              <LockOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box sx={inputBoxStyle}>
            <CustomInput
              placeholder="New Password"
              type="password"
              name="newPassword"
              inputValues={values}
              value={values.newPassword || ""}
              error={errors?.newPassword}
              onChange={handleChange("newPassword")}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
            >
              <LockOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>
          <Box sx={inputBoxStyle}>
            <CustomInput
              placeholder="Confirm Password"
              type="password"
              name="confirmPassword"
              inputValues={values}
              value={values.confirmPassword || ""}
              error={errors?.confirmPassword}
              onChange={handleChange("confirmPassword")}
              handleClickShowPassword={handleClickShowPassword}
              handleMouseDownPassword={handleMouseDownPassword}
            >
              <LockOutlinedIcon style={inputIconStyle} />
            </CustomInput>
          </Box>

          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="caption"
              display="block"
              style={{
                maxWidth: "450px",
                fontSize: "14px",
                textAlign: "center",
                fontFamily: "myfontregular",
              }}
              gutterBottom
            >
              *Password should be at least 8 characters, contain at least one
              number & one special character.
            </Typography>
          </Box>

          <Box style={{ textAlign: "center" }}>
            {resetPasswordLoader ? (
              <CustomButton disabled loading="true">
                <CircularProgress color="inherit" size={20} />
              </CustomButton>
            ) : (
              <CustomButton primary type="submit">
                Submit
              </CustomButton>
            )}
            <CustomButton
              type="button"
              onClick={() => {
                setErrors({});
                handleClose();
              }}
              style={{backgroundColor: "white"}}
            >
              Cancel
            </CustomButton>
          </Box>
        </form>
      
        </Box>
      </Modal>
        </>
    )
}

export default ConfirmPassword;