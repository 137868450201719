import React from 'react';
import {Typography }from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const renderLoader = param => {
    switch(param) {
        case 'nuggetobject':
          return (
              <>
               <Typography component='h1' variant="h2" style={{Width:'80%'}} >
                    <Skeleton animation="wave"  style={{borderRadius:'0px 12px 12px 0px',}} /> 
                    </Typography>
                {["h3","h3","h3","h3"].map((variant) =>(
                    <Typography component='h1' variant={variant} style={{Width:'75%',padding:'0 50px',display:'inline-flex'}} >
                    <Skeleton animation="wave" variant="circle" width={30} height={30} /> 
                    <Skeleton animation="wave"  style={{minWidth:'200px',marginLeft:'10px'}} /> 
                    </Typography>
                ))}
                
              </>
          );
        case 'audio':
          return <div className='bgimage audioiconDardk'></div>;
        case 'pdf':
          return <div className='bgimage pdficonDardk'></div>;
        case 'interactivity':
          return <div className='bgimage intericonDardk'></div>;
        default:
          return param;
      }
  };
const NuggetsLoader = ({type}) => {

  
    return (
        <>
        {renderLoader(type)}
        </>
        );
    };
    
export default NuggetsLoader;